import { jwtDecode } from "jwt-decode";
import fernet from "fernet";

export default class Claims {
  static getAccessToken() {
    try {
      const idToken = localStorage.getItem("idToken");
      if (!idToken) return undefined;
      const decoded = jwtDecode(idToken);
      return decoded.token ?? "";
    } catch (error) {
      console.error("Error: ", error);
      return undefined;
    }
  }

  static getUsername() {
    try {
      const decryptedToken = this.decryptAccessToken();
      return decryptedToken.userId ?? "";
    } catch (error) {
      console.error("Error: ", error);
      return undefined;
    }
  }

  static getUserRole() {
    try {
      const decryptedToken = this.decryptAccessToken();
      return decryptedToken.userRole ?? "";
    } catch (error) {
      console.error("Error: ", error);
      return undefined;
    }
  }

  static decryptAccessToken() {
    try {
      const idToken = localStorage.getItem("idToken");
      if (!idToken) return undefined;
      const decoded = jwtDecode(idToken);
      const secret = new fernet.Secret(process.env.REACT_APP_ACCESS_TOKEN_SYM_KEY);
      const token = new fernet.Token({
        secret: secret,
        token: decoded.token,
        ttl: 0,
      });
      return JSON.parse(token.decode());
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }
}
