import { IonCol, IonFab, IonFabButton, IonRow } from "@ionic/react";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { Chart } from "react-google-charts";
import AP from "../assets/img/Apicon.png";
import measureIcon from "../assets/img/Measure-icon-img-white.png";
import addCPEIcon from "../assets/img/add-cpe-icon-img.png";
import calcmob from "../assets/img/align-icon.png";
import arch from "../assets/img/arch.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import Heat from "../assets/img/heaticon.png";
import heatact from "../assets/img/heaticonactive.png";
import SearchIcon from "../assets/img/search_icon.png";
import geo from "../assets/img/target-icon.png";
import store from "../store";
import "./measure.css";
import Claims from "../service/claims";

const INITIAL_STATE = {
  mesh: "0",
  mesh1: "0",
  distance: 0.0,
  bar: true,
  val: "0",
};

let elevator = new google.maps.ElevationService();

export default class Measure extends React.Component {
  state: any = {};
  props: any = {};
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE, mesh3: this.props.temp };
  }
  componentDidMount() {
    console.log(this.props);
    console.log(this.reduxStore);
  }

  change = () => {
    this.setState({
      mesh3: this.props.temp,
    });
  };
  switch = () => {
    this.setState((prevValues: any) => ({
      bar: !prevValues.bar,
    }));
  };
  click = (val: any) => {
    this.setState({
      val: val.target.id,
    });
  };

  setCurrentPosition = () => {
    this.props.setCurrentPosition();
  };

  graph = () => {
    elevator.getElevationAlongPath(
      {
        path: this.props.measure_pair,
        samples: 500,
      },
      this.plot_elevation
    );
  };
  plot_elevation = (elevations: any, status: any) => {
    let chart_data: any[][] = [];
    chart_data.push(["x", "Elevation", { type: "string", role: "tooltip" }]);
    elevations?.map((data: any, index: any) => {
      let set_values = [];
      set_values.push(index);
      set_values.push(parseFloat(data.elevation.toFixed(1)));
      set_values.push("Elevation: " + data.elevation.toFixed(1) + " m");
      chart_data.push(set_values);
    });
    this.setState({
      chartVal: chart_data,
    });
  };

  getPropsTempHtml = () => {
    if (this.props.temp === 0)
      return (
        <div className="col-12 measuredot">
          <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#f59343" }}></i>
          <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#cccccc" }}></i>
          <i className="fas fa-circle" style={{ fontSize: "6px", color: "#cccccc" }}></i>
        </div>
      );
    else if (this.props.temp === 1)
      return (
        <div className="col-12 measuredot">
          <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#fdeada" }}></i>
          <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#f59343" }}></i>
          <i className="fas fa-circle" style={{ fontSize: "6px", color: "#cccccc" }}></i>
        </div>
      );
    else if (this.props.temp === 2)
      if (this.reduxStore["tabType"] == "outdoor") {
        return (
          <div className="col-12 measuredot" style={{ bottom: "207px" }}>
            <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#fdeada" }}></i>
            <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#fdeada" }}></i>
            <i className="fas fa-circle" style={{ fontSize: "6px", color: "#f59343" }}></i>
          </div>
        );
      } else {
        return (
          <div className="col-12 measuredot">
            <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#fdeada" }}></i>
            <i className="fas fa-circle p-r-5" style={{ fontSize: "6px", color: "#fdeada" }}></i>
            <i className="fas fa-circle" style={{ fontSize: "6px", color: "#f59343" }}></i>
          </div>
        );
      }
  };

  render() {
    return (
      <div>
        {isBrowser && (
          <div className="webonly">
            <div className="row">
              <div className="col-md-9">
                <div className="card searchcard">
                  <div className="apcardbody">
                    <div className="row">
                      <div className="col-md-3">
                        <img alt="back-icon" className="backicon" src={BackIcon} onClick={this.props.back} />
                        <button type="button" className="backbutton"></button>
                      </div>
                      <div className="col-md-6" style={{ padding: "13px 0", marginLeft: "-5px" }}>
                        <span className="apheader">MEASURING</span>
                      </div>
                    </div>
                    <div className="row" style={{ padding: "0px 35px" }}>
                      <div className="col-md-12 apborder">
                        <span className="apnote">Measure distances between points</span>
                      </div>
                    </div>

                    <div>
                      <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                        <span className="measurecontent" style={this.props.temp === 0 ? { color: "#f59343" } : {}}>
                          1. Select first CBSD
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                        <span className="measurecontent" style={this.props.temp === 1 ? { color: "#f59343" } : {}}>
                          2. Select Second CBSD
                        </span>
                      </div>
                    </div>

                    <div>
                      <div className="" style={{ padding: "0px 35px", position: "fixed", bottom: "20px" }}>
                        <div className="row justify-content-center">
                          <div>
                            <span style={{ color: "#8F9FAC" }}> Distance:</span>
                          </div>
                        </div>
                        <div className="row measurecard-web" style={{ marginTop: "15px" }}>
                          <div className="card-body text-center measure-text justify-col-center">
                            {(() => {
                              if ([0, 1].includes(this.props.temp)) return <span>-</span>;
                              else if (this.props.temp === 2)
                                return (
                                  <span>
                                    &nbsp;
                                    <span style={{ color: "#f59343", fontWeight: 500, fontSize: "20px" }}>{this.props.distance} m</span>
                                  </span>
                                );
                              else
                                return (
                                  <span>
                                    &nbsp;<span style={{ color: "#f59343", fontWeight: 500, fontSize: "20px" }}></span>
                                  </span>
                                );
                            })()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="col-md-12 justify-content-flex-end">
                  <div className="heaticon text-center" style={{ display: "none" }}>
                    <img alt="heat" className="heat" src={Heat} />
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div className="geoicon text-center">
                    <img alt="current-position" style={{ padding: "5px" }} src={geo} onClick={() => this.setCurrentPosition()} />
                  </div>
                </div>
              </div>
            </div>
            {this.props.temp === 2 && this.state.chartVal ? (
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-8">
                  <div className="card elevationcard">
                    <div className="apcardbody" style={{ padding: "5px" }}>
                      <div>
                        {this.state.chartVal && window.google ? (
                          <Chart
                            width={"900px"}
                            height={"200px"}
                            chartType="ColumnChart"
                            loader={<div>Loading Chart</div>}
                            data={this.state.chartVal}
                            options={{
                              title: "Elevation Profile",
                              vAxis: {
                                title: "Elevation(m)",
                              },
                              hAxis: {
                                textPosition: "none",
                              },
                              legend: "none",
                            }}
                            rootProps={{ "data-testid": "1" }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            ) : null}
          </div>
        )}
        {isMobile && (
          <div className="mobonly">
            {(() => {
              if ([0, 1].includes(this.props.temp))
                return (
                  <div style={{ position: "fixed", bottom: "120px", right: "0px", filter: "contrast(0.9)" }}>
                    <IonFab horizontal="end" style={{ position: "relative", display: "none" }}>
                      {this.state.heatMap ? (
                        <IonFabButton className="backg" color="danger" size="small" style={{ marginBottom: "10px" }}>
                          <img alt="heat-mob" className="heat-mob" style={{ padding: "0px", marginTop: "3px" }} src={heatact} />
                        </IonFabButton>
                      ) : (
                        <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                          <img alt="heat-mob" className="heat-mob" style={{ marginTop: "3px", padding: "0px" }} src={Heat} />
                        </IonFabButton>
                      )}
                    </IonFab>
                    <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                      <IonFabButton
                        className="backg"
                        color="light"
                        size="small"
                        style={{ marginBottom: "10px" }}
                        onClick={() => this.setCurrentPosition()}
                      >
                        <img alt="geo-logo" style={{ padding: "5px" }} src={geo} />
                      </IonFabButton>
                    </IonFab>
                    {Claims.getUserRole() == "Installer" ? null : (
                      <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                        <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                          <img alt="calc-icon" className="calcicon" src={calcmob} />
                        </IonFabButton>
                      </IonFab>
                    )}
                    <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                      <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                        <img alt="search-mob" className="search-mob" src={SearchIcon} />
                      </IonFabButton>
                    </IonFab>
                  </div>
                );
              else if (this.props.temp === 2)
                if (this.reduxStore["tabType"] == "outdoor") {
                  return (
                    <div style={{ position: "fixed", bottom: "270px", right: "0px", filter: "contrast(0.9)" }}>
                      <IonFab horizontal="end" style={{ position: "relative", display: "none" }}>
                        {this.state.heatMap ? (
                          <IonFabButton className="backg" color="danger" size="small" style={{ marginBottom: "10px" }}>
                            <img alt="icons-mob" className="heat-mob" style={{ padding: "0px", marginTop: "3px" }} src={heatact} />
                          </IonFabButton>
                        ) : (
                          <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                            <img alt="icons-mob" className="heat-mob" style={{ marginTop: "3px", padding: "0px" }} src={Heat} />
                          </IonFabButton>
                        )}
                      </IonFab>
                      <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                        <IonFabButton
                          className="backg"
                          color="light"
                          size="small"
                          style={{ marginBottom: "10px" }}
                          onClick={() => this.setCurrentPosition()}
                        >
                          <img alt="icons-mob" style={{ padding: "5px" }} src={geo} />
                        </IonFabButton>
                      </IonFab>
                      {Claims.getUserRole() == "Installer" ? null : (
                        <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                          <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                            <img alt="icons-mob" className="calcicon" src={calcmob} />
                          </IonFabButton>
                        </IonFab>
                      )}
                      <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                        <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                          <img alt="icons-mob" className="search-mob" src={SearchIcon} />
                        </IonFabButton>
                      </IonFab>
                    </div>
                  );
                } else {
                  return (
                    <div style={{ position: "fixed", bottom: "120px", right: "0px", filter: "contrast(0.9)" }}>
                      <IonFab horizontal="end" style={{ position: "relative", display: "none" }}>
                        {this.state.heatMap ? (
                          <IonFabButton className="backg" color="danger" size="small" style={{ marginBottom: "10px" }}>
                            <img alt="icons-mob" className="heat-mob" style={{ padding: "0px", marginTop: "3px" }} src={heatact} />
                          </IonFabButton>
                        ) : (
                          <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                            <img alt="icons-mob" className="heat-mob" style={{ marginTop: "3px", padding: "0px" }} src={Heat} />
                          </IonFabButton>
                        )}
                      </IonFab>
                      <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                        <IonFabButton
                          className="backg"
                          color="light"
                          size="small"
                          style={{ marginBottom: "10px" }}
                          onClick={() => this.setCurrentPosition()}
                        >
                          <img alt="icons-mob" style={{ padding: "5px" }} src={geo} />
                        </IonFabButton>
                      </IonFab>
                      {Claims.getUserRole() == "Installer" ? null : (
                        <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                          <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                            <img alt="icons-mob" className="calcicon" src={calcmob} />
                          </IonFabButton>
                        </IonFab>
                      )}
                      <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                        <IonFabButton className="backg" color="light" size="small" style={{ marginBottom: "10px" }}>
                          <img alt="icons-mob" className="search-mob" src={SearchIcon} />
                        </IonFabButton>
                      </IonFab>
                    </div>
                  );
                }
            })()}
            {(() => {
              if ([0, 1].includes(this.props.temp))
                return (
                  <IonRow className="bottom-menu">
                    <IonCol className="d-none" size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                      {/* <i className="fa fa-circle cpeicon"></i> */}
                      <img alt="icons-mob" className="cpeicon" src={addCPEIcon} style={{ width: "38px", filter: "contrast(0)" }} />
                      <button type="button" className="cardbutton p-l-25" disabled>
                        Add CPE
                      </button>
                    </IonCol>
                    <IonCol size="6" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                      <img alt="icons-mob" className="apicon" src={AP} style={{ filter: "contrast(0)" }} />
                      <button type="button" className="cardbutton p-l-25" disabled>
                        Add CBSD
                      </button>
                    </IonCol>
                    <IonCol size="6" style={{ padding: "5px" }}>
                      <img alt="icons-mob" className="measureiconmob" src={measureIcon} />
                      <button
                        type="button"
                        className="cardbutton p-l-25"
                        style={{ backgroundColor: "#fd9344", color: "#ffffff", border: "none" }}
                        onClick={this.props.back}
                      >
                        Finish
                      </button>
                    </IonCol>
                  </IonRow>
                );
              else if (this.props.temp === 2)
                if (this.reduxStore["tabType"] == "outdoor") {
                  return (
                    <IonRow className="bottom-menu" style={{ bottom: "230px" }}>
                      <IonCol className="d-none" size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                        {/* <i className="fa fa-circle cpeicon"></i> */}
                        <img alt="icons-mob" className="cpeicon" src={addCPEIcon} style={{ width: "38px", filter: "contrast(0)" }} />
                        <button type="button" className="cardbutton p-l-25" disabled>
                          Add CPE
                        </button>
                      </IonCol>
                      <IonCol size="6" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                        <img alt="icons-mob" className="apicon" src={AP} style={{ filter: "contrast(0)" }} />
                        <button type="button" className="cardbutton p-l-25" disabled>
                          Add CBSD
                        </button>
                      </IonCol>
                      <IonCol size="6" style={{ padding: "5px" }}>
                        <img alt="icons-mob" className="measureiconmob" src={measureIcon} />
                        <button
                          type="button"
                          className="cardbutton p-l-25"
                          style={{ backgroundColor: "#fd9344", color: "#ffffff", border: "none" }}
                          onClick={this.props.back}
                        >
                          Finish
                        </button>
                      </IonCol>
                    </IonRow>
                  );
                } else {
                  return (
                    <IonRow className="bottom-menu">
                      <IonCol className="d-none" size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                        {/* <i className="fa fa-circle cpeicon"></i> */}
                        <img alt="icons-mob" className="cpeicon" src={addCPEIcon} style={{ width: "38px", filter: "contrast(0)" }} />
                        <button type="button" className="cardbutton p-l-25" disabled>
                          Add CPE
                        </button>
                      </IonCol>
                      <IonCol size="6" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                        <img alt="icons-mob" className="apicon" src={AP} style={{ filter: "contrast(0)" }} />
                        <button type="button" className="cardbutton p-l-25" disabled>
                          Add CBSD
                        </button>
                      </IonCol>
                      <IonCol size="6" style={{ padding: "5px" }}>
                        <img alt="icons-mob" className="measureiconmob" src={measureIcon} />
                        <button
                          type="button"
                          className="cardbutton p-l-25"
                          style={{ backgroundColor: "#fd9344", color: "#ffffff", border: "none" }}
                          onClick={this.props.back}
                        >
                          Finish
                        </button>
                      </IonCol>
                    </IonRow>
                  );
                }
            })()}

            {this.getPropsTempHtml()}

            {(() => {
              if ([0, 1].includes(this.props.temp))
                return (
                  <div>
                    <div className="arch">
                      <img alt="icons-mob-other" src={arch} style={{ height: "30px" }}></img>
                    </div>
                    <div className="card measuremob" style={{ position: "fixed" }}>
                      <div className="card-body text-center" style={{ padding: "15px", fontSize: "14px" }}>
                        {(() => {
                          if (this.props.temp === 0) return <span>Select first CBSD</span>;
                          if (this.props.temp === 1) return <span>Select Second CBSD</span>;
                          if (this.props.temp === 2)
                            return (
                              <span>
                                Distance:<span style={{ color: "#f59343" }}> {`${this.props.distance} m`}</span>
                              </span>
                            );
                        })()}
                      </div>
                    </div>
                  </div>
                );
              else if (this.props.temp === 2) {
                if (this.reduxStore["tabType"] == "outdoor") {
                  return (
                    <div>
                      <div className="arch" style={{ bottom: "207px" }}>
                        <img alt="icons-mob-other" src={arch} style={{ height: "30px" }}></img>
                      </div>
                      <div className="card measuremob v-h-center" style={{ position: "fixed", height: "212px" }}>
                        <div className="col-12 p-0 m-t-10" style={{ height: "150px", zIndex: -1 }}>
                          {this.state.chartVal && window.google ? (
                            <Chart
                              width={"100%"}
                              height={"180px"}
                              chartType="ColumnChart"
                              loader={
                                <div>
                                  <br />
                                  Loading Chart
                                </div>
                              }
                              data={this.state.chartVal}
                              options={{
                                title: "Elevation Profile",
                                vAxis: {
                                  title: "Elevation(m)",
                                },
                                hAxis: {
                                  textPosition: "none",
                                },
                                chartArea: { width: "80%", height: "180px", left: "15%" },
                                //curveType: 'function',
                                legend: "none",
                              }}
                              rootProps={{ "data-testid": "1" }}
                            />
                          ) : null}
                        </div>
                        <div className="card-body text-center" style={{ padding: "15px", fontSize: "14px", position: "absolute", marginTop: "75px" }}>
                          {(() => {
                            if (this.props.temp === 0) return <span>Select first CBSD</span>;
                            if (this.props.temp === 1) return <span>Select Second CBSD</span>;
                            if (this.props.temp === 2)
                              return (
                                <span>
                                  {`Distance:`}
                                  <span style={{ color: "#f59343" }}> {`${this.props.distance} m`}</span>
                                </span>
                              );
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <div className="arch">
                        <img alt="icons-mob-other" src={arch} style={{ height: "30px" }}></img>
                      </div>
                      <div className="card measuremob v-h-center" style={{ position: "fixed" }}>
                        <div style={{ height: "150px", zIndex: -1 }}>
                          {this.state.chartVal && window.google ? (
                            <Chart
                              width={"100%"}
                              height={"180px"}
                              chartType="ColumnChart"
                              loader={
                                <div>
                                  <br />
                                  Loading Chart
                                </div>
                              }
                              data={this.state.chartVal}
                              options={{
                                title: "Elevation Profile",
                                vAxis: {
                                  title: "Elevation(m)",
                                },
                                hAxis: {
                                  textPosition: "none",
                                },
                                chartArea: { width: "80%", height: "180px", left: "15%" },
                                //curveType: 'function',
                                legend: "none",
                              }}
                              rootProps={{ "data-testid": "1" }}
                            />
                          ) : null}
                        </div>
                        <div className="card-body text-center" style={{ padding: "15px", fontSize: "14px" }}>
                          {(() => {
                            if (this.props.temp === 0) return <span>Select first CBSD</span>;
                            if (this.props.temp === 1) return <span>Select Second CBSD</span>;
                            if (this.props.temp === 2)
                              return (
                                <span>
                                  {`Distance:`}
                                  <span style={{ color: "#f59343" }}> {`${this.props.distance} m`}</span>
                                </span>
                              );
                          })()}
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            })()}
          </div>
        )}
      </div>
    );
  }
}
