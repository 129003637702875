import { IonContent, IonPage, withIonLifeCycle } from '@ionic/react';
import React, { Component } from 'react';
import logo from '../assets/img/Spectra_logo.png'
import qricon from '../assets/img/bar-code-img-icon.png'
import './Login.css'
import { Plugins, AppState } from '@capacitor/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner';

const { App } = Plugins;
const { BackgroundTask } = Plugins

class Loginqr extends Component {
    state: any = {};
    props: any = {};
    backButton_event: any
    backButton_timer: any = null
    appState_event: any
    constructor(props: any) {
        super(props);
        this.state = {
            baseUrl: ""
        }
        this.onChange = this.onChange.bind(this)
        this.configureEndPoint = this.configureEndPoint.bind(this)
        this.openScanner = this.openScanner.bind(this)
    }

    openScanner = async () => {
        const data = await BarcodeScanner.scan();
        this.setState({ baseUrl: data.text })

    };
    configureEndPoint() {
        localStorage.setItem("baseUrl", this.state.baseUrl)
        this.props.history.push('/home');

    }
    onChange = (e: any) => {
        this.setState({ baseUrl: e.target.value })

    }
    componentDidMount(){
        console.log('qr config did mount**')
        this.set_backButton_listener()
    }
    // ionViewDidEnter() {
    //     console.log('qr config did mount**')
    //     this.set_backButton_listener()
    // }
    set_backButton_listener = () => {

        let backButtonListenerExists = localStorage.getItem('backButton')
        console.log('checking for back button listener..')
        if (backButtonListenerExists == null) {
            localStorage.setItem('backButton', "true")
            console.log('creating back button listener')
            this.backButton_event = App.addListener('backButton', (state: any) => {
                // state.isActive contains the active state
                console.log('back button pressed..............');
                this.props.history.push('/home')
            });
        }
        else {
            console.log('backButton listener already exists')
        }
        if (this.backButton_timer == null) {
            console.log('creating timer.............')
            this.backButton_timer = setInterval(() => {
                this.set_backButton_listener()
            }, 2000)
        }
        else {
            console.log('timer already exists.............')
        }
        let appStateListenerExists = localStorage.getItem('appState')
        console.log('checking for app state listener..')
        if (appStateListenerExists == null) {
            console.log('creating app state event listener....................')
            localStorage.setItem('appState', "true")
            this.appState_event = App.addListener('appStateChange', (state: AppState) => {
                if (state.isActive) {

                    this.set_backButton_listener()

                }
                if (!state.isActive) {
                    let taskId = BackgroundTask.beforeExit(async () => {
                        // In this function We might finish an upload, let a network request
                        // finish, persist some data, or perform some other task
                        console.log('inactive state.............')
                        // Example of long task

                        if (this.backButton_timer != null) {
                            console.log('clearing timer.......')
                            clearInterval(this.backButton_timer)
                            this.backButton_timer = null
                        }
                        if (this.backButton_event != null) {
                            console.log('clearing back button event..........')
                            this.backButton_event.remove()
                        }
                        localStorage.removeItem('backButton')
                        // Must call in order to end our task otherwise
                        // we risk our app being terminated, and possibly
                        // being labeled as impacting battery life
                        BackgroundTask.finish({
                            taskId
                        });
                    });
                }
            }
            )
        }
        else {
            console.log('app state listener already exists............')
        }

    }

    ionViewWillLeave() { //NOSONAR
        console.log('ion will unmount called qrconfig............')
        if (this.backButton_timer != null) {
            console.log('clearing timer.......')
            clearInterval(this.backButton_timer)
            this.backButton_timer = null
        }
        if (this.backButton_event != null) {
            console.log('clearing back button event..........')
            this.backButton_event.remove()
        }
        if (this.appState_event != null) {
            console.log('clearing app state event..........')
            this.appState_event.remove()
        }
        localStorage.removeItem('backButton')
        localStorage.removeItem('appState')
    }
    render() {
        return (
            <IonPage>
                <IonContent>
                    <div className="mobonly">
                        <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
                            <div className='row m-r-0 m-l-0'>
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-5 login-logo'>
                                            <div className='row m-r-0 m-l-0 logo'>
                                                <div className='col-md-12'>
                                                    <img className='img-center img' src={logo} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-r-16 p-l-16" style={{ marginTop: '25%' }}>
                                <div className="col-12 text-center p-0">
                                    <img src={qricon} style={{ width: '45px' }} />
                                </div>
                                <div className="col-12 m-t-20 p-0">
                                    <button className="lightbtnmob p-t-5" style={{ height: '55px' }} onClick={() => { this.openScanner() }}>Scan</button>
                                </div>
                                <div className="col-12 m-t-20 text-center p-0">
                                    <span className="font20 fontw500">OR</span>
                                </div>
                                <div className="col-12 m-t-20 p-0">
                                    <div className="form-label-group m-0">
                                        <input type="text" value={this.state.baseUrl} onChange={(e: any) => this.onChange(e)} className="accesspointname font14" style={{ height: '55px', width: '100%' }} id="scanner" placeholder="CBSD Name" />
                                        <label htmlFor="scanner"></label>
                                    </div>
                                </div>
                                <div className="col-12 m-t-20 p-0">
                                    <button className="btn cardbtn" style={{ height: '55px' }} onClick={this.configureEndPoint} >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        );
    }
}

export default withIonLifeCycle(Loginqr);