import {
  IonContent,
  IonLoading,
  IonAlert,
  IonPage
} from "@ionic/react";
import React, { Component } from "react";
import logo from "../assets/img/Spectra_logo.png";
import { requestAPICall } from "../service/apiHandler";
import "./Login.css";
import BackIcon from "../assets/img/go-back-icon-img.png";

const INITIAL_STATE = {
  step: 1,
  email: "",
  submit: false,
  is_loader: false,
  alertbox: false,
  warnMessage: "",
};

export default class Forgot extends Component {
  state: any = {};
  props: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onChange = this.onChange.bind(this);
    // this.submitForm = this.submitForm.bind(this)
    this.callEmailAPI = this.callEmailAPI.bind(this);
  }
  resetCard = () => {
    this.setState({ step: 1 });
  };
  submitForm = (e: any) => {
    this.formValidation();
    let email_validate = false;
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (this.state.email.match(mailformat)) {
      email_validate = true;
    }
    e.preventDefault();
    if (this.state.email != "" && email_validate) {
      this.callEmailAPI();
      this.setState({
        email: ""
      });
    } else {
      this.setState({
        warnMessage: "Please Enter valid Email Address",
      });
      this.setState({
        alertbox: true,
      });
    }

    //console.log('sendemail')
    this.setState({
      submit: true,
    });
  };
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };
  formValidation() {
    if (this.state.email == "") {
      this.setState({
        warnMessage: "Please enter email",
      });
      this.setState({
        alertbox: true,
      });
    } else {
      this.setState({
        submit: true,
      });
    }
  }
  callEmailAPI() {
    this.setState({
      is_loader: true,
    });
    let requestBody = {
      email: this.state.email,
    };

    requestAPICall("email", {}, requestBody).then((data) => {
      //console.log(data)
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        if (data.message.includes("HTTPConnectionPool")) {
          this.setState({
            warnMessage: "Please Check your Internet connection",
          });
          this.setState({
            alertbox: true,
          });
        } else {
          this.setState({
            warnMessage: data.message,
          });
          this.setState({
            step: 2,
          });
        }
      } else {
        //console.log(data.message , 'mmmmmmmmmmmmmmm')
        this.setState({
          warnMessage: data.message,
        });
        this.setState({
          alertbox: true,
        });
      }
    });
  }

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  navigateToLogin(){
    this.props.history.push("/login");
  }

  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="webonly">
            <div
              className="app-body img-responsive"
              style={{ marginTop: "0px" }}
            >
              <div className="row m-r-0 m-l-0">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 login-bg">
                      <div className="row bg-text bg-slogan">
                        <div className="col-md-12">
                          <span>Your Signal.</span>
                          <br />
                          <span style={{ lineHeight: "1.8" }}>
                            Your Company.
                          </span>
                          <br />
                          <span style={{ lineHeight: "1.8" }}>
                            Your Promise to Customers.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12 login-logo">
                      <div className="row m-r-0 m-l-0">
                        <div className="col-md-12 ">
                          <img className="img-center img" src={logo} />
                        </div>
                      </div>
                      <div
                        className="row m-r-0 m-l-0"
                        style={{ paddingTop: "14%" }}
                      >
                        <div className="col-md-12 homecontent">
                          <span className="login-text img-center">
                            Forgot Password
                          </span>
                          <div className="login-info m-t-10">
                            <span>Enter the email address associated with</span>
                            <br />
                            <span>
                              {" "}
                              your account and we'll send instructions to
                            </span>
                            <br />
                            <span>reset your password.</span>
                          </div>
                          {this.state.step === 1 ? (
                            <div>
                              <div
                                className="row m-t-10 justify-content-center"
                                style={{ paddingTop: "13%" }}
                              >
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    name="email"
                                    id="forgetemailweb"
                                    onChange={this.onChange}
                                    style={{
                                      borderRadius: "6px",
                                      border: "1px solid #8F9FAC",
                                    }}
                                    className="text-user img-center"
                                    placeholder="Email"
                                  />
                                  <label htmlFor="forgetemailweb">Email*</label>
                                </div>
                              </div>
                              {/* <div className="form-group m-t-50" style={{ paddingTop: "13%" }} >
                                                            </div> */}
                              {this.state.email === "" ? (
                                <div>
                                  <button
                                    className="btn img-center m-t-30"
                                    disabled={true}
                                  >
                                    Send
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    className="btn img-center m-t-30"
                                    onClick={this.submitForm}
                                  >
                                    Send
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div
                                className="login-card img-center m-t-60"
                                style={{ paddingTop: "15px" }}
                              >
                                <span className="login-text img-center">
                                  Got it!
                                </span>
                                <div className="login-info m-t-20">
                                  <span>We've sent you an email with</span>
                                  <br />
                                  <span>
                                    {" "}
                                    instructions on reseting your password.
                                  </span>
                                </div>
                              </div>
                              <button
                                className="btn img-center m-t-30"
                                onClick={() => {
                                  this.props.history.push("/login");
                                  this.resetCard();
                                }}
                              >
                                Sign In Again
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobonly">
            <div
              className="app-body img-responsive"
              style={{ marginTop: "0px" }}
            >
              <div className="row m-r-0 m-l-0">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-7 col-sm-12 login-bg">
                      <div className="row bg-text bg-slogan">
                        <div className="col-md-12">
                          <span>Your Signal.</span>
                          <br />
                          <span style={{ lineHeight: "1.8" }}>
                            Your Company.
                          </span>
                          <br />
                          <span style={{ lineHeight: "1.8" }}>
                            Your Promise to Customers.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-12 login-logo">
                      <div className="col-md-12 ">
                        <img
                          className="backicon"
                          src={BackIcon}
                          onClick={() => {
                            this.navigateToLogin()
                          }}
                          onKeyDown={(e) => e.key == "Enter" && this.navigateToLogin()}
                        />
                      </div>
                      <div className="row m-r-0 m-l-0 logo">
                        <div className="col-md-12 ">
                          <img className="img-center img" src={logo} />
                        </div>
                      </div>
                      <div
                        className="row m-r-0 m-l-0"
                        style={{ paddingTop: "14%" }}
                      >
                        <div
                          className="col-md-12 homecontent p-r-16 p-l-16"
                          style={{ bottom: "64px" }}
                        >
                          <span className="login-text">Forgot Password?</span>
                          <div
                            className="login-info m-t-12"
                            style={{ marginBottom: "50%" }}
                          >
                            <span>Enter the email address associated with</span>
                            <br />
                            <span>
                              {" "}
                              your account and we'll send instructions to
                            </span>
                            <br />
                            <span>reset your password.</span>
                          </div>
                          {this.state.step === 1 ? (
                            <div>
                              <div className="justify-content-center">
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    id="forgetemailmob"
                                    name="email"
                                    className="text-user img-center"
                                    style={{
                                      height: "59px",
                                      borderRadius: "6px",
                                      border: "0.5px solid #8F9FAC",
                                    }}
                                    placeholder="Email"
                                    onChange={this.onChange}
                                  />
                                  <label
                                    className="forgot"
                                    htmlFor="forgetemailmob"
                                  >
                                    Email
                                  </label>
                                </div>
                              </div>
                              {/* <button className='btn img-center m-t-30' onClick={this.submitForm}>Send</button> */}
                              {this.state.email === "" ? (
                                <div>
                                  <button
                                    className="btn img-center m-t-24"
                                    disabled={true}
                                  >
                                    Send
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <button
                                    className="btn img-center m-t-24"
                                    onClick={this.submitForm}
                                  >
                                    Send
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div>
                              <div
                                className="login-card img-center m-t-30"
                                style={{ paddingTop: "15px" }}
                              >
                                <span className="login-text img-center">
                                  Got it!
                                </span>
                                <div className="login-info m-t-20">
                                  <span>We've sent you an email with</span>
                                  <br />
                                  <span>
                                    {" "}
                                    instructions on reseting your password.
                                  </span>
                                </div>
                              </div>
                              <button
                                className="btn img-center m-t-24"
                                onClick={() => {
                                  this.props.history.push("/login");
                                  this.resetCard();
                                }}
                              >
                                Try logging in again
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IonAlert
            isOpen={this.state.alertbox}
            onDidDismiss={() => this.setShowAlert(false)}
            message={this.state.warnMessage}
            buttons={["OK"]}
          />

          <IonLoading
            isOpen={this.state.is_loader}
            onDidDismiss={() => this.setShowLoading(false)}
            message={"Loading..."}
            duration={120000}
          />
        </IonContent>
      </IonPage>
    );
  }
}