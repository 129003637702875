import React, { Component } from "react";
import { IonContent, IonPage, IonAlert, IonLoading } from "@ionic/react";
import "./building.css";
import BackIcon from "../assets/img/go-back-icon-img.png";
import lockIcon from "../assets/img/password-img-icon-img.png";
import geo from "../assets/img/target-icon.png";
import align from "../assets/img/align-icon.png";
import { requestAPICall } from "../service/apiHandler";
import { isBrowser, isMobile } from "react-device-detect";
import Dropzone from "react-dropzone";
import store from "../store";

const FLOOR_INITIAL_STATE = {
  floorproperty: [],
  floorName: "",
  building_id: "",
  floor_type: "Basement",
  floor_number: "",
  floor_name: "",
  floor_notes: "",
  ceiling_height: "",
  alertbox: false,
  is_loader: false,
  fl_number: 0,
  building_name: "",
  action: "Add",
  high: 0,
  floor: "",
  floorPlanFileImage: "",
  files: [],
  addFloors: 0,
};
let floor_data: any = [];
let is_submit_form = false;
let is_edit: boolean = false;
let unit: any = "";

export default class BuildingInfo2 extends Component {
  state: any = {};
  props: any = {};
  floornameInput: any;
  floorNumberInput: any;
  floornotesInput: any;
  heightInput: any;
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.floornameInput = React.createRef();
    this.floorNumberInput = React.createRef();
    this.floornotesInput = React.createRef();
    this.heightInput = React.createRef();
    this.state = { ...FLOOR_INITIAL_STATE };
    this.next5 = this.next5.bind(this);
    this.load_floor_property = this.load_floor_property.bind(this);
    this.submit_parameters = this.submit_parameters.bind(this);
    this.selectParkingType = this.selectParkingType.bind(this);
    this.set_floor_id = this.set_floor_id.bind(this);
    this.onChange = this.onChange.bind(this);
    this.save_changes = this.save_changes.bind(this);
    this.goBackWeb = this.goBackWeb.bind(this);
    this.goBackFloor = this.goBackFloor.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  next5 = () => {
    this.props.next5();
  };

  goToCalculate = (e: any) => {
    this.props.calculate(e);
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  componentDidMount() {
    console.log("componentDidMount event fired");
    console.log(this.props.floor_no);
    console.log(this.props.action);

    if (isMobile) {
      this.setState(
        {
          fl_number: this.props.floor_no,
          building_name: this.props.building_name,
          action: this.props.action,
          floor_type: "Basement",
          floor_number: "",
          floor_name: "",
          floor_notes: "",
          ceiling_height: "",
        },
        () => {
          this.set_floor_id(this.props.action);
        }
      );
    }
    if (isBrowser) {
      this.setState(
        {
          fl_number: this.props.floor_no,
          building_name: this.props.building_name,
          action: this.props.action,
        },
        () => {
          this.set_floor_id(this.props.action);
        }
      );
    }
  }
  setFloorType = () => {
    console.log(this.state.floorproperty);
    console.log("===============");
    this.state.floorproperty?.forEach((property: any) => {
      if (property.propertyName == "floor_type") {
        property.value = this.state.floor_type;
      }
    });
  };

  componentDidUpdate() {
    this.reduxStore = store.getState()["general"];
  }
  set_floor_id(action: any) {
    console.log(this.props.floor_no);
    console.log(this.props.action);
    console.log(this.state.fl_number);
    console.log(action);
    console.log(this.reduxStore);
    if (action == "EditFloor") {
      console.log("Edit mode");
      this.setState({
        selected_floor_id: this.reduxStore["floorId"],
        building_name: this.reduxStore["buildingName"],
      });
      this.get_floor_details();
    } else if (action == "Add" || action == "AddFloor" || action == "back" || action == "next") {
      console.log("Adding new building");
      this.setState({
        building_name: this.props.building_name,
      });

      is_edit = false;
      this.load_floor_property();
    } else if (action == "back3" || action == "back3FromEdit" || action == "AddFloorBack") {
      console.log("===============");
      this.setState(
        {
          floorproperty: this.props.back_pop_req_body["properties"][0],
        },
        () => this.setFloorType()
      );
    }
  }
  get_floor_details() {
    this.setState({
      is_loader: true,
    });
    console.log("loading selected building data.....");
    let requestBody = {};

    requestAPICall("get_particular_floor", { floor_id: this.reduxStore["floorId"] }, requestBody).then((data) => {
      this.setState({
        is_loader: false,
      });
      console.log(data);
      if (data.responseCode == "200") {
        is_edit = true;
        this.setState(
          {
            floorproperty: data.properties,
            selected_floor_id: this.reduxStore["floorId"],
            floorPlanFileImage: data.floorPlanFileImage,
          },
          () => this.set_floor_type()
        );
      }
      console.log(data.details);
      this.setState({
        floorproperty: data.properties,
        button_text: "Edit",
      });
    });
  }
  set_floor_type() {
    this.state.floorproperty?.forEach((property: any) => {
      if (property.propertyName == "floor_type") {
        this.setState({
          floor_type: property.value,
        });
      }
    });
  }
  load_floor_property() {
    let building_property: any = [];
    let requestBody = {};
    let floorAddProperty = localStorage.getItem("floorAddProperty");
    if (floorAddProperty === null) {
      requestAPICall("get_floor_property", {}, requestBody).then((data) => {
        console.log(data["properties"]);
        building_property = data["properties"];
        localStorage.setItem("floorAddProperty", JSON.stringify(building_property));
        floor_data = data["properties"];
        this.fetch_data();
      });
    } else {
      console.log(localStorage.getItem("floorAddProperty"));
      console.log(JSON.parse(floorAddProperty));
      floor_data = JSON.parse(floorAddProperty);
      building_property = floor_data;
      this.fetch_data();
    }
  }
  fetch_data() {
    this.setState(
      {
        floorproperty: floor_data,
      },
      () => this.setFloorType()
    );
    console.log(this.state.floorproperty);
  }

  goBack() {
    console.log("============");
    this.props.b2_back_action("back");
  }
  goBackFromEdit() {
    console.log("============");
    this.props.hideB2Overlay();
    this.props.next6();
  }
  validateRequest() {
    let check = false;
    this.state.floorproperty?.forEach((property: any) => {
      if (!check) {
        if (property.propertyName == "floor_number" && property.value == "" && property.isRequired) {
          this.floorNumberInput.current.focus();
          check = true;
        } else if (property.propertyName === "floor_name" && property.value === "" && property.isRequired === true) {
          this.floornameInput.current.focus();
          check = true;
        } else if (property.propertyName == "ceiling_height" && property.value == "" && property.isRequired) {
          this.heightInput.current.focus();
          check = true;
        }
        if (isMobile) {
          if (this.state.files.length == 0 && this.state.action !== "EditFloor") {
            check = true;
            this.setState({
              warnMessage: "Please upload Floor plan file",
              alertbox: true,
            });
          }
        }
      }
    });
    is_submit_form = !check;
  }
  submit_parameters = (e: any, action: any) => {
    e.preventDefault();
    this.validateRequest();
    console.log(is_submit_form);
    let build_id = this.props.buildingId;
    let requestBody = {
      floorName: this.state.floor_name,
      properties: [this.state.floorproperty],
      number_of_floors: this.state.fl_number,
      building_id: build_id,
      floorPlanFileImage: this.state.floorPlanFileImage,
    };
    if (isBrowser) {
      if (is_submit_form) {
        this.next5();
        this.props.request_body(requestBody);
      }
    } else {
      this.create_floor(requestBody, action);
    }
  };
  save_changes() {
    this.validateRequest();
    if (is_submit_form) {
      let requestBody: any = {};
      requestBody["properties"] = this.state.floorproperty;

      if (this.state.files.length > 0) {
        let picReader = new FileReader();
        let filerequestBody = {};
        let fileName = this.state.files[0].name;
        let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
        picReader.onload = (e) => {
          filerequestBody = {
            fileName: fileName,
            fileExtension: fileExt,
            fileValue: picReader.result,
          };
          requestAPICall("upload_file", {}, filerequestBody).then((data) => {
            if (data["responseCode"] == 200) {
              requestBody["floor_plan_file_id"] = data.file_id;
              this.editFloorChanges(requestBody);
            }
          });
        };
        picReader.readAsDataURL(this.state.files[0]);
      } else {
        this.editFloorChanges(requestBody);
      }
    }
  }
  editFloorChanges = (requestBody: any) => {
    console.log(requestBody);
    this.setState({
      is_loader: true,
    });
    requestAPICall("update_floor", { building_id: this.reduxStore["buildingId"], floor_id: this.reduxStore["floorId"] }, requestBody).then((data) => {
      if (data.responseCode == "200") {
        this.setState({
          is_loader: false,
          warnMessage: data.message,
          alertbox: true,
        });
        this.props.next6();
      }
    });
  };
  create_floor(requestBody: any, action: any) {
    this.validateRequest();
    console.log(is_submit_form);

    let picReader = new FileReader();
    let filerequestBody = {};
    if (!is_submit_form) return;
    this.setState({
      is_loader: true,
    });
    $("#btna").attr("disabled", "true");
    $("#btnb").attr("disabled", "true");
    $("#btnc").attr("disabled", "true");
    let fileName = this.state.files[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    picReader.onload = (e) => {
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: picReader.result,
      };
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        let building_id;
        if (data["responseCode"] == 200) {
          building_id = this.reduxStore["buildingId"];
          requestBody["floor_plan_file_id"] = data.file_id;
          requestAPICall("create_floor", { buildingId: building_id }, requestBody).then((data) => {
            this.setState({
              is_loader: false,
            });
            if (data.responseCode !== "200") return;
            this.props.hideB2Overlay();
            this.setState({
              ceiling_height: "",
              floor_notes: "",
              floor_name: "",
            });
            if (action == "complete") {
              this.props.building_status_action("complete");
              this.next1();
            } else {
              this.props.building_status_action("next");
              this.next1();
            }

            this.setState({
              warnMessage: data.message,
              alertbox: true,
            });
          });
        }
      });
    };
    picReader.readAsDataURL(this.state.files[0]);
  }
  next1() {
    if (this.props.action == "AddFloor") {
      this.props.next1(0);
    } else {
      this.props.next1();
    }
  }

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.state.floorproperty?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
      if (property.propertyName == "Ceiling Height" && e.target.name == "ceiling_height") {
        this.setState({
          ceiling_height: e.target.value,
        });
      }
    });
    if (e.target.name == "floor_name") {
      if (isBrowser) {
        this.props.floor_name(e.target.value);
      }
    }
  }

  goBackWeb(e: any) {
    this.props.build_action("back");
    this.props.back();
  }

  onDrop = (files: any) => {
    this.setState({ files: files });
  };
  goBackFloor() {
    this.props.build_action("back");
    this.props.backFloor();
  }

  selectParkingType(type: any) {
    this.setState({ floor_type: type });

    this.state.floorproperty?.forEach((property: any) => {
      if (property.propertyName == "floor_type") {
        property.value = type;
      }
    });
    console.log(this.state.floor_type);
    console.log(this.state.floorproperty);
  }

  uploadFileHtml = (files: any) => {
    if (this.state.floorPlanFileImage != "" && files.length == 0) {
      return <span style={{ fontSize: "14px" }}> Floor plan file uploaded Already</span>;
    } else if (files.length) {
      return <span style={{ color: "#00D56E", display: "flex" }}>{files}</span>;
    } else
      return (
        <span className="" style={{ fontSize: "14px" }}>
          Add Floor Plan File
        </span>
      );
  };

  render() {
    let files = this.state.files?.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "dropzone-uploaded" : "dropzone";
    let classImageUpload = files.length ? "-uploaded" : "";
    return (
      <div>
        <div className="webonly">
          <div className="row">
            <div className="col-md-9">
              <div className="card apcard">
                <div className="card-body" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        alt="backicon"
                        className="backicon"
                        src={BackIcon}
                        onClick={
                          this.state.action == "Add" || this.state.action == "back" || this.state.action == "back3"
                            ? this.goBackWeb
                            : this.goBackFloor
                        }
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (this.state.action == "Add" || this.state.action == "back" || this.state.action == "back3") this.goBackWeb(e);
                            else this.goBackFloor();
                          }
                        }}
                      />{" "}
                      :<button type="button" className="backbutton"></button>
                    </div>
                    <div className="col-md-8" style={{ padding: "13px 0" }}>
                      {/* <span className='apheader m-r-m'>NEW BUILDING INFO</span> */}
                      {this.state.action == "Add" || this.state.action == "back" || this.state.action == "back3" || this.state.action == "next" ? (
                        <span className="apheader m-r-m">NEW FLOOR INFO</span>
                      ) : null}
                      {this.state.action == "EditFloor" || this.state.action == "back3FromEdit" ? (
                        <span className="apheader m-r-m">EDIT FLOOR INFO</span>
                      ) : null}
                      {this.state.action == "AddFloor" ? <span className="apheader m-r-m">ADD FLOOR</span> : null}
                      <span className="apnote float-right" style={{ fontSize: "12px", margin: "5px" }}>
                        2/3
                      </span>
                    </div>
                  </div>
                  <div className="row" style={{ padding: "0px 35px" }}>
                    <span className="apnote" style={{ fontSize: "14px" }}>
                      You can add floors one by one or{"\n"} bulk import
                    </span>
                  </div>
                  <div className="col-md-12 m-t-10">
                    <button className="btn btn-secondary-c3">Bulk Import Floors</button>
                  </div>
                  <div className="row" style={{ padding: "0px 35px" }}>
                    <div className="col-md-5 apborder" />
                    <div className="col-md-2 p-0 orspan">OR</div>
                    <div className="col-md-5 apborder" />
                  </div>

                  <div className="col-md-12 m-t-20">
                    <img
                      alt="backicon"
                      className="backicon"
                      style={{ position: "absolute", width: "40px", right: "10px", zIndex: 999 }}
                      src={lockIcon}
                      onClick={this.state.goBack}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.state.goBack();
                        }
                      }}
                    />
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        id="buildname"
                        disabled
                        className="apheader indoordrop-web buildingellipsis"
                        title={this.state.building_name}
                        style={{ textTransform: "none", fontWeight: 400, borderRadius: "6px 6px 0 0" }}
                        value={this.state.building_name}
                      />
                      <label className="pages" style={{ top: "3px" }} htmlFor="buildname">
                        Building
                      </label>
                    </div>

                    {this.state.floorproperty?.map((property: any, index: any) => {
                      if (property.units !== "") {
                        unit = "(" + property.units + ")";
                      } else {
                        unit = "";
                      }
                      if (property.propertyName == "floor_type") {
                        const element = (
                          <div className="secdrop b-t-0" style={{ margin: "0px", borderRadius: "0px", cursor: "text", height: "65px" }}>
                            <div className="row col-md-12">
                              <span className="" style={{ color: "#8f9fac", fontSize: "12px" }}>
                                {property.displayName}
                              </span>
                            </div>
                            <div className="row col-md-12 p-0 m-t-5 p-0">
                              <div className="col-md-4">
                                <button
                                  name="Parking"
                                  className={`floorbtn ${this.state.floor_type === "Parking" ? "floorbtn-active" : ""}`}
                                  onClick={(e: any) => this.selectParkingType("Parking")}
                                >
                                  Parking{" "}
                                </button>
                              </div>
                              <div className="col-md-4">
                                <button
                                  name="Basement"
                                  className={`floorbtn ${this.state.floor_type === "Basement" ? "floorbtn-active" : ""}`}
                                  onClick={(e: any) => this.selectParkingType("Basement")}
                                >
                                  Basement
                                </button>
                              </div>
                              <div className="col-md-4">
                                <button
                                  name="Floor"
                                  className={`floorbtn ${this.state.floor_type === "Floor" ? "floorbtn-active" : ""}`}
                                  onClick={(e: any) => this.selectParkingType("Floor")}
                                >
                                  Floor
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                        return element;
                      } else if (property.propertyName == "floor_number") {
                        return (
                          <div key={`${index.toString()}-floor-number`} className="col-md-12 p-0">
                            <div className="form-label-group m-0">
                              <input
                                ref={this.floorNumberInput}
                                type="number"
                                value={property.value}
                                name={property.propertyName}
                                className="buildtext"
                                id={property.propertyName}
                                placeholder={property.displayName}
                                onChange={this.onChange}
                              />
                              <label className="pages" htmlFor={property.propertyName}>
                                {this.state.floor_type + " Number"}
                              </label>
                            </div>
                          </div>
                        );
                      } else if (property.propertyName == "floor_name") {
                        return (
                          <div key={`${index.toString()}-floor-name`} className="col-md-12 p-0">
                            <div className="form-label-group m-0">
                              <input
                                ref={this.floornameInput}
                                type="text"
                                value={property.value}
                                name={property.propertyName}
                                className="buildtext"
                                id={property.propertyName}
                                placeholder={property.displayName}
                                onChange={this.onChange}
                              />
                              <label className="pages" htmlFor={property.propertyName}>
                                {this.state.floor_type + " Name"}
                              </label>
                            </div>
                          </div>
                        );
                      } else if (property.propertyName == "floor_notes") {
                        return (
                          <div key={`${index.toString()}-floor-notes`} className="col-md-12 p-0">
                            <div className="form-label-group m-0">
                              <input
                                ref={this.floornotesInput}
                                type="text"
                                maxLength={45}
                                value={property.value}
                                name={property.propertyName}
                                className="buildtext"
                                id={property.propertyName}
                                placeholder={property.displayName}
                                onChange={this.onChange}
                              />
                              <label className="pages" htmlFor={property.propertyName}>
                                {this.state.floor_type + " Notes"}
                              </label>
                            </div>
                          </div>
                        );
                      } else if (property.propertyName == "ceiling_height") {
                        return (
                          <div key={`${index.toString()}-ceiling-height`} className="col-md-12 p-0">
                            <div className="form-label-group m-0">
                              <input
                                ref={this.heightInput}
                                type="number"
                                value={property.value}
                                name={property.propertyName}
                                style={{ borderRadius: "0px 0px 6px 6px" }}
                                className="buildtext"
                                id={property.propertyName}
                                placeholder={property.displayName}
                                onChange={this.onChange}
                              />
                              <label className="pages" htmlFor={property.propertyName}>
                                {property.displayName} {unit}
                              </label>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="row m-t-10" style={{ padding: "0px 35px", position: "absolute", bottom: "10px" }}>
                    <button className="btn btn-secondary-c3" style={{ width: "258px" }} onClick={(e: any) => this.submit_parameters(e, "")}>
                      Next Step
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img alt="geo-location" style={{ padding: "5px" }} src={geo} />
                </div>
              </div>
              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img
                    alt="align"
                    style={{ padding: "6.5px" }}
                    onClick={this.goToCalculate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.goToCalculate(e);
                      }
                    }}
                    src={align}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobonly">
          <IonPage>
            <IonContent>
              <div className="param-info-mob" style={{ height: "100vh" }}>
                <div className="col-12 text-center header-mob" style={{ padding: "10px" }}>
                  <img
                    alt="backicon-mob"
                    className="backicon-mob"
                    style={{ width: "43px", paddingTop: "6px" }}
                    onClick={() => {
                      if (this.state.action == "Add") this.goBack();
                      else this.goBackFromEdit();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (this.state.action == "Add") this.goBack();
                        else this.goBackFromEdit();
                      }
                    }}
                    src={BackIcon}
                  />

                  {this.state.action == "Add" || this.state.action == "back" || this.state.action == "back3" || this.state.action == "next" ? (
                    <span className="apheader" style={{ fontSize: "14px" }}>
                      NEW FLOOR
                    </span>
                  ) : null}
                  {this.state.action == "EditFloor" || this.state.action == "back3FromEdit" ? (
                    <span className="apheader" style={{ fontSize: "14px" }}>
                      EDIT FLOOR
                    </span>
                  ) : null}
                </div>
                <div className="container-fluid apdash">
                  <div className="card buildinfocard" style={{ height: "calc(100vh - 58px)" }}>
                    <div className="card-body" style={{ padding: "15px", overflowY: "auto", maxHeight: "calc(100vh - 183px)" }}>
                      <div className="col-12 p-0">
                        <h6 className="m-0 d-inline-block font18">Floor Info</h6>
                        {this.state.action == "Add" ||
                        this.state.action == "back" ||
                        this.state.action == "next" ||
                        this.state.action == "AddFloor" ? (
                          <span className="apnote float-right" style={{ fontSize: "11px", margin: "5px" }}>
                            2/2
                          </span>
                        ) : null}
                      </div>
                      {this.state.action == "Add" || this.state.action == "back" || this.state.action == "next" || this.state.action == "AddFloor" ? (
                        <div className="col-12 m-t-5 p-0">
                          <div className="apnote" style={{ fontSize: "13px" }}>
                            Floors don't have to be added in any
                            <br />
                            particular order.
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12 m-t-15 p-0">
                        {/* <img className='backicon' style={{ position: "absolute", width: "40px", right: "10px", zIndex: 999 }} src={lockIcon} /> */}
                        <div className="form-label-group m-0">
                          <input
                            type="text"
                            id="buildname"
                            disabled
                            className="apheader indoordrop-web"
                            style={{
                              height: "58px",
                              textTransform: "none",
                              fontWeight: 400,
                              borderRadius: "6px 6px 0 0",
                            }}
                            value={this.state.building_name}
                          />
                          <label className="forgot" htmlFor="buildname">
                            Building
                          </label>
                        </div>
                        {this.state.floorproperty?.map((property: any, index: any) => {
                          if (property.displayName == "Floor Type") {
                            console.log(this.state.action, this.state.fl_number);

                            const element = (
                              <div className="secdrop b-t-0" style={{ margin: "0px", borderRadius: "0px", cursor: "text", height: "65px" }}>
                                <div className="row col-12">
                                  <span className="" style={{ color: "#8f9fac", fontSize: "12px" }}>
                                    Floor Type
                                  </span>
                                </div>
                                <div className="row col-12 p-0 m-t-5 p-0">
                                  <div className="col-4">
                                    <button
                                      name="Parking"
                                      className={`floorbtn ${this.state.floor_type === "Parking" ? "floorbtn-active" : ""}`}
                                      onClick={(e: any) => this.selectParkingType("Parking")}
                                    >
                                      Parking{" "}
                                    </button>
                                  </div>
                                  <div className="col-4">
                                    <button
                                      name="Basement"
                                      className={`floorbtn ${this.state.floor_type === "Basement" ? "floorbtn-active" : ""}`}
                                      onClick={(e: any) => this.selectParkingType("Basement")}
                                    >
                                      Basement
                                    </button>
                                  </div>
                                  <div className="col-4">
                                    <button
                                      name="Floor"
                                      className={`floorbtn ${this.state.floor_type === "Floor" ? "floorbtn-active" : ""}`}
                                      onClick={(e: any) => this.selectParkingType("Floor")}
                                    >
                                      Floor
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                            return element;
                          } else if (property.propertyName == "floor_number") {
                            return (
                              <div key={`${index.toString()}-floor-number-2`} className="col-12 p-0">
                                <div className="form-label-group m-0">
                                  <input
                                    ref={this.floorNumberInput}
                                    value={property.value}
                                    type="number"
                                    name={property.propertyName}
                                    style={{ height: "58px" }}
                                    className="buildtext"
                                    id={"floor_" + property.propertyName}
                                    placeholder={property.displayName}
                                    onChange={this.onChange}
                                  />
                                  <label className="forgot" htmlFor={"floor_" + property.propertyName}>
                                    {this.state.floor_type + " Number"}
                                  </label>
                                </div>
                              </div>
                            );
                          } else if (property.propertyName == "floor_name") {
                            return (
                              <div className="col-12 p-0" key={`${index.toString()}-floor-name-2`}>
                                <div className="form-label-group m-0">
                                  <input
                                    ref={this.floornameInput}
                                    value={property.value}
                                    type="text"
                                    name={property.propertyName}
                                    style={{ height: "58px" }}
                                    className="buildtext"
                                    id={"floor_" + property.propertyName}
                                    placeholder={property.displayName}
                                    onChange={this.onChange}
                                  />
                                  <label className="forgot" htmlFor={"floor_" + property.propertyName}>
                                    {this.state.floor_type + " Name"}
                                  </label>
                                </div>
                              </div>
                            );
                          } else if (property.propertyName == "floor_notes") {
                            return (
                              <div className="col-12 p-0" key={`${index.toString()}-floor-notes-2`}>
                                <div className="form-label-group m-0">
                                  <input
                                    ref={this.floornotesInput}
                                    maxLength={45}
                                    value={property.value}
                                    type="text"
                                    name={property.propertyName}
                                    style={{ height: "58px" }}
                                    className="buildtext"
                                    id={"floor_" + property.propertyName}
                                    placeholder={property.displayName}
                                    onChange={this.onChange}
                                  />
                                  <label className="forgot" htmlFor={"floor_" + property.propertyName}>
                                    {this.state.floor_type + " Notes"}
                                  </label>
                                </div>
                              </div>
                            );
                          } else if (property.propertyName == "ceiling_height") {
                            return (
                              <div className="col-12 p-0" key={`${index.toString()}-ceiling-height-2`}>
                                <div className="form-label-group m-0">
                                  <input
                                    ref={this.heightInput}
                                    type="number"
                                    value={property.value}
                                    name={property.propertyName}
                                    style={{ height: "58px" }}
                                    className="buildtext"
                                    id={"floor_" + property.propertyName}
                                    placeholder={property.displayName}
                                    onChange={this.onChange}
                                  />
                                  <label className="forgot" htmlFor={"floor_" + property.propertyName}>
                                    {property.displayName} {unit}
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        })}

                        <div className="buildtext b-t-0" style={{ height: "70px", borderRadius: "0px 0px 6px 6px" }}>
                          <div className="row m-t-12">
                            <div className="col-10 p-r-0" style={{ fontSize: "14px" }}>
                              <Dropzone onDrop={this.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                  <section className="dropzone-set">
                                    <div {...getRootProps({ className: classDName + "-mob" })}>
                                      <input {...getInputProps()} />
                                      {this.uploadFileHtml(files)}
                                    </div>
                                    <div></div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>

                            <div className="col-2 p-l-0">
                              <div className={"v-h-center image-field-action" + classImageUpload}>
                                <i
                                  className="material-icons imgclose-file"
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    files = [];
                                    this.onDrop([]);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      files = [];
                                      this.onDrop([]);
                                    }
                                  }}
                                >
                                  clear
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {(this.state.action == "Add" || this.state.action == "next" || this.state.action == "back") && (this.props.floor_no > 1) ? */}
                      {this.state.action == "Add" || this.state.action == "next" || this.state.action == "back" ? (
                        <div className="row col-md-12" style={{ position: "absolute", bottom: "68px" }}>
                          <button id="btna" className="btn btn-secondary-c3" onClick={(e: any) => this.submit_parameters(e, "next")}>
                            Add Next Floor
                          </button>{" "}
                        </div>
                      ) : null}

                      {this.state.action == "Add" || this.state.action == "back" || this.state.action == "next" ? (
                        <div className="row col-md-12 savebtn" style={{ position: "absolute", width: "100%" }}>
                          <button id="btnb" className="btn " onClick={(e: any) => this.submit_parameters(e, "complete")}>
                            Complete Floor Setup
                          </button>
                        </div>
                      ) : null}

                      {this.state.action == "EditFloor" ? (
                        <div className="row col-md-12 savebtn" style={{ position: "absolute", width: "100%" }}>
                          <button className="btn " onClick={this.save_changes}>
                            Save Changes
                          </button>
                        </div>
                      ) : null}

                      {this.state.action == "AddFloor" ? (
                        <div className="row col-md-12 savebtn" style={{ position: "absolute", width: "100%" }}>
                          <button id="btnc" className="btn " onClick={(e: any) => this.submit_parameters(e, "complete")}>
                            Complete Floor Setup
                          </button>{" "}
                        </div>
                      ) : null}

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <IonAlert
                isOpen={this.state.alertbox}
                onDidDismiss={() => this.setShowAlert(false)}
                message={this.state.warnMessage}
                buttons={["OK"]}
              />

              <IonLoading isOpen={this.state.is_loader} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} />
            </IonContent>
          </IonPage>
        </div>
      </div>
    );
  }
}
