import React from "react";
import { IonPage, IonContent, IonAlert, IonLoading, withIonLifeCycle } from "@ionic/react";
import Heat from "../assets/img/heaticon.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import geo from "../assets/img/target-icon.png";
import "./dashboard.css";
import { isBrowser, isMobile } from "react-device-detect";
import { requestAPICall } from "../service/apiHandler";
import store from "../store";

class AddCPE3 extends React.Component {
  state: any = {};
  props: any = {};
  apFocus: any = [];
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.apFocus = [];
    this.state = {
      add_cp3_property: [],
      add_ap2_properties: [],
      alertbox: false,
      is_loader: false,
      asset_action: "",
      asset_property: [],
    };
    this.load_property = this.load_property.bind(this);
  }

  componentDidMount() {
    this.reduxStore = store.getState()["general"];
    if (isBrowser) {
      this.load_property();
      this.setState(
        {
          add_ap2_properties: this.props.asset_property,
          asset_action: this.props.asset_properties["asset_action"],
          asset_property: this.props.asset_properties["properties"],
        },
        () => this.load_property()
      );
    } else {
      this.setState({
        add_cp3_property: this.reduxStore["cp3_property"],
        asset_property: this.reduxStore["asset_property"],
      });
    }
  }
  load_property = () => {
    this.setState({
      add_ap2_properties: this.props.asset_property,
    });
  };
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.state.asset_property?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
      if (property.propertyName == "name" && e.target.name == "name") {
        this.setState({
          ap_name: e.target.value,
        });
      }
      return property;
    });
  }

  submit_parameters = () => {
    console.log(this.state.asset_property);
    let request_list = this.state.asset_property.filter((property: any) => property.propertyName !== "cpe_id");
    let requestBody = {
      assetName: this.reduxStore["requestBody"]["assetName"],
      assetTypeId: this.reduxStore["requestBody"]["assetTypeId"],
      properties: request_list,
      asset_id_value: this.reduxStore["requestBody"]["asset_id_value"],
    };
    let image_value = this.state.asset_property.filter((property: any) => property.propertyName === "image");
    console.log(image_value[0].value, "imggggggggggggggg");
    if (image_value[0].value !== undefined && image_value[0].value !== "") {
      let picReader = new FileReader();
      let filerequestBody = {};
      let fileName = image_value[0]["value"][0].name;
      let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
        };
        requestAPICall("upload_file", {}, filerequestBody).then((data) => {
          if (data["responseCode"] == 200) {
            request_list?.forEach((property: any) => {
              if (property.propertyName == "image") {
                property.value = data.file_id;
              }
            });
            this.create_asset(requestBody);
          }
        });
      };
      picReader.readAsDataURL(image_value[0]["value"][0]);
      return;
    }
    request_list = this.state.asset_property.filter((property: any) => property.propertyName !== "image");
    if (isBrowser) {
      let requestBody = {
        assetName: this.props.asset_properties["assetName"],
        assetTypeId: this.props.asset_properties["assetTypeId"],
        properties: request_list,
        asset_id_value: this.props.asset_properties["asset_id_value"],
      };
      this.create_asset(requestBody);
    } else {
      let requestBody = {
        assetName: this.reduxStore["requestBody"]["assetName"],
        assetTypeId: this.reduxStore["requestBody"]["assetTypeId"],
        properties: request_list,
        asset_id_value: this.reduxStore["requestBody"]["asset_id_value"],
      };
      this.create_asset(requestBody);
    }
  };

  update_changes = () => {
    let request_list = this.props.asset_properties["properties"].filter((property: any) => property.propertyName !== "cpe_id");
    let requestBody = {
      assetName: this.props.asset_properties["assetName"],
      assetTypeId: this.props.asset_properties["assetTypeId"],
      properties: request_list,
      asset_id_value: this.props.asset_properties["asset_id_value"],
      status: "Offline",
    };
    let image_value = this.props.asset_properties["properties"].filter((property: any) => property.propertyName === "image");
    let image_name = image_value[0]["value"][0].name;
    if (image_name !== undefined) {
      let picReader = new FileReader();
      let filerequestBody = {};
      let fileName = image_value[0]["value"][0].name;
      let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
        };
        requestAPICall("upload_file", {}, filerequestBody).then((data) => {
          if (data["responseCode"] == 200) {
            request_list?.forEach((property: any) => {
              if (property.propertyName == "image") {
                property.value = data.file_id;
              }
            });
            this.edit_asset(requestBody);
          }
        });
      };
      picReader.readAsDataURL(image_value[0]["value"][0]);
    } else {
      let new_request_list = request_list.filter((property: any) => property.propertyName !== "image");
      let requestBody = {
        assetName: this.props.asset_properties["assetName"],
        assetTypeId: this.props.asset_properties["assetTypeId"],
        properties: new_request_list,
        asset_id_value: this.props.asset_properties["asset_id_value"],
        status: "Offline",
      };
      this.edit_asset(requestBody);
    }
  };

  edit_asset(requestBody: any) {
    requestAPICall(
      "edit_asset_details",
      {
        asset_type_id: this.props.asset_properties["assetTypeId"],
        asset_id: this.props.asset_properties["selected_asset_id"],
      },
      requestBody
    ).then((data) => {
      this.setState({
        is_loader: false,
        warnMessage: data.message,
        alertbox: true,
      });
    });
  }

  create_asset = (requestBody: any) => {
    requestAPICall("create_asset", { asset_type_id: this.reduxStore["requestBody"]["assetTypeId"] }, requestBody).then((data) => {
      this.setState({
        is_loader: false,
        warnMessage: data.message,
        alertbox: true,
      });
      if (isMobile) {
        this.props.history.push("/map");
      }
    });
  };

  render() {
    return (
      <div>
        {isBrowser && (
          <div className="webonly">
            <div className="row">
              <div className="col-md-9">
                <div className="card apcard">
                  <div className="apcardbody">
                    <div className="row">
                      <div className="col-md-3">
                        <img alt="back-icon-mob" className="backicon" src={BackIcon} />
                        <button type="button" className="backbutton"></button>
                      </div>
                      <div className="col-md-8" style={{ padding: "13px 0" }}>
                        <span className="apheader">{this.state.asset_action == "add" ? "ADDING CPE" : "EDIT CPE"}</span>
                        <span className="apnote float-right" style={{ fontSize: "12px", margin: "5px" }}>
                          3/3
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12 apborder p-0">
                        <span className="apnote">Click where you'd like to drop the CPE</span>
                      </div>
                    </div>

                    {this.props.asset_property?.map((property: any, key: any) => {
                      if (property.dataType !== "string") return null;
                      if (property.isRequired) {
                        const element = (
                          <div className="col-md-12 p-0 m-t-7">
                            <div className="form-label-group m-0">
                              <input
                                ref={(ref) => {
                                  this.apFocus[key] = ref;
                                  return true;
                                }}
                                type="text"
                                id={property.propertyName}
                                name={property.propertyName}
                                disabled={this.state.field_disable}
                                className="accesspointname"
                                value={property.value}
                                placeholder={property.displayName + "*"}
                                onChange={this.onChange.bind(this)}
                              />
                              <label className="pages" htmlFor={property.propertyName}>
                                {property.displayName + " *"}
                              </label>
                            </div>
                          </div>
                        );
                        return element;
                      } else {
                        const element = (
                          <div className="col-md-12 p-0 m-t-7">
                            <div className="form-label-group m-0">
                              <input
                                ref={(ref) => {
                                  this.apFocus[key] = ref;
                                  return true;
                                }}
                                type="text"
                                id={property.propertyName}
                                name={property.propertyName}
                                disabled={this.state.field_disable}
                                className="accesspointname"
                                value={property.value}
                                placeholder={property.displayName + "*"}
                                onChange={this.onChange.bind(this)}
                              />
                              <label className="pages" htmlFor={property.propertyName}>
                                {property.displayName}
                              </label>
                            </div>
                          </div>
                        );
                        return element;
                      }
                    })}
                    <div className="row">
                      <div className="col-md-12 m-t-5">
                        {this.state.asset_action == "add" ? (
                          <button className=" btn savebtn" style={{ bottom: "20px", width: "268px" }} onClick={this.submit_parameters}>
                            Save
                          </button>
                        ) : (
                          <button className=" btn savebtn" style={{ bottom: "20px", width: "268px" }} onClick={this.update_changes}>
                            Update
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="col-md-12 justify-content-flex-end">
                  <div className="heaticon text-center">
                    <img alt="heat-icon" className="heat" src={Heat} />
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div className="geoicon text-center">
                    <img alt="geo-icon" style={{ padding: "5px" }} src={geo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className="mobonly">
            <IonPage>
              <IonContent>
                <div className="param-info-mob " style={{ height: "100vh" }}>
                  <div className="col-12 text-center searchborder" style={{ padding: "10px" }}>
                    <img
                      alt="back-mob"
                      className="backicon-mob"
                      style={{ width: "43px", paddingTop: "6px" }}
                      src={BackIcon}
                      onClick={() => {
                        this.props.history.push("/addCPE2");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.props.history.push("/addCPE2");
                        }
                      }}
                    />
                    <span className="apheader">ADD CPE</span>
                  </div>
                  <div className="container-fluid apdash">
                    <div className="card apeditcard" style={{ height: "calc(100vh - 150px)" }}>
                      <div className="card-body" style={{ overflowY: "auto" }}>
                        <div className="row">
                          <div className="col-12">
                            <span className="apheader">CUSTOMER EQUIPMENT</span>
                          </div>
                        </div>
                        {this.state.add_cp3_property?.map((property: any, key: any) => {
                          if (property.dataType === "string") {
                            if (property.isRequired) {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <input
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      type="text"
                                      id={property.propertyName}
                                      name={property.propertyName}
                                      disabled={this.state.field_disable}
                                      className="accesspointname"
                                      placeholder={property.displayName + "*"}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" htmlFor={property.propertyName}>
                                      {property.displayName + " *"}
                                    </label>
                                  </div>
                                </div>
                              );
                              return element;
                            } else {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <input
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      type="text"
                                      id={property.propertyName}
                                      name={property.propertyName}
                                      disabled={this.state.field_disable}
                                      className="accesspointname"
                                      placeholder={property.displayName + "*"}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" htmlFor={property.propertyName}>
                                      {property.displayName}
                                    </label>
                                  </div>
                                </div>
                              );
                              return element;
                            }
                          }
                        })}
                        <div className="row m-t-10 ">
                          <div className="col-12 ">
                            <button className="lightbtnmob " disabled={false} onClick={this.submit_parameters}>
                              {" "}
                              Save{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </IonContent>
            </IonPage>
          </div>
        )}
        <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />

        <IonLoading isOpen={this.state.is_loader} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} duration={5000} />
      </div>
    );
  }
}

export default withIonLifeCycle(AddCPE3);
