import { IonAlert, IonContent, IonLoading, IonPage } from "@ionic/react";
import React, { Component } from "react";
import logo from "../assets/img/Spectra_logo.png";
import UpdateAvailabe from "../assets/img/update-available.png";
import { requestAPICall } from "../service/apiHandler";
import ConfigJSON from "../config/config.json";
import "./appUpdate.css";
import moment from "moment";

const INITIAL_STATE = {
  updateDate: "",
  currentVersion: "",
  newVersion: "",
  redirectUrl: "",
  loading: false,
  alert: false,
  alertMessage: "",
};

class AppUpdate extends Component {
  state: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount(): void {
    this.setState({ loading: true });
    requestAPICall("version", {}, { env: ConfigJSON["env"] }).then((response: any) => {
      this.setState({ loading: false });
      if (response.status == 200) {
        this.setState({
          newVersion: response.data["version"].toString(),
          updateDate: moment().utc(response.data["timestamp"]).format("Do MMM YYYY"),
          redirectUrl: response.data["url"].toString(),
          currentVersion: ConfigJSON["version"],
        });
      } else {
        this.setState({ alert: true, alertMessage: response.message });
      }
    });
  }

  render() {
    return (
      <IonPage>
        <IonContent className="ion-padding" scrollY={false}>
          <div className="col-wise h-100 w-100 p-2 align-items-center background justify-content-between">
            <img alt="product-logo" src={logo} width="100px" className="pt-4" />
            <img alt="update-available-logo" src={UpdateAvailabe} className="update-available-img mt-4 mb-4" />
            <div className="col-wise align-items-center">
              <label className="update-text">{`We have improved!`}</label>
              <label className="update-subtext">{`Please update the app to continue.`}</label>
            </div>

            <div className="row w-100 mt-2">
              <div className="col text-right col-wise">
                <label className="update-info">{`Update issued on:`}</label>
                <label className="update-info">{`New version:`}</label>
                <label className="update-info">{`Current version:`}</label>
              </div>
              <div className="col text-left col-wise">
                <label className="update-info">{this.state.updateDate}</label>
                <label className="update-info">{this.state.newVersion}</label>
                <label className="update-info">{this.state.currentVersion}</label>
              </div>
            </div>

            <a href={this.state.redirectUrl} target="_blank" className="update-button mt-2 mb-4">
              {"Update"}
            </a>
          </div>

          <IonAlert
            isOpen={this.state.alert}
            onDidDismiss={() => this.setState({ alert: false })}
            message={this.state.alertMessage}
            buttons={["OK"]}
          />
          <IonLoading
            isOpen={this.state.loading}
            onDidDismiss={() => this.setState({ loading: false })}
            message={"Fetching version..."}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default AppUpdate;
