import React from "react";
import { useDropzone } from "react-dropzone";

const Floordropz: React.FC = (props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  let files = acceptedFiles?.map((file: any) => (
    <span key={file.path}>
      {file.path}
      {/* - {file.size} bytes */}
    </span>
  ));
  let classDName = files.length ? "dropzone-uploaded" : "dropzone";
  return (
    <section className="dropzone-set">
      <div style={{ height: "280px" }} {...getRootProps({ className: classDName })}>
        <input {...getInputProps()} />
        {files.length ? (
          <span style={{ color: "#00D56E" }}>
            {/* <div className='row'>
                            <div className='col-md-10 col-10'> */}
            {files}
            {/* </div>
                            <div className='col-md-2 col-2'> */}
            <i
              className="fas fa-times imgclose-file"
              onClick={(e: any) => {
                e.preventDefault();
                files = [];
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  files = [];
                }
              }}
            ></i>
            {/* </div>
                        </div> */}
          </span>
        ) : (
          <div>
            <div style={{ textAlign: "center" }}>
              <i className="fas fa-plus-circle text-center" style={{ padding: "10px", fontSize: "40px", color: "#CCC" }}></i>
            </div>
            <div style={{ textAlign: "center" }}>
              <span className="text-center" style={{ fontSize: "14px" }}>
                Drag and Drop files here
              </span>
            </div>
            <div>
              <span className="browse-set-floor">Browse</span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Floordropz;
