import React from "react";
import "./ap-parameters.css";
import { IonPage, IonContent, withIonLifeCycle } from "@ionic/react";
import BackIcon from "../assets/img/go-back-icon-img.png";
import RFcalculator from "./RFcalculators";
import Linkbudget from "./linkbudget";
import Fresnelzone from "./fresnelzone";
import Antennatilt from "./antennatilt";
import { Plugins, AppState } from "@capacitor/core";
import { withRouter } from "react-router-dom";

const { App } = Plugins;
const { BackgroundTask } = Plugins;
class Calculator extends React.Component {
  state: any = {};
  props: any = {};
  backButton_event: any;
  backButton_timer: any = null;
  appState_event: any;
  constructor(props: any) {
    super(props);
    this.state = {
      assetAction: null,
      show_fresnel_overlay: false,
      show_link_overlay: false,
      show_tilt_overlay: false,
      show_fr_overlay: false,
    };
    this.setPage = this.setPage.bind(this);
    this.hideRFOverlay = this.hideRFOverlay.bind(this);
    this.hideTiltOverlay = this.hideTiltOverlay.bind(this);
    this.hideLinkOverlay = this.hideLinkOverlay.bind(this);
    this.hideFresnelOverlay = this.hideFresnelOverlay.bind(this);
    this.navigateToMap = this.navigateToMap.bind(this);
  }
  componentDidMount() {
    this.set_backButton_listener();
    localStorage.setItem("urlPath", this.props.location.pathname);
  }
  componentWillUnmount() {
    if (this.backButton_timer != null) {
      clearInterval(this.backButton_timer);
      this.backButton_timer = null;
    }
    if (this.backButton_event != null) {
      this.backButton_event.remove();
    }
    if (this.appState_event != null) {
      this.appState_event.remove();
    }
    localStorage.removeItem("backButton");
    localStorage.removeItem("appState");
  }

  backButtonEventListener = () => {
    let backButtonListenerExists = localStorage.getItem("backButton");
    if (backButtonListenerExists == null) {
      localStorage.setItem("backButton", "true");
      this.backButton_event = App.addListener("backButton", (state: any) => {
        // state.isActive contains the active state
        if (this.state.assetAction === "rf") {
          this.hideRFOverlay();
        } else if (this.state.assetAction === "tilt") {
          this.hideTiltOverlay();
        } else if (this.state.assetAction === "fresnel") {
          this.hideFresnelOverlay();
        } else if (this.state.assetAction === "link") {
          this.hideLinkOverlay();
        } else if (this.state.assetAction === null) {
          this.props.history.push("/map/outdoor/main");
        }
      });
    }
    if (this.backButton_timer == null) {
      this.backButton_timer = setInterval(() => {
        this.set_backButton_listener();
      }, 2000);
    }
  };

  appStateEventListener = () => {
    let appStateListenerExists = localStorage.getItem("appState");
    if (appStateListenerExists !== null) return;
    localStorage.setItem("appState", "true");
    this.appState_event = App.addListener("appStateChange", (state: AppState) => {
      if (state.isActive) {
        this.set_backButton_listener();
      } else {
        let taskId = BackgroundTask.beforeExit(async () => {
          // In this function We might finish an upload, let a network request
          // finish, persist some data, or perform some other task
          // Example of long task

          if (this.backButton_timer != null) {
            clearInterval(this.backButton_timer);
            this.backButton_timer = null;
          }
          if (this.backButton_event != null) {
            this.backButton_event.remove();
          }
          localStorage.removeItem("backButton");
          // Must call in order to end our task otherwise
          // we risk our app being terminated, and possibly
          // being labeled as impacting battery life
          BackgroundTask.finish({
            taskId,
          });
        });
      }
    });
  };

  set_backButton_listener = () => {
    this.backButtonEventListener();
    this.appStateEventListener();
  };

  setPage = (page: any) => {
    this.setState({ assetAction: page });

    if (page == "rf") {
      this.setState({
        show_rf_overlay: true,
      });
    }
    if (page == "tilt") {
      this.setState({
        show_tilt_overlay: true,
      });
    }
    if (page == "fresnel") {
      this.setState({
        show_fresnel_overlay: true,
      });
    }
    if (page == "link") {
      this.setState({
        show_link_overlay: true,
      });
    }
  };
  hideRFOverlay() {
    this.setState({
      show_rf_overlay: false,
    });
    this.setPage(null);
  }
  hideTiltOverlay() {
    this.setState({
      show_tilt_overlay: false,
    });
    this.setPage(null);
  }
  hideFresnelOverlay() {
    this.setState({
      show_fresnel_overlay: false,
    });
    this.setPage(null);
  }
  hideLinkOverlay() {
    this.setState({
      show_link_overlay: false,
    });
    this.setPage(null);
  }

  getRFhtml = () => (
    <div
      className="fixed-top"
      id="overlay"
      style={{
        height: "100%",
        display: this.state.show_rf_overlay === true ? "block" : "none",
      }}
    >
      {this.state.show_rf_overlay ? (
        <RFcalculator hideRFOverlay={this.hideRFOverlay} assetInfo={this.state.assetDetails} />
      ) : null}
    </div>
  );

  getTitlHtml = () => (
    <div
      className="fixed-top"
      id="overlay"
      style={{
        height: "100%",
        display: this.state.show_tilt_overlay === true ? "block" : "none",
      }}
    >
      {this.state.show_tilt_overlay ? <Antennatilt hideTiltOverlay={this.hideTiltOverlay} /> : null}
    </div>
  );

  getFresnelHtml = () => (
    <div
      className="fixed-top"
      id="overlay"
      style={{
        height: "100%",
        display: this.state.show_fresnel_overlay === true ? "block" : "none",
      }}
    >
      {this.state.show_fresnel_overlay ? <Fresnelzone hideOverlay={this.hideFresnelOverlay} /> : null}
    </div>
  );

  getLinkHtml = () => (
    <div
      className="fixed-top"
      id="overlay"
      style={{
        height: "100%",
        display: this.state.show_link_overlay === true ? "block" : "none",
      }}
    >
      {this.state.show_link_overlay ? <Linkbudget hideOverlay={this.hideLinkOverlay} /> : null}
    </div>
  );

  renderer() {
    switch (this.state.assetAction) {
      case "rf":
        return this.getRFhtml();
      case "tilt":
        return this.getTitlHtml();
      case "fresnel":
        return this.getFresnelHtml();
      case "link":
        return this.getLinkHtml();

      default:
        return null;
    }
  }
  navigateToMap() {
    this.props.history.push("/map/outdoor/main");
  }
  render() {
    return (
      <div className="mobonly">
        {this.renderer()}
        <IonPage>
          <IonContent>
            <div className="param-info-mob" style={{ height: "100vh" }}>
              <div className="col-12 text-center searchborder" style={{ padding: "10px", paddingTop: "50px" }}>
                <img
                  alt="back-icon-calc"
                  className="backicon-mob"
                  style={{ width: "43px", paddingTop: "6px" }}
                  onClick={this.navigateToMap}
                  src={BackIcon}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.navigateToMap();
                    }
                  }}
                />
                <span className="apheader">CALCULATOR</span>
              </div>

              <div className="col-12 p-0">
                <div className="container-fluid notidash">
                  <div className="card noticard">
                    <div
                      className="card-body"
                      onClick={() => {
                        this.setPage("rf");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.setPage("rf");
                        }
                      }}
                    >
                      RF Calculators
                    </div>
                  </div>
                  <div className="card noticard">
                    <div
                      className="card-body"
                      onClick={() => {
                        this.setPage("tilt");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.setPage("tilt");
                        }
                      }}
                    >
                      Antenna and Tilt Calculators
                    </div>
                  </div>
                  <div className="card noticard">
                    <div
                      className="card-body"
                      onClick={() => {
                        this.setPage("fresnel");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.setPage("fresnel");
                        }
                      }}
                    >
                      Fresnel zone
                    </div>
                  </div>
                  <div className="card noticard">
                    <div
                      className="card-body"
                      onClick={() => {
                        this.setPage("link");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.setPage("link");
                        }
                      }}
                    >
                      Link Budget
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      </div>
    );
  }
}

export default withRouter(withIonLifeCycle(Calculator));
