import { IonContent, IonAlert, IonLoading, withIonLifeCycle, IonPage } from "@ionic/react";
import React, { Component } from "react";
import logo from "../assets/img/Spectra_logo.png";
import { requestAPICall } from "../service/apiHandler";
import "./Login.css";
import { Plugins } from "@capacitor/core";
import { withRouter } from "react-router-dom";
const { Network } = Plugins;

const INITIAL_STATE = {
  rePassword: "",
  password: "",
  rePasswordType: "password",
  passwordType: "password",
  token: "",
  submit: false,
  showLoading: false,
  alertbox: false,
  warnMessage: "",
  success: false,
  isNewCreation: false,
};

class Password extends Component {
  state: any = {};
  props: any = {};

  constructor(props: any) {
    super(props);
    console.log(this.props);
    this.state = { ...INITIAL_STATE };
    this.setShowAlert = this.setShowAlert.bind(this);
    this.setShowLoading = this.setShowLoading.bind(this);
    this.clearfields = this.clearfields.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.formValidation = this.formValidation.bind(this);
    this.callChangePasswordAPI = this.callChangePasswordAPI.bind(this);
    this.handleViewPassword = this.handleViewPassword.bind(this);
    this.handleViewRePassword = this.handleViewRePassword.bind(this);
  }

  componentDidMount() {
    this.clearfields();
    const queryString = window.location.href;
    if (queryString.includes("token")) {
      const splitted = queryString.split("token=");
      const urlToken = splitted[1];
      this.setState({ token: urlToken, isNewCreation: false });
    } else {
      this.setState({
        warnMessage: "Token not found",
        alertbox: true,
      });
    }
  }

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  setShowLoading = (st: boolean) => {
    this.setState({ showLoading: st });
  };

  clearfields = () => {
    this.setState({
      rePassword: "",
      password: "",
    });
  };

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  submitForm = async (e: any) => {
    this.formValidation();
    e.preventDefault();
    let status = await Network.getStatus();
    console.log(status);
    if (status.connected) {
      if (this.state.password != "" && this.state.rePassword != "" && this.state.password === this.state.rePassword && this.state.token != "") {
        this.callChangePasswordAPI();
      }
      this.setState({
        submit: true,
      });
    } else {
      this.setState({
        warnMessage: "Check for internet connectivity",
        alertbox: true,
      });
    }
  };

  formValidation() {
    if (this.state.password == "") {
      this.setState({
        warnMessage: "Please enter password",
        alertbox: true,
      });
    } else if (this.state.rePassword == "") {
      this.setState({
        warnMessage: "Please re-enter password",
        alertbox: true,
      });
    } else if (this.state.rePassword !== this.state.password) {
      this.setState({
        warnMessage: "passwords do not match",
        alertbox: true,
      });
    } else if (this.state.token == "") {
      this.setState({
        warnMessage: "Invalid token",
        alertbox: true,
      });
    } else {
      this.setState({
        submit: true,
      });
    }
  }
  callChangePasswordAPI() {
    this.setState({
      showLoading: true,
    });
    let requestBody = {};
    let api = "";
    if (!this.state.isNewCreation) {
      requestBody = {
        token: this.state.token,
        newPassword: this.state.password,
      };
      api = "forgot_password";
    } else {
      requestBody = {
        username: this.state.token,
        newPassword: this.state.password,
        resetEmpPassword: true,
      };
      api = "change_password";
    }

    requestAPICall(api, {}, requestBody)
      .then((data) => {
        this.setState({
          showLoading: false,
        });
        console.log(data);
        if (data.responseCode == 200) {
          this.setState({ success: true });
        } else {
          this.setState({
            warnMessage: data.message,
            isLoggedIn: false,
          });
          this.setState({
            alertbox: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          showLoading: false,
          isLoggedIn: false,
        });
        console.log(error);
        this.setState({
          warnMessage: "Error occurred",
        });
        this.setState({
          alertbox: true,
        });
      });
  }

  handleViewPassword = (e: any, type: any) => {
    if (type === "password") {
      if (this.state.passwordType === "text") {
        this.setState({
          passwordType: "password",
        });
      } else {
        this.setState({
          passwordType: "text",
        });
      }
    }
  };

  handleViewRePassword = (e: any, type: any) => {
    if (type === "rePassword") {
      if (this.state.rePasswordType === "text") {
        this.setState({
          rePasswordType: "password",
        });
      } else {
        this.setState({
          rePasswordType: "text",
        });
      }
    }
  };

  render() {
    const { password, rePassword } = this.state;
    const isEnabled = rePassword.length > 0 && password.length > 0;
    return (
      <IonPage>
        <IonContent>
          <div className="webonly">
            <div className="app-body img-responsive" style={{ marginTop: "0px" }}>
              <div className="row m-r-0 m-l-0">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-7 login-bg">
                      <div className="row bg-text bg-slogan">
                        <div className="col-md-12">
                          <span>Your Signal.</span>
                          <br />
                          <span style={{ lineHeight: "1.8" }}>Your Company.</span>
                          <br />
                          <span style={{ lineHeight: "1.8" }}>Your Promise to Customers.</span>
                        </div>
                      </div>
                    </div>
                    {this.state.success ? (
                      <div className="col-md-5 login-logo">
                        <div className="row m-r-0 m-l-0 logo">
                          <div className="col-md-12">
                            <img alt="product-logo" className="img-center img" src={logo} />
                            <div className="p-r-10 p-l-10 m-t-60">
                              <span className="login-text img-center">Your password has been updated.</span>
                            </div>
                            <div className="p-r-10 p-l-10">
                              <span className="login-text img-center">Kindly login through the home page.</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-5 login-logo">
                        <div className="row m-r-0 m-l-0 logo">
                          <div className="col-md-12">
                            <img alt="prod-logo-mob" className="img-center img" src={logo} />
                          </div>
                        </div>
                        <form onSubmit={this.submitForm}>
                          <div className="row m-r-0 m-l-0" style={{ paddingTop: "10%" }}>
                            <div className="col-md-12 col-sm-12 homecontent">
                              <span className="login-text img-center">Set New Password</span>
                              <div className="row justify-content-center m-t-50">
                                <div className="form-label-group m-0">
                                  <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    maxLength={20}
                                    className="text-pwd-border img-center b-t-0"
                                    placeholder="Password"
                                    onChange={this.onChange}
                                  />
                                  <label htmlFor="password">Password</label>
                                </div>
                              </div>
                              <div className="row justify-content-center">
                                <div className="form-label-group m-0">
                                  <input
                                    type="password"
                                    id="rePassword"
                                    name="rePassword"
                                    maxLength={20}
                                    className="text-pwd-border img-center b-t-0"
                                    placeholder="Re-Enter Password"
                                    onChange={this.onChange}
                                  />
                                  <label htmlFor="rePassword">Re-Enter Password</label>
                                </div>
                              </div>
                              <div className="img-center justify-content-center" style={{ display: "grid" }}>
                                <button className="btn btn-log m-t-50" disabled={!isEnabled} type="submit">
                                  Set Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobonly">
            <div className="app-body img-responsive" style={{ marginTop: "0px" }}>
              <div className="row m-r-0 m-l-0">
                <div className="col-md-12">
                  <div className="row">
                    {this.state.success ? (
                      <div className="col-md-12 login-logo">
                        <div className="col-md-12">
                          <img alt="prod-icon-mob-2" className="img-center img" src={logo} />
                          <div className="p-r-0 p-l-0 m-t-60">
                            <span className="login-text img-center" style={{ width: "100%" }}>{`Your password has been updated.`}</span>
                            <span className="login-text img-center" style={{ width: "100%" }}>{`This window can be closed now.`}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-5 login-logo">
                        <div className="row m-r-0 m-l-0 logo">
                          <div className="col-md-12">
                            <img alt="prod-icon-mob-3" className="img-center img" src={logo} />
                          </div>
                        </div>
                        <form onSubmit={this.submitForm}>
                          <div className="row m-r-0 m-l-0" style={{ paddingTop: "10%" }}>
                            <div className="col-md-12 col-sm-12 welcome " style={{ marginTop: "36px", marginBottom: "32px" }}>
                              <span className="login-text img-center" style={{ width: "100%" }}>
                                Set New Password
                              </span>
                            </div>
                            <div className="col-md-12 col-sm-12 homecontent p-r-16 p-l-16 m-t-10">
                              <div className="m-t-10 justify-content-center">
                                <i
                                  className="far fa-eye eyeicon"
                                  onClick={(e) => this.handleViewPassword(e, "password")}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.handleViewPassword(e, "password");
                                    }
                                  }}
                                ></i>
                                <div className="form-label-group m-0">
                                  <input
                                    type={this.state.passwordType}
                                    id="passwordmob"
                                    name="password"
                                    maxLength={20}
                                    className="text-pwd img-center"
                                    placeholder="Password"
                                    onChange={this.onChange}
                                  />
                                  <label htmlFor="passwordmob">Password</label>
                                </div>
                              </div>

                              <div className="justify-content-center">
                                <i
                                  className="far fa-eye eyeicon"
                                  onClick={(e) => this.handleViewRePassword(e, "rePassword")}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.handleViewRePassword(e, "rePassword");
                                    }
                                  }}
                                ></i>
                                <div className="form-label-group m-0">
                                  <input
                                    type={this.state.rePasswordType}
                                    id="repasswordmob"
                                    name="rePassword"
                                    maxLength={20}
                                    className="text-pwd img-center"
                                    placeholder="Re-Enter Password"
                                    onChange={this.onChange}
                                  />
                                  <label htmlFor="repasswordmob">Re-Enter Password</label>
                                </div>
                              </div>

                              <div className="img-center justify-content-center d-block d-grid">
                                <button className="btn btn-log m-t-12" type="submit" disabled={!isEnabled}>
                                  Set Password
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />

          <IonLoading
            isOpen={this.state.showLoading}
            onDidDismiss={() => this.setShowLoading(false)}
            message={"Loading..."}
            // duration={100}
            animated={true}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default withRouter(withIonLifeCycle(Password));
