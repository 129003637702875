import { IonAlert, IonCol, IonFab, IonFabButton, IonLoading, IonRow } from "@ionic/react";
import React, { Component } from "react";
import { isBrowser } from "react-device-detect";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { default as AP, default as apicon } from "../assets/img/Apicon.png";
import measureIcon from "../assets/img/Measure-icon-img.png";
import { default as addCPEIcon, default as addDASIcon } from "../assets/img/add-cpe-icon-img.png";
import calcmob from "../assets/img/align-icon.png";
import edit from "../assets/img/edit.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import { default as Heat, default as heatIcon } from "../assets/img/heaticon.png";
import Lake from "../assets/img/lake.png";
import rmaLogo from "../assets/img/rma_logo.svg";
import SearchIcon from "../assets/img/search_icon.png";
import geo from "../assets/img/target-icon.png";
import { ASSET, COORDINATES, CURRENT_TAB } from "../constants/actionTypes";
import { requestAPICall } from "../service/apiHandler";
import store from "../store";
import Acpmob from "./access-point-details-mob";
import "./access-point-details.css";
import Apreplace from "./access-point-replace";
import APMoreInfo from "./ap-more-info";
import Modal from "./modal";
import Params from "./parameters-mob";
import Claims from "../service/claims";

const INITIAL_STATE = {
  card1: false,
  card2: false,
  show: true,
  alertbox: false,
  isLoading: false,
  assetAction: null,
  assetDetails: {},
  assetName: "",
  operationalStatus: "",
  latitude: 0,
  longitude: 0,
  imageSrc: "",
  height: 0,
  image: "",
  button: false,
  button1: false,
  button2: false,
  show_overlay: false,
  show_param_overlay: false,
  show_replace_overlay: false,
  predictionsList: [],
  search_list: [],
  ap_cpe_list: [],
  lat_search: 0,
  lng_search: 0,
  bar: true,
  measureIcon: "",
  heatIcon: "",
  editLocationEnabled: false,
  locationEdited: false,
  mountingType: "",
  enableReplacement: false,
};
let rad_center: any;
let lat: any;
let lng: any;
let addressPredictions: any = {};

interface Iprops {
  back: Function;
  addAP: any;
  addCPE: any;
  measure: any;
  enableAssetDrop: any;
  setCurrentPosition: any;
  calculate: any;
  set_values: any;
  set_subcard: any;
  changeMapCenter: any;
  updateSearchText: any;
  setAddress: any;
  next6: any;
  refreshAsset: Function;
  latlong_apcpe: any;
  placeMarker: Function;
  removeMarker: Function;
  azimuthClickedGoBack: Function;
  resetToOutdoor: Function;
  changeMapToSatellite: any;
  zoomPlus: any;
  zoomMinus: any;
  vanishInfoPopup: Function;
}
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  upadateAssetTypeId: (value: any) => dispatch({ type: ASSET, key: "assetTypeId", value }),
  updateNOS: (value: any) => dispatch({ type: ASSET, key: "NOS", value }),
  updateSectorId: (value: any) => dispatch({ type: ASSET, key: "sectorId", value }),
  updateAssetVendorId: (value: any) => dispatch({ type: ASSET, key: "assetVendorId", value }),
  updateCPEparams: (value: any) => dispatch({ type: ASSET, key: "CPEparams", value }),
  updateNetworkScreenType: (value: any) => dispatch({ type: CURRENT_TAB, key: "networkScreenType", value }),
  upadateSearchMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "asset_search", value }),
  upadateMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "coordinates", value }),
});

class APdetails extends Component<Iprops> {
  state: any = {};
  props: any = {};
  autocompleteInput: any;

  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.confirmClicked = this.confirmClicked.bind(this);
    this.editLocationClicked = this.editLocationClicked.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.assetdelete = this.assetdelete.bind(this);
    this.hideParamOverlay = this.hideParamOverlay.bind(this);
    this.hideReplaceOverlay = this.hideReplaceOverlay.bind(this);
    this.hideNetworkOverlay = this.hideNetworkOverlay.bind(this);
    this.backToMap = this.backToMap.bind(this);
    this.autocompleteInput = React.createRef();
  }

  componentDidMount() {
    if ("azimuthForEdit" in this.reduxStore) {
      this.setState({ assetAction: "details", show_overlay: true });
    }
    this.get_asset_details();
  }

  componentDidUpdate() {
    this.reduxStore = store.getState()["general"];
  }

  showModal1 = () => {
    this.setState({
      card1: true,
    });
  };
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  setShowLoading = (st: any) => {
    this.setState({ isLoading: st });
  };
  hideModal1 = () => {
    this.setState({
      card1: false,
    });
  };
  showModal2 = () => {
    this.setState({
      card2: true,
    });
  };
  hideModal2 = () => {
    this.setState({
      card2: false,
    });
  };
  button_change = (e: any) => {
    this.setState((prevValues: any) => ({
      button: !prevValues.button,
    }));
  };
  button1_change = (e: any) => {
    this.setState((prevValues: any) => ({
      button1: !prevValues.button1,
    }));
  };
  button2_change = (e: any) => {
    this.setState((prevValues: any) => ({
      button2: !prevValues.button2,
    }));
  };
  addAP = (e: any) => {
    e.preventDefault();
    this.props.addAP();
  };
  addCPE = (e: any) => {
    e.preventDefault();
    this.props.addCPE();
  };

  measure = (e: any) => {
    e.preventDefault();
    this.props.measure();
  };

  setCurrentPosition = () => {
    this.props.setCurrentPosition();
  };

  delete_asset() {
    let requestbody = {};
    this.setShowLoading(true);
    requestAPICall("delete_asset", { asset_id: this.reduxStore["assetId"] }, requestbody).then((data) => {
      this.setShowLoading(false);
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        this.hideModal2();
        this.hideModal1();
        setTimeout(() => {
          this.props.set_subcard(null);
          this.props.refreshAsset(this.reduxStore["assetId"]);
          this.props.back();
        }, 500);
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  }
  hideOverlay() {
    this.props.set_subcard(null);
    if (this.state.locationEdited) {
      this.setState({ locationEdited: false });
    }
    this.setState({
      show_overlay: false,
    });
    this.setPage(null);
  }
  hideParamOverlay() {
    this.props.set_subcard(null);
    this.setState({
      show_param_overlay: false,
    });
    this.setPage(null);
  }
  hideReplaceOverlay() {
    this.props.set_subcard(null);
    this.setState({
      show_replace_overlay: false,
    });
    this.setPage(null);
  }
  hideNetworkOverlay() {
    this.props.set_subcard(null);
    this.setState({
      show_network_overlay: false,
    });
    this.setPage(null);
  }
  replaceSerialNumber() {
    this.setPage("details");
  }
  listAssets = () => {
    this.props.back();
  };
  assetdelete() {
    this.props.next6();
  }
  backToMap() {
    this.props.refreshAsset(this.reduxStore["assetId"]);
  }
  confirmClicked() {
    this.props.removeMarker();
    this.setState({ editLocationEnabled: false, locationEdited: true });
    this.setPage("details");
  }
  editLocationClicked() {
    this.props.placeMarker();
    this.setState({ editLocationEnabled: true });
  }

  renderer() {
    switch (this.state.assetAction) {
      case "parameter":
        return (
          <div
            className="fixed-top"
            id="overlay"
            style={{
              height: "100%",
              display: this.state.show_param_overlay === true ? "block" : "none",
            }}
          >
            {this.state.show_param_overlay ? (
              <Params
                vanishPopup={() => {
                  this.props.vanishInfoPopup();
                }}
                refreshAsset={(id: any) => {
                  this.props.refreshAsset(id);
                }}
                refreshStatus={() => {
                  this.get_asset_details();
                }}
                hideParamOverlay={this.hideParamOverlay}
                assetInfo={this.state.assetDetails}
                backToAP={this.backToMap}
              />
            ) : null}
          </div>
        );
      case "network":
        return <Redirect to="/network-status-mob" />;
      case "details":
        return (
          <div
            className="fixed-top"
            id="overlay"
            style={{
              height: "100%",
              display: this.state.show_overlay === true ? "block" : "none",
            }}
          >
            {this.state.show_overlay ? (
              <Acpmob
                vanishPopup={() => {
                  this.props.vanishInfoPopup();
                }}
                refreshAsset={(id: any) => {
                  this.props.refreshAsset(id);
                }}
                azimuthClickedGoBackAcpmob={(lat: any, lng: any) => this.props.azimuthClickedGoBack(lat, lng)}
                getParticularAsset={() => this.get_asset_details()}
                editLocation={this.editLocationClicked}
                locationEdited={this.state.locationEdited}
                hideOverlay={this.hideOverlay}
                deleteAssetConfrm={this.assetdelete}
                listAssets={this.listAssets}
              />
            ) : null}
          </div>
        );
      case "replace":
        return (
          <div
            className="fixed-top"
            id="overlay"
            style={{
              height: "100%",
              display: this.state.show_replace_overlay === true ? "block" : "none",
            }}
          >
            {this.state.show_replace_overlay ? (
              <Apreplace
                assetInfo={this.state.assetDetails}
                hideReplaceOverlay={this.hideReplaceOverlay}
                replaceSerialNumber={() => {
                  this.replaceSerialNumber();
                }}
              />
            ) : null}
          </div>
        );

      case "map":
        return <Redirect from="/map" to="/map" />;
      default:
        return null;
    }
  }

  setPage = (page: any) => {
    localStorage.setItem("networkScreenType", "detailed");
    this.setState({ assetAction: page });
    if (page == "details") {
      this.props.set_subcard(page);
      this.setState({
        show_overlay: true,
      });
    }
    if (page == "parameter") {
      this.props.set_subcard(page);
      this.setState({
        show_param_overlay: true,
      });
    }
    if (page == "network") {
      this.props.set_subcard(page);
      this.setState({
        show_network_overlay: true,
      });
    }
    if (page == "replace") {
      if (this.state.enableReplacement) {
        this.props.set_subcard(page);
        this.setState({
          show_replace_overlay: true,
        });
      } else {
        this.setState({
          warnMessage: "Replacement cannot be done unless all the sectors are submitted to SAS.",
          alertbox: true,
        });
      }
    }
  };
  addDAS = (e: any) => {
    e.preventDefault();
    this.props.addAP();
  };
  get_asset_details = () => {
    let requestBody = {};
    this.setShowLoading(true);
    this.setState({ image: "" });
    requestAPICall("get_asset_details", { asset_id: this.reduxStore["assetId"] }, requestBody).then((data) => {
      this.setShowLoading(false);
      data.properties?.map((data: any) => {
        if (data.propertyName == "radiation_center") {
          rad_center = data["value"];
        }
        if (data.propertyName == "latitude") {
          lat = data["value"];
        }
        if (data.propertyName == "longitude") {
          lng = data["value"];
        }
        if (data.propertyName == "ap_mounting_type") {
          this.setState({ mountingType: data.value });
        }
      });

      if (Object.keys(data.sasStatus).length == 0) {
        this.setState({ enableReplacement: false });
      } else {
        let count = 0;
        for (const key in data.sasStatus) {
          console.log("sas status split: ", data.sasStatus[key].split(","));
          if (data.sasStatus[key].split(",")[0] == "4" || data.sasStatus[key].split(",")[0] == "5") {
            count = count + 1;
          }
        }
        if (data["assetTypeId"] == 6) {
          if (count == Object.keys(data.sasStatus).length) {
            this.setState({ enableReplacement: true });
          } else {
            this.setState({ enableReplacement: false });
          }
        } else if (count == data["sector_id"].length) {
          this.setState({ enableReplacement: true });
        } else {
          this.setState({ enableReplacement: false });
        }
      }

      this.props.set_values(lat, lng, rad_center);
      if (data.responseCode == 200) {
        this.setState({ assetDetails: data });
        if (data.assetTypeId !== 1) {
          this.props.updateCPEparams(data.installation_params);
        }
        this.props.updateSectorId(data.sector_id);
        this.props.upadateAssetTypeId(data.assetTypeId);
        this.props.updateAssetVendorId(data.assetVendorId);
        data.properties?.map((property: any) => {
          if (property.propertyName === "no_of_sectors") {
            this.props.updateNOS(parseInt(property.value));
          }
          if (property.propertyName === "image") {
            this.setState({ image: property.value });
          } else if (property.propertyName === "latitude") {
            this.setState({ latitude: property.value });
          } else if (property.propertyName === "longitude") {
            this.setState({ longitude: property.value });
          } else if (property.propertyName === "radiation_center") {
            this.setState({ height: property.value });
          }
        });
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };
  switch = () => {
    this.setState(
      (prevValues: any) => ({
        bar: !prevValues.bar,
        searches: "",
        ap_cpe_list: [],
        search_list: [],
        predictionsList: [],
      }),
      () => {
        if (this.state.bar) {
          this.props.set_subcard(null);
        } else {
          this.props.set_subcard("search");
        }
        if (!this.state.bar) {
          this.list_asset();
        }
      }
    );
  };
  onChange(e: any) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.list_asset_search(this.state.searches);
      }
    );
  }
  onSearch = (e: any) => {
    this.setState({
      searches: e.target.value,
    });
    const predict = this.getQuery();
    this.setState({
      predictionsList: predict,
    });
  };
  getQuery = () => {
    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
    return addressPredictions;
  };
  predictionCallback = (predictions: any, status: any) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) return;

    addressPredictions = predictions;
  };
  list_asset_search = (searches: any) => {
    this.setState((prevValues: any) => ({
      search_list: prevValues.asset_list.filter((asset: any) => asset.assetIdentifierId.toUpperCase().includes(prevValues.searches.toUpperCase())),
    }));
  };
  details = (details: any) => {
    this.setState({
      searches: details.assetIdentifierId,
    });
    let coordinates = {
      latitude: details.latitude,
      longitude: details.longitude,
    };
    details["coOrdinates"] = coordinates;
    this.setState(
      {
        lat_search: details["latitude"],
        lng_search: details["longitude"],
      },
      () => {
        let locmark = true;
        this.props.upadateSearchMapCoordinates({
          lat: parseFloat(details["latitude"]),
          long: parseFloat(details["longitude"]),
        });
        this.props.upadateMapCoordinates({
          latitude: parseFloat(details["latitude"]),
          longitude: parseFloat(details["longitude"]),
        });
        this.props.changeMapCenter(this.state.lat_search, this.state.lng_search);
        this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, details);
      }
    );
  };
  setPlace = (value: any) => {
    this.setState(
      {
        searches: value,
        bar: true,
      },
      () => {
        this.props.updateSearchText(this.state.searches);
        this.props.setAddress(value, true);
      }
    );
  };
  list_asset = () => {
    let requestBody = {};
    requestAPICall("list_asset", {}, requestBody).then((data: any) => {
      let list: any = [];
      data["assets"]?.forEach((element: any) => {
        if (this.reduxStore["tabType"] == "outdoor") {
          if (element["assetTypeId"] === 1 || element["assetTypeId"] === 3) {
            list.push(element);
          }
        }
        if (this.reduxStore["tabType"] == "indoor") {
          if (element["assetTypeId"] === 2 || element["assetTypeId"] === 5) {
            list.push(element);
          }
        }
      });
      this.setState({
        asset_list: list,
        ap_cpe_list: list,
      });
    });
  };

  addButtonMobDecideHtml = () => {
    if (this.reduxStore["tabType"] == "outdoor" && !this.state.button) {
      return (
        <IonCol size="4" style={{ padding: "5px" }}>
          <img alt="cpeicon" className="cpeicon" src={addCPEIcon} style={{ width: "38px" }} />
          {this.state.button1 ? (
            <button
              type="button"
              className="cardbutton p-l-25 "
              style={{
                background: "#61d76f",
                color: "#fff",
                border: "none",
              }}
              onClick={(e: any) => {
                this.button1_change(e);
                this.addCPE(e);
                this.props.enableAssetDrop(false);
              }}
            >
              Create
            </button>
          ) : (
            <button
              type="button"
              className="cardbutton p-l-25 d-none"
              onClick={(e: any) => {
                this.button1_change(e);
                this.props.enableAssetDrop(true);
              }}
            >
              Add CPE
            </button>
          )}
        </IonCol>
      );
    } else if (this.reduxStore["tabType"] == "outdoor") {
      return (
        <IonCol size="0" style={{ padding: "5px", filter: "contrast(0.9)" }}>
          <img
            alt="cpeicon"
            className="cpeicon"
            src={addCPEIcon}
            style={{
              width: "38px",
              filter: "brightness(0) invert(0.9)",
            }}
          />
          <button type="button" className="cardbutton p-l-25 b-none d-none" style={{ border: "none" }} disabled>
            Add CPE
          </button>
        </IonCol>
      );
    } else return null;
  };

  addButtonMobIndoorHtml = () => {
    if (this.reduxStore["tabType"] == "indoor" && !this.state.button1) {
      return (
        <IonCol size="4" style={{ padding: "5px" }}>
          <img alt="dasicon" className="dasicon" src={addDASIcon} style={{ width: "38px" }} />
          {this.state.button2 ? (
            <button
              type="button"
              className="cardbutton p-l-25 "
              style={{
                background: "#61d76f",
                color: "#fff",
                border: "none",
              }}
              onClick={(e: any) => {
                this.button2_change(e);
                this.addDAS(e);
                this.props.enableAssetDrop(false);
              }}
            >
              Create
            </button>
          ) : (
            <button
              type="button"
              className="cardbutton p-l-25 "
              onClick={(e: any) => {
                this.button1_change(e);
                this.props.enableAssetDrop(true);
              }}
            >
              Add DAS
            </button>
          )}
        </IonCol>
      );
    } else if (this.reduxStore["tabType"] == "indoor") {
      return (
        <IonCol size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
          <img
            alt="cpeicon"
            className="cpeicon"
            src={addDASIcon}
            style={{
              width: "38px",
              filter: "brightness(0) invert(0.9)",
            }}
          />
          <button type="button" className="cardbutton p-l-25 b-none" style={{ border: "none" }} disabled>
            Add DAS
          </button>
        </IonCol>
      );
    } else return null;
  };

  render() {
    const isEnabled = !this.state.button1 && !this.state.button && !this.state.button2;
    return (
      <div>
        {isBrowser ? (
          <div className="row webonly">
            <div className="col-md-5">
              <div className="card apcard">
                <div className="card-body" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        alt="backicon"
                        className="backicon"
                        src={BackIcon}
                        onClick={this.props.back}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.props.back();
                          }
                        }}
                      />
                      <button type="button" className="backbutton"></button>
                    </div>
                    <div className="col-md-9 m-r-m" style={{ padding: "13px 0" }}>
                      <span className="apheader">Access Point Details</span>
                    </div>
                  </div>
                  <div className="row m-t-5" style={{ padding: "0px 35px", position: "relative" }}>
                    <span className="">
                      <i
                        className="fas fa-times imgclose"
                        data-toggle="modal"
                        data-target="#deleteimageModal"
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.showModal2();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            this.showModal2();
                          }
                        }}
                      ></i>
                    </span>
                    <img alt="lake" className="apimage" src={Lake} />
                  </div>
                  <div className="row " style={{ padding: "0px 35px" }}>
                    <span className="apheader" style={{ textTransform: "none" }}>
                      Piedmont Lake
                    </span>
                    <span className="distance">. 270ft</span>
                  </div>
                  <div className="row " style={{ padding: "0px 35px" }}>
                    <div
                      className="col-md-12"
                      style={{
                        padding: "0 0 0px 0",
                        borderBottom: "1px solid rgba(216, 216, 216, 0.34)",
                      }}
                    >
                      <p className="apdetails">
                        This is the description for this access point which could be short or long. We will have to implement scrolling measures in
                        the case it does get long.
                      </p>
                    </div>
                  </div>
                  <div className="row" style={{ padding: "0px 35px" }}>
                    <div className="col-md-6 p-0">
                      <span className="distance" style={{ fontSize: "13px" }}>
                        Status:
                      </span>
                      <span className="distance apinfo" style={{ color: "#E22B2B", paddingLeft: "5px" }}>
                        Offline
                      </span>
                    </div>
                    <div className="col-md-6">
                      <span className="distance" style={{ fontSize: "13px" }}>
                        ID:
                      </span>
                      <span className="distance apinfo" style={{ paddingLeft: "5px" }}>
                        123456
                      </span>
                    </div>
                  </div>
                  <div className="row " style={{ padding: "0px 35px" }}>
                    <div className="col-md-6 p-0">
                      <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                        Latitude:
                      </span>
                      <span className="distance apinfo">43.197167</span>
                    </div>
                    <div className="col-md-6">
                      <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                        Longitude:
                      </span>
                      <span className="distance apinfo">43.197167</span>{" "}
                    </div>
                  </div>
                  <div className="row" style={{ padding: "0px 35px" }}>
                    <div className="col-md-6 p-0">
                      <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                        Serial #:
                      </span>
                      <span className="distance apinfo">123456</span>
                    </div>
                    <div className="col-md-6">
                      <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                        Sector 1 ID #:
                      </span>
                      <span className="distance apinfo">1</span>{" "}
                    </div>
                  </div>
                  <div className="row " style={{ padding: "0px 35px" }}>
                    <div className="col-md-6 p-0">
                      <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                        Sector 2 ID #:
                      </span>
                      <span className="distance apinfo">2</span>
                    </div>
                    <div className="col-md-6">
                      <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                        Sector 3 ID #:
                      </span>
                      <span className="distance apinfo">3</span>{" "}
                    </div>
                  </div>

                  <div className="row " style={{ padding: "0px 35px" }}>
                    <i className="fas fa-pen editicon"></i>
                    <button className="editbtn distance" style={{ color: "#0073FF" }}>
                      Edit
                    </button>
                  </div>
                  <div className="row m-t-5" style={{ padding: "0px 35px" }}>
                    <i className="fas fa-trash deleteicon"></i>
                    <button
                      className="deletebtn distance"
                      style={{ color: "#E22B2B" }}
                      data-toggle="modal"
                      data-target="#deleteModal"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.showModal1();
                      }}
                    >
                      Delete AP
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-md-12 justify-content-flex-end" style={{ display: "none" }}>
                <div className="heaticon text-center">
                  <img alt="heat" className="heat" src={Heat} />
                </div>
              </div>

              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img alt="geolocation" style={{ padding: "5px" }} src={geo} />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <APMoreInfo />
            </div>
          </div>
        ) : (
          <div className="mobonly">
            {this.renderer()}
            <div>
              <div style={{ position: "fixed", bottom: "230px", left: "20px" }}>
                {this.state.bar ? (
                  <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                    <IonFabButton className="backg" onClick={this.props.zoomPlus} color="light" size="small" style={{ marginBottom: "10px" }}>
                      <i className="fas fa-plus" style={{ color: "#5b5e62", fontSize: "15px" }}></i>
                    </IonFabButton>
                  </IonFab>
                ) : null}
                {this.state.bar ? (
                  <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                    <IonFabButton className="backg" onClick={this.props.zoomMinus} color="light" size="small" style={{ marginBottom: "10px" }}>
                      <i className="fas fa-minus" style={{ color: "#5b5e62", fontSize: "15px" }}></i>
                    </IonFabButton>
                  </IonFab>
                ) : null}
              </div>
              <div style={{ position: "fixed", bottom: "230px", right: "0px" }}>
                {this.state.bar ? (
                  <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                    <IonFabButton
                      className="backg"
                      disabled={!isEnabled}
                      color="light"
                      size="small"
                      style={{ marginBottom: "10px" }}
                      onClick={() => this.setCurrentPosition()}
                    >
                      <img alt="geolocation" style={{ padding: "5px" }} src={geo} />
                    </IonFabButton>
                  </IonFab>
                ) : null}
                {this.state.bar && Claims.getUserRole() !== "Installer" ? (
                  <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                    <IonFabButton
                      className="backg"
                      disabled={!isEnabled}
                      color="light"
                      size="small"
                      style={{ marginBottom: "10px" }}
                      onClick={this.props.calculate}
                    >
                      <img alt="calcicon" className="calcicon" src={calcmob} />
                    </IonFabButton>
                  </IonFab>
                ) : null}
                {this.state.bar ? (
                  <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                    <IonFabButton
                      className="backg"
                      onClick={this.props.changeMapToSatellite}
                      color="light"
                      size="small"
                      style={{ marginBottom: "10px" }}
                    >
                      <i className="fas fa-map" style={{ color: "#5b5e62", fontSize: "15px" }}></i>
                    </IonFabButton>
                  </IonFab>
                ) : null}
                {this.state.bar ? (
                  <div className="">
                    <IonFab horizontal="end" style={{ position: "relative", marginBottom: "10px" }}>
                      <IonFabButton className="backg" color="light" onClick={this.switch} size="small">
                        <img alt="search-mob" className="search-mob" src={SearchIcon} />
                      </IonFabButton>
                    </IonFab>
                  </div>
                ) : (
                  <div className="dashbtn" style={{ bottom: "230px" }}>
                    <div style={{ padding: "0px 7px", transition: "9s all ease" }}>
                      <div>
                        <img
                          alt="search-icon"
                          className=""
                          style={{
                            position: "absolute",
                            padding: "8px",
                            filter: "brightness(0.5)",
                            left: "7px",
                          }}
                          src={SearchIcon}
                        />
                      </div>
                      <div>
                        <span
                          className="searchclose"
                          onClick={this.switch}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.switch();
                            }
                          }}
                        >
                          <span className="material-icons" style={{ margin: "7px", color: "#9c9c9c" }}>
                            {" "}
                            clear
                          </span>
                        </span>
                      </div>
                      <input
                        name={this.state.searches}
                        id="autocomplete"
                        onChange={(e: any) => {
                          this.onSearch(e);
                          this.onChange(e);
                        }}
                        value={this.state.searches}
                        className="searchnew"
                        type="text"
                        placeholder="Search Address"
                        ref={this.autocompleteInput}
                      />
                      {!this.state.bar ? (
                        <div>
                          <div className=" searchcardmob">
                            <div className="row">
                              <div className="col-md-12">
                                <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                  <li className="nav-item">
                                    <a
                                      className="nav-link active"
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        this.setState({
                                          searches: "",
                                          predictionsList: "",
                                        });
                                      }}
                                      id="address-tab"
                                      data-toggle="tab"
                                      href="#address"
                                      role="tab"
                                      aria-controls="address"
                                      aria-selected="true"
                                      style={{
                                        fontWeight: "bold",
                                        padding: ".5rem 1rem .5rem .8rem;",
                                      }}
                                    >
                                      ADDRESS
                                    </a>
                                  </li>
                                  <li className="nav-item">
                                    <a
                                      className="nav-link"
                                      onClick={(e: any) => {
                                        e.preventDefault();
                                        this.setState({
                                          searches: "",
                                          predictionsList: "",
                                        });
                                      }}
                                      id="ap-cpe-tab"
                                      data-toggle="tab"
                                      href="#apcpe"
                                      role="tab"
                                      aria-controls="apcpe"
                                      aria-selected="false"
                                      style={{
                                        fontWeight: "bold",
                                        padding: ".5rem 1rem .5rem .8rem;",
                                      }}
                                    >
                                      CBSD/CPE
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="tab-content" id="searchTabContent">
                              <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">
                                {this.state.predictionsList.length > 0 ? (
                                  <div className="card-body searchdetails">
                                    {this.state.predictionsList?.map((predict: any, index: any) => {
                                      return (
                                        <div key={`${index.toString()}-predict`} className="row" style={{ paddingRight: "18px" }}>
                                          <div className="col-2 p-r-10">
                                            <i className="fas fa-map-marker-alt searchcontenticon  float-right" style={{ fontSize: "20px" }}></i>
                                          </div>
                                          <div className="col-10 searchborder" style={{ padding: "10px 0px" }}>
                                            <span
                                              onClick={() => this.setPlace(predict.description)}
                                              onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  this.setPlace(predict.description);
                                                }
                                              }}
                                              className="searchcontent"
                                            >
                                              {predict.description}
                                            </span>
                                            <br />
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                ) : null}
                              </div>

                              <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                {this.state.search_list.length > 0 ? (
                                  <div className="card-body searchdetails">
                                    {this.state.search_list?.map((item: any, index: any) => (
                                      <div
                                        key={`${index.toString()}-search-list`}
                                        id="indexname"
                                        onClick={() => this.details(item)}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.details(item);
                                          }
                                        }}
                                      >
                                        <div className="row m-t-10 col-12 p-r-0">
                                          {item.assetType === "AP-outdoor" ? (
                                            <div className="col-2">
                                              <img alt="apicon" className="searchapicon" src={apicon} />
                                            </div>
                                          ) : (
                                            <div className="col-2">
                                              <i className="far fa-circle searchcontenticon-mob "></i>
                                            </div>
                                          )}
                                          <div className="col-10 searchborder">
                                            <div className="font14" style={{ color: "#353435" }}>
                                              {item.assetIdentifierId}
                                            </div>
                                            <div className="font14" style={{ color: "#8F9FAC" }}>
                                              {item.name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ) : (
                                  <div className="card-body searchdetails">
                                    {this.state.ap_cpe_list?.map((item: any, index: any) => (
                                      <div
                                        key={`${index.toString()}-search--details`}
                                        id="indexname"
                                        onClick={() => this.details(item)}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.details(item);
                                          }
                                        }}
                                      >
                                        <div className="row m-t-10 col-12 p-r-0">
                                          {item.assetType === "AP-outdoor" ? (
                                            <div className="col-2">
                                              <img alt="search" className="searchapicon" src={apicon} />
                                            </div>
                                          ) : (
                                            <div className="col-2">
                                              <i className="far fa-circle searchcontenticon-mob "></i>
                                            </div>
                                          )}
                                          <div className="col-10 searchborder">
                                            <div className="font14" style={{ color: "#353435" }}>
                                              {item.assetIdentifierId}
                                            </div>
                                            <div className="font14" style={{ color: "#8F9FAC" }}>
                                              {item.name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
              <IonRow className="bottom-menu d-none" style={{ bottom: "176px" }}>
                {this.addButtonMobDecideHtml()}

                {!this.state.button1 ? (
                  <IonCol size="4" style={{ padding: "5px" }}>
                    {this.state.button ? (
                      <img alt="access-point" className="apicon" src={AP} style={{ filter: "brightness(0) invert(0.9)" }} />
                    ) : (
                      <img alt="access-point" className="apicon" src={AP} />
                    )}
                    {this.state.button ? (
                      <button
                        type="button"
                        className="cardbutton p-l-25 "
                        style={{
                          background: "#0372fb",
                          color: "#fff",
                          border: "none",
                        }}
                        onClick={(e: any) => {
                          this.button_change(e);
                          this.addAP(e);
                          this.props.enableAssetDrop(false);
                        }}
                      >
                        Create
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="cardbutton p-l-25 "
                        onClick={(e: any) => {
                          this.button_change(e);
                          this.props.enableAssetDrop(true);
                        }}
                      >
                        Add CBSD
                      </button>
                    )}
                  </IonCol>
                ) : (
                  <IonCol size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                    <img alt="ap-icon" className="apicon" src={AP} style={{ filter: "brightness(0) invert(0.9)" }} />
                    <button type="button" className="cardbutton p-l-25 b-none" style={{ border: "none" }} disabled>
                      Add CBSD
                    </button>
                  </IonCol>
                )}

                {this.addButtonMobIndoorHtml()}

                {this.reduxStore["tabType"] == "outdoor" && (
                  <IonCol size="4" style={this.state.button || this.state.button1 ? { padding: "5px", filter: "contrast(0.9)" } : { padding: "5px" }}>
                    <img
                      alt="measure-mob"
                      className="measureiconmob"
                      src={measureIcon}
                      style={this.state.button || this.state.button1 ? { filter: "grayscale(1)" } : {}}
                    />
                    <button
                      type="button"
                      className="cardbutton p-l-25"
                      style={{ border: "none" }}
                      disabled={this.state.button || this.state.button1}
                      onClick={this.measure}
                    >
                      Measure
                    </button>
                  </IonCol>
                )}

                {this.reduxStore["tabType"] == "indoor" && (
                  <IonCol size="4" style={this.state.button || this.state.button1 ? { padding: "5px", filter: "contrast(0.9)" } : { padding: "5px" }}>
                    <img
                      alt="measure"
                      className="measureiconmob"
                      src={heatIcon}
                      style={this.state.button || this.state.button1 ? { filter: "grayscale(1)" } : {}}
                    />
                    <button type="button" className="cardbutton p-l-25" style={{ border: "none" }} disabled={this.state.button || this.state.button1}>
                      Heat
                    </button>
                  </IonCol>
                )}
              </IonRow>
              <div className="card apinfocard bottom-actions p-b-15" style={{ position: "fixed" }}>
                <div className="card-body apinfobody">
                  <button
                    className="material-icons infowindowclose"
                    style={{ background: "none" }}
                    onClick={() => {
                      this.props.resetToOutdoor();
                    }}
                  >
                    clear
                  </button>
                  <div className="row  m-t-10">
                    <div className="col-3 p-r-0" style={{ paddingRight: "4px" }}>
                      {this.state.image !== "" && this.state.image !== undefined ? (
                        <img alt="access-point" className="apimage" src={this.state.image} />
                      ) : (
                        <div style={{ marginTop: "50px", textAlign: "center" }}>
                          {" "}
                          <div className="font14" style={{ textAlign: "center" }}>
                            No Image
                          </div>{" "}
                          <i className="fa fa-picture-o"></i>{" "}
                        </div>
                      )}
                    </div>
                    <div className="col-7 p-r-0">
                      <div className="m-t-5">
                        <span>
                          <span
                            className="distance apinfo"
                            style={{
                              fontSize: "16px",
                              color: "#FF9445",
                              fontWeight: "bold",
                            }}
                          >
                            Site ID:{" "}
                          </span>
                          <span className="distance" style={{ color: "black", fontSize: "16px" }}>
                            {this.state.assetDetails["assetIdentifierId"]}
                          </span>
                        </span>
                      </div>
                      <div className="m-t-5">
                        {/* StatusMobBig */}
                        <span
                          className="distance apinfo"
                          style={{
                            fontSize: "16px",
                            color: "#FF9445",
                            fontWeight: "bold",
                          }}
                        >
                          Mounting Type:{" "}
                        </span>
                        <span className="distance" style={{ color: "black", fontSize: "16px" }}>
                          {this.state.mountingType}
                        </span>
                      </div>
                      <div className="m-t-5">
                        <span
                          className="distance apinfo"
                          style={{
                            fontSize: "16px",
                            color: "#FF9445",
                            fontWeight: "bold",
                          }}
                        >
                          Lat:{" "}
                        </span>
                        <span className="distance  " style={{ color: "black", fontSize: "16px" }}>
                          {this.state.latitude}
                        </span>
                      </div>
                      <div className="m-t-5">
                        <span
                          className="distance apinfo"
                          style={{
                            fontSize: "16px",
                            color: "#FF9445",
                            fontWeight: "bold",
                          }}
                        >
                          Long:{" "}
                        </span>
                        <span className="distance  " style={{ color: "black", fontSize: "16px" }}>
                          {this.state.longitude}
                        </span>
                      </div>

                      {this.state.editLocationEnabled ? (
                        <div className="m-t-10 m-b-10" style={{ display: "flex", justifyContent: "center" }}>
                          <button
                            type="button"
                            className="cardbutton"
                            style={{
                              background: "#0372fb",
                              color: "#fff",
                              border: "none",
                              width: "80%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            onClick={this.confirmClicked}
                          >
                            <i className="material-icons-outlined m-r-5">check</i>
                            {"Confirm"}
                          </button>
                        </div>
                      ) : (
                        <div className="col-12 p-0 m-t-10" style={{ display: "flex" }}>
                          <div
                            style={{ width: "45px", height: "45px", borderRadius: "50%" }}
                            className="apedit"
                            onClick={() => {
                              this.setPage("details");
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setPage("details");
                              }
                            }}
                          >
                            <img alt="edit" style={{ paddingBottom: "0px", width: "21px" }} src={edit} />
                          </div>
                          <div
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="aptoggle"
                            onClick={() => {
                              this.setPage("parameter");
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setPage("parameter");
                              }
                            }}
                          >
                            <i className="fas fa-cog font25"></i>
                          </div>

                          <div
                            style={{
                              padding: "0px",
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="aptoggle"
                            onClick={() => {
                              this.setPage("replace");
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.setPage("replace");
                              }
                            }}
                          >
                            <img alt="rmalogo" src={rmaLogo}></img>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal show={this.state.show && this.state.card1} handleClose={this.hideModal1}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px" }}>
                    CONFIRMATION
                  </h6>
                  <i
                    className="material-icons modalclose imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal1();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal1();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 text-center justify-content-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to delete this CBSD?</span>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 col-6 p-r-5">
                    <button
                      className="lightbtnmob modalbtn"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal1();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 col-6 p-l-5">
                    <button
                      className="btn modalbtn"
                      onClick={() => {
                        this.delete_asset();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <Modal show={this.state.show && this.state.card2} handleClose={this.hideModal2}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px" }}>
                    CONFIRMATION
                  </h6>
                  <i
                    className="material-icons modalclose imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal2();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal2();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to remove this photo?</span>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn modalbtn"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal2();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 p-0">
                    <button className="btn modalbtn">Yes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />
        <IonLoading isOpen={this.state.isLoading} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(APdetails);
