import React from "react";
import { connect } from "react-redux";
import align from "../assets/img/align-icon.png";
import SearchIcon from "../assets/img/search_icon.png";
import geo from "../assets/img/target-icon.png";
import { ASSET } from "../constants/actionTypes";
import { requestAPICall } from "../service/apiHandler";
import "./building1.css";
import Modal from "./modal";

let buliding_list: any = [];
let center = {
  lat: 38.9072,
  lng: -77.0369,
};
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  upadateBuildingId: (value: any) => dispatch({ type: ASSET, key: "buildingId", value }),
  upadateBuildingAction: (value: any) => dispatch({ type: ASSET, key: "buildingAction", value }),
  upadateBuildingName: (value: any) => dispatch({ type: ASSET, key: "buildingName", value }),
  upadateBuildingCoordinates: (value: any) => dispatch({ type: ASSET, key: "buildingCoordinates", value }),
  updateFloorId: (value: any) => dispatch({ type: ASSET, key: "floorId", value }),
});

interface Iprops {
  building_name: Function;
  next3: Function;
  next6: Function;
  send_id: Function;
  build_action: Function;
  calculate: Function;
  focusBuilding: any;
  searchfn: any;
  setCurrentPosition: any;
}

class Building1 extends React.Component<Iprops> {
  state: any = {};
  props: any = {};
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      is_loader: 0,
      buildingid: 0,
      searches: "",
      list_building: [],
    };
    this.next3 = this.next3.bind(this);
    this.list_building = this.list_building.bind(this);
    this.fetch_data = this.fetch_data.bind(this);
    this.onChange = this.onChange.bind(this);
    this.searchAdrs = this.searchAdrs.bind(this);
  }
  componentDidMount() {
    this.list_building();
  }

  hideModal = () => {
    this.setState({
      show: false,
    });
  };

  showBuildingInfo(info: any) {
    this.props.upadateBuildingName(info.building_name);
    this.props.upadateBuildingId(info.building_id);
    this.props.building_name(info.building_name);
    center = {
      lat: parseFloat(info.latitude),
      lng: parseFloat(info.longitude),
    };
    console.log(center);
    console.log(this.props.buildingId);
    console.log(info.building_id);
    if (this.props.buildingId !== info.building_id) {
      this.props.updateFloorId(null);
    }

    this.props.upadateBuildingCoordinates({ lat: center["lat"], lng: center["lng"] });
    this.props.focusBuilding(center["lat"], center["lng"]);
    this.props.next6();
  }
  next3 = (e: any) => {
    e.preventDefault();
    this.props.upadateBuildingAction("Add");
    this.props.build_action("Add");
    this.props.next3();
  };

  list_building() {
    // Not calling list building api
  }
  fetch_data() {
    this.setState({
      list_building: buliding_list,
    });
    this.setState({
      is_loader: 2,
    });
  }
  searchAdrs = (e: any) => {
    e.preventDefault();
    this.props.searchfn();
  };
  onChange(e: any) {
    this.setState(
      {
        searches: e.target.value,
      },
      () => {
        this.list_building_search(this.state.searches);
        console.log(this.state.searches);
      }
    );
  }
  list_building_search = (searches: any) => {
    this.setState((prevValues: any) => ({
      list_building: buliding_list.filter((building: any) => building.building_name.toUpperCase().includes(prevValues.searches.toUpperCase())),
    }));
  };

  setBuilding(building: any) {
    center = {
      lat: parseFloat(building.latitude),
      lng: parseFloat(building.longitude),
    };
    if (this.props.buildingId !== building.building_id) {
      this.props.updateFloorId(null);
    }

    this.props.upadateBuildingCoordinates({ lat: center["lat"], lng: center["lng"] });
    this.props.focusBuilding(center["lat"], center["lng"]);
    this.props.send_id(building.building_id);
    this.props.building_name(building.building_name);
    this.props.next6();
  }
  render() {
    return (
      <div>
        <div className="webonly">
          <div className="row">
            <div className="col-md-9">
              <div className="card searchcard">
                <div className="card-body">
                  <div className="row" style={{ margin: "0px" }}>
                    <div className="col-md-10 p-0">
                      <div>
                        <img alt="search" className="search-web" src={SearchIcon} />
                      </div>
                      <input className="search" type="text" placeholder="Search Address | Building" onClick={this.searchAdrs} />
                    </div>
                    <div className="col-md-10 p-l-10 m-t-15" style={{ marginBottom: "10px" }}>
                      <span className="apheader">SELECT A BUILDING</span>
                    </div>
                    <div className="col-md-2 m-t-15" style={{ left: "10px" }}>
                      <div className="icon-bg" style={{ position: "relative" }} onClick={this.next3}>
                        <i className="fas fa-plus"></i>
                      </div>
                    </div>
                  </div>
                  {(() => {
                    if (this.state.is_loader === 2 && this.state.list_building != undefined) {
                      const elementlist: any = [];
                      this.state.list_building?.map((building: any) => {
                        let element = (
                          <div className="m-t-10">
                            <div
                              className="row"
                              style={{ cursor: "pointer", margin: "0px" }}
                              onClick={() => {
                                this.setBuilding(building);
                              }}
                            >
                              <div className="col-md-4 p-0 "></div>
                              <div className="col-md-8" style={{ padding: "0px" }}>
                                <div className="searchcontent p-l-5 buildinglistellipsis" title={building.building_name} style={{ fontWeight: 500 }}>
                                  {building.building_name}
                                </div>
                                {building.street_address !== "" ? (
                                  <div className="apnote  p-l-5" style={{ fontSize: "12px" }}>
                                    <i style={{ fontSize: "16px" }} className="fas fa-map-marker-alt selectbuild"></i>
                                    {building.street_address}
                                  </div>
                                ) : null}
                                {building.city !== "" ? (
                                  <span className="apnote  p-l-5" style={{ fontSize: "12px", marginLeft: "17px" }}>
                                    {building.city},
                                  </span>
                                ) : null}
                                {building.city !== "" ? (
                                  <span className="apnote  p-l-5" style={{ fontSize: "12px" }}>
                                    {building.state},
                                  </span>
                                ) : null}
                                {building.city !== "" ? (
                                  <span className="apnote  p-l-5" style={{ fontSize: "12px" }}>
                                    {building.country}
                                  </span>
                                ) : null}

                                <div className="apnote  p-l-5">
                                  <i className="fas fa-building selectbuild" style={{ fontSize: "15px" }}></i> {building.no_of_floors} floors
                                </div>
                              </div>
                              <div className="col-md-12 searchborder"></div>
                            </div>
                          </div>
                        );
                        elementlist.push(element);
                      });
                      return (
                        <div className="apcardbody" style={{ maxHeight: "calc(100vh - 200px)" }}>
                          {elementlist}
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
            <div className="" style={{ position: "fixed", right: "20px", top: "60px" }}>
              <div className="geoicon text-center" onClick={() => this.props.setCurrentPosition()}>
                <img alt="geo-and-align" style={{ padding: "5px" }} src={geo} />
              </div>
            </div>
            <div className="" style={{ position: "fixed", right: "20px", top: "110px" }}>
              <div className="geoicon text-center">
                <img alt="geo-and-align" style={{ padding: "6.5px" }} onClick={this.props.calculate} src={align} />
              </div>
            </div>
          </div>
        </div>

        <div className="mobonly">
          <div
            className="stay-bottom-mob submit-btn-wrapper"
            id="scrollcard"
            style={{ height: "360px", overflow: "auto", paddingTop: "0px", boxShadow: "1px 0px 6px 0px #b8b8b8" }}
          >
            <div className="card-body build-cardbody">
              <div className="row">
                <div className="col-12 m-t-20">
                  <div>
                    <img alt="search" className="search-web" src={SearchIcon} />
                  </div>
                  <input className="search" type="text" placeholder="Search Address | Building" onClick={this.searchAdrs} />
                </div>
                <div id="flip" className="col-9 m-t-10 p-l-25" style={{ paddingTop: "10px" }}>
                  <span className="apheader font18" style={{ textTransform: "initial", color: "#424242", fontWeight: 400 }}>
                    Select a building{" "}
                  </span>
                </div>
                <div className="col-3 p-r-25 m-t-5" style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <div className="addbuild" style={{ marginRight: "0px" }} onClick={this.next3}>
                    <i className="fas fa-plus plusicon" style={{ fontSize: "19px", margin: "10px 11px" }}></i>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  {this.state.list_building?.map((building: any, index: any) => {
                    return (
                      <div
                        key={`${index.toString()}-building-list`}
                        className="row building-infocard-mob"
                        onClick={() => {
                          this.showBuildingInfo(building);
                        }}
                      >
                        <div className="col-4 p-0"></div>
                        <div className="col-8" style={{ padding: "0px 10px 0px 3px" }}>
                          <div className="searchcontent-mob font18 buildinglistellipsis" title={building.building_name}>
                            {building.building_name}
                          </div>
                          {building.street_address !== "" ? (
                            <div className="apnote" style={{ fontSize: "14px" }}>
                              <i className="fas fa-map-marker-alt selectbuild"></i>
                              {building.street_address}
                            </div>
                          ) : null}
                          {building.city !== "" ? (
                            <span className="apnote" style={{ fontSize: "14px", marginLeft: "16px" }}>
                              {building.city},{" "}
                            </span>
                          ) : null}
                          {building.state !== "" ? (
                            <span className="apnote" style={{ fontSize: "14px" }}>
                              {building.state},{" "}
                            </span>
                          ) : null}
                          {building.country !== "" ? (
                            <span className="apnote" style={{ fontSize: "14px" }}>
                              {building.country}{" "}
                            </span>
                          ) : null}
                          <br />

                          <div className="apnote font12" style={{ fontSize: "14px" }}>
                            <i className="fas fa-building selectbuild"></i>
                            {building.no_of_floors} floors
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    PROPAGATION SETTINGS
                  </h6>
                  <i
                    className="far fa-times-circle modalclose"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </div>
                <div className="col-md-12 p-0 m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="form-group">
                    <select className="form-control paramtext" style={{ border: "1px solid #8F9FAC", borderRadius: "6px 6px 0px 0px" }}>
                      <option value="">Propagation Model</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          type="text"
                          className="paramtext-web b-t-0"
                          id="Transmit"
                          style={{ border: "1px solid #8F9FAC" }}
                          placeholder="Transmit Power"
                        />
                        <label className="acpdetails" htmlFor="Transmit">
                          Transmit Power
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          type="text"
                          className="paramtext-web b-t-0"
                          id="Cable"
                          style={{ border: "1px solid #8F9FAC" }}
                          placeholder="Cable Loss"
                        />
                        <label className="acpdetails" htmlFor="Cable">
                          Cable Loss
                        </label>
                      </div>
                    </div>
                    <select className="form-control paramtext b-t-0" style={{ border: "1px solid #8F9FAC", borderRadius: "0px 0px 6px 6px" }}>
                      <option value="">No. of Transmitters</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <button className="btn cardbtn" style={{ width: "268px" }}>
                    Generate Heat Map
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Building1);
