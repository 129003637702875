import { IonAlert, IonLoading } from "@ionic/react";
import React from "react";
import Dropzone from "react-dropzone";
import XLSX from "xlsx";
import Shops from "../assets/img/ShopsatColumbus1.png";
import sort from "../assets/img/checked.png";
import { requestAPICall } from "../service/apiHandler";
import Modal from "./modal";
const INITIAL_STATE = {
  card: "",
  show: false,
  status_show: false,
};
let status_list: any = [];
let selected_building_list: any = [];
let building_property_list: any = [];
let floor_property: any = [];
let floor_type_list: any = [];
export default class Buildings extends React.Component {
  state: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    floor_type_list = ["Parking", "Basement", "Floor"];
    this.state = {
      files: [],
      floor_files: [],
      list_building: [],
      floor_details: [],
      alertbox: false,
      show_building_details: false,
      selected_floor_asset_count: 0,
      delete_floor_building_id: "",
      delete_floor_id: "",
      edit_floor_building_id: "",
      selected_floor_id: "",
      show_floor_details: false,
      upload_floor_plan_building_id: "",
      check_arrow: false,
    };
    this.import_files = this.import_files.bind(this);
    this.list_building = this.list_building.bind(this);
    this.onChange = this.onChange.bind(this);
    this.floorplanUploadClick = this.floorplanUploadClick.bind(this);
  }
  componentDidMount() {
    this.list_building();
  }
  list_building() {
    // Not calling list building api
  }
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  setShowLoading = (st: number) => {
    this.setState({ is_loader: st });
  };
  showModal = (e: any) => {
    e.preventDefault();
    this.setState({
      card: e.target.id,
      show: true,
    });
  };

  hideModal = () => {
    this.setState({
      deletecardweb: "",
      files: [],
      show: false,
    });
  };
  delete_single_building = () => {
    this.setState({ is_loader: 1 });
    let requestbody = {
      is_active: 0,
    };
    requestAPICall("delete_manual_building", { building_id: this.state.delete_building_id }, requestbody).then((data) => {
      this.setState({ is_loader: 0 });
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        this.list_building();
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
    this.hideModal();
  };
  status_hideModal = () => {
    this.setState({
      status_show: false,
    });
    console.log(this.state.status_show, "ssssssssssss");
  };
  onDrop = (files: any) => {
    this.setState({ files: files });
  };
  floorplanUploadClick = (buildingId: any, floorId: any) => {
    console.log("========", floorId, buildingId);
    this.setState(
      {
        upload_floor_plan_building_id: buildingId,
        selected_floor_id: floorId,
      },
      () => this.load_floor_property()
    );
    $("#floor_plan_drop_zone").trigger("click");
  };
  floorPlanonDrop = (files: any) => {
    this.setState({ floor_files: files }, () => this.uploadFile(this.state.floor_files));
  };
  uploadFile = (files: any) => {
    console.log("=============", files);
    let filerequestBody = {};

    let picReader = new FileReader();

    let fileName = files[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    if (files[0].uploadType === undefined) {
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
        };
        console.log(filerequestBody);
        this.setState({ isLoading: true });
        this.uploadFloorPlan(filerequestBody);
      };
      picReader.readAsDataURL(files[0]);
    }
  };
  uploadFloorPlan = (filerequestBody: any) => {
    requestAPICall("upload_file", {}, filerequestBody).then((data) => {
      console.log(data);
      if (data["responseCode"] == 200) {
        console.log(data.file_id);
        this.setState({
          floor_plan_file_id: data.file_id,
        });
        this.updateFloorPlanWithFloor(data.file_id);
      }
    });
  };
  updateFloorPlanWithFloor = (fileId: any) => {
    let requestBody = {
      properties: floor_property,
      floor_plan_file_id: fileId,
    };
    this.setState({
      is_loader: 1,
    });
    requestAPICall(
      "update_floor",
      { building_id: this.state.upload_floor_plan_building_id, floor_id: this.state.selected_floor_id },
      requestBody
    ).then((data) => {
      if (data.responseCode == "200") {
        this.setState({
          is_loader: 0,
          warnMessage: "Floor plan file uploaded successfully",
          alertbox: true,
        });
      } else {
        this.setState({
          is_loader: 0,
          warnMessage: "Failed to upload Floor plan file",
          alertbox: true,
        });
      }
    });
  };
  import_files() {
    this.setState({
      is_loader: 1,
    });
    status_list = [];
    let picReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.files[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    console.log(fileExt, "fileeeeeeeeeeeee");
    picReader.onload = (e) => {
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: picReader.result,
      };
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        let requestBody = {
          file_path_id: data.file_id,
          file_path: data.file_path,
        };
        requestAPICall("import_building", {}, requestBody).then((data) => {
          console.log(data);

          if (data.responseCode == 200) {
            this.list_building();
            status_list = data.building_status;
            this.setState({
              show: false,
              status_show: true,
              is_loader: 3,
            });
          } else {
            this.setState({
              show: false,
              status_show: false,
              warnMessage: data.message,
              alertbox: true,
            });
          }
        });
      });
    };
    picReader.readAsDataURL(this.state.files[0]);
    this.hideModal();
  }
  editBuildingDetails = () => {
    let check = true;
    building_property_list?.map((property: any) => {
      if (property.propertyName === "latitude" || property.propertyName === "longitude" || property.propertyName === "building_name") {
        if (property.value === "" || property.value === 0) {
          check = false;
          this.setState({
            warnMessage: property.displayName + " Cannot be empty",
            alertbox: true,
          });
        }
      }
    });
    if (check) {
      console.log("submitting floor for edit");
      this.setState({ is_loader: 1 });
      let requestBody = {
        properties: building_property_list,
        building_address_id: this.state.building_address_id,
      };

      console.log(requestBody);
      requestAPICall("update_building", { building_id: this.state.selected_building_id }, requestBody).then((data) => {
        this.setState({ is_loader: 0 });
        if (data.responseCode == 200) {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
            card1: false,
            show_building_details: false,
          });
        } else {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
          });
        }
      });
    }
  };
  showModal1 = (building: any) => {
    this.setState({
      deletecardweb: true,
      delete_building_id: building.building_id,
      selected_data: building,
    });
  };
  setFloorType(e: any, selectedfloor: any) {
    this.setState({
      floor_type: e.target.value,
    });
    this.state.floor_details?.map((floor: any) => {
      if (floor.floorId === selectedfloor.floorId) {
        floor.floor_type = e.target.value;
      }
    });
    floor_property?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
    });
  }
  onChange(e: any, type: any) {
    console.log(e.target.name, e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (type == "building") {
      console.log(this.state.building_id);
      console.log(this.state.list_building);
      console.log(building_property_list);
      this.state.list_building?.forEach((building: any) => {
        if (building.building_id === this.state.building_id) {
          if (e.target.name === "latitude") {
            building.latitude = e.target.value;
          }
          if (e.target.name === "longitude") {
            building.longitude = e.target.value;
          }
          if (e.target.name === "building_name") {
            building.building_name = e.target.value;
          }
          if (e.target.name === "state") {
            building.state = e.target.value;
          }
          if (e.target.name === "zip") {
            building.zip = e.target.value;
          }
          if (e.target.name === "street_address") {
            try {
              building.street_address = e.target.value;
              // building.city = e.target.value.split(',')[1]
            } catch (error) {}
          }
        }
        building_property_list?.forEach((property: any) => {
          if (property.propertyName == e.target.name) {
            property.value = e.target.value;
          }
        });
      });
    }
    if (type == "floor") {
      console.log(this.state.floor_details);
      this.state.floor_details?.forEach((floor: any) => {
        if (floor.floorId === this.state.selected_floor_id) {
          if (e.target.name === "floor_number") {
            floor.floor_number = e.target.value;
          }
          if (e.target.name === "floor_name") {
            floor.floor_name = e.target.value;
          }
          if (e.target.name === "floor_notes") {
            floor.floor_notes = e.target.value;
          }
          if (e.target.name === "floor_type") {
            floor.floor_type = e.target.value;
          }
          if (e.target.name === "ceiling_height") {
            floor.ceiling_height = e.target.value;
          }
        }
        console.log(floor_property);
        floor_property?.forEach((property: any) => {
          if (property.propertyName == e.target.name) {
            property.value = e.target.value;
          }
        });
      });
    }
  }

  edit_building_value = (building: any) => {
    this.setState(
      {
        show_building_details: true,
        building_id: building.building_id,
        selected_building_id: building.building_id,
      },
      () => this.getBuildingEditableProperties()
    );
  };
  getBuildingEditableProperties = () => {
    console.log(this.state.asset_type_id);
    requestAPICall("get_particular_building", { building_id: this.state.building_id }, {}).then((data) => {
      console.log(data);
      if (data.responseCode === 200) {
        building_property_list = data["details"];
        this.setState({
          building_property_list: data["details"],
        });
      }
    });
  };
  getFLoor = (val: any, buildingClickId: any) => {
    this.setState({
      floor_details: [],
      check_arrow: true,
    });
    console.log(val);
    this.setState(
      {
        building_id: val.building_id,
        buildingClickId: buildingClickId,
      },
      () => this.get_floor_details(val.building_id)
    );
  };
  hide_arrow = () => {
    this.setState({
      check_arrow: false,
    });
  };
  get_floor_details = (building_id: number) => {
    let requestBody = {};
    console.log(building_id);
    console.log(this.state.buildingClickId);
    requestAPICall("get_floor_details", { building_id: building_id }, requestBody).then((data) => {
      console.log(data);
      if (data.responseCode == "200") {
        this.setState({
          floor_details: data["floordetails"],
          show_floor_details: false,
        });
      }
    });
  };
  download_files = () => {
    let requestBody = {
      sheet_name: "buildings",
    };
    requestAPICall("download_files", {}, requestBody).then((data) => {
      let worksheet = XLSX.utils.aoa_to_sheet(data["file_content"]);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "buildings");
      let requestBody = {
        sheet_name: "building_floor",
      };
      requestAPICall("download_files", {}, requestBody).then((data) => {
        let worksheet1 = XLSX.utils.aoa_to_sheet(data["file_content"]);
        XLSX.utils.book_append_sheet(new_workbook, worksheet1, "building_floor");
        XLSX.writeFile(new_workbook, "buildings" + ".xlsx");
      });
    });
  };

  edit_floor_value = (floor: any, building: any) => {
    this.setState(
      {
        selected_floor_id: floor.floorId,
        show_floor_details: true,
        edit_floor_building_id: building.building_id,
      },
      () => this.load_floor_property()
    );
  };
  delete_floor() {
    let requestBody = {};
    if (this.state.selected_floor_asset_count > 0) {
      requestBody = {
        asset_delete: true,
      };
    } else {
      requestBody = {
        asset_delete: false,
      };
    }

    requestAPICall("delete_floor", { building_id: this.state.delete_floor_building_id, floor_id: this.state.delete_floor_id }, requestBody).then(
      (data) => {
        if (data.responseCode == 200) {
          this.get_floor_details(this.state.delete_floor_building_id);
          this.list_building();
          this.setState({
            status_show: false,
            warnMessage: data.message,
            alertbox: true,
          });
        } else {
          this.setState({
            warnMessage: "Please Select Correct floor number",
            alertbox: true,
          });
        }
        this.hidefloorDeleteModal();
      }
    );
  }
  load_floor_property() {
    console.log("loading selected floor data.....");
    let requestBody = {};

    requestAPICall("get_particular_floor", { floor_id: this.state.selected_floor_id }, requestBody).then((data) => {
      console.log(data);
      if (data.responseCode == "200") {
        floor_property = data.properties;
        this.setState({
          floor_property: data.properties,
          floorPlanFileImage: data.floorPlanFileImage,
        });
      }
    });
  }

  updateFloorDetails = () => {
    let check = true;
    floor_property?.map((property: any) => {
      if (
        property.propertyName === "floor_name" ||
        property.propertyName === "floor_number" ||
        property.propertyName === "ceiling_height" ||
        property.propertyName === "floor_type"
      ) {
        if (property.value === "" || property.value === 0) {
          check = false;
          this.setState({
            warnMessage: property.displayName + " Cannot be empty",
            alertbox: true,
          });
        }
      }
    });
    if (check) {
      let requestBody = {
        properties: floor_property,
      };
      this.setState({
        is_loader: 1,
      });
      requestAPICall("update_floor", { building_id: this.state.edit_floor_building_id, floor_id: this.state.selected_floor_id }, requestBody).then(
        (data) => {
          if (data.responseCode == "200") {
            this.setState({
              is_loader: 0,
              warnMessage: data.message,
              alertbox: true,
              show_floor_details: false,
            });
          }
        }
      );
    }
  };
  showDeleteModalFloor = (floor: any, building: any) => {
    let requestBody = {};
    requestAPICall("get_floor_asset_count", { building_id: building.building_id, floor_id: floor.floorId }, requestBody).then((data) => {
      console.log(data);
      this.setState({
        selected_floor_asset_count: data.floor_asset_count,
        deletefloorcard: true,
        delete_floor_building_id: building.building_id,
        delete_floor_id: floor.floorId,
        selected_data: floor,
      });
    });
  };
  hidefloorDeleteModal() {
    this.setState({
      deletefloorcard: false,
      show: false,
    });
  }
  sortByValueAsc = (key: any) => {
    this.setState({
      show_sector_details: false,
    });
    this.ascending(key);
  };
  sortByValueDesc = (key: any) => {
    this.setState({
      show_sector_details: false,
    });
    this.descending(key);
  };

  ascending = (key: any) => {
    return this.state.list_building.sort(function (a: any, b: any) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (b[key] > a[key]) {
        return -1;
      }
      return 0;
    });
  };
  descending = (key: any) => {
    return this.state.list_building.sort(function (a: any, b: any) {
      if (b[key] > a[key]) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      return 0;
    });
  };

  getAdressTooltip = (building: any) => {
    let address = "";

    if (building.street_address !== "") {
      address = building.street_address + ",";
    } else if (building.city !== "") {
      address = building.city;
    } else {
      address = "-";
    }
    return address;
  };

  render() {
    let files = this.state.files?.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let floor_files = this.state.floor_files?.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "importdropzuploaded" : "importdropz";
    let classDName2 = floor_files.length ? "dropzone-uploaded" : "dropzone";
    return (
      <div className="card importcard">
        <div className="card-body">
          <div className="row" style={{ padding: "0px 10px" }}>
            <div className="col-md-8">
              <span style={{ fontSize: "13px" }}>LISTING</span>
            </div>
            <div className="col-md-4" style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <span className="p-l-5 m-r-30 font13" style={{ color: '#0073FF', paddingTop: '2px', cursor: 'pointer' }} onClick={this.delete_building}>Delete File</span> */}
              <i className="material-icons" style={{ color: "#0073FF", cursor: "pointer" }}>
                get_app
              </i>
              <span
                className="p-l-5 m-r-30 font13"
                style={{ color: "#0073FF", cursor: "pointer", paddingTop: "2px" }}
                onClick={this.download_files}
                onKeyDown={(e) => e.key == "Enter" && this.download_files()}
              >
                Download Sample File
              </span>
              <i className="material-icons p-l-5" style={{ color: "#0073FF", transform: "rotate(180deg)", cursor: "pointer" }}>
                get_app
              </i>
              <span
                className="font13"
                style={{ color: "#0073FF", paddingTop: "2px", cursor: "pointer" }}
                id="heat"
                onClick={this.showModal}
                onKeyDown={(e) => e.key == "Enter" && this.showModal(e)}
              >
                Import File
              </span>
              {/* <span className="material-icons  p-l-5" style={{ color: '#0073FF', cursor: 'pointer', marginBottom: '12px' }}>cloud_upload</span>
                            <span className="font13 p-l-10" style={{ color: '#0073FF', paddingTop: '2px', cursor: 'pointer' }} onClick={(e) => this.exportFile()} >Export Data</span> */}
            </div>
          </div>
          <div className="row m-t-10" style={{ padding: "0px 10px" }}>
            <div className="col-md-12">
              <table className="table sitetable">
                <thead className="sitehead" style={{ background: "none", display: "table" }}>
                  <tr>
                    <th className="w4 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}></th>
                    <th className="w15 table-bg-hdr-color" style={{ paddingLeft: "0px" }}>
                      Building Name {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("building_name")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("building_name")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("building_name")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("building_name")}
                      />
                    </th>
                    <th className="w10 table-bg-hdr-color">
                      No. Floors {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("no_of_floors")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("no_of_floors")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("no_of_floors")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("no_of_floors")}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      Address {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("street_address")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("street_address")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("street_address")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("street_address")}
                      />
                    </th>
                    <th className="w10 table-bg-hdr-color">
                      Market {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("state")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("state")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("state")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("state")}
                      />
                    </th>
                    <th className="w10 table-bg-hdr-color">
                      Zipcode {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("zip")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("zip")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("zip")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("zip")}
                      />
                    </th>
                    <th className="w13 table-bg-hdr-color">
                      Latitude {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("latitude")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("latitude")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("latitude")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("latitude")}
                      />
                    </th>
                    <th className="w13 table-bg-hdr-color">
                      Longitude {/* */}
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("longitude")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueDesc("longitude")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("longitude")}
                        onKeyDown={(e) => e.key == "Enter" && this.sortByValueAsc("longitude")}
                      />
                    </th>
                    <th className="w10 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    display: "block",
                    overflow: "hidden scroll",
                    maxHeight: "calc(100vh - 300px)",
                    width: "calc(100% + 8px)",
                  }}
                >
                  {this.state.list_building?.map((building: any, index: any) => {
                    return (
                      <React.Fragment key={building.building_id}>
                        {this.state.show_building_details === true && this.state.selected_building_id === building.building_id ? (
                          <tr className="panel-group" style={{ display: "flex" }}>
                            <td className="w4">
                              <img
                                src={sort}
                                style={{ width: "10px", marginRight: "10px", cursor: "pointer" }}
                                onClick={() => this.getFLoor(building, "#_" + building.building_id + index)}
                                onKeyDown={(e) => e.key == "Enter" && this.getFLoor(building, "#_" + building.building_id + index)}
                                data-toggle="collapse"
                                data-target={"#_" + building.building_id + index}
                              />
                            </td>
                            <td className="w15" style={{ paddingLeft: "0px", paddingTop: "6px" }}>
                              <input
                                className="accesspointname"
                                style={{ height: "38px" }}
                                maxLength={45}
                                name="building_name"
                                value={building.building_name}
                                onChange={(e) => {
                                  this.onChange(e, "building");
                                }}
                              />
                            </td>

                            <td className="w10">{building.no_of_floors}</td>
                            <td className="w15" style={{ paddingTop: "6px" }}>
                              <input
                                className="accesspointname"
                                maxLength={45}
                                style={{ height: "38px" }}
                                name="street_address"
                                value={building.street_address}
                                onChange={(e) => {
                                  this.onChange(e, "building");
                                }}
                              />
                            </td>
                            <td className="w10" style={{ paddingTop: "6px" }}>
                              <input
                                className="accesspointname"
                                style={{ height: "38px" }}
                                name="state"
                                value={building.state}
                                onChange={(e) => {
                                  this.onChange(e, "building");
                                }}
                              />
                            </td>
                            <td className="w10" style={{ paddingTop: "6px" }}>
                              <input
                                className="accesspointname"
                                style={{ height: "38px" }}
                                name="zip"
                                value={building.zip}
                                onChange={(e) => {
                                  this.onChange(e, "building");
                                }}
                              />
                            </td>
                            <td className="w13" style={{ paddingTop: "6px" }}>
                              <input
                                className="accesspointname"
                                style={{ height: "38px" }}
                                name="latitude"
                                value={building.latitude}
                                onChange={(e) => {
                                  this.onChange(e, "building");
                                }}
                              />
                            </td>
                            <td className="w13" style={{ paddingTop: "6px" }}>
                              <input
                                className="accesspointname"
                                style={{ height: "38px" }}
                                name="longitude"
                                value={building.longitude}
                                onChange={(e) => {
                                  this.onChange(e, "building");
                                }}
                              />
                            </td>
                            <td className="w10 text-center">
                              <i
                                className="far fa-check-circle p-r-15"
                                style={{ color: "#0272fb", fontSize: "22px" }}
                                title="Save Building"
                                onClick={(e) => this.editBuildingDetails()}
                                onKeyDown={(e) => e.key == "Enter" && this.editBuildingDetails()}
                              ></i>
                              <i
                                className="fas fa-trash "
                                style={{ color: "#E22B2B", fontSize: "20px" }}
                                title="Delete Building"
                                onClick={(e) => this.showModal1(building)}
                                onKeyDown={(e) => e.key == "Enter" && this.showModal1(building)}
                              ></i>
                            </td>
                          </tr>
                        ) : (
                          <tr className="panel-group" style={{ display: "flex" }}>
                            {this.state.buildingClickId === "#_" + building.building_id + index && this.state.check_arrow === true ? (
                              <td className="w4 ">
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginRight: "10px",
                                    cursor: "pointer",
                                    transform: "rotate(180deg)",
                                  }}
                                  onClick={this.hide_arrow}
                                  data-toggle="collapse"
                                  data-target={"#_" + building.building_id + index}
                                  onKeyDown={(e) => e.key == "Enter" && this.hide_arrow()}
                                />
                              </td>
                            ) : (
                              <td className="w4 ">
                                <img
                                  src={sort}
                                  style={{ width: "10px", marginRight: "10px", cursor: "pointer" }}
                                  onClick={() => this.getFLoor(building, "#_" + building.building_id + index)}
                                  onKeyDown={(e) => e.key == "Enter" && this.getFLoor(building, "#_" + building.building_id + index)}
                                  data-toggle="collapse"
                                  data-target={"#_" + building.building_id + index}
                                />
                              </td>
                            )}

                            <td className="w15 networkellipsis" title={building.building_name} style={{ paddingLeft: "0px" }}>
                              {building.building_name}
                            </td>
                            <td className="w10 networkellipsis" title={building.no_of_floors}>
                              {building.no_of_floors + " Floors"}
                            </td>
                            <td className="w15 networkellipsis" title={this.getAdressTooltip(building)}>
                              {" "}
                              {building.street_address !== "" ? building.street_address + "," : "-"}
                              {building.city}
                            </td>
                            <td className="w10 networkellipsis" title={building.state === "" ? "-" : building.state}>
                              {building.state === "" ? "-" : building.state}
                            </td>
                            <td className="w10 networkellipsis" title={building.zip === "" ? "-" : building.zip}>
                              {building.zip === "" ? "-" : building.zip}
                            </td>
                            <td className="w15 networkellipsis" title={building.latitude}>
                              {building.latitude}º
                            </td>
                            <td className="w15 networkellipsis" title={building.longitude}>
                              {building.longitude}º
                            </td>
                            <td className="w6 text-center">
                              <div className="col-3">
                                <button
                                  className="actionIconBtn"
                                  id="dropdownMenuLink"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v " style={{ color: "#B4B4B4", marginTop: "10px", height: "30px" }}></i>
                                </button>
                                <div className="dropdown-menu indoorfloor" style={{ backgroundColor: "black" }} aria-labelledby="dropdownMenuLink">
                                  <a
                                    className="dropdown-item cursor"
                                    style={{ color: "white" }}
                                    onClick={(e) => this.edit_building_value(building)}
                                    onKeyDown={(e) => e.key == "Enter" && this.edit_building_value(building)}
                                  >
                                    Edit Building
                                  </a>
                                  <a
                                    className="dropdown-item cursor"
                                    style={{ color: "white" }}
                                    id="delete"
                                    onClick={(e) => this.showModal1(building)}
                                    onKeyDown={(e) => e.key == "Enter" && this.showModal1(building)}
                                  >
                                    {" "}
                                    Delete Building
                                  </a>
                                </div>
                              </div>
                              {/* <i className="fas fa-pen p-r-20" style={{ color: '#0272fb', fontSize: '16px' }} title="Edit Building" onClick={(e) => this.edit_building_value(building)}></i>
                                                        <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete Building" onClick={(e) => this.showModal1(building)}></i> */}
                            </td>
                          </tr>
                        )}
                        {/* <tr style={{ height: '0px' }}> */}
                        {/* <td style={{ padding: '0px', border: 'none' }} colSpan={11}> */}
                        <div id={"_" + building.building_id + index} className="panel-collapse collapse">
                          {this.state.buildingClickId === "#_" + building.building_id + index ? (
                            <div className="" style={{ padding: "0px 25px", background: "#f3f3f5", marginLeft: "20px" }}>
                              <div className="" style={{ padding: "0px 12px", background: "#f3f3f5" }}>
                                <table className="subtable sitecollapse b-b-0">
                                  <thead>
                                    <tr>
                                      <th className="w15 b-t-0"></th>
                                      <th className="w15 fontw500 b-t-0" style={{ paddingLeft: "0px" }}>
                                        Floor Name
                                      </th>
                                      <th className="w10 fontw500 b-t-0">Floor Number </th>
                                      <th className="w12 fontw500 b-t-0">Floor Type </th>
                                      <th className="w25 fontw500 b-t-0">Floor Notes </th>
                                      <th className="w13 fontw500 b-t-0 text-center">Ceiling Height (ft)</th>
                                      <th className="w10 b-t-0"></th>
                                    </tr>
                                  </thead>
                                  {building.no_of_floors == 0 ? (
                                    <tbody>
                                      <tr>
                                        <td className="p-0 text-center" style={{ border: "none", fontSize: "20px" }} colSpan={11}>
                                          No Floors Found
                                        </td>
                                      </tr>
                                    </tbody>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.floor_details?.map((floor: any) => {
                                    return (
                                      <tbody style={{ fontWeight: 300 }} key={floor.sectorId_id}>
                                        {this.state.show_floor_details === true && floor.sectorId_id === this.state.floor_id ? (
                                          <tr className="panel-group">
                                            <td className="w15 text-center" style={{ position: "relative", right: "10px", border: "none" }}>
                                              <img src={Shops} style={{ width: "50%" }} />
                                            </td>
                                            <td className="w15" style={{ paddingLeft: "0px" }}>
                                              <input
                                                className="accesspointname"
                                                style={{ height: "38px" }}
                                                name="floor_name"
                                                value={floor.floor_name}
                                                onChange={(e) => {
                                                  this.onChange(e, "floor");
                                                }}
                                              />
                                            </td>
                                            <td className="w10">
                                              <input
                                                className="accesspointname"
                                                style={{ height: "38px" }}
                                                name="floor_number"
                                                value={floor.floor_number}
                                                onChange={(e) => {
                                                  this.onChange(e, "floor");
                                                }}
                                              />
                                            </td>
                                            {/* <td className="w10"><input className="accesspointname" style={{ height: '38px' }} name="floor_type" value={floor.floor_type} onChange={(e) => { this.onChange(e, "floor") }} /></td> */}
                                            <td className="w12">
                                              <select
                                                className=" form-control"
                                                name="floor_type"
                                                value={floor.floor_type}
                                                onChange={(e) => this.setFloorType(e, floor)}
                                              >
                                                {floor_type_list?.map((status: any) => (
                                                  <option value={status} key={status}>
                                                    {status}
                                                  </option>
                                                ))}
                                              </select>
                                            </td>
                                            <td className="w25">
                                              <input
                                                className="accesspointname"
                                                style={{ height: "38px" }}
                                                name="floor_notes"
                                                value={floor.floor_notes}
                                                onChange={(e) => {
                                                  this.onChange(e, "floor");
                                                }}
                                              />
                                            </td>
                                            <td className="w13 ">
                                              <input
                                                className="accesspointname"
                                                style={{ height: "38px" }}
                                                name="ceiling_height"
                                                value={floor.ceiling_height}
                                                onChange={(e) => {
                                                  this.onChange(e, "floor");
                                                }}
                                              />
                                            </td>
                                            <td className="w10 text-center">
                                              <i
                                                className="fas fa-upload p-r-15"
                                                style={{ color: "#0272fb", fontSize: "16px" }}
                                                title="Upload Floor Plan"
                                              ></i>
                                              <i
                                                className="far fa-check-circle p-r-15"
                                                style={{ color: "#0272fb", fontSize: "22px" }}
                                                title="Save Floor"
                                                onClick={this.updateFloorDetails}
                                                onKeyDown={(e) => e.key == "Enter" && this.updateFloorDetails()}
                                              ></i>
                                              <i
                                                className="fas fa-trash"
                                                style={{ color: "#E22B2B", fontSize: "20px" }}
                                                title="Delete Floor"
                                                onClick={(e) => this.showDeleteModalFloor(floor, building)}
                                                onKeyDown={(e) => e.key == "Enter" && this.showDeleteModalFloor(floor, building)}
                                              ></i>
                                            </td>
                                          </tr>
                                        ) : (
                                          <tr className="panel-group">
                                            <td className="w15 text-center" style={{ position: "relative", right: "10px", border: "none" }}>
                                              <img src={Shops} style={{ width: "50%" }} />
                                            </td>
                                            <td
                                              className="w15 ellipsitooltip"
                                              data-toggle="tooltip"
                                              title={floor.floor_name}
                                              style={{ paddingLeft: "0px" }}
                                            >
                                              {floor.floor_name}
                                            </td>
                                            <td className="w10 ellipsitooltip" data-toggle="tooltip" title={floor.floor_number}>
                                              {floor.floor_number}
                                            </td>

                                            <td className="w12 ellipsitooltip" data-toggle="tooltip" title={floor.floor_type}>
                                              {floor.floor_type}
                                            </td>
                                            <td className="w25 ellipsitooltip" data-toggle="tooltip" title={floor.floor_notes ?? "-"}>
                                              {floor.floor_notes ?? "-"}
                                            </td>
                                            <td className="w13 ellipsitooltip text-center" data-toggle="tooltip" title={floor.ceiling_height ?? "-"}>
                                              {floor.ceiling_height ?? "-"}
                                            </td>
                                            <td className="w10  text-center">
                                              <div className="col-3">
                                                <button
                                                  className="actionIconBtn"
                                                  id="dropdownMenuLink"
                                                  data-toggle="dropdown"
                                                  aria-haspopup="true"
                                                  aria-expanded="false"
                                                >
                                                  <i
                                                    className="fas fa-ellipsis-v "
                                                    style={{ color: "#B4B4B4", marginTop: "10px", height: "30px" }}
                                                  ></i>
                                                </button>
                                                <div
                                                  className="dropdown-menu indoorfloor"
                                                  style={{ backgroundColor: "black" }}
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <a
                                                    className="dropdown-item cursor"
                                                    style={{ color: "white" }}
                                                    onClick={(e) => this.floorplanUploadClick(building.building_id, floor.floorId)}
                                                    onKeyDown={(e) =>
                                                      e.key == "Enter" && this.floorplanUploadClick(building.building_id, floor.floorId)
                                                    }
                                                  >
                                                    Upload Floor Plan
                                                  </a>
                                                  <a
                                                    className="dropdown-item cursor"
                                                    style={{ color: "white" }}
                                                    id="edit"
                                                    onClick={(e) => this.edit_floor_value(floor, building)}
                                                    onKeyDown={(e) => e.key == "Enter" && this.edit_floor_value(floor, building)}
                                                  >
                                                    {" "}
                                                    Edit Floor
                                                  </a>
                                                  <a
                                                    className="dropdown-item cursor"
                                                    style={{ color: "white" }}
                                                    id="delete"
                                                    onClick={(e) => this.showDeleteModalFloor(floor, building)}
                                                    onKeyDown={(e) => e.key == "Enter" && this.showDeleteModalFloor(floor, building)}
                                                  >
                                                    Delete Floor
                                                  </a>
                                                </div>
                                              </div>
                                              {/* <i className="fas fa-upload p-r-15" style={{ color: '#0272fb', fontSize: '16px' }} onClick={(e) => this.floorplanUploadClick(building.building_id, floor.floorId)} title="Upload Floor Plan"></i>
                                                                                    <i className="fas fa-pen p-r-15" style={{ color: '#0272fb', fontSize: '16px' }} title="Edit Floor" onClick={(e) => this.edit_floor_value(floor, building)}></i>
                                                                                    <i className="fas fa-trash" style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete Floor" onClick={(e) => this.showDeleteModalFloor(floor, building)}></i> */}
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    );
                                  })}
                                </table>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* </td> */}
                        {/* </tr> */}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <Dropzone
            onDrop={(e: any) => {
              this.floorPlanonDrop(e);
            }}
            disabled={this.state.field_disable}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-set">
                <div style={{ display: "none" }} id="floor_plan_drop_zone" {...getRootProps({ className: classDName2 })}>
                  <input {...getInputProps()} accept=".geojson" />
                </div>
                <div></div>
              </section>
            )}
          </Dropzone>
          <Modal show={this.state.show} handleClose={this.hideModal}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 10px" }}>
                    <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                      IMPORT FILE
                    </h6>
                    <i
                      className="material-icons modalclose imgclose-file m-l-5"
                      onClick={this.hideModal}
                      onKeyDown={(e) => e.key == "Enter" && this.hideModal()}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      clear
                    </i>
                  </div>
                  <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                    <div className="col-md-12 p-0">
                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone-set">
                            <div style={{ height: "125px", width: "260px", marginLeft: "8px" }} {...getRootProps({ className: classDName })}>
                              <input {...getInputProps()} accept=".xlsx" />
                              {files.length ? (
                                <div>
                                  <div className="m-t-30">
                                    <span className="justify-content-center" style={{ color: "#00D56E", display: "flex" }}>
                                      {files}
                                      {/* <i className="material-icons imgclose-file m-l-5" onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}
                                    </span>
                                  </div>
                                  <div className="m-t-10">
                                    <span>
                                      <a style={{ color: "#8F9FAC", fontSize: "14px" }}>Change File</a>
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="m-t-5" style={{ textAlign: "center" }}>
                                    <i className="fas fa-plus-circle text-center" style={{ fontSize: "40px", color: "#ccc" }}></i>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <span className="text-center" style={{ fontSize: "14px", color: "#9b9b9b" }}>
                                      Drag and drop files here
                                    </span>
                                  </div>
                                  <div className="m-t-5" style={{ textAlign: "center", paddingBottom: "10px" }}>
                                    <span className="font14 cursor" style={{ color: "#0059b2" }}>
                                      Browse
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  {files.length ? (
                    <div className="row m-t-10 justify-content-center" style={{ marginLeft: "1px", width: "265px" }}>
                      <div className="col-md-12 p-0">
                        <button
                          className="btn cardbtn"
                          style={{ width: "100%" }}
                          onClick={() => {
                            this.import_files();
                            this.hideModal();
                          }}
                        >
                          IMPORT
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="row m-t-10 justify-content-center" style={{ marginLeft: "1px", width: "265px" }}>
                      <div className="col-md-12 p-0">
                        <button className="btn cardbtn" style={{ width: "100%" }} disabled={true}>
                          IMPORT
                        </button>
                      </div>
                    </div>
                  )}
                  {/* <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                        <button className='btn cardbtn' style={{ width: '268px' }} onClick={this.import_files}>IMPORT</button>
                                    </div> */}
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={this.state.status_show}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 10px" }}>
                    <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                      IMPORT BUILDING STATUS
                    </h6>
                    <i
                      className="material-icons modalclose imgclose-file m-l-5"
                      onClick={this.status_hideModal}
                      onKeyDown={(e) => e.key == "Enter" && this.status_hideModal()}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      clear
                    </i>
                  </div>
                  <table className="table sitecollapse m-t-20">
                    <thead>
                      <tr>
                        <th className="w15">Building Name</th>
                        <th className="w20">Status</th>
                      </tr>
                      {status_list?.map((status: any) => {
                        return (
                          <tr key={status.name}>
                            <td>{status.name}</td>
                            <td>{status.status}</td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Modal>
          <Modal show={this.state.deletecardweb} handleClose={this.hideModal}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm" style={{ maxWidth: "335px" }}>
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 15px" }}>
                    <span className="modal-title" style={{ marginTop: "0px", fontSize: "14px", fontWeight: 500 }}>
                      CONFIRMATION
                    </span>
                    {/* <img className='' src={closeIconImg} /> */}

                    <i
                      className="far fa-times-circle modalclose"
                      data-dismiss="modal"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal();
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                        e.key == "Enter" && this.hideModal();
                      }}
                      aria-label="Close"
                    ></i>
                  </div>
                  <div className="row m-t-20 justify-content-center text-center" style={{ padding: "0 10px" }}>
                    <span className="modaltext">Are you sure you want to delete this Building?</span>
                  </div>

                  <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                    <div className="col-md-6 p-0">
                      <button
                        className="btn btn-secondary-c3"
                        style={{ width: "95%" }}
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.hideModal();
                        }}
                      >
                        No
                      </button>
                    </div>
                    <div className="col-md-6 p-0">
                      <button
                        className="btn modalbtn"
                        style={{ width: "98%", marginLeft: "2px" }}
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.delete_single_building();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal show={this.state.deletefloorcard} handleClose={this.hidefloorDeleteModal}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm" style={{ maxWidth: "335px" }}>
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 15px" }}>
                    <span className="modal-title" style={{ marginTop: "0px", fontSize: "14px", fontWeight: 500 }}>
                      CONFIRMATION
                    </span>
                    <i
                      className="far fa-times-circle modalclose"
                      data-dismiss="modal"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hidefloorDeleteModal();
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                        e.key == "Enter" && this.hidefloorDeleteModal();
                      }}
                      aria-label="Close"
                    ></i>
                  </div>
                  {this.state.selected_floor_asset_count > 0 ? (
                    <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                      <span className="modaltext">
                        Deleting the floor will also delete the assets mapped to it. Are you sure you want to continue?
                      </span>
                    </div>
                  ) : (
                    <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                      <span className="modaltext">Are you sure you want to delete this Floor?</span>
                    </div>
                  )}
                  <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                    <div className="col-md-6 p-0">
                      <button
                        className="btn btn-secondary-c3"
                        style={{ width: "95%" }}
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.hidefloorDeleteModal();
                        }}
                      >
                        No
                      </button>
                    </div>
                    <div className="col-md-6 p-0">
                      <button
                        className="btn modalbtn"
                        style={{ width: "98%", marginLeft: "2px" }}
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.delete_floor();
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />

          <IonLoading isOpen={this.state.is_loader === 1} onDidDismiss={() => this.setShowLoading(2)} message={"Loading..."} duration={5000} />
        </div>
      </div>
    );
  }
}
