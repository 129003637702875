import { saveAs } from "file-saver";
import React from "react";
import Dropzone from "react-dropzone";
import sort from "../assets/img/checked.png";
import { requestAPICall } from "../service/apiHandler";
import Modal from "./modal";

const INITIAL_STATE = {
  card: "",
  show: false,
  status_show: false,
};
let status_list: any = [];
let selected_polygon_list: any = [];
export default class Polygon extends React.Component {
  props: any = {};
  state: any = {};
  constructor(props: any) {
    super(props);
    console.log(this.props);
    this.state = { ...INITIAL_STATE };
    this.state = {
      files: [],
      country_name: "",
      boundary_type: "",
      boundary_value: "",
      region_name: "",
      geometry_type: "",
      polygon_list: [],
      showButton: false,
      select_polygonId: 0,
    };
    this.onChange = this.onChange.bind(this);
    this.list_polygon = this.list_polygon.bind(this);
    this.import_files = this.import_files.bind(this);
    this.showButton = this.showButton.bind(this);
  }
  componentDidMount() {
    this.list_polygon();
  }
  showModal = (e: any) => {
    e.preventDefault();
    this.setState({
      card: e.target.id,
      show: true,
    });
  };

  hideModal = () => {
    this.setState({
      card: "",
      files: [],
      show: false,
    });
  };
  status_hideModal = () => {
    this.setState({
      status_show: false,
    });
  };
  onDrop = (files: any) => {
    this.setState({ files: files });
  };
  import_files() {
    status_list = [];
    let picReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.files[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    picReader.onload = (e) => {
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: picReader.result,
        type: "polygon",
      };
      console.log(filerequestBody);
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        let requestBody = {
          file_path_id: data.file_id,
        };
        requestAPICall("import_polygan_boundaries", {}, requestBody).then((data) => {
          if (data.responseCode == 200) {
            status_list = data.status;
            this.setState({
              show: false,
              status_show: true,
            });
            this.list_polygon();
          } else {
            this.setState({
              show: false,
              status_show: false,
            });
          }
        });
      });
    };
    picReader.readAsDataURL(this.state.files[0]);
    this.hideModal();
  }
  update_polygon = (polygon: any) => {
    let requestBody = {
      country_name: this.state.country_name,
      boundary_type: this.state.boundary_type,
      boundary_value: this.state.boundary_value,
      region_name: this.state.region_name,
      geometry_type: this.state.geometry_type,
    };
    console.log(requestBody);
    requestAPICall("update_polygon_boundaries", { polygon_id: polygon.polygonId }, requestBody).then((data) => {
      this.list_polygon();
    });
  };
  list_polygon() {
    // Not calling polyganboundaries api
  }
  download_polygon = () => {
    let requestBody = {
      json_file: "polygon_boundaries.kml",
    };

    requestAPICall("download_files", {}, requestBody).then((data) => {
      let exportedFilenmae = "polygon_boundaries.kml";
      let blob = new Blob([data["file_content"]]);
      saveAs(blob, exportedFilenmae);
    });
  };
  showButton(polygonId: number) {
    this.setState({
      showButton: true,
      select_polygonId: polygonId,
    });
  }
  onChange(e: any, type: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.state.polygon_list?.map((polygon: any) => {
      if (polygon.polygonId === parseInt(e.target.name)) {
        if (type === "geometryType") {
          polygon.geometryType = e.target.value;
          this.setState({
            geometry_type: e.target.value,
          });
          return polygon;
        } else if (type === "regionName") {
          this.setState({
            region_name: e.target.value,
          });
          polygon.regionName = e.target.value;
          return polygon;
        } else if (type === "boundaryType") {
          this.setState({
            boundary_type: e.target.value,
          });
          polygon.boundaryType = e.target.value;
          return polygon;
        } else if (type === "boundaryValue") {
          polygon.boundaryValue = e.target.value;
          this.setState({
            boundary_value: e.target.value,
          });
          return polygon;
        } else if (type === "countryName") {
          polygon.countryName = e.target.value;
          this.setState({
            country_name: e.target.value,
          });
          return polygon;
        }
      }
      return polygon;
    });
  }

  coverage_layers_checked = (e: any) => {
    let floor_index = selected_polygon_list.indexOf(e.target.id);
    if (e.target.checked) {
      selected_polygon_list.push(parseInt(e.target.id));
    } else if (floor_index <= 0) {
      selected_polygon_list.splice(floor_index, 1);
    }
  };

  delete_polygon = () => {
    if (selected_polygon_list.length === 0) {
      this.setState({
        alertbox: true,
        warnMessage: "Please select one row",
      });
    }
    let requestBody = {
      polygon_id_list: selected_polygon_list,
    };
    requestAPICall("delete_polygon_boundary", {}, requestBody).then((data) => {
      console.log(data);
      this.setState({
        showButton: false,
      });
      this.setState({
        alertbox: true,
        warnMessage: data.message,
      });
      this.list_polygon();
    });
    this.list_polygon();
  };

  sortByValueAsc = (key: any) => {
    this.setState({
      show_sector_details: false,
    });
    let sortedProductsAsc;
    sortedProductsAsc = this.state.polygon_list.sort((a: any, b: any) => {
      return parseInt(a.key) - parseInt(b.key);
    });
    this.setState({
      polygon_list: sortedProductsAsc,
    });
  };
  sortByValueDesc = (key: any) => {
    this.setState({
      show_sector_details: false,
    });
    let sortedProductsDesc;
    sortedProductsDesc = this.state.polygon_list.sort((a: any, b: any) => {
      return parseInt(a.key) - parseInt(b.key);
    });
    this.setState({
      polygon_list: sortedProductsDesc,
    });
  };

  render() {
    let files = this.state.files?.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "importdropzuploaded" : "importdropz";
    return (
      <div className="card importcard">
        <div className="card-body">
          <div className="row" style={{ padding: "0px 10px" }}>
            <div className="col-md-8">
              <span style={{ fontSize: "13px" }}>LISTING</span>
            </div>
            <div className="col-md-4" style={{ display: "flex", justifyContent: "flex-end" }}>
              <span
                className="p-l-5 m-r-30 font13"
                style={{ color: "#0073FF", paddingTop: "2px", cursor: "pointer" }}
                onClick={this.delete_polygon}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.delete_polygon();
                  }
                }}
              >
                Delete File
              </span>
              <i className="material-icons" style={{ color: "#0073FF", cursor: "pointer" }}>
                get_app
              </i>
              <span
                className="p-l-5 m-r-30 font13"
                style={{ color: "#0073FF", cursor: "pointer", paddingTop: "2px" }}
                onClick={this.download_polygon}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.download_polygon();
                  }
                }}
              >
                Download Sample File
              </span>
              <i className="material-icons p-l-5" style={{ color: "#0073FF", transform: "rotate(180deg)", cursor: "pointer" }}>
                get_app
              </i>
              <span
                className="font13"
                style={{ color: "#0073FF", paddingTop: "2px", cursor: "pointer" }}
                id="heat"
                onClick={this.showModal}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.showModal(e);
                  }
                }}
              >
                Import File
              </span>
            </div>
          </div>
          <div className="row m-t-10" style={{ padding: "0px 10px" }}>
            <div className="col-md-12" style={{ overflowY: "auto", maxHeight: "calc(100vh - 244px)" }}>
              <table className="table sitetable">
                <thead className="sitehead" style={{ background: "none" }}>
                  <tr>
                    <th className="w5 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}></th>
                    <th className="w15 table-bg-hdr-color">
                      {"File Name"}
                      <img
                        alt="sort"
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("polyganFilename")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueDesc("polyganFilename");
                          }
                        }}
                      />
                      <img
                        alt="sort"
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("polyganFilename")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueAsc("polyganFilename");
                          }
                        }}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      {"Country Name"}
                      <img
                        alt="sort"
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("countryName")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueDesc("countryName");
                          }
                        }}
                      />
                      <img
                        alt="sort"
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("countryName")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueAsc("countryName");
                          }
                        }}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      {"Boundary Type"}
                      <img
                        alt="sort"
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("boundaryType")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueDesc("boundaryType");
                          }
                        }}
                      />
                      <img
                        alt="sort"
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("boundaryType")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueAsc("boundaryType");
                          }
                        }}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      {"Boundary Value"}
                      <img
                        alt="sort"
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("boundaryValue")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueDesc("boundaryValue");
                          }
                        }}
                      />
                      <img
                        alt="sort"
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("boundaryValue")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueAsc("boundaryValue");
                          }
                        }}
                      />
                    </th>
                    <th className="w10 table-bg-hdr-color">
                      {"Region Name"}
                      <img
                        alt="sort"
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("regionName")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueDesc("regionName");
                          }
                        }}
                      />
                      <img
                        alt="sort"
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("regionName")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueAsc("regionName");
                          }
                        }}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      {"Geometry Type"}
                      <img
                        alt="sort"
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("geometryType")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueDesc("geometryType");
                          }
                        }}
                      />
                      <img
                        alt="sort"
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("geometryType")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.sortByValueAsc("geometryType");
                          }
                        }}
                      />
                    </th>

                    <th className="w5 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.polygon_list?.map((polygon: any, index: any) => {
                    if (this.state.showButton && this.state.select_polygonId === polygon.polygonId) {
                      return (
                        <tr id={polygon.polygonId} key={`${index.toString()}-polygon-list`}>
                          <td className="w5"></td>
                          <td className="w20">{polygon.polyganFilename} </td>
                          <td className="w20">
                            <input
                              name={polygon.polygonId}
                              value={polygon.countryName}
                              onChange={(e) => {
                                this.onChange(e, "countryName");
                              }}
                            />{" "}
                          </td>
                          <td className="w20">
                            <input
                              name={polygon.polygonId}
                              value={polygon.boundaryType}
                              onChange={(e) => {
                                this.onChange(e, "boundaryType");
                              }}
                            />{" "}
                          </td>
                          <td className="w20">
                            <input
                              name={polygon.polygonId}
                              value={polygon.boundaryValue}
                              onChange={(e) => {
                                this.onChange(e, "boundaryValue");
                              }}
                            />
                          </td>
                          <td className="w15">{polygon.regionName}</td>
                          <td className="w15">
                            <input
                              name={polygon.polygonId}
                              value={polygon.geometryType}
                              onChange={(e) => {
                                this.onChange(e, "geometryType");
                              }}
                            />
                          </td>

                          <td className="w5">
                            <button className="btn cardbtn" style={{ width: "100%" }} onClick={() => this.update_polygon(polygon)}>
                              Save
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={`${index.toString()}-coverage-layer`}>
                          <td className="w5">
                            <input type="checkbox" id={polygon.polygonId} onChange={this.coverage_layers_checked}></input>
                          </td>
                          <td className="w20">{polygon.polyganFilename} </td>
                          <td className="w20">{polygon.countryName} </td>
                          <td className="w20">{polygon.boundaryType} </td>
                          <td className="w20">{polygon.boundaryValue}</td>
                          <td className="w15">{polygon.regionName}</td>
                          <td className="w15">{polygon.geometryType}</td>

                          <td className="w5">
                            <i
                              className="fas fa-ellipsis-v float-right"
                              style={{ color: "#B4B4B4" }}
                              onClick={() => this.showButton(polygon.polygonId)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.showButton(polygon.polygonId);
                                }
                              }}
                            ></i>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    IMPORT FILE
                  </h6>
                  <i
                    className="material-icons modalclose imgclose-file m-l-5"
                    onClick={this.hideModal}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.hideModal();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-12 p-0">
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone-set">
                          <div style={{ height: "125px", width: "260px", marginLeft: "8px" }} {...getRootProps({ className: classDName })}>
                            <input {...getInputProps()} accept=".json , .kml" />
                            {files.length ? (
                              <div>
                                <div className="m-t-30">
                                  <span className="justify-content-center" style={{ color: "#00D56E", display: "flex" }}>
                                    {files}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="m-t-5" style={{ textAlign: "center" }}>
                                  <i className="fas fa-plus-circle text-center" style={{ fontSize: "40px", color: "#ccc" }}></i>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <span className="text-center" style={{ fontSize: "14px", color: "#9b9b9b" }}>
                                    Drag and drop files here
                                  </span>
                                </div>
                                <div className="m-t-5" style={{ textAlign: "center", paddingBottom: "10px" }}>
                                  <span className="font14 cursor" style={{ color: "#0059b2" }}>
                                    Browse
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {files.length ? (
                  <div className="row m-t-10 justify-content-center" style={{ marginLeft: "1px", width: "265px" }}>
                    <div className="col-md-12 p-0">
                      <button
                        className="btn cardbtn"
                        style={{ width: "100%" }}
                        onClick={() => {
                          this.import_files();
                          this.hideModal();
                        }}
                      >
                        IMPORT
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row m-t-10 justify-content-center" style={{ marginLeft: "1px", width: "265px" }}>
                    <div className="col-md-12 p-0">
                      <button className="btn cardbtn" style={{ width: "100%" }} disabled={true}>
                        IMPORT
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.status_show}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    POLYGON BOUNDARY STATUS
                  </h6>
                  <i
                    className="material-icons modalclose imgclose-file m-l-5"
                    onClick={this.status_hideModal}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.status_hideModal();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <table className="table sitecollapse m-t-20">
                  <thead>
                    <tr>
                      <th className="w15">Polygon Name</th>
                      <th className="w20">Status</th>
                    </tr>
                    {status_list?.map((status: any, index: any) => {
                      return (
                        <tr key={`${index.toString()}-status`}>
                          <td>{status.name}</td>
                          <td>{status.status}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
