import React from "react";
import { IonPage, IonContent, withIonLifeCycle } from "@ionic/react";
import Heat from "../assets/img/heaticon.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import geo from "../assets/img/target-icon.png";
import "./dashboard.css";
import { isBrowser, isMobile } from "react-device-detect";
import store from "../store";

let is_submit_form = false;

class AddCPE2 extends React.Component {
  state: any = {};
  props: any = {};
  cpeFocus: any = [];
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.cpeFocus = [];
    this.state = {
      add_cp2_property: [],
      asset_action: "",
      asset_property: [],
    };
    this.load_property = this.load_property.bind(this);
    this.next_step = this.next_step.bind(this);
  }

  initializeComponent = () => {
    this.reduxStore = store.getState()["general"];
  };
  componentDidMount() {
    this.initializeComponent();
    if (isBrowser) {
      this.load_property();
      this.setState(
        {
          add_cp2_property: this.props.asset_property,
          asset_action: this.props.asset_properties["asset_action"],
          asset_property: this.props.asset_properties["properties"],
        },
        () => this.load_property()
      );
    } else {
      this.setState({
        add_cp2_property: this.reduxStore["cp2_property"],
        asset_property: this.reduxStore["asset_property"],
      });
    }
  }
  load_property = () => {
    this.setState({
      add_cp2_property: this.props.asset_property,
    });
  };
  onChange(e: any) {
    console.log(e.target.name, e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.state.asset_property?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
      return property;
    });
  }
  validationRequest() {
    let check = false;

    this.props.asset_property?.forEach((property: any, key: any) => {
      if (!check) {
        if (property.propertyName === "enb_id" && property.value == "") {
          this.cpeFocus[key].focus();
          check = true;
        }
      }
    });
    is_submit_form = !check;
  }

  next_step = () => {
    this.validationRequest();
    if (is_submit_form) {
      this.props.set_asset_property(this.props.asset_properties, 3);
      this.props.next_step3();
    }
  };

  mob_next_step = () => {
    this.props.history.push("/addCPE3");
  };

  navigateToAddCPE() {
    this.props.history.push("/addCPE");
  }

  render() {
    return (
      <div>
        {isBrowser && (
          <div className="webonly">
            <div className="row">
              <div className="col-md-9">
                <div className="card apcard">
                  <div className="apcardbody">
                    <div className="row">
                      <div className="col-md-3">
                        <img className="backicon" src={BackIcon} />
                        <button type="button" className="backbutton" onClick={this.props.back}></button>
                      </div>
                      <div className="col-md-8" style={{ padding: "13px 0" }}>
                        <span className="apheader"> {this.state.asset_action === "add" ? "ADDING CPE" : "EDIT CPE"}</span>
                        <span className="apnote float-right" style={{ fontSize: "12px", margin: "5px" }}>
                          1/3
                        </span>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12 apborder p-0">
                        <span className="apnote">Click where you'd like to drop the CPE</span>
                      </div>
                    </div>
                    {this.props.asset_property?.map((property: any, key: any) => {
                      if (property.dataType === "string" || property.dataType === "int") {
                        if (property.isRequired) {
                          const element = (
                            <div className="col-md-12 p-0 m-t-7">
                              <div className="form-label-group m-0">
                                <input
                                  ref={(ref) => {
                                    this.cpeFocus[key] = ref;
                                    return true;
                                  }}
                                  type="text"
                                  id={property.propertyName}
                                  name={property.propertyName}
                                  disabled={this.state.field_disable}
                                  className="accesspointname"
                                  value={property.value}
                                  placeholder={property.displayName + "*"}
                                  onChange={this.onChange.bind(this)}
                                />
                                <label className="pages" htmlFor={property.propertyName}>
                                  {property.displayName + " *"}
                                </label>
                              </div>
                            </div>
                          );
                          return element;
                        } else {
                          const element = (
                            <div className="col-md-12 p-0 m-t-7">
                              <div className="form-label-group m-0">
                                <input
                                  ref={(ref) => {
                                    this.cpeFocus[key] = ref;
                                    return true;
                                  }}
                                  type="text"
                                  id={property.propertyName}
                                  name={property.propertyName}
                                  disabled={this.state.field_disable}
                                  className="accesspointname"
                                  value={property.value}
                                  placeholder={property.displayName + "*"}
                                  onChange={this.onChange.bind(this)}
                                />
                                <label className="pages" htmlFor={property.propertyName}>
                                  {property.displayName}
                                </label>
                              </div>
                            </div>
                          );
                          return element;
                        }
                      }
                    })}

                    <div className="row">
                      <div className="col-md-12 m-t-5">
                        <button className="btn savebtn btn-secondary-c3" style={{ bottom: "20px", width: "268px" }} onClick={this.next_step}>
                          Next Step
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="col-md-12 justify-content-flex-end">
                  <div className="heaticon text-center">
                    <img className="heat" src={Heat} />
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div className="geoicon text-center">
                    <img style={{ padding: "5px" }} src={geo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className="mobonly">
            <IonPage>
              <IonContent>
                <div className="param-info-mob " style={{ height: "100vh" }}>
                  <div className="col-12 text-center searchborder" style={{ padding: "10px" }}>
                    <img
                      className="backicon-mob"
                      style={{ width: "43px", paddingTop: "6px" }}
                      src={BackIcon}
                      onClick={this.navigateToAddCPE}
                      onKeyDown={(e) => e.key == "Enter" && this.navigateToAddCPE()}
                    />
                    <span className="apheader">ADD CPE</span>
                  </div>
                  <div className="container-fluid apdash">
                    <div className="card apeditcard" style={{ height: "calc(100vh - 150px)" }}>
                      <div className="card-body" style={{ overflowY: "auto" }}>
                        <div className="row">
                          <div className="col-12">
                            <span className="apheader">CUSTOMER EQUIPMENT</span>
                          </div>
                        </div>
                        {this.state.add_cp2_property?.map((property: any, key: any) => {
                          if (property.dataType === "string" || property.dataType === "int") {
                            const element = (
                              <div className="col-md-12 p-0 m-t-7">
                                <div className="form-label-group m-0">
                                  <input
                                    ref={(ref) => {
                                      this.cpeFocus[key] = ref;
                                      return true;
                                    }}
                                    type="text"
                                    id={property.propertyName}
                                    name={property.propertyName}
                                    disabled={this.state.field_disable}
                                    className="accesspointname"
                                    placeholder={property.displayName + "*"}
                                    onChange={this.onChange.bind(this)}
                                  />
                                  <label className="pages" htmlFor={property.propertyName}>
                                    {`${property.displayName} ${property.isRequired ? "*" : ""}`}
                                  </label>
                                </div>
                              </div>
                            );
                            return element;
                          }
                        })}

                        <div className="row m-t-10 ">
                          <div className="col-12 ">
                            <button className="lightbtnmob " disabled={false} onClick={this.mob_next_step}>
                              {" "}
                              Next Step{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </IonContent>
            </IonPage>
          </div>
        )}
      </div>
    );
  }
}

export default withIonLifeCycle(AddCPE2);
