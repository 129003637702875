import ConfigJSON from "../config/config";
import fernet from "fernet";

const secret = new fernet.Secret(process.env.REACT_APP_API_SYM_KEY);

export const requestAPICall = (api, params, body) => {
  // Static from ENV for local Development
  // const baseUrl = process.env.REACT_APP_API_URL;
  // Dynamic for Deployments
  const baseUrl = `${window.location.origin}/api`;

  // Encypting the request body
  body = {
    enc_body: encryptBody(body),
  };

  let url = getParsedUrlByJson(ConfigJSON["api"][api]["url"], params);
  let headersObj = {
    "Content-type": "application/json; charset=UTF-8",
    Accept: "application/json",
  };
  if (ConfigJSON["exclude_authorization"].indexOf(api) < 0) {
    headersObj["Authorization"] = localStorage.getItem("idToken");
  }
  if (ConfigJSON["api"][api]["method"] !== "GET") {
    return fetch(baseUrl + url, {
      method: ConfigJSON["api"][api]["method"],
      headers: headersObj,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 404) {
          return { message: "Found Invalid Endpoint", responseCode: 400 };
        } else if (response.status === 500) {
          return { message: "Internal Server Error", responseCode: 400 };
        } else {
          return decryptBody(response.json());
        }
      })
      .catch((_) => {});
  } else {
    return fetch(baseUrl + url, {
      method: ConfigJSON["api"][api]["method"],
      headers: headersObj,
    })
      .then((response) => {
        if (response.status === 404) {
          return { message: "Found Invalid Endpoint", responseCode: 400 };
        } else {
          return decryptBody(response.json());
        }
      })
      .catch((_) => {});
  }
};

function getParsedUrlByJson(str, val) {
  for (let key in val) {
    let k = "{" + key + "}";
    do {
      str = str.replace(k, val[key]);
    } while (str.includes(k));
  }
  return str;
}

function encryptBody(body) {
  try {
    const token = new fernet.Token({
      secret: secret,
    });
    const data = token.encode(JSON.stringify(body));
    return data;
  } catch (e) {
    console.error(e);
    return "";
  }
}

function decryptBody(encryptedText) {
  return new Promise((resolve) => {
    try {
      encryptedText.then((encryptedText) => {
        if (!encryptedText?.enc_body) {
          resolve(encryptedText);
        } else {
          const token = new fernet.Token({
            secret: secret,
            token: encryptedText?.enc_body,
          });
          resolve(JSON.parse(token.decode()));
        }
      });
    } catch (e) {
      console.error(e);
      resolve({ responseCode: 400, message: e });
    }
  });
}
