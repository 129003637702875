import React, { Component } from "react";
import APParameters from "./ap-paramaters";
import NetworkStatus from "./network-status";

export default class APMoreInfo extends Component {
  state: any = {};
  props: any = {};
  ap_parameters_value: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.ap_parameters_value = React.createRef();
    this.backfromAPParam = this.backfromAPParam.bind(this);
    this.backToList = this.backToList.bind(this);
  }

  set_property = () => {
    this.ap_parameters_value.current.set_property();
  };

  backfromAPParam() {
    this.props.backtoAPInfo();
  }
  backToList() {
    this.props.backToAssetList();
  }
  render() {
    return (
      <div>
        <div className="card accesscard">
          <div className="card-body" style={{ padding: "0px" }}>
            <div className="row m-t-10">
              <div className="col-md-12">
                <ul className="nav nav-tabs aptab" id="apTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="param-tab"
                      data-toggle="tab"
                      href="#param"
                      role="tab"
                      aria-controls="param"
                      aria-selected="true"
                      style={{ height: "36px" }}
                    >
                      PARAMETERS
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="network-tab"
                      data-toggle="tab"
                      href="#network"
                      role="tab"
                      aria-controls="network"
                      aria-selected="false"
                      style={{ height: "36px" }}
                    >
                      NETWORK TEST
                    </a>
                  </li>
                </ul>
              </div>
              <i
                className="fa fa-times closeicon"
                style={{ padding: "11px 10px 0px 0px" }}
                onClick={this.backToList}
                onKeyDown={(e) => e.key === "Enter" && this.backToList()}
              ></i>
            </div>
            <div className="tab-content" id="searchTabContent">
              <div className="tab-pane fade show active" id="param" role="tabpanel" aria-labelledby="param-tab">
                <APParameters
                  refreshAsset={(id: any) => {
                    this.props.refreshAsset(id);
                  }}
                  refreshStatus={(id: any) => {
                    this.props.refreshStatus(id);
                  }}
                  ref={this.ap_parameters_value}
                  assetInfo={this.props["assetInfo"]}
                  backfromAPParam={this.backfromAPParam}
                />
              </div>
              <div className="tab-pane fade" id="network" role="tabpanel" aria-labelledby="network-tab">
                <NetworkStatus assetInfo={this.props["assetInfo"]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
