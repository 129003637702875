import React from "react";
import "./profile-info.css";
import { IonPage, IonContent, withIonLifeCycle, IonAlert } from "@ionic/react";
import BackIcon from "../assets/img/go-back-icon-img.png";
import { requestAPICall } from "../service/apiHandler";
import { connect } from "react-redux";
import { UPDATE_FIELD_AUTH, CURRENT_TAB } from "../constants/actionTypes";
import { Plugins, AppState } from "@capacitor/core";

const { App } = Plugins;
const { BackgroundTask } = Plugins;

let INITIAL_STATE = {
  rejectedNotificationList: [],
  alertbox: false,
};

const mapStateToProps = (state: any) => ({ ...state.auth, ...state.general });
const mapDispatchToProps = (dispatch: any) => ({
  upadatePendingNotificationCount: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "pendingNotificationCount", value }),
  upadateRejectedNotificationCount: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "rejectedNotificationCount", value }),
  upadateMapCoordinates: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "notificationCoordinates", value }),
  upadateMapCoordinatesBool: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "updateMapCoordinateBool", value }),
  upadateUrlPath: (value: any) => dispatch({ type: CURRENT_TAB, key: "updateUrlPath", value }),
});
class Notification extends React.Component {
  state: any = {};
  props: any = {};
  backButton_event: any;
  backButton_timer: any = null;
  appState_event: any;
  constructor(props: any) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.getNotificationList = this.getNotificationList.bind(this);
  }

  componentDidMount() {
    localStorage.setItem("urlPath", this.props.location.pathname);
    this.getNotificationList();
    this.set_backButton_listener();
  }

  componentWillUnmount() {
    if (this.backButton_timer != null) {
      clearInterval(this.backButton_timer);
      this.backButton_timer = null;
    }
    if (this.backButton_event != null) {
      this.backButton_event.remove();
    }
    if (this.appState_event != null) {
      this.appState_event.remove();
    }
    localStorage.removeItem("backButton");
    localStorage.removeItem("appState");
  }
  set_backButton_listener = () => {
    let backButtonListenerExists = localStorage.getItem("backButton");
    if (backButtonListenerExists == null) {
      localStorage.setItem("backButton", "true");
      this.backButton_event = App.addListener("backButton", (state: any) => {
        // state.isActive contains the active state
        this.props.history.push("/map/outdoor/main");
      });
    }
    if (this.backButton_timer == null) {
      this.backButton_timer = setInterval(() => {
        this.set_backButton_listener();
      }, 2000);
    }
    let appStateListenerExists = localStorage.getItem("appState");
    if (appStateListenerExists == null) {
      localStorage.setItem("appState", "true");
      this.appState_event = App.addListener("appStateChange", (state: AppState) => {
        if (state.isActive) {
          this.set_backButton_listener();
        }
        if (!state.isActive) {
          let taskId = BackgroundTask.beforeExit(async () => {
            if (this.backButton_timer != null) {
              clearInterval(this.backButton_timer);
              this.backButton_timer = null;
            }
            if (this.backButton_event != null) {
              this.backButton_event.remove();
            }
            localStorage.removeItem("backButton");
            // Must call in order to end our task otherwise
            // we risk our app being terminated, and possibly
            // being labeled as impacting battery life
            BackgroundTask.finish({
              taskId,
            });
          });
        }
      });
    }
  };
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };
  getNotificationList() {
    let requestBody = {};
    requestAPICall("get_notification_list", requestBody).then((data) => {
      if (data.responseCode == 200) {
        this.setState({
          rejectedNotificationList: data.rejectedNotificationList,
        });
        if (data.rejectedNotificationList.length == 0) {
          this.setState({
            alertbox: true,
            warnMessage: "No Actions",
          });
        }
      }
    });
  }
  navigateToMap = (lat: any, long: any, assetTypeId: any) => {
    this.props.upadateMapCoordinatesBool(true);
    this.props.upadateMapCoordinates({ latitude: lat, longitude: long });
    if (assetTypeId == 1 || assetTypeId == 3) this.props.history.push("/map/outdoor/main");
    if (assetTypeId == 2 || assetTypeId == 4 || assetTypeId == 5) this.props.history.push("/map/indoor/main");
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div>
            <div className="mobonly">
              <div className="param-info-mob" style={{ height: "100vh" }}>
                <div className="col-12 text-center header" style={{ padding: "10px", zIndex: 99, paddingTop: "env(safe-area-inset-top)" }}>
                  <img
                    alt="back-icon-notif"
                    className="backicon-mob"
                    style={{ width: "43px", paddingTop: "6px" }}
                    src={BackIcon}
                    onClick={() => {
                      this.props.history.push("/map/outdoor/main");
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.props.history.push("/map/outdoor/main");
                      }
                    }}
                  />
                  <span className="apheader">NOTIFICATIONS</span>
                </div>
                <div className="container-fluid notidash">
                  {this.state.rejectedNotificationList?.map((val: any, index: number) => {
                    return (
                      <div className="card noticard" key={`${index.toString()}-notifcard`}>
                        <div
                          className="card-body"
                          onClick={() => {
                            this.navigateToMap(val.asset_details.latitude, val.asset_details.longitude, val.asset_details.asset_type_id);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.navigateToMap(val.asset_details.latitude, val.asset_details.longitude, val.asset_details.asset_type_id);
                            }
                          }}
                        >
                          Param {val.asset_details.asset_type}-{val.asset_details.serial_id} is rejected by CPI
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />
        </IonContent>
      </IonPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Notification));
