import { CameraResultType, CameraSource, Plugins } from "@capacitor/core";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import { IonAlert, IonItem, IonLabel, IonLoading, IonTextarea, withIonLifeCycle } from "@ionic/react";
import React from "react";
import { isBrowser, isMobile } from "react-device-detect";
import Dropzone from "react-dropzone";
import qricon from "../assets/img/bar-code-img-icon.png";
import camicon from "../assets/img/camera-icon-img.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import Heat from "../assets/img/heaticon.png";
import geo from "../assets/img/target-icon.png";
import { ASSET } from "../constants/actionTypes";
import { requestAPICall } from "../service/apiHandler";
import store from "../store";
import "./AddAP.css";
import "./dashboard.css";

const { Camera } = Plugins;
const { Keyboard } = Plugins;

let sector_list_ids: any = [];
let asset_name = "";
let is_submit_form = false;
let secval = false;
let assetTrackingId = "";
let add_ap2_properties: any = [];
let add_ap3_properties: any = [];
let AP_Data: any = [];
let unit: any = "";
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  set_ap2_value: (value: any) => dispatch({ type: ASSET, key: "ap2_property", value }),
  set_ap3_value: (value: any) => dispatch({ type: ASSET, key: "ap3_property", value }),
  set_asset_value: (value: any) => dispatch({ type: ASSET, key: "asset_property", value }),
  set_responseBody: (value: any) => dispatch({ type: ASSET, key: "requestBody", value }),
});
class AddAP extends React.Component {
  state: any = {};
  props: any = {};
  apFocus: any = [];
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);

    this.apFocus = [];
    this.state = {
      card: 1,
      sectorsCount: [1, 2, 3, 4, 5, 6],
      selectedSectorCount: 0,
      name: "",
      asset_id: "",
      description: "",
      image: "",
      alertbox: false,
      is_loader: false,
      serial_id: "",
      ap_name: "",
      sectorlist: [],
      //DONE: instead of files, create variables for all image types
      // azimuth_image: [],
      // height_image: [],
      // site_image: [],
      // downtilt_image: [],
      assetAction: "add",
      assetProperties: [],
      disable_button: true,
      field_disable: true,
      assetTypeId: 0,
      serial: "",
      asset_ap_id: "",
      asset_serial_id: "",
      add_ap1_properties: [],
      vendor_type: "",
      ap_mounting_type: "",
      isShowSectorIcons: false,
      oldSerialId: "",
      rma: false,
      // isAzimuthInAction: false
    };
    this.load_sector = this.load_sector.bind(this);
    this.setSelectedSector = this.setSelectedSector.bind(this);
    this.submit_parameters = this.submit_parameters.bind(this);
    this.editAssetChanges = this.editAssetChanges.bind(this);
    this.validate_request = this.validate_request.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sector_id_list = this.sector_id_list.bind(this);
    this.azimuthClickedMob = this.azimuthClickedMob.bind(this);
    this.azimuthClicked = this.azimuthClicked.bind(this);
    this.removeAzimuthFromRedux = this.removeAzimuthFromRedux.bind(this);
  }

  componentDidMount() {
    console.log("redux: ", this.reduxStore["newSerialId"]);
    if (isBrowser) {
      this.initializeComponent();
    }
    if (isMobile) {
      this.initializeComponent();
    }
  }

  componentWillUnmount() {
    delete this.reduxStore["newSerialId"];
    console.log("redux: ", this.reduxStore["newSerialId"]);
  }

  removeAzimuthFromRedux() {
    if ("currentAssetProperties" in this.reduxStore) {
      delete this.reduxStore["currentAssetProperties"];
    }
    if ("currentSectorList" in this.reduxStore) {
      delete this.reduxStore["currentSectorList"];
    }
    if ("newSerialId" in this.reduxStore) {
      delete this.reduxStore["newSerialId"];
    }
    this.props.changeAziToDefault();
  }

  initializeComponent = () => {
    this.reduxStore = store.getState()["general"];
    if (this.state.action === "edit") {
      this.setState({
        disable_button: false,
      });
    }
    // DONE: remove files from bottom
    this.setState(
      {
        assetAction: this.reduxStore["assetAction"],
        assetProperties: [],
        assetTypeId: this.reduxStore["assetTypeId"],
      },
      () => {
        this.getProperties();
      }
    );
  };

  openScanner = async () => {
    const data = await BarcodeScanner.scan();
    if (!data.cancelled) {
      this.setState({ serial_id: data.text });
      this.onChange({ target: { name: "serial_id", value: data.text } });
    }
  };

  takePicture = async (imageType: any) => {
    try {
      let image: any = await Camera.getPhoto({
        quality: 50,
        allowEditing: false,
        source: CameraSource.Camera,
        resultType: CameraResultType.DataUrl,
        saveToGallery: false,
      });
      image["fileName"] = "camera_upload";
      image["uploadType"] = "capture";
      image["name"] = "IMG_" + imageType + "." + image["format"];
      this.handleImage([image]);
    } catch (error) {
      this.setState({
        is_loader: false,
        warnMessage: error,
        alertbox: true,
      });
    }
  };

  getPropertiesFromRedux = () => {
    this.reduxStore["currentAssetProperties"]?.map((property: any) => {
      if (property.propertyName == "no_of_sectors") {
        this.setSelectedSector(property.value);
      }
      if (property.propertyName == "ap_mounting_type") {
        this.setState({ is_loader: true });
        let requestBody = {};
        requestAPICall("list_asset_properties", { asset_type_id: property.value }, requestBody).then((data) => {
          this.setState({ is_loader: false });
          if (data.responseCode == 200) {
            this.setState({
              vendor_type: data["vendor_type"],
            });
            if (data["vendor_type"] == "Directional") {
              this.setState({ isShowSectorIcons: true });
            } else {
              this.setState({ isShowSectorIcons: false });
            }
          }
        });
      }
      this.setState({
        [property.propertyName]: property.value,
      });
      if (property.propertyName === "serial_id") {
        this.setState({
          serial_id: property.value,
        });
      }
      if (property.propertyName === "access_point_id") {
        assetTrackingId = property.value;
        console.log("Assets tracking ID: ", assetTrackingId);
      }
      if (property.propertyName === "ap_name") {
        asset_name = property.value;
        this.setState({
          ap_name: property.value,
        });
      }
    });

    // check how the sector values get populated..if its a list then throw a list from azimuth
    // page and set that list == redux store list here otherwise individual values.
    // console.log("filter isAzimuthSet ", this.props.isAzimuthSet, this.reduxStore['currentAssetProperties'])
    sector_list_ids = this.reduxStore["currentSectorList"];
    this.setState({ assetProperties: this.reduxStore["currentAssetProperties"] }, () => this.removeAzimuthFromRedux());
  };

  getEditableProperties = () => {
    requestAPICall(
      "get_asset_editable_data",
      {
        asset_type: this.reduxStore["assetTypeId"],
        asset_id: this.reduxStore["assetId"],
      },
      {}
    ).then((data) => {
      try {
        if (data.responseCode == 200) {
          let number_of_sector = data["properties"].filter((property: any) => property.propertyName === "no_of_sectors");
          let name = data["properties"].filter((property: any) => property.propertyName === "ap_name");
          assetTrackingId = data["properties"].filter((property: any) => property.propertyName === "access_point_id")[0].value;
          asset_name = name[0].value;

          if ("newSerialId" in this.reduxStore && this.reduxStore["newSerialId"] !== undefined) {
            this.setState(
              {
                serial_id: this.reduxStore["newSerialId"],
                oldSerialId: data["properties"].filter((property: any) => property.propertyName === "serial_id")[0].value,
                rma: true,
                // edit_serial: true,
              },
              () => {
                data["properties"].filter((property: any) => property.propertyName === "serial_id")[0].value = this.reduxStore["newSerialId"];
              }
            );
          } else {
            this.setState({
              serial_id: data["properties"].filter((property: any) => property.propertyName === "serial_id")[0].value,
            });
          }

          this.setState(
            {
              assetProperties: data["properties"],
              asset_id: this.props.selectedAssetId,
              ap_name: name[0].value,
            },
            () => {
              this.setDefaults();
            }
          );
          this.setState(
            {
              selectedSectorCount: number_of_sector[0].value,
              asset_id: this.props.selectedAssetId,
              sectorlist: this.props.sector_id_list,
              assetTypeId: this.props.typeId,
            },
            () => {
              this.load_sector(this.state.selectedSectorCount);
            }
          );
          this.setState({
            vendor_type: data["vendor_type"],
          });
          if (data["vendor_type"] == "Directional") {
            this.setState({ isShowSectorIcons: true });
          } else {
            this.setState({ isShowSectorIcons: false });
          }
        }
      } catch (error) {}
    });
  };

  setNonEmptyApData = () => {
    let parse_data = JSON.parse(AP_Data);
    try {
      if (isMobile) {
        parse_data?.forEach((property: any) => {
          if (property.propertyName === "latitude") {
            property.value = this.reduxStore["coordinates"]["latitude"];
          } else if (property.propertyName === "longitude") {
            property.value = this.reduxStore["coordinates"]["longitude"];
          }
        });
      }
      this.setState(
        {
          assetProperties: parse_data,
        },
        () => {
          this.setDefaults();
        }
      );
    } catch (error) {}
  };

  setEmptyApData = () => {
    requestAPICall("list_asset_properties", { asset_type_id: this.reduxStore["assetTypeId"] }, {}).then((data) => {
      if (data.responseCode == 200) {
        if (this.reduxStore["tabType"] == "outdoor") {
          localStorage.setItem("AP_Outdoor_Data", JSON.stringify(data["properties"]));
        }
        if (this.reduxStore["tabType"] == "indoor") {
          localStorage.setItem("AP_Indoor_Data", JSON.stringify(data["properties"]));
        }
        try {
          if (isMobile) {
            data.properties?.forEach((property: any) => {
              if (property.propertyName === "latitude") {
                property.value = this.reduxStore["coordinates"]["latitude"];
              } else if (property.propertyName === "longitude") {
                property.value = this.reduxStore["coordinates"]["longitude"];
              }
            });
          }
          this.setState({ assetProperties: data.properties }, () => {
            this.setDefaults();
          });
        } catch (error) {}
      }
    });
  };

  getNonEditableProperties = () => {
    if (this.reduxStore["tabType"] == "outdoor") {
      AP_Data = localStorage.getItem("AP_Outdoor_Data");
    }
    if (this.reduxStore["tabType"] == "indoor") {
      AP_Data = localStorage.getItem("AP_Indoor_Data");
    }
    if (AP_Data == null) {
      this.setEmptyApData();
    } else {
      this.setNonEmptyApData();
    }
  };

  getPropertiesFromAPI = () => {
    if (this.reduxStore["assetAction"] === "edit") {
      this.getEditableProperties();
    } else {
      this.getNonEditableProperties();
    }
  };

  getProperties() {
    if ("currentAssetProperties" in this.reduxStore) {
      this.getPropertiesFromRedux();
    } else {
      this.getPropertiesFromAPI();
    }
  }

  setDefaults() {
    if (isBrowser) {
      this.state.assetProperties?.forEach((property: any) => {
        if (property.propertyName == "no_of_sectors") {
          this.setState({
            selectedSectorCount: property.value,
          });
        }
      });
      this.setState(
        {
          assetAction: this.reduxStore["assetAction"],
          assetId: this.props.assetId,
        },
        () => {
          if (this.state.assetAction === "edit") {
            this.setState(
              {
                asset_id: this.props.parentState.selectedAssetId,
                field_disable: false,
                sectorlist: this.props.parentState.asset_details.properties.sector_id,
                assetTypeId: this.props.parentState.asset_details.assetTypeId,
              },
              () => {
                this.load_sector(this.state.selectedSectorCount);
              }
            );
          }
          this.disableButton();
        }
      );
    }
  }

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  goBack(deviceType: any) {
    if (isBrowser) {
      this.props.back();
    } else {
      this.props.history.push("/map");
    }
  }

  disableButton = () => {
    this.reduxStore = store.getState()["general"];
    if (this.props.assetAction === "add") {
      if (this.props.latlong.length === 0) {
        this.setState({
          field_disable: true,
          disable_button: true,
        });
      } else {
        this.setState({
          field_disable: false,
          disable_button: false,
        });
      }
    }

    this.setState((prevState: any) => ({
      assetProperties: prevState.assetProperties?.map((property: any) => {
        if (property.propertyName === "latitude") {
          property.value = this.reduxStore["coordinates"]["latitude"];
        } else if (property.propertyName === "longitude") {
          property.value = this.reduxStore["coordinates"]["longitude"];
        }
        return property;
      }),
    }));
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };

  setSelectedSector = (count: number) => {
    sector_list_ids = [];
    this.setState({ selectedSectorCount: count });
    if (this.props.assetAction !== "edit") {
      if (isBrowser) {
        this.props.setSector(count);
      }
    }
    this.load_sector(count);
    this.state.assetProperties?.forEach((property: any) => {
      if (property.propertyName == "no_of_sectors") {
        property.value = count;
      }
    });
    let diff = count - sector_list_ids.length;
    if (this.props.assetAction === "edit") {
      if (diff > 0) {
        for (let index = sector_list_ids.length; index < count; index++) {
          let json: any = {};
          json["sector_id"] = 0;
          json["sector_value"] = index + 1;
          json["azimuth_value"] = 0;
          sector_list_ids.push(json);
        }
      } else {
        for (let index = 0; index < Math.abs(diff); index++) {
          sector_list_ids.pop();
        }
      }
    }
  };
  validate_request() {
    let check = false;
    this.state.assetProperties?.forEach((property: any) => {
      if (property.value === "") {
        check = true;
      }
    });
    if (!check) {
      this.setState({
        disable_button: check,
      });
    }
  }
  validateSectors() {
    let check = false;
    let check_sector_value: any;
    check_sector_value = [];
    sector_list_ids?.forEach((sector: any, key: any) => {
      if (check) return;
      if (sector.sector_value === 0 || sector.sector_value === "") {
        this.setState({
          warnMessage: "Sector" + " Id cannot be empty",
          alertbox: true,
        });
        check = true;
      }
      if (sector.sector_value !== "") {
        if (!/^[a-zA-Z0-9.-]+$/.test(sector.sector_value)) {
          this.setState({
            warnMessage: "Please enter only Alphanumeric values for Sector Id ",
            alertbox: true,
          });
          check = true;
        }
      }
    });
    sector_list_ids?.map((element: any) => {
      check_sector_value.push(element.sector_value);
    });
    const sector_set = new Set(check_sector_value);
    if (check_sector_value.length !== sector_set.size) {
      this.setState({
        alertbox: true,
        warnMessage: "Sector value cannot be duplicate",
      });
      check = true;
    }
    if (check === false) {
      secval = true;
    } else if (check === true) {
      secval = false;
    }
  }

  validationRequest() {
    let check = false;
    this.state.assetProperties?.forEach((property: any, key: any) => {
      if (check) return;
      if (property.value === "" && property.isRequired && property.isShowable && property.propertyName !== "no_of_sectors") {
        check = true;
        this.setState({
          warnMessage: property.displayName + " cannot be empty",
          alertbox: true,
        });
      }
      if (
        property.propertyName == "no_of_sectors" &&
        property.value == "" &&
        property.isRequired &&
        this.reduxStore["assetTypeId"] == 1 &&
        this.state.vendor_type != "Omni"
      ) {
        check = true;
        this.setState({
          warnMessage: "Please enter " + property.displayName,
          alertbox: true,
        });
      }
    });

    if (!check && this.props.assetAction === "edit" && this.state.serial_id === "") {
      check = true;
    }
    is_submit_form = !check;

    if (is_submit_form && this.reduxStore["assetTypeId"] === 1 && this.state.vendor_type === "Directional") {
      this.validateSectors();
    } else if (
      is_submit_form &&
      (this.reduxStore["assetTypeId"] === 2 || this.reduxStore["assetTypeId"] === 6 || this.state.vendor_type === "Omni")
    ) {
      secval = true;
    }
  }

  uploadImages = (trackingId: any, imageData: any) => {
    try {
      let filerequestBody = {};
      let fileExt = imageData.name.split(/\.(?=[^.]+$)/)[1];
      let fileName = trackingId + "_" + "site." + fileExt;
      if (imageData.uploadType === undefined) {
        let picReader = new FileReader();
        picReader.onload = async (e) => {
          filerequestBody = {
            fileName: fileName,
            fileExtension: fileExt,
            fileValue: e.target?.result,
            type: "asset-image",
          };
          this.uploadImageAndAddAsset(filerequestBody);
        };
        picReader.readAsDataURL(imageData);
      } else {
        filerequestBody = {
          fileName: fileName,
          fileExtension: imageData.format,
          fileValue: imageData.dataUrl,
          type: "asset-image",
        };
        this.uploadImageAndAddAsset(filerequestBody);
      }
    } catch (error) {
      this.setState({
        is_loader: false,
        warnMessage: error,
        alertbox: true,
      });
    }
  };

  isBodyValid = (requestBody: any): boolean => {
    const alphanumeric = /[^a-zA-Z0-9]/;
    const alphanum_ = /[^a-zA-Z0-9_]/;
    const descreg = /[^a-zA-Z0-9_\-\.\s]/;
    const number = /[^0-9\.]/;
    const latlong = /[^0-9\.\-]/;

    // Tracking ID
    let trackingID = requestBody.properties.filter((property: any) => property.propertyName === "access_point_id")[0].value;
    if (alphanumeric.test(trackingID)) {
      this.setState({
        warnMessage: "Tracking ID can only contain alphanumeric characters",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    // Name
    let name = requestBody.properties.filter((property: any) => property.propertyName === "ap_name")[0].value;
    if (alphanum_.test(name)) {
      this.setState({
        warnMessage: "Name can only contain alphanumeric characters and underscore",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    // siteID
    let siteID = requestBody.properties.filter((property: any) => property.propertyName === "ap_site_id")[0].value;
    if (alphanumeric.test(siteID)) {
      this.setState({
        warnMessage: "Site ID can only contain alphanumeric characters",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    // Description
    let description = requestBody.properties.filter((property: any) => property.propertyName === "description")[0].value;
    if (descreg.test(description)) {
      this.setState({
        warnMessage: "Description can only contain alphanumeric characters, underscore, hyphen, period and space",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    // Height
    let height = requestBody.properties.filter((property: any) => property.propertyName === "radiation_center")[0].value;
    if (number.test(height)) {
      this.setState({
        warnMessage: "Height can only contain numbers",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    // serial number
    let serial = requestBody.properties.filter((property: any) => property.propertyName === "serial_id")[0].value;
    if (alphanum_.test(serial)) {
      this.setState({
        warnMessage: "Serial ID can only contain alphanumeric characters and underscore",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    // Coordinates
    let lat = requestBody.properties.filter((property: any) => property.propertyName === "latitude")[0].value;
    let long = requestBody.properties.filter((property: any) => property.propertyName === "longitude")[0].value;
    if (latlong.test(lat) || latlong.test(long)) {
      this.setState({
        warnMessage: "Latitude and Longitude can only contain numeric values",
        alertbox: true,
        is_loader: false,
      });
      return false;
    }
    return true;
  };

  createAssetAPI = (assetTypeId: any, requestBody: any) => {
    if (!this.isBodyValid(requestBody)) return;
    requestAPICall("create_asset", { asset_type_id: assetTypeId }, requestBody).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        this.removeAzimuthFromRedux();
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        if (isBrowser) {
          if (this.reduxStore["tabType"] == "outdoor") {
            this.props.setSector(-1);
            this.props.markAssetsOnMap(data.assetId);
          } else {
            this.props.markAssetsOnMap(data.assetId, this.reduxStore["floorId"]);
          }
          this.goBack("Browser");
        } else {
          if (this.reduxStore["tabType"] == "outdoor") {
            this.props.setSector(-1);
            this.props.markAssetsOnMap(data.assetId);
            this.props.changeMapCenter(this.reduxStore["coordinates"]["latitude"], this.reduxStore["coordinates"]["longitude"]);
          } else {
            this.props.markAssetsOnMap(data.assetId, this.reduxStore["floorId"]);
          }
          this.props.hideOverlay();
        }
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };

  submit_parameters() {
    this.validationRequest();

    let new_request_list: any = [];
    new_request_list = this.state.assetProperties?.map((property: any) => {
      if (property.propertyName === "radiation_center") {
        if (property.value === "") {
          property.value = 0;
        }
      } else if (property.propertyName === "vendor_type") {
        property.value = this.state.vendor_type;
      } else if (property.propertyName === "ap_mounting_type") {
        property.value = this.state.ap_mounting_type;
      }
      return property;
    });

    let asset: any = this.state.assetProperties.filter((property: any) => property.propertyName === "access_point_id");
    let asset_serial: any = this.state.assetProperties.filter((property: any) => property.propertyName === "serial_id");

    this.setState({
      asset_ap_id: asset[0]?.value,
      asset_serial_id: asset_serial[0]?.value,
    });

    if (secval) {
      this.setState({
        is_loader: true,
      });
      let assetTypeId;
      if (this.state.ap_mounting_type === "SMB") {
        assetTypeId = 6;
      } else {
        assetTypeId = 1;
      }
      // correct the sector list here for each mounting type
      if (this.state.ap_mounting_type === "SMB") {
        sector_list_ids = [];
      } else if (this.state.selectedSectorCount < sector_list_ids.length) {
        sector_list_ids.pop();
      }

      let requestBody = {
        assetName: this.state.ap_name,
        asset_id_value: asset[0]?.value,
        serialId: asset_serial[0]?.value,
        assetTypeId: assetTypeId,
        properties: new_request_list,
        sectorIdList: sector_list_ids,
        building_id: this.reduxStore["buildingId"],
        floor_id: this.reduxStore["floorId"],
      };
      this.createAssetAPI(assetTypeId, requestBody);
    }
    secval = false;
  }

  uploadImageAndAddAsset = (imageData: any) => {
    requestAPICall("upload_file", {}, imageData)
      .then((data) => {
        this.setState({ is_loader: false });
        if (data["responseCode"] == 200) {
          this.state.assetProperties?.map((property: any) => {
            if (property.propertyName == "image") {
              property.value = data.file_id;
            }
          });
          this.setState({
            warnMessage: "Image Uploaded Successfully",
            alertbox: true,
          });
        } else {
          this.setState({
            warnMessage: "Failed to upload image. Please try again.",
            alertbox: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          is_loader: false,
          warnMessage: error,
          alertbox: true,
        });
      });
  };

  azimuthClicked() {
    this.reduxStore["currentAssetProperties"] = this.state.assetProperties;
    this.reduxStore["currentSectorList"] = sector_list_ids;
    this.props.azimuthClickedWeb(this.reduxStore["coordinates"]["latitude"], this.reduxStore["coordinates"]["longitude"]);
  }

  azimuthClickedMob() {
    this.reduxStore["currentAssetProperties"] = this.state.assetProperties;
    this.reduxStore["currentSectorList"] = sector_list_ids;
    this.props.azimuthClickedGoBack(this.reduxStore["coordinates"]["latitude"], this.reduxStore["coordinates"]["longitude"]);
  }

  saveChanges = () => {
    this.validationRequest();
    let mountingType = "";
    let assetTypeId = 0;
    this.state.assetProperties?.forEach((property: any) => {
      if (property.propertyName == "name") {
        this.setState({ ap_name: property.value });
      } else if (property.propertyName === "radiation_center") {
        if (property.value === "") {
          property.value = 0;
        }
      } else if (property.propertyName === "ap_mounting_type") {
        mountingType = property.value;
      }
    });

    if (mountingType === "SMB") {
      assetTypeId = 6;
      sector_list_ids = [];
    } else {
      assetTypeId = 1;
    }

    let new_request_list = this.state.assetProperties;
    let requestBody = {
      rma: this.state.rma,
      oldSerialId: this.state.oldSerialId,
      assetName: asset_name,
      serialId: this.state.serial_id,
      assetTypeId: assetTypeId,
      properties: new_request_list,
      building_id: this.reduxStore.buildid,
      sector_id: sector_list_ids,
      status: "Offline",
    };
    if (secval) {
      this.editAssetChanges(requestBody);
    }
    secval = false;
    this.removeAzimuthFromRedux();
  };

  editAssetChanges = (requestBody: any) => {
    if (!this.isBodyValid(requestBody)) return;
    this.setState({
      is_loader: true,
    });
    requestAPICall(
      "edit_asset_details",
      {
        asset_type_id: requestBody["assetTypeId"],
        asset_id: this.state.asset_id,
      },
      requestBody
    ).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          disable_button: false,
        });
        let latitude: any;
        let longitude: any;
        requestBody["properties"]?.forEach((element: any) => {
          if (element.propertyName === "latitude") {
            latitude = element.value;
          }
          if (element.propertyName === "longitude") {
            longitude = element.value;
          }
        });
        if (this.reduxStore["tabType"] == "outdoor") {
          this.props.changeMapCenter(latitude, longitude);
        }

        this.props.get_asset_details(this.state.asset_id);
        if (this.reduxStore["tabType"] == "indoor") {
          this.props.markAssetsOnMap(this.state.asset_id, this.reduxStore["floorId"]);
        } else {
          this.props.markAssetsOnMap(this.state.asset_id);
        }
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };

  getAzimuthValue = (index: any, count: any) => {
    if (this.state.assetAction === "edit") {
      sector_list_ids = this.props.sector_id_list;
      try {
        return [this.props.sector_id_list[index - 1]["sector_value"], this.props.sector_id_list[index - 1]["azimuth_value"]];
      } catch (error) {
        return ["", ""];
      }
    } else if (this.props.isAzimuthSet) {
      sector_list_ids = this.reduxStore["currentSectorList"];
      try {
        return [sector_list_ids[index - 1]["sector_value"], sector_list_ids[index - 1]["azimuth_value"]];
      } catch (error) {
        return ["", ""];
      }
    } else {
      if (sector_list_ids.length == 0) {
        for (let index = 0; index < count; index++) {
          let json: any = {};
          json["sector_id"] = index;
          json["sector_value"] = index + 1;
          json["azimuth_value"] = 0;
          sector_list_ids.push(json);
        }
      }
      try {
        return [sector_list_ids[index - 1]["sector_value"], sector_list_ids[index - 1]["azimuth_value"]];
      } catch (error) {
        return ["", ""];
      }
    }
  };

  load_sector(count: any) {
    const element_list = [];
    let val = "";
    let azval = "";
    for (let index = 1; index <= this.state.selectedSectorCount; index++) {
      [val, azval] = this.getAzimuthValue(index, count);

      let element = (
        <div className="col-md-12 col-12 m-t-7 p-l-10 p-r-0">
          <div className="col-md-6 col-6 d-inline-block form-label-group m-0 p-l-0">
            <input
              disabled={true}
              type="text"
              style={{ height: "38px" }}
              value={val}
              name={"Sector_" + index}
              className="serial"
              id={"Sector_" + index}
              placeholder={"Sector " + index + " ID #"}
              onChange={(e: any) => this.sector_id_list(e, index)}
            />
            <label className="pages" htmlFor={"Sector_" + index}>
              {"Sector " + index + " ID #"}
            </label>
          </div>
          <div className="col-md-6 col-6 d-inline-block form-label-group m-0 p-l-0">
            <input
              disabled={true}
              type="text"
              style={{ height: "38px" }}
              value={azval}
              name={"Azimuth_" + index}
              className="serial"
              id={"Azimuth_" + index}
              placeholder={"Azimuth " + index}
              onChange={(e: any) => this.azimuth_id_list(e, index)}
            />
            <label className="pages" htmlFor={"Azimuth_" + index}>
              {"Azimuth " + index}
            </label>
          </div>
        </div>
      );

      element_list.push(element);
    }
    return <div className="row">{element_list}</div>;
  }

  handleImage = (files: any) => {
    try {
      if (assetTrackingId == "") {
        this.setState({
          warnMessage: "Tracking ID cannot be empty",
          alertbox: true,
        });
      } else if (files.length != 0) {
        this.setState({
          is_loader: true,
        });
        this.uploadImages(assetTrackingId, files[0]);
      } else {
        this.state.assetProperties?.map((property: any) => {
          if (property.propertyName == "image") {
            property.value = "";
          }
        });
        this.setState({
          warnMessage: "Image Removed!",
          alertbox: true,
        });
      }
    } catch (error) {
      this.setState({
        is_loader: false,
        warnMessage: error,
        alertbox: true,
      });
    }
  };

  listPropertiesOnChange = (evt: any, mountType: any) => {
    requestAPICall("list_asset_properties", { asset_type_id: evt.target.value }, {}).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode !== 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        return;
      }
      if (data["vendor_type"] == "") {
        this.setState({
          warnMessage: `Vendor is not selected for ${mountType} mounting type. Please select an appropriate vendor for ${mountType} on the settings page.`,
          alertbox: true,
        });
      }
      this.setState({
        vendor_type: data["vendor_type"],
      });
      this.setState({ isShowSectorIcons: data["vendor_type"] === "Directional" });
    });
  };

  handleChange = (evt: any) => {
    this.setState({
      is_loader: true,
    });
    let mountType = evt.target.value;
    if (evt.target.name == "ap_mounting_type") {
      let count = 0;
      if (evt.target.value == "Aerial") count = 2;
      else if (evt.target.value == "ROE") count = 1;
      this.setState({ selectedSectorCount: count, ap_mounting_type: evt.target.value });
      this.setSelectedSector(count);

      this.listPropertiesOnChange(evt, mountType);
    }

    this.state.assetProperties?.map((property: any) => {
      if (property.propertyName === "vendor_type" && evt.target.name == "vendor_type") {
        property.value = evt.target.value;
      }
      if (property.propertyName === "ap_mounting_type" && evt.target.name == "ap_mounting_type") {
        property.value = evt.target.value;
      }
      return property;
    });
    this.setState({ [evt.target.name]: evt.target.value });
  };

  setDirectValuesOnChange(e: any) {
    if (e.target.name === "serial_id") {
      this.setState({
        serial_id: e.target.value,
      });
    } else if (e.target.name === "access_point_id") {
      assetTrackingId = e.target.value;
    } else if (e.target.name === "ap_name") {
      asset_name = e.target.value;
    } else if (e.target.name === "latitude") {
      this.state.assetProperties?.map((property: any) => {
        if (property.propertyName === "latitude") {
          property.value = e.target.value;
        }
        return property;
      });
    } else if (e.target.name === "longitude") {
      this.state.assetProperties?.map((property: any) => {
        if (property.propertyName === "longitude") {
          property.value = e.target.value;
        }
        return property;
      });
    }
  }

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.setDirectValuesOnChange(e);
    this.state.assetProperties?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      } else if (property.propertyName == "radiation_center" && e.target.name == "radiation_center") {
        if (e.target.value == "") {
          property.value = e.target.value;
        }
      } else if (property.propertyName == "ap_name" && e.target.name == "ap_name") {
        this.setState({
          ap_name: e.target.value,
        });
      } else if (property.propertyName == "serial" && e.target.name == "serial_id") {
        this.setState({
          serial_id: e.target.value,
        });
      }
      return property;
    });
    this.validate_request();
  }

  sector_id_list(e: any, index: any) {
    sector_list_ids[index - 1]["sector_value"] = e.target.value;
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.state.assetProperties?.forEach((property: any) => {
      if (property.propertyName == "sector_id") {
        property.value = sector_list_ids;
      }
    });
    this.validate_request();
  }

  azimuth_id_list(e: any, index: any) {
    sector_list_ids[index - 1]["azimuth_value"] = e.target.value;
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.state.assetProperties?.forEach((property: any) => {
      if (property.propertyName == "sector_id") {
        property.value = sector_list_ids;
      }
    });
  }

  setCurrentPosition = () => {
    this.props.setCurrentPosition();
  };
  render() {
    // DONE: do for all image types
    // let files = this.state.files?.map((file: any) => (
    //     <span className="ellipsistooltip90" key={file.name}>
    //         {file.name}
    //     </span>
    // ));
    // let classDName = files.length ? 'dropzone-uploaded' : 'dropzone'
    // let classImageUpload = files.length ? '-uploaded' : ''
    //console.log("data for populating fillers ", this.state.assetProperties);
    return (
      <div>
        {isBrowser ? (
          <div className="webonly">
            <div className="row">
              <div className="col-md-9">
                <div className="card apcard">
                  <div className="card-body" style={{ padding: "0px" }}>
                    {/* apcardbody */}
                    <div className="row">
                      <div className="col-md-3">
                        <img
                          className="backicon"
                          src={BackIcon}
                          onClick={() => {
                            this.goBack("browser");
                            this.removeAzimuthFromRedux();
                          }}
                          onKeyDown={(e) => {
                            if (e.key == "Enter") {
                              this.goBack("browser");
                              this.removeAzimuthFromRedux();
                            }
                          }}
                        />
                        <button type="button" className="backbutton"></button>
                        {/* <button type='button' className='backbutton material-icons' style={{ color: '#0059B2' }} onClick={this.props.back}>keyboard_backspace </button> */}
                      </div>
                      <div className="col-md-8" style={{ padding: "13px 0", marginLeft: "-5px" }}>
                        <span className="apheader">{this.state.assetAction === "add" ? "ADDING CBSD" : "EDIT CBSD"}</span>
                        {/* <span className='apnote float-right' style={{ fontSize: '12px', margin: '5px' }} >1/3</span> */}
                      </div>
                    </div>
                    <div
                      className="col-md-12"
                      style={{
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: "calc(100vh - 205px)",
                      }}
                    >
                      <div className="col-md-12 p-0">
                        <div className="col-md-12 apborder p-0">
                          <span className="apnote">Click where you'd like to drop the CBSD</span>
                        </div>
                      </div>

                      {this.state.assetProperties?.map((property: any, key: any) => {
                        if (property.units !== "") {
                          unit = "(" + property.units + ")";
                        } else {
                          unit = "";
                        }
                        if (property.isShowable === true || this.props.loc === true) {
                          if (property.dataType === "string" || property.dataType == "float" || property.dataType === "int") {
                            if (property.isRequired && property.propertyName != "latitude" && property.propertyName != "longitude") {
                              if (property.propertyName === "access_point_id") {
                                const element = (
                                  <div className="col-md-12 p-0 m-t-7">
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        maxLength={45}
                                        type="text"
                                        id={property.propertyName}
                                        name={property.propertyName}
                                        disabled={this.state.assetAction == "add" ? this.state.field_disable : true}
                                        className="accesspointname"
                                        value={property.value}
                                        placeholder={property.displayName + "*"}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName + "*"}
                                      </label>
                                    </div>
                                  </div>
                                );
                                return element;
                              } else if (property.propertyName === "no_of_sectors" && this.state.assetId == 1 && this.state.isShowSectorIcons) {
                                return (
                                  <div className="row m-t-7 p-0 sectorIcon" key={property.propertyName}>
                                    {this.state.sectorsCount?.map((index: any) => (
                                      <div className="col-md-2" key={index}>
                                        {/* onClick={() => this.setSelectedSector(index)} (taken out from below i tag since we dont want users to select sectors)*/}
                                        <i
                                          className={`fa fa-map-marker mapmarker m-r-20 ${
                                            index <= this.state.selectedSectorCount ? "mapmarker-selected" : ""
                                          }`}
                                        ></i>
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else if (property.propertyName !== "no_of_sectors" && property.propertyName !== "serial_id") {
                                const element = (
                                  <div className="col-md-12 p-0 m-t-7">
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        type="text"
                                        id={property.propertyName}
                                        name={property.propertyName}
                                        maxLength={45}
                                        disabled={this.state.field_disable}
                                        className="accesspointname"
                                        value={property.value}
                                        placeholder={property.displayName + "*"}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName + "*"}
                                      </label>
                                    </div>
                                  </div>
                                );
                                return element;
                              }
                            } else if (property.isRequired && property.propertyName == "latitude") {
                              if (this.reduxStore["assetAction"] === "edit") {
                                const element = (
                                  <div className="col-md-12 p-0 m-t-7">
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        type="number"
                                        id={property.propertyName}
                                        name={property.propertyName}
                                        className="accesspointname"
                                        value={
                                          this.reduxStore["assetAction"] === "edit" ? property.value : this.reduxStore["coordinates"]["latitude"]
                                        }
                                        disabled={true}
                                        placeholder={property.displayName + "*"}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName + "*"}
                                      </label>
                                    </div>
                                  </div>
                                );
                                return element;
                              } else {
                                const element = (
                                  <div className="col-md-12 p-0 m-t-7">
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        type="number"
                                        id={property.propertyName}
                                        name={property.propertyName}
                                        disabled={true}
                                        className="accesspointname"
                                        value={property.value === 0 ? this.reduxStore["coordinates"]["latitude"] : property.value}
                                        placeholder={property.displayName + "*"}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName + "*"}
                                      </label>
                                    </div>
                                  </div>
                                );
                                return element;
                              }
                            } else if (property.isRequired && property.propertyName == "longitude") {
                              if (this.reduxStore["assetAction"] === "edit") {
                                const element = (
                                  <div className="col-md-12 p-0 m-t-7">
                                    <div
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      className="form-label-group m-0"
                                    >
                                      <input
                                        type="number"
                                        id={property.propertyName}
                                        name={property.propertyName}
                                        className="accesspointname"
                                        value={property.value}
                                        disabled={true}
                                        placeholder={property.displayName + "*"}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName + "*"}
                                      </label>
                                    </div>
                                  </div>
                                );
                                return element;
                              } else {
                                const element = (
                                  <div className="col-md-12 p-0 m-t-7">
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        type="number"
                                        id={property.propertyName}
                                        name={property.propertyName}
                                        disabled={true}
                                        className="accesspointname"
                                        value={property.value === 0 ? this.reduxStore["coordinates"]["longitude"] : property.value}
                                        placeholder={property.displayName + "*"}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName + "*"}
                                      </label>
                                    </div>
                                  </div>
                                );
                                return element;
                              }
                            } else if (property.propertyName === "radiation_center") {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <input
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      type="number"
                                      id={property.propertyName}
                                      name={property.propertyName}
                                      disabled={this.state.field_disable}
                                      className="accesspointname"
                                      value={property.value}
                                      placeholder={property.displayName + "*"}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" style={{ padding: "4px 0px 0px 8px" }} htmlFor={property.propertyName}>
                                      {property.displayName} {unit}
                                    </label>
                                  </div>
                                </div>
                              );
                              return element;
                            }

                            // Included on 10-7-20 due to serial id can not be editable after reg to SAS
                            else if (property.propertyName === "serial_id" && this.reduxStore["assetAction"] === "edit") {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <input
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      maxLength={45}
                                      type="text"
                                      id={property.propertyName}
                                      name={property.propertyName}
                                      disabled={this.state.rma ? false : !this.props.editSerial}
                                      className="accesspointname"
                                      value={property.value}
                                      placeholder={property.displayName + "*"}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" htmlFor={property.propertyName}>
                                      {property.displayName + "*"}
                                    </label>
                                  </div>
                                </div>
                              );
                              return element;
                            } else {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <input
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      maxLength={45}
                                      value={property.value}
                                      type="text"
                                      id={property.propertyName}
                                      name={property.propertyName}
                                      className="accesspointname"
                                      placeholder={property.displayName}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" htmlFor={property.propertyName}>
                                      {property.displayName}
                                    </label>
                                  </div>
                                </div>
                              );
                              return element;
                            }
                            // DONE: remove from here and put it on the bottom of the screen
                          } else if (property.propertyName === "image") {
                            const element = (
                              <div
                                className="col-md-12 m-t-7 p-0 justify-content-center"
                                style={{
                                  padding: "0px 35px",
                                  fontSize: "14px",
                                }}
                              >
                                {property.value != "" ? (
                                  <i
                                    className="material-icons imgclose-file dropzclose"
                                    style={{
                                      marginBottom: "10px",
                                      marginTop: "20px",
                                    }}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      this.handleImage([]);
                                    }}
                                    onKeyDown={(e: any) => {
                                      e.preventDefault();
                                      e.key == "Enter" && this.handleImage([]);
                                    }}
                                  >
                                    clear
                                  </i>
                                ) : (
                                  ""
                                )}
                                <Dropzone multiple={false} onDrop={(file) => this.handleImage(file)} disabled={this.state.field_disable}>
                                  {({ getRootProps, getInputProps }) => (
                                    <section className="dropzone-set">
                                      <div
                                        {...getRootProps({
                                          className: property.value != "" ? "dropzone-uploaded" : "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                        {property.value != "" ? (
                                          <span
                                            style={{
                                              color: "#00D56E",
                                              display: "flex",
                                            }}
                                          >
                                            <span className="ellipsistooltip90"> Site Image Uploaded </span>
                                          </span>
                                        ) : (
                                          <span style={{ fontSize: "14px" }}>+ Add Site Image</span>
                                        )}
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            );
                            return element;
                          } else if (property.dataType === "textArea") {
                            if (this.state.assetAction === "edit") {
                              return (
                                <div className="col-md-12 p-0 m-t-10" key={property.propertyName}>
                                  <IonItem lines="none" color="none" className="description">
                                    <IonLabel position="floating">{property.displayName}</IonLabel>
                                    <IonTextarea
                                      name={property.propertyName}
                                      id={property.propertyName}
                                      disabled={this.state.field_disable}
                                      rows={3}
                                      value={property.value}
                                      onInput={this.onChange}
                                    />
                                  </IonItem>
                                </div>
                              );
                            } else {
                              const element = (
                                <div className="col-md-12 p-0 m-t-10">
                                  <IonItem lines="none" color="none" className="description">
                                    <IonLabel position="floating">Description</IonLabel>
                                    <IonTextarea
                                      style={{ marginTop: "0px" }}
                                      name={property.propertyName}
                                      maxlength={45}
                                      id={property.propertyName}
                                      disabled={this.state.field_disable}
                                      rows={4}
                                      defaultValue={property.value}
                                      onIonInput={this.onChange}
                                    />
                                  </IonItem>
                                </div>
                              );

                              return element;
                            }
                          } else if (property.dataType === "list") {
                            if (property.isRequired && property.propertyName == "vendor_type" && this.state.assetId == 1) {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      ref={(ref) => {
                                        this.apFocus[key] = ref;
                                        return true;
                                      }}
                                      id={property.propertyName}
                                      name={property.propertyName}
                                      disabled={true}
                                      className="accesspointname"
                                      value={this.state.vendor_type}
                                      placeholder={property.displayName}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" style={{ padding: "4px 0px 0px 8px" }} htmlFor={property.propertyName}>
                                      {property.displayName}
                                    </label>
                                  </div>
                                </div>
                              );
                              return element;
                            }
                            if (property.propertyName == "ap_mounting_type") {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <select
                                      className="form-control settingdropdown"
                                      id={property.propertyName}
                                      disabled={this.state.assetAction == "add" ? this.state.field_disable : true}
                                      style={{
                                        border: "1px solid #CED7DF",
                                        borderTopRightRadius: "0px",
                                        borderTopLeftRadius: "0px",
                                      }}
                                      name={property.propertyName}
                                      value={property.value}
                                      onChange={this.handleChange}
                                    >
                                      <option hidden>{property.displayName + " *"}</option>
                                      {property.validValues?.map((value: any) =>
                                        this.state.ap_mounting_type == value ? (
                                          <option value={value} selected key={value}>
                                            {value}
                                          </option>
                                        ) : (
                                          <option value={value} key={value}>
                                            {value}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                              );
                              return element;
                            }
                          }
                        }
                      })}
                      {this.load_sector(this.state.selectedSectorCount)}
                      {this.state.vendor_type == "Directional" ? (
                        <div className="m-t-5">
                          <button
                            className="btn"
                            style={{ width: "268px", bottom: "50px" }}
                            disabled={this.state.assetAction == "add" ? this.state.disable_button : !this.state.disable_button}
                            onClick={this.azimuthClicked}
                          >
                            {"Set Azimuth Value"}
                          </button>
                        </div>
                      ) : null}

                      <div className="savebtn">
                        <div className="m-t-5">
                          {this.state.assetAction == "add" ? (
                            <button className="btn" style={{ width: "268px" }} onClick={this.submit_parameters} disabled={this.state.disable_button}>
                              {"Save changes"}
                            </button>
                          ) : (
                            <button className="btn" style={{ width: "268px" }} disabled={!this.state.disable_button} onClick={this.saveChanges}>
                              {"Update changes"}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-none col-md-12 justify-content-flex-end">
                  <div className="heaticon text-center">
                    <img className="heat" src={Heat} />
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div className="geoicon text-center">
                    <img
                      style={{ padding: "5px" }}
                      src={geo}
                      onClick={this.setCurrentPosition}
                      onKeyDown={(e) => e.key == "Enter" && this.setCurrentPosition()}
                    />
                  </div>
                </div>
                <div className="col-md-12 float-right">
                  <div
                    className="geoicon text-center dashoption"
                    onClick={this.props.changeMapToSatellite}
                    onKeyDown={(e) => e.key == "Enter" && this.props.changeMapToSatellite()}
                    title={"Map View Toggle"}
                  >
                    <i className="fas fa-map"> </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobonly">
            {/* <IonPage>
                            <IonContent> */}
            <div
              className="param-info-mob"
              style={{
                height: "100vh",
                paddingTop: "env(safe-area-inset-top)",
              }}
            >
              <div className="col-12 text-center searchborder" style={{ padding: "10px" }}>
                <img
                  className="backicon-mob"
                  style={{ width: "43px", paddingTop: "6px" }}
                  onClick={() => {
                    this.removeAzimuthFromRedux();
                    this.props.hideOverlay();
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      this.removeAzimuthFromRedux();
                      this.props.hideOverlay();
                    }
                  }}
                  src={BackIcon}
                />
                <span className="apheader">ADD CBSD</span>
              </div>
              <div className="container-fluid apdash">
                <div className="card apeditcard">
                  <div className="card-body card-body-padded">
                    <div className="row">
                      <div className="col-12">
                        <div className="">
                          <div className="col-12 p-0">
                            <span className="apheader">CBSD</span>
                          </div>
                        </div>
                        {this.state.assetProperties?.map((property: any, key: any) => {
                          if (property.units !== "") {
                            unit = "(" + property.units + ")";
                          } else {
                            unit = "";
                          }
                          if (property.isShowable === true || this.props.loc === true) {
                            if ((property.dataType === "string" && property.propertyName !== "serial_id") || property.dataType === "int") {
                              if (property.isRequired) {
                                if (property.propertyName === "access_point_id") {
                                  const element = (
                                    <div className="col-md-12 p-0 m-t-9">
                                      <div className="form-label-group m-0">
                                        <input
                                          ref={(ref) => {
                                            this.apFocus[key] = ref;
                                            return true;
                                          }}
                                          type="text"
                                          id={property.propertyName}
                                          maxLength={45}
                                          name={property.propertyName}
                                          className="accesspointname"
                                          value={property.value}
                                          placeholder={property.displayName + "*"}
                                          onChange={this.onChange.bind(this)}
                                        />
                                        <label className="pages" htmlFor={property.propertyName}>
                                          {property.displayName + "*"}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                  return element;
                                } else if (
                                  property.propertyName === "no_of_sectors" &&
                                  this.reduxStore["assetTypeId"] == 1 &&
                                  this.state.isShowSectorIcons
                                ) {
                                  return (
                                    <div key={property.propertyName}>
                                      <div className="row m-t-10">
                                        <div className="col-12">
                                          <span className="cardtext">Number of Sectors</span>
                                        </div>
                                      </div>
                                      <div className="row m-t-10">
                                        {this.state.sectorsCount?.map((index: any) => (
                                          <div className="col-2" key={index}>
                                            {/* onClick={() => this.setSelectedSector(index)} (taken out from below i tag since we dont want users to select sectors) */}
                                            <i
                                              className={`fa fa-map-marker mapmarker m-r-20 ${
                                                index <= this.state.selectedSectorCount ? "mapmarker-selected" : ""
                                              }`}
                                            ></i>{" "}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  );
                                } else if (property.propertyName !== "no_of_sectors") {
                                  return (
                                    <div className="row m-t-10" key={property.propertyName}>
                                      <div className="col-12">
                                        <div className="form-label-group m-0">
                                          <input
                                            type="text"
                                            ref={(ref) => {
                                              this.apFocus[key] = ref;
                                              return true;
                                            }}
                                            className="accesspointname font14"
                                            name={property.propertyName}
                                            id={property.propertyName}
                                            style={{ width: "100%" }}
                                            placeholder={property.displayName}
                                            onChange={this.onChange.bind(this)}
                                          />
                                          <label className="pages" htmlFor={property.propertyName}>
                                            {property.displayName + "*"}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (property.dataType === "int") {
                                return (
                                  <div className="row m-t-5" key={property.propertyName}>
                                    <div className="col-12">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="number"
                                          ref={(ref) => {
                                            this.apFocus[key] = ref;
                                            return true;
                                          }}
                                          className="accesspointname font14"
                                          maxLength={45}
                                          value={property.value}
                                          name={property.propertyName}
                                          id={property.propertyName}
                                          style={{ width: "100%" }}
                                          placeholder={property.displayName}
                                          onChange={this.onChange.bind(this)}
                                        />
                                        <label className="pages" htmlFor={property.propertyName}>
                                          {property.displayName}
                                          {unit}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="row m-t-10" key={property.propertyName}>
                                    <div className="col-12">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          ref={(ref) => {
                                            this.apFocus[key] = ref;
                                            return true;
                                          }}
                                          maxLength={45}
                                          className="accesspointname font14"
                                          value={property.value}
                                          name={property.propertyName}
                                          id={property.propertyName}
                                          style={{ width: "100%" }}
                                          placeholder={property.displayName}
                                          onChange={this.onChange.bind(this)}
                                        />
                                        <label className="pages" htmlFor={property.propertyName}>
                                          {property.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            } else if (property.dataType === "float" || property.dataType === "int") {
                              if (property.isRequired && property.propertyName === "latitude") {
                                if (this.reduxStore["assetAction"] === "edit") {
                                  return (
                                    <div className="row m-t-10" key={property.propertyName}>
                                      <div className="col-12">
                                        <div className="form-label-group m-0">
                                          <input
                                            disabled={true}
                                            type="number"
                                            ref={(ref) => {
                                              this.apFocus[key] = ref;
                                              return true;
                                            }}
                                            className="accesspointname font14"
                                            name={property.propertyName}
                                            id={property.propertyName}
                                            style={{ width: "100%" }}
                                            placeholder={property.displayName}
                                            value={this.state.assetAction === "edit" ? property.value : this.reduxStore["coordinates"]["latitude"]}
                                            onChange={this.onChange.bind(this)}
                                          />
                                          <label className="pages" htmlFor={property.propertyName}>
                                            {property.displayName + "*"}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="row m-t-10" key={property.propertyName}>
                                      <div className="col-12">
                                        <div className="form-label-group m-0">
                                          <input
                                            disabled={true}
                                            type="number"
                                            ref={(ref) => {
                                              this.apFocus[key] = ref;
                                              return true;
                                            }}
                                            maxLength={45}
                                            className="accesspointname font14"
                                            name={property.propertyName}
                                            id={property.propertyName}
                                            style={{ width: "100%" }}
                                            placeholder={property.displayName}
                                            value={property.value === 0 ? this.reduxStore["coordinates"]["latitude"] : property.value}
                                            onChange={this.onChange.bind(this)}
                                          />
                                          <label className="pages" htmlFor={property.propertyName}>
                                            {property.displayName + "*"}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (property.isRequired && property.propertyName === "longitude") {
                                if (this.reduxStore["assetAction"] === "edit") {
                                  return (
                                    <div className="row m-t-10" key={property.propertyName}>
                                      <div className="col-12">
                                        <div className="form-label-group m-0">
                                          <input
                                            disabled={true}
                                            type="number"
                                            ref={(ref) => {
                                              this.apFocus[key] = ref;
                                              return true;
                                            }}
                                            className="accesspointname font14"
                                            name={property.propertyName}
                                            id={property.propertyName}
                                            style={{ width: "100%" }}
                                            placeholder={property.displayName}
                                            value={this.state.assetAction === "edit" ? property.value : this.reduxStore["coordinates"]["longitude"]}
                                            onChange={this.onChange.bind(this)}
                                          />
                                          <label className="pages" htmlFor={property.propertyName}>
                                            {property.displayName + "*"}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="row m-t-10" key={property.propertyName}>
                                      <div className="col-12">
                                        <div className="form-label-group m-0">
                                          <input
                                            disabled={true}
                                            type="number"
                                            ref={(ref) => {
                                              this.apFocus[key] = ref;
                                              return true;
                                            }}
                                            className="accesspointname font14"
                                            name={property.propertyName}
                                            id={property.propertyName}
                                            style={{ width: "100%" }}
                                            placeholder={property.displayName}
                                            value={property.value === 0 ? this.reduxStore["coordinates"]["longitude"] : property.value}
                                            onChange={this.onChange.bind(this)}
                                          />
                                          <label className="pages" htmlFor={property.propertyName}>
                                            {property.displayName}
                                            {unit}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              } else {
                                return (
                                  <div className="row m-t-5" key={property.propertyName}>
                                    <div className="col-12">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="number"
                                          ref={(ref) => {
                                            this.apFocus[key] = ref;
                                            return true;
                                          }}
                                          maxLength={45}
                                          className="accesspointname font14"
                                          name={property.propertyName}
                                          id={property.propertyName}
                                          style={{ width: "100%" }}
                                          placeholder={property.displayName}
                                          onChange={this.onChange.bind(this)}
                                        />
                                        <label className="pages" htmlFor={property.propertyName}>
                                          {property.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            } else if (property.dataType === "textArea") {
                              return (
                                <div className="row m-t-10" key={property.propertyName}>
                                  <div className="col-12">
                                    <IonItem lines="none" color="none" className="description">
                                      <IonLabel position="floating">{property.displayName}</IonLabel>
                                      <IonTextarea
                                        style={{
                                          width: "100%",
                                          margin: "0px",
                                        }}
                                        rows={3}
                                        maxlength={45}
                                        name={property.propertyName}
                                        placeholder={property.value}
                                        onIonInput={this.onChange.bind(this)}
                                      />
                                    </IonItem>
                                  </div>
                                </div>
                              );
                            } else if (property.propertyName == "image") {
                              return (
                                <div className="row m-t-10" key={property.propertyName}>
                                  <div className="col-10 p-r-0" style={{ fontSize: "14px" }}>
                                    <Dropzone multiple={false} onDrop={(file) => this.handleImage(file)} disabled={false}>
                                      {({ getRootProps, getInputProps }) => (
                                        <section className="dropzone-set">
                                          <div
                                            {...getRootProps({
                                              className: (property.value != "" ? "dropzone-uploaded" : "dropzone") + "-mob",
                                            })}
                                          >
                                            <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                            {property.value != "" ? (
                                              <span
                                                style={{
                                                  color: "#00D56E",
                                                  display: "flex",
                                                }}
                                              >
                                                <span className="ellipsistooltip90"> Site Image Uploaded </span>
                                              </span>
                                            ) : (
                                              <div>
                                                <span style={{ fontSize: "14px" }}>+ Add Site Image</span>
                                              </div>
                                            )}
                                          </div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                  <div className="col-2 p-l-0">
                                    <div className={"v-h-center image-field-action" + (property.value != "" ? "-uploaded" : "")}>
                                      {property.value != "" ? (
                                        <i
                                          className="material-icons imgclose-file"
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            this.handleImage([]);
                                          }}
                                          onKeyDown={(e: any) => {
                                            e.preventDefault();
                                            e.key == "Enter" && this.handleImage([]);
                                          }}
                                        >
                                          clear
                                        </i>
                                      ) : (
                                        <img
                                          src={camicon}
                                          style={{ width: "21px" }}
                                          onClick={(e) => this.takePicture("site")}
                                          onKeyDown={(e) => e.key == "Enter" && this.takePicture("site")}
                                        ></img>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (property.propertyName === "serial_id") {
                              return (
                                <div className="row m-t-10" key={property.propertyName}>
                                  <div className="col-10 p-r-0">
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        maxLength={45}
                                        className="accesspointname font14"
                                        value={this.state.serial_id}
                                        name={property.propertyName}
                                        id={property.propertyName}
                                        style={{
                                          width: "100%",
                                          borderRadius: "6px 0px 0px 6px",
                                          borderRight: "none",
                                        }}
                                        placeholder={property.displayName}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName}{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-2 p-l-0">
                                    <div
                                      className="v-h-center"
                                      style={{
                                        height: "100%",
                                        borderRadius: "0px 6px 6px 0px",
                                        border: "0.5px solid #CED7DF",
                                        borderLeft: "none",
                                      }}
                                      onClick={() => {
                                        this.openScanner();
                                      }}
                                      onKeyDown={(e) => e.key == "Enter" && this.openScanner()}
                                    >
                                      <img src={qricon} style={{ width: "25px" }} />
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (property.propertyName === "vendor_type" && this.reduxStore["assetTypeId"] == 1) {
                              return (
                                <div className="row m-t-5" key={property.propertyName}>
                                  <div className="col-12">
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        ref={(ref) => {
                                          this.apFocus[key] = ref;
                                          return true;
                                        }}
                                        className="accesspointname font14"
                                        name={property.propertyName}
                                        id={property.propertyName}
                                        style={{ width: "100%" }}
                                        value={this.state.vendor_type}
                                        disabled={true}
                                        placeholder={property.displayName}
                                        onChange={this.onChange.bind(this)}
                                      />
                                      <label className="pages" htmlFor={property.propertyName}>
                                        {property.displayName}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (property.propertyName == "ap_mounting_type") {
                              const element = (
                                <div className="col-md-12 p-0 m-t-7">
                                  <div className="form-label-group m-0">
                                    <select
                                      className="form-control settingdropdown"
                                      id={property.propertyName}
                                      style={{
                                        border: "1px solid #CED7DF",
                                        borderTopRightRadius: "0px",
                                        borderTopLeftRadius: "0px",
                                      }}
                                      name={property.propertyName}
                                      value={property.value}
                                      onChange={this.handleChange}
                                    >
                                      <option hidden>{property.displayName + " *"}</option>
                                      {property.validValues?.map((value: any) =>
                                        this.state.ap_mounting_type == value ? (
                                          <option value={value} selected key={value}>
                                            {value}
                                          </option>
                                        ) : (
                                          <option value={value} key={value}>
                                            {value}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                              );
                              return element;
                            }
                          }
                        })}
                        {this.load_sector(this.state.selectedSectorCount)}
                        {this.state.vendor_type == "Directional" ? (
                          <div className="m-t-10">
                            <button className="lightbtnmob p-t-5" onClick={this.azimuthClickedMob}>
                              {"Set Azimuth Value"}
                            </button>
                          </div>
                        ) : null}

                        <div>
                          <div className="m-t-10">
                            <button className="lightbtnmob p-t-5" onClick={this.submit_parameters}>
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />

        <IonLoading isOpen={this.state.is_loader} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} />
      </div>
    );
  }
}

export default withIonLifeCycle(AddAP);
