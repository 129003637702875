import React from 'react'
import "./ap-parameters.css";
import { IonPage, IonContent, withIonLifeCycle } from '@ionic/react';
import BackIcon from '../assets/img/go-back-icon-img.png'
import Tilt from "../assets/img/tilt.png"

class Antennatilt extends React.Component {
    state: any = {};
    props: any = {};
    constructor(props: any) {

        super(props);
        this.state = {

            beamwidth: "",
            tilt: "",
            antheight: "",
            recheight: "",
            recdist: "",
            recdistUnit: "2",
            orc: "",
            irc: "",
            crc: ""

        };
        this.dropdownChange = this.dropdownChange.bind(this)
        this.calculate = this.calculate.bind(this)
        this.tiltCalculate = this.tiltCalculate.bind(this)
        this.reset = this.reset.bind(this)

    }
    dropdownChange = (e: any) => {
        this.setState({
            recdistUnit: e.target.value
        })

    }
    inputChange = (e: any) => {
        console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    calculate() {
        let feet: any = ""
        if (this.state.recdistUnit == "1") {
            feet = parseInt(this.state.recdist) * 3281.5
        }

        else {
            feet = parseInt(this.state.recdist) * 5280

        }

        console.log(feet)
        this.tiltCalculate(feet)

    }
    centerRadiusCalculate() {
        let crc: any = ""
        crc = (this.state.antheight - this.state.recheight) / (Math.tan((this.state.tilt * (Math.PI / 180))))
        crc = crc.toFixed(2)
        crc = Math.sign(crc) == -1 ? "NA" : crc
        this.setState({
            crc: crc
        })
    }
    innerRadiusCalculate() {
        let irc: any = ""
        irc = (this.state.antheight - this.state.recheight) / (Math.tan((this.state.tilt * (Math.PI / 180)) + (this.state.beamwidth / 2 * (Math.PI / 180))))
        irc = irc.toFixed(2)
        irc = Math.sign(irc) == -1 ? "NA" : irc
        this.setState({
            irc: irc
        })
    }
    outerRadiusCalculate() {
        let orc: any = ""
        orc = (this.state.antheight - this.state.recheight) / (Math.tan((this.state.tilt * (Math.PI / 180)) - (this.state.beamwidth / 2 * (Math.PI / 180))))
        orc = orc.toFixed(2)
        orc = Math.sign(orc) == -1 ? "NA" : orc
        this.setState({
            orc: orc
        })
    }
    tiltCalculate = (feet: any) => {

        let tilt: any = ""
        let degrees = Math.atan((this.state.antheight - this.state.recheight) / (feet))
        tilt = degrees * 180 / Math.PI
        tilt = tilt.toFixed(2)
        tilt = Math.sign(tilt) == -1 ? "NA" : tilt
        this.setState({
            tilt: tilt
        }, () => {
            if (this.state.tilt !== "NA") {
                this.centerRadiusCalculate()
                this.innerRadiusCalculate()
                this.outerRadiusCalculate()
            } else {
                this.setState({
                    irc: "NA",
                    orc: "NA",
                    crc: "NA",
                })
            }

        })
    }

    reset() {
        this.setState({
            beamwidth: "",
            tilt: "",
            antheight: "",
            recheight: "",
            recdist: "",
            orc: "",
            irc: "",
            crc: ""
        })
    }


    render() {
        return (
            <div className="mobonly">
                <IonPage>
                    <IonContent>
                        <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                            <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={this.props.hideTiltOverlay}  onKeyDown={(e)=> e.key === "Enter" && this.props.hideTiltOverlay()}  src={BackIcon} />
                            <span className="apheader">Antenna and Tilt Calculator</span>
                        </div>
                        <div className='container-fluid apdash calcfont' style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 47px)' }}>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div className="col-12 p-0 m-t-10">
                                        <img src={Tilt} />
                                    </div>
                                    <h6 className="font14 m-t-20">Base Antenna Height</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="antheight" name="antheight" value={this.state.antheight} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="antheight" name="antheight" value="ft" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Receiver Height</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="recheight" name="recheight" value={this.state.recheight} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="recheight" name="recheight" value="ft" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Receiver Distance</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" value={this.state.recdist} onChange={(e: any) => { this.inputChange(e) }} id="recdist" name="recdist" className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.dropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="2">Miles</option>
                                                <option value="1">Km</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Vertical Beamwdith</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="beamwidth" value={this.state.beamwidth} onChange={(e: any) => { this.inputChange(e) }} name="beamwidth" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="beamwidth" name="beamwidth" value="deg" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <div className="row m-t-20 text-center">
                                        <div className="col-6 p-r-5">
                                            <button className="btn modalbtn" onClick={this.calculate} >Calculate</button>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <button className="lightbtnmob modalbtn" onClick={this.reset}>Reset</button>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Downtilt</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="text" id="Downtilt" value={this.state.tilt} name="Downtilt" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="Downtilt" name="Downtilt" value="deg" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Center Radius</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="text" id="centrad" value={this.state.crc} name="centrad" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="centrad" name="centrad" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Inner Radius</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="text" id="innerrad" value={this.state.irc} name="innerrad" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="innerrad" name="innerrad" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Outer Radius</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="text" id="outrad" value={this.state.orc} name="outrad" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="outrad" name="outrad" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonContent>
                </IonPage>
            </div >
        );
    }
}

export default withIonLifeCycle(Antennatilt);