import React, { Component } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import Heat from "../assets/img/heaticon.png";
import "./search.css";
import Pin from "../assets/img/search-suggestions-map-icon-gray.png";
import { IonPage, IonContent } from "@ionic/react";
import SearchIcon from "../assets/img/search_icon.png";
import geo from "../assets/img/target-icon.png";
import apicon from "../assets/img/Apicon.png";
import { requestAPICall } from "../service/apiHandler";

let addressPredictions: any = {};
let assetList: any = [];
export default class Search1 extends Component {
  state: any = {};
  props: any = {};
  autocompleteInput: any;

  constructor(props: any) {
    super(props);

    this.state = {
      ap_cpe_list: [],
      asset_list: [],
      searches: "",
      predictionsList: [],
      address: "",
      search: "",
      serial: "",
      search_list: [],
      lat_search: 0,
      lng_search: 0,
      prefferedWay: "cbsd",
    };
    this.onChange = this.onChange.bind(this);
    this.searchValue = this.searchValue.bind(this);
  }

  onSearch = (e: any) => {
    console.log(e.target.name, e.target.value, "ddfdf");
    this.setState({
      searches: e.target.value,
    });
    if (this.state.prefferedWay == "address") {
      console.log(this.autocompleteInput, "ggggggg");
      const regex = /-?\d+\.\d+/g;
      const latlng: any = e.target.value.match(regex);
      console.log("latlng", latlng);
      if (latlng && latlng.length === 2) {
        this.props.searchLatLng({
          lat: parseFloat(latlng[0].toString()),
          lng: parseFloat(latlng[1].toString()),
        });
        return;
      }
      const predict = this.getQuery();
      console.log("predictions", predict);
      this.setState({
        predictionsList: predict,
      });

      console.log(this.state.predictionsList, "list");
    }
  };

  onChange(e: any) {
    this.setState({
      searches: e.target.value,
    });
    if (this.state.prefferedWay == "cbsd") {
      requestAPICall("list_search_asset", { trackingId: e.target.value == "" ? "none" : e.target.value }, {}).then((data) => {
        console.log(data);
        this.setState({
          search_list: data.assets,
        });
      });
    }
  }

  details = (assetIdentifierId: any) => {
    console.log(assetIdentifierId);
    this.setState({
      searches: assetIdentifierId,
    });
    let list_filter: any;
    this.setState(
      (prevState: any) => {
        list_filter = prevState.search_list.filter((asset: any) => asset.assetIdentifierId === assetIdentifierId);
        list_filter = list_filter[0];
        let coordinates = {
          latitude: list_filter.latitude,
          longitude: list_filter.longitude,
        };
        list_filter["coOrdinates"] = coordinates;
        return {
          lat_search: list_filter["latitude"],
          lng_search: list_filter["longitude"],
        };
      },
      () => {
        let locmark = true;
        this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, list_filter);
      }
    );
  };

  searchValue = (e: any) => {
    console.log(e);
    console.log(e.target.value);
  };
  getQuery = () => {
    const service = new google.maps.places.AutocompleteService();

    service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
    return addressPredictions;
  };
  predictionCallback = (predictions: any, status: any) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) return;

    addressPredictions = predictions;
  };
  setPlace = (value: any) => {
    this.setState({
      searches: value,
    });
    let locmark = true;
    this.props.setAddress(value, locmark);

    console.log(value, "sdsds");
  };

  render() {
    return (
      <div>
        <div className="webonly">
          <div className="row">
            <div className="col-md-9">
              <div className="card searchcard">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <img className="search-web" src={SearchIcon} />
                      <i
                        className="material-icons closeicon"
                        style={{ fontSize: "21px", color: "#8f9fac" }}
                        onClick={this.props.back}
                        onKeyDown={(e) => e.key === "Enter" && this.props.back()}
                      >
                        clear
                      </i>
                      <input
                        name={this.state.searches}
                        className="search"
                        id="autocomplete"
                        onChange={(e: any) => {
                          this.onSearch(e);
                          this.onChange(e);
                        }}
                        value={this.state.searches}
                        placeholder="Enter Tracking ID | Address"
                        type="text"
                        ref={this.autocompleteInput}
                        autoFocus
                      ></input>
                      {/* <input className='search' value={this.state.search} name='search' type='text' placeholder='Search Address' onChange={this.onChange} /> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            onClick={(e: any) => {
                              e.preventDefault();
                              this.setState({
                                searches: "",
                                predictionsList: "",
                                prefferedWay: "cbsd",
                                // listingAssets: this.state.ap_cpe_list.length == 0,
                              });
                            }}
                            id="ap-cpe-tab"
                            data-toggle="tab"
                            href="#apcpe"
                            role="tab"
                            aria-controls="apcpe"
                            aria-selected="true"
                            style={{ fontWeight: "bold", padding: ".5rem 1rem .5rem .8rem;" }}
                          >
                            CBSD
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            onClick={(e: any) => {
                              e.preventDefault();
                              this.setState({ searches: "", predictionsList: "", prefferedWay: "address" });
                            }}
                            id="address-tab"
                            data-toggle="tab"
                            href="#address"
                            role="tab"
                            aria-controls="address"
                            aria-selected="false"
                            style={{ fontWeight: "bold", padding: ".5rem 1rem .5rem .8rem;" }}
                          >
                            ADDRESS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content" id="searchTabContent">
                    <div
                      className={this.state.prefferedWay == "address" ? "tab-pane fade show active" : "tab-pane fade"}
                      id="address"
                      role="tabpanel"
                      aria-labelledby="address-tab"
                    >
                      {this.state.predictionsList.length > 0 ? (
                        <div style={{ overflow: "hidden auto", maxHeight: "calc(100vh - 190px)" }}>
                          {this.state.predictionsList?.map((predict: any) => {
                            return (
                              <div
                                key={predict.description}
                                className="row"
                                onClick={() => this.setPlace(predict.description)}
                                onKeyDown={(e) => e.key === "Enter" && this.setPlace(predict.description)}
                                style={{ padding: "0px 15px 0px 0px" }}
                              >
                                <div className="col-md-2" style={{ cursor: "default" }}>
                                  <img className="searchcontenticon" src={Pin} />
                                </div>
                                <div className="col-md-10 searchborder" style={{ cursor: "pointer" }}>
                                  <span className="searchcontent">{predict.description}</span>
                                  <br />
                                  {/* <span className='apnote'>New York, NY 12345</span> */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className={this.state.prefferedWay == "cbsd" ? "tab-pane fade show active" : "tab-pane fade"}
                      id="apcpe"
                      role="tabpanel"
                      aria-labelledby="ap-cpe-tab"
                    >
                      {this.state.search_list.length > 0 ? (
                        <div style={{ overflow: "hidden auto", maxHeight: "calc(100vh - 190px)" }}>
                          {this.state.search_list?.map((index: any) => (
                            <div
                              id="indexname"
                              onClick={() => this.details(index.assetIdentifierId)}
                              onKeyDown={(e) => e.key === "Enter" && this.details(index.assetIdentifierId)}
                              key={index.assetIdentifierId}
                            >
                              <div className="row" style={{ padding: "0px 15px 0px 0px" }}>
                                {index.assetType === "AP-outdoor" ? (
                                  <div className="col-md-2">
                                    <img className="heat" src={apicon} style={{ transform: "rotate(-39deg)", width: "47px" }} />
                                  </div>
                                ) : (
                                  <div className="col-md-2">
                                    <i className="far fa-circle searchcontenticon"></i>
                                  </div>
                                )}
                                <div className="col-md-10 searchborder" style={{ cursor: "pointer" }}>
                                  <div className="font14" style={{ color: "#353435" }}>
                                    {index.assetIdentifierId}{" "}
                                  </div>
                                  <div className="font14" style={{ color: "#8F9FAC" }}>
                                    {" "}
                                    {index.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12 justify-content-flex-end">
                <div className="heaticon text-center" style={{ display: "none" }}>
                  <img className="heat" src={Heat} />
                </div>
              </div>

              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img style={{ padding: "5px" }} src={geo} />
                </div>
              </div>
            </div>
          </div>
          {/* <IonLoading
            isOpen={this.state.listingAssets}
            onDidDismiss={() => this.setState({ listingAssets: false })}
            message={"Listing Assets..."}
            // duration={5000}
          /> */}
        </div>

        <div className="mobonly">
          <IonPage>
            <IonContent>
              <div className="param-info-mob ">
                <div className="col-12 searchborder" style={{ padding: "10px" }}>
                  <i className="fa fa-search searchicon" aria-hidden="true"></i>
                  <i className="fa fa-times closeicon" onClick={this.props.back} onKeyDown={(e) => e.key === "Enter" && this.props.back()}></i>
                  <StandaloneSearchBox>
                    <input className="search" type="text" placeholder="Search Address" />
                  </StandaloneSearchBox>
                </div>

                <div className="container-fluid apdash">
                  <div className="card profilecard" style={{ height: "calc(100vh - 78px)" }}>
                    <div className="card-body" style={{ overflowY: "auto", padding: "10px 5px", overflowX: "hidden" }}>
                      <div className="row" style={{ paddingRight: "18px" }}>
                        <div className="col-2 p-r-10">
                          <i className="fas fa-map-marker-alt searchcontenticon  float-right"></i>
                        </div>
                        <div className="col-10 searchborder">
                          <span className="searchcontent">9th Avenue</span>
                          <br />
                          <span className="apnote">New York, NY 12345</span>
                        </div>
                      </div>
                      <div className="row" style={{ paddingRight: "18px" }}>
                        <div className="col-2 p-r-10">
                          <i className="fas fa-map-marker-alt searchcontenticon  float-right"></i>
                        </div>
                        <div className="col-10 searchborder">
                          <span className="searchcontent">9th Avenue</span>
                          <br />
                          <span className="apnote">New York, NY 12345</span>
                        </div>
                      </div>
                      <div className="row" style={{ paddingRight: "18px" }}>
                        <div className="col-2 p-r-10">
                          <i className="fas fa-map-marker-alt searchcontenticon  float-right"></i>
                        </div>
                        <div className="col-10 searchborder">
                          <span className="searchcontent">9th Avenue</span>
                          <br />
                          <span className="apnote">New York, NY 12345</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*                     
                    <div className='row'>
                        <div className='col-md-9'>
                            <div className="card searchcard">
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <i className="fa fa-search searchicon" aria-hidden="true"></i>
                                            <i className="fa fa-times closeicon" onClick={this.props.back}></i>
                                            <StandaloneSearchBox>
                                                <input className='search' type='text' placeholder='Search Address' />
                                            </StandaloneSearchBox>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active p-l-0" id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                        aria-selected="true">mob</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                        aria-selected="false">CBSD/CPE</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="tab-content" id="searchTabContent">
                                        <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="fas fa-map-marker-alt searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>9th Avenue</span><br />
                                                    <span className='apnote'>New York, NY 12345</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="far fa-circle searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>CPE Name</span><br />
                                                    <span className='apnote'>12345</span>
                                                </div>
                                            </div>
                                            <div className='row' style={{ padding: "0px 15px 0px 0px" }}>
                                                <div className='col-md-2'>
                                                    <i className="far fa-circle searchcontenticon"></i>
                                                </div>
                                                <div className='col-md-10 searchborder'>
                                                    <span className='searchcontent'>AP Name</span><br />
                                                    <span className='apnote'>67890</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
            </IonContent>
          </IonPage>
        </div>
      </div>
    );
  }
}
