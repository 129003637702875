import React from "react";
import { GoogleMap, Marker, Polyline, InfoWindow, Circle, KmlLayer } from "@react-google-maps/api";
import { isBrowser, isMobile } from "react-device-detect";
import { withRouter, Redirect } from "react-router-dom";
import { IonLoading, IonAlert } from "@ionic/react";
import cbsdSectorImage from "../assets/img/azimuth_sectors_black.svg";
import cbsdSectorRoeImage from "../assets/img/roe_sector.svg";
import gps_icon from "../assets/img/add-cpe-icon-img-blue.png";
import Dashboard from "./dashboard";
import AddAP from "./AddAP";
import AddCPE from "./AddCPE";
import Measure from "./measure";
import Calculate from "./calculate";
import APdetails from "./access-point-details";
import { requestAPICall } from "../service/apiHandler";
import APdetails1 from "./access-point-details1";
import Search1 from "./search1";
import { connect } from "react-redux";
import store from "../store";
import ADDCP2 from "./AddCPE2";
import ADDCP3 from "./AddCPE3";
import AddAP2 from "./AddAP2";
import AddAP3 from "./AddAP3";
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from "../constants/actionTypes";
import { getSectorImage, getSectorImageMeasure } from "../service/rotateIcon";
import { Plugins, AppState } from "@capacitor/core";
import ClusterCircle from "../assets/img/clusterCircle.svg";
import Claims from "../service/claims";

const { Geolocation } = Plugins;
const { App } = Plugins;
const { Toast } = Plugins;
const { BackgroundTask } = Plugins;

const ASSET_INITIAL_STATE = {
  center: null,
};

const INITIAL_STATE = {
  card: 1,
  subcard: null,
  marks: [],
  username: "",
  APname: [],
  Description: "",
  AddImage: "",
  Serial: "",
  properties: [],
  asset_details: [],
  temp: 0,
  cord: {},
  param1: "",
  param2: "",
  para: [],
  latlonglist: [],
  azimuth1: 0,
  azimuth2: 0,
  height1: 0,
  height2: 0,
  tilt1: 0,
  tilt2: 0,
  distance: 0,
  lat: 0,
  long: 0,
  lng: 0,
  typeId: 0,
  cardlist: [],
  rawAssetList: [],
  assetList: [],
  clusterList: [],
  disabledAssetList: [],
  addAssetList: [],
  currentMapClickEvent: {},
  selectedAssetId: 0,
  assetAction: "add",
  noOfSectors: 0,
  browserLatitude: 0.0,
  browserLongitude: 0.0,
  map: {},
  radiation_center: [],
  sector_id_list: [],
  serial: "",
  assetPointDropList: [],
  isAssetDropEnabled: false,
  measure_pair: [],
  locmark: false,
  rad_center: null,
  warnMessage: "",
  isLoading: false,
  alertbox: false,
  add_ap2_properties: [],
  add_ap3_properties: [],
  add_cp2_properties: [],
  add_cp3_properties: [],
  gps_lat: 0,
  gps_lng: 0,
  gps_accuracy: 0,
  accuracy_visible: false,
  show_overlay: false,
  assetPointclickList: [],
  assetPointclickEnabled: false,
  assetCardShow: true,
  assetCardLatLongList: [],
  assetStatus: "",
  onHover: false,
  assetIdentifierId: "",
  assetName: "",
  assethoverLatLongList: [],
  polygon_files: null,
  ap_cpe_locamrk: false,
  search_asset: {},
  sasStatus: "",
  pciCardShow: false,
  editSerial: true,
  isSafari: false,
  displaySASIcon: true,
  transparentIcon: {
    url: require("../assets/img/status-img/transparent-circle-marker.png"),
    scaledSize: new window.google.maps.Size(0, 0),
    anchor: new window.google.maps.Point(5, 5),
    labelAnchor: new window.google.maps.Size(0, 0),
    labelOrigin: new window.google.maps.Point(5, 45),
  },
  mapZoom: 18,
  azimuthInAction: false,
  azimuthInActionWeb: false,
  isAzimuthSet: false,
  markerVisible: true,
  currentAzimuthAngle: 0,
  isFetchingAssets: false,
};

const markerIcon = {
  url: require("../assets/img/gps-icon-img2.svg"),
  scaledSize: new window.google.maps.Size(54, 66),
};

let API_VALUES = {
  code: 0,
};

const lineSymbol = {
  path: "M 0,-1 0,1",
  scale: 4,
  strokeColor: "#FF9445",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF9445",
  fillOpacity: 0.35,
  radius: 30000,
  zIndex: 1,
};

const circleOptions = {
  strokeColor: "#0059B2AD",
  strokeOpacity: 0.35,
  strokeWeight: 1,
  fillColor: "#0059B2AD",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  zIndex: 1,
};

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  upadateGMapClickEvent: (value: any) =>
    dispatch({
      type: CURRENT_MAP_CLICK_INSTANCE,
      key: "gMapClickInstance",
      value,
    }),
  upadateMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "coordinates", value }),
  upadateSearchMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "asset_search", value }),
  updateAssetTypeId: (value: any) => dispatch({ type: ASSET, key: "assetTypeId", value }),
  updateAssetAction: (value: any) => dispatch({ type: ASSET, key: "assetAction", value }),
  updateAssetId: (value: any) => dispatch({ type: ASSET, key: "assetId", value }),
  updateSearchText: (value: any) => dispatch({ type: COORDINATES, key: "search", value }),
  updateTabType: (value: any) => dispatch({ type: ASSET, key: "tabType", value }),
  upadateUrlPath: (value: any) => dispatch({ type: CURRENT_TAB, key: "updateUrlPath", value }),
});

let isFirstLoadComplete = false;
let lat: any;
let lng: any;

let azimuthInterval: any;

class Outdoor extends React.Component {
  state: any = {};
  props: any = {};
  childCPE: React.RefObject<any>;
  childAP: React.RefObject<any>;
  childAssetDetails: React.RefObject<any>;
  apdetails_ref: React.RefObject<any>;
  childMeasure: React.RefObject<any>;
  dashRef: React.RefObject<any>;
  _map: any;
  marker_event1: any;
  marker_event2: any;
  inBetween: any;
  backButton_timer: any = null;
  measure_timer: any;
  reduxStore: any = store.getState()["general"];
  checkIconsLoaded: any;
  callMeasureIcon1: any;
  callMeasureIcon2: any;
  counter: any = 0;

  backButton_event: any;
  appState_event: any;
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.onMapClick = this.onMapClick.bind(this);
    this.onMapDragEnd = this.onMapDragEnd.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.search = this.search.bind(this);
    this.geocodeAddress = this.geocodeAddress.bind(this);
    this.setLatLngSearch = this.setLatLngSearch.bind(this);
    this.refreshAsset = this.refreshAsset.bind(this);
    this.childCPE = React.createRef();
    this.childAP = React.createRef();
    this.childAssetDetails = React.createRef();
    this.apdetails_ref = React.createRef();
    this.childMeasure = React.createRef();
    this.dashRef = React.createRef();
    this.resetState = this.resetState.bind(this);
    this.azimuthClickedNewCbsd = this.azimuthClickedNewCbsd.bind(this);
    this.azimuthClickedNewCbsdWeb = this.azimuthClickedNewCbsdWeb.bind(this);
    this.azimuthConfirmClicked = this.azimuthConfirmClicked.bind(this);
    this.azimuthBackClicked = this.azimuthBackClicked.bind(this);
    this.removeAzimuthFromRedux = this.removeAzimuthFromRedux.bind(this);
  }

  azimuthUpHoldStart = () => {
    this.azimuthUpClicked();
    azimuthInterval = setInterval(() => {
      this.azimuthUpClicked();
    }, 100);
  };

  azimuthHoldStop = () => {
    azimuthInterval = clearInterval(azimuthInterval);
  };

  azimuthDownHoldStart = () => {
    this.azimuthDownClicked();
    azimuthInterval = setInterval(() => {
      this.azimuthDownClicked();
    }, 100);
  };

  azimuthUpClicked() {
    let currentAngle = this.state.currentAzimuthAngle;
    this.setState({
      currentAzimuthAngle: currentAngle == 360 ? 1 : currentAngle + 1,
    });
  }

  azimuthDownClicked() {
    let currentAngle = this.state.currentAzimuthAngle;
    this.setState({
      currentAzimuthAngle: currentAngle == 0 ? 359 : currentAngle - 1,
    });
  }

  azimuthBackClicked() {
    this._map.setOptions({ draggable: true });
    this.setState({
      azimuthInActionWeb: false,
      isAzimuthSet: false,
      markerVisible: true,
      currentAzimuthAngle: 0,
    });
  }

  removeAzimuthFromRedux() {
    if ("currentAssetProperties" in this.reduxStore) {
      delete this.reduxStore["currentAssetProperties"];
    }
    if ("currentSectorList" in this.reduxStore) {
      delete this.reduxStore["currentSectorList"];
    }
    this._map.setOptions({ draggable: true });
    this.setState({
      azimuthInActionWeb: false,
      isAzimuthSet: false,
      markerVisible: true,
      currentAzimuthAngle: 0,
    });
  }

  azimuthConfirmClicked() {
    let s1azimuth;
    let s2azimuth;
    if (this.reduxStore["currentAssetProperties"].filter((property: any) => property.propertyName == "ap_mounting_type")[0].value == "ROE") {
      s1azimuth = this.state.currentAzimuthAngle;
    } else {
      s1azimuth = this.state.currentAzimuthAngle + 225;
      s2azimuth = this.state.currentAzimuthAngle + 45;
      if (s1azimuth >= 360) s1azimuth = s1azimuth - 360;
      if (s2azimuth >= 360) s2azimuth = s2azimuth - 360;
    }

    let azimuth_final_list = [s1azimuth, s2azimuth];
    for (let i = 0; i < this.reduxStore["currentSectorList"].length; i++) {
      this.reduxStore["currentSectorList"][i]["azimuth_value"] = azimuth_final_list[i];
    }
    this._map.setOptions({ draggable: true });
    this.setState({
      azimuthInActionWeb: false,
      isAzimuthSet: true,
      markerVisible: true,
      currentAzimuthAngle: 0,
    });
  }

  resetState() {
    this.setState({
      assetPointclickList: [],
      isAssetDropEnabled: false,
      card: 1,
    });
  }

  async firstLocation() {
    if (
      [undefined, null, "undefined", "null"].includes(localStorage.getItem("outdoor_map_center_lat")) ||
      [undefined, null, "undefined", "null"].includes(localStorage.getItem("outdoor_map_center_lng"))
    ) {
      let latitude = "35.992072";
      let longitude = "-78.899219";
      this._map?.setZoom(15);
      this.setState(
        {
          center: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          },
        },
        () => {
          this.props.upadateMapCoordinates({
            latitude: this.state.center["lat"],
            longitude: this.state.center["lng"],
          });
        }
      );
      localStorage.setItem("outdoor_map_center_lat", latitude);
      localStorage.setItem("outdoor_map_center_lng", longitude);

      Geolocation.getCurrentPosition().then((coordinates: any) => {
        if (coordinates.coords !== undefined) {
          this.setState({
            gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
            gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
            gps_accuracy: coordinates.coords.accuracy,
          });
          this.setState(
            {
              center: {
                lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
                lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
              },
            },
            () => {
              this.props.upadateMapCoordinates({
                latitude: coordinates.coords.latitude.toFixed(6),
                longitude: coordinates.coords.longitude.toFixed(6),
              });
            }
          );
          localStorage.setItem("outdoor_map_center_lat", coordinates.coords.latitude.toFixed(6));
          localStorage.setItem("outdoor_map_center_lng", coordinates.coords.longitude.toFixed(6));
        } else {
          let latitude = "35.992072";
          let longitude = "-78.899219";
          this._map?.setZoom(15);
          this.setState(
            {
              center: {
                lat: parseFloat(latitude),
                lng: parseFloat(longitude),
              },
            },
            () => {
              this.props.upadateMapCoordinates({
                latitude: this.state.center["lat"],
                longitude: this.state.center["lng"],
              });
            }
          );
          localStorage.setItem("outdoor_map_center_lat", latitude);
          localStorage.setItem("outdoor_map_center_lng", longitude);
        }
      });
    } else {
      lat = localStorage.getItem("outdoor_map_center_lat");
      lng = localStorage.getItem("outdoor_map_center_lng");
      this.setState(
        {
          center: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
        },
        () => {
          this.props.upadateMapCoordinates({
            latitude: this.state.center["lat"],
            longitude: this.state.center["lng"],
          });
        }
      );
    }
  }

  handleSubCard = () => {
    if (this.state.subcard == null) {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 2000);
      } else {
        App.exitApp();
      }
    } else if (this.state.subcard == "search") {
      this.dashRef.current.switch();
    } else if (this.state.subcard == "network") {
      this.dashRef.current.hideNetworkOverlay();
    } else if (this.state.subcard == "replace") {
      this.dashRef.current.hideNetworkOverlay();
    }
  };

  onBackKeyDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.card === 1 && localStorage.getItem("urlPath") === "/map/outdoor/main") this.handleSubCard();

    if (this.state.card == 2 || this.state.card == 4) {
      this.hideOverlay();
    } else if (this.state.card == 5 || this.state.card == 6) {
      this.dash();
    } else if (this.state.card == 7) {
      if (this.state.subcard == null) {
        this.dash();
      } else if (this.state.subcard == "details") {
        this.childAssetDetails.current.hideOverlay();
      } else if (this.state.subcard == "parameter") {
        this.childAssetDetails.current.hideParamOverlay();
      } else if (this.state.subcard == "network") {
        this.childAssetDetails.current.hideNetworkOverlay();
      } else if (this.state.subcard == "replace") {
        this.childAssetDetails.current.hideParamOverlay();
      }
    }
    localStorage.setItem("urlPath", this.props.location.pathname);
  };

  onDeviceReady = (e: any) => {
    localStorage.setItem("backButton", "true");
    localStorage.setItem("appState", "true");
    document.addEventListener("backbutton", this.onBackKeyDown, false);
  };

  handleSubCardBackButton = () => {
    if (this.state.subcard == null) {
      if (this.counter == 0) {
        this.counter++;
        this.presentToast();
        setTimeout(() => {
          this.counter = 0;
        }, 2000);
      } else {
        App.exitApp();
      }
    } else if (this.state.subcard == "search") {
      this.dashRef.current.switch();
    } else if (this.state.subcard == "network") {
      this.dashRef.current.hideNetworkOverlay();
    } else if (this.state.subcard == "replace") {
      this.dashRef.current.hideNetworkOverlay();
    }
  };

  backButtonListenerCallback = (state: any) => {
    if (this.state.card == 1 && localStorage.getItem("urlPath") === "/map/outdoor/main") this.handleSubCardBackButton();
    else if (this.state.card == 2 || this.state.card == 4) {
      this.hideOverlay();
    } else if (this.state.card == 5 || this.state.card == 6) {
      this.dash();
    } else if (this.state.card == 7) {
      if (this.state.subcard == null) {
        this.dash();
      } else if (this.state.subcard == "details") {
        this.childAssetDetails.current.hideOverlay();
      } else if (this.state.subcard == "parameter") {
        this.childAssetDetails.current.hideParamOverlay();
      } else if (this.state.subcard == "network") {
        this.childAssetDetails.current.hideNetworkOverlay();
      } else if (this.state.subcard == "replace") {
        this.childAssetDetails.current.hideParamOverlay();
      }
    }
  };

  handleBackButtonListenerNotExists = () => {
    let backButtonListenerExists = localStorage.getItem("backButton");
    if (backButtonListenerExists !== null) return;
    localStorage.setItem("backButton", "true");
    this.backButton_event = App.addListener("backButton", this.backButtonListenerCallback);
  };

  appStateListenerCallback = (state: AppState) => {
    if (state.isActive) {
      this.set_backButton_listener();
    }
    if (!state.isActive) {
      let taskId = BackgroundTask.beforeExit(async () => {
        if (this.backButton_timer != null) {
          clearInterval(this.backButton_timer);
          this.backButton_timer = null;
        }
        if (this.backButton_event != null) {
          this.backButton_event.remove();
        }
        localStorage.removeItem("backButton");
        BackgroundTask.finish({
          taskId,
        });
      });
    }
  };

  handleAppStateListenerNotExists = () => {
    let appStateListenerExists = localStorage.getItem("appState");
    if (appStateListenerExists == null) {
      localStorage.setItem("appState", "true");
      this.appState_event = App.addListener("appStateChange", this.appStateListenerCallback);
    }
  };

  set_backButton_listener = () => {
    this.handleBackButtonListenerNotExists();
    if (this.backButton_timer == null) {
      this.backButton_timer = setInterval(() => {
        this.set_backButton_listener();
      }, 2000);
    }
    this.handleAppStateListenerNotExists();
    localStorage.setItem("urlPath", this.props.location.pathname);
  };

  componentDidMount() {
    $("#searchbox").hide();

    setTimeout(() => {
      $("#searchbox").css("width", "99.9%");
      $("#searchbox").show();
    }, 50);
    setTimeout(() => {
      $("#searchbox").css("width", "100%");
      $("#searchbox").show();
    }, 1000);

    if (localStorage.getItem("browser") == "Safari") {
      this.setState({
        isSafari: true,
      });
    }

    this.firstLocation();

    if (isMobile) {
      $("#searchbox").hide();
      setTimeout(() => {
        $("#searchbox").css("width", "99.9%");
        $("#searchbox").show();
      }, 50);
      setTimeout(() => {
        $("#searchbox").css("width", "100%");
        $("#searchbox").show();
      }, 1000);
      document.addEventListener("deviceready", this.onDeviceReady, false);
    }

    this.props.updateTabType("outdoor");

    this.reduxStore = store.getState()["general"];
  }
  componentWillUnmount() {
    if (isMobile) {
      document.removeEventListener("backbutton", this.onBackKeyDown, false);
      if (this.backButton_timer != null) {
        clearInterval(this.backButton_timer);
        this.backButton_timer = null;
      }
      if (this.backButton_event != null) {
        this.backButton_event.remove();
      }
      if (this.appState_event != null) {
        this.appState_event.remove();
      }
      localStorage.removeItem("backButton");
      localStorage.removeItem("appState");
    }
  }
  componentDidUpdate() {
    this.reduxStore = store.getState()["general"];
  }

  presentToast = async () => {
    await Toast.show({
      text: "Press again to exit",
      duration: "short",
      position: "center",
    });
  };

  latlong_apcpe = (lat: any, lng: any, ap_cpe_locamrk: any, search_asset: any) => {
    this.setState(
      {
        ap_cpe_locamrk: ap_cpe_locamrk,
        search_asset: search_asset,
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
      },
      () => {
        localStorage.setItem("outdoor_map_center_lat", this.state.center["lat"].toString());
        localStorage.setItem("outdoor_map_center_lng", this.state.center["lng"].toString());
        this.props.upadateMapCoordinates({
          latitude: this.state.center["lat"],
          longitude: this.state.center["lng"],
        });
        if (Claims.getUserRole() === "Installer") {
          this.getSelectedAsset(search_asset["assetIdentifierId"]);
        }
        this._map?.setZoom(22);
      }
    );
  };

  enableAssetDrop = (st: any) => {
    this.setState({ isAssetDropEnabled: st });
  };

  setAddress = (address: any, locmark: any) => {
    this.geocodeAddress(address, locmark);
  };
  geocodeAddress = (address: any, locmark: any) => {
    this.setState({
      locmark: false,
    });
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: address },
      function handleResults(this: any, results: any, status: any) {
        if (status === google.maps.GeocoderStatus.OK) {
          this.setState(
            {
              center: {
                lat: parseFloat(results[0].geometry.location.lat()),
                lng: parseFloat(results[0].geometry.location.lng()),
              },
            },
            () => {
              localStorage.setItem("outdoor_map_center_lat", this.state.center["lat"].toFixed(6).toString());
              localStorage.setItem("outdoor_map_center_lng", this.state.center["lng"].toFixed(6).toString());
              this.props.upadateMapCoordinates({
                latitude: this.state.center["lat"].toFixed(6),
                longitude: this.state.center["lng"].toFixed(6),
              });
              this._map?.setZoom(18);
            }
          );

          this.setState({
            locmark: true,
          });
        }

        this.setState({
          foundAddress: null,
          isGeocodingError: true,
        });
      }.bind(this)
    );
    this.props.updateSearchText(null);
  };

  setLatLngSearch = (latLng: any) => {
    this.setState(
      {
        center: latLng,
      },
      () => {
        localStorage.setItem("outdoor_map_center_lat", this.state.center["lat"].toFixed(6).toString());
        localStorage.setItem("outdoor_map_center_lng", this.state.center["lng"].toFixed(6).toString());
        this.props.upadateMapCoordinates({
          latitude: this.state.center["lat"].toFixed(6),
          longitude: this.state.center["lng"].toFixed(6),
        });
        this._map?.setZoom(18);
      }
    );

    this.setState({
      locmark: true,
    });
  };

  curren_geo_position = async () => {
    this.setState({ isLoading: true });
    Geolocation.getCurrentPosition({
      timeout: 3000,
      enableHighAccuracy: true,
      maximumAge: 0,
    })
      .then((coordinates: any) => {
        if (coordinates.coords !== undefined) {
          this.setState(
            {
              browserLatitude: parseFloat(coordinates.coords.latitude.toFixed(6)),
              browserLongitude: parseFloat(coordinates.coords.longitude.toFixed(6)),
              gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
              gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
              gps_accuracy: coordinates.coords.accuracy,
            },
            () => {
              this.setState(
                {
                  center: {
                    lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
                    lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
                  },
                },
                () => {
                  try {
                    this._map.setCenter(this.state.center);
                    this._map?.setZoom(18);
                  } catch (error) {}
                }
              );
              localStorage.setItem("outdoor_map_center_lat", coordinates.coords.latitude.toFixed(6));
              localStorage.setItem("outdoor_map_center_lng", coordinates.coords.longitude.toFixed(6));
              this.props.upadateMapCoordinates({
                latitude: parseFloat(coordinates.coords.latitude.toFixed(6)),
                longitude: parseFloat(coordinates.coords.longitude.toFixed(6)),
              });
              this.setState({ isLoading: false });
            }
          );
        } else {
          this.setState({
            isLoading: false,
            alertbox: true,
            warnMessage: "Unable to find your location. Please check your location services.",
          });
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          alertbox: true,
          warnMessage: "Unable to find location. " + error.message,
        });
      });
  };

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  setShowLoading = (st: any) => {
    this.setState({ isLoading: st });
  };
  onMapLoad = (map: any) => {
    try {
      this._map = map;
      setTimeout(() => {
        if (Claims.getUserRole() !== "Installer" && this._map != undefined) {
          this.decideClustersOrAssets();
        }
      }, 1000);
      google.maps.event.addListener(this._map, "dragend", this.onMapDragEnd);
      if (this.reduxStore["networkSelectedAsset"] !== undefined) {
        let asset = this.reduxStore["networkSelectedAsset"];
        this.reduxStore["networkSelectedAsset"] = undefined;
        delete this.reduxStore["networkSelectedAsset"];
        this.assetDetail({}, asset, "Info");
      }
    } catch (exception) {
      this.setState({
        alertbox: true,
        warnMessage: "Error while loading map. " + exception,
      });
    }
  };
  displayHeatmap = (val: any) => {
    if (this._map != null && this._map != undefined) {
      if (val != null && val.length > 0) {
        val?.map((folder_name: any) => {
          let imageMapType = new google.maps.ImageMapType({
            getTileUrl: function (tile, zoom) {
              let ymax = 1 << zoom;
              let y = ymax - tile.y - 1;
              return "https://cpiprodemo1.c3spectra.com/heatmaps/tiles/" + folder_name + "/" + zoom + "/" + tile.x + "/" + y + ".png";
            },
            tileSize: new google.maps.Size(256, 256),
            opacity: 0.3,
          });
          this._map.overlayMapTypes.push(imageMapType);
        });
      }
      if (val == null) {
        this._map.overlayMapTypes.clear();
      }
    }
  };
  displaySASstatus = (val: any) => {};

  handleTilesLoad = () => {
    isFirstLoadComplete = true;
  };

  onMarkerDragEnd = (e: any) => {
    this.setState({
      lat: e.latLng.lat().toFixed(6),
      lng: e.latLng.lng().toFixed(6),
    });
    localStorage.setItem("outdoor_map_center_lat", e.latLng.lat().toFixed(6));
    localStorage.setItem("outdoor_map_center_lng", e.latLng.lng().toFixed(6));
    this.props.upadateMapCoordinates({
      latitude: e.latLng.lat().toFixed(6),
      longitude: e.latLng.lng().toFixed(6),
    });
  };

  onMapClick = async (e: any) => {
    Geolocation.getCurrentPosition().then((coordinates: any) => {
      this.setState({
        lat: coordinates.coords.latitude.toFixed(6),
        lng: coordinates.coords.longitude.toFixed(6),
      });
      localStorage.setItem("outdoor_map_center_lat", coordinates.coords.latitude.toFixed(6));
      localStorage.setItem("outdoor_map_center_lng", coordinates.coords.longitude.toFixed(6));
      this.props.upadateMapCoordinates({
        latitude: coordinates.coords.latitude.toFixed(6),
        longitude: coordinates.coords.longitude.toFixed(6),
      });
      if (this.state.marks.length <= 0) {
        this.setState((prevState: any) => ({
          marks: [
            ...prevState.marks,
            {
              lat: coordinates.coords.latitude,
              lng: coordinates.coords.longitude,
            },
          ],
        }));
      }
    });
  };

  dash = () => {
    clearInterval(this.measure_timer);
    if (isMobile) {
      this.props.history.push("/map/outdoor/main");
    }
    if (isBrowser) {
      setTimeout(() => {
        $(".gm-bundled-control").css("left", "95.6%");
        $(".gm-bundled-control").show();
      }, 5);
      this.props.history.push("/map/outdoor");
    }
    try {
      clearInterval(this.callMeasureIcon1);
      clearInterval(this.callMeasureIcon2);
    } catch (error) {}
    this.setState({
      card: 1,
      marks: [],
      param1: "",
      param2: "",
      temp: 0,
      latlonglist: [],
      azimuth1: 0,
      azimuth2: 0,
      tilt1: 0,
      tilt2: 0,
      height1: 0,
      height2: 0,
      distance: 0,
      assetPointDropList: [],
      addAssetList: [],
      isAssetDropEnabled: false,
      measure_pair: [],
      rad_center: null,
      isAssetClickEnabled: false,
    });
    this.marker_event1 = null;
    this.marker_event2 = null;
    this.inBetween = null;
  };
  dash1 = () => {
    this.setState({
      card: 7,
      marks: [],
      assetPointDropList: [],
      isAssetDropEnabled: false,
    });
  };
  sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  addAP = () => {
    this.enableAssetDrop(true);
    this.props.updateAssetTypeId(1);
    this.props.updateAssetAction("add");

    this.sleep(500).then(() => {
      this.setState({
        show_overlay: true,
        card: 2,
        assetAction: "add",
        noOfSectors: 0,
        typeId: 1,
        ap_cpe_locamrk: false,
      });
    });
  };

  search = () => {
    this.setState({
      card: 3,
    });
  };

  addCPE = () => {
    this.props.updateAssetTypeId(3);
    this.props.updateAssetAction("add");
    this.sleep(500).then(() => {
      this.setState({
        card: 4,
        typeId: 3,
        assetAction: "add",
        show_overlay: true,
        ap_cpe_locamrk: false,
      });
    });
  };

  measure = () => {
    this.setState({
      isAssetClickEnabled: false,
    });
    this.props.history.push("/map/outdoor/measure");

    this.setState({
      card: 5,
      ap_cpe_locamrk: false,
    });
  };

  calculate = () => {
    this.props.history.push("/map/outdoor/calculate");
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 5);

    this.setState({
      card: 6,
      lat: 0,
      lng: 0,
      ap_cpe_locamrk: false,
    });
  };

  changeMapType = () => {
    if (this._map?.getMapTypeId() == google.maps.MapTypeId.ROADMAP) {
      this._map?.setMapTypeId(google.maps.MapTypeId.HYBRID);
    } else {
      this._map?.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    }
    this._map.setTilt(0);
  };

  set_calculate = (lat: any, long: any, radc: any) => {
    this.setState({
      lat: lat,
      lng: long,
      rad_center: radc,
    });
  };
  refreshAsset = (selectedAssetId: any) => {
    let label_Text: any = "";
    let requestBody = {};
    let new_request_list: any = [];
    let new_raw_list: any = [];
    if (Claims.getUserRole() !== "Installer") {
      new_request_list = this.state.assetList.filter((asset: any) => asset.assetId !== selectedAssetId);
      new_raw_list = this.state.rawAssetList.filter((asset: any) => asset.assetId !== selectedAssetId);
    }
    try {
      clearInterval(this.checkIconsLoaded);
    } catch (error) {}

    requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody).then((asset) => {
      if (asset.responseCode == 200) {
        new_raw_list.push(asset["rawAssetData"]);

        asset.properties?.map((property: any) => {
          if (property.propertyName == "latitude") {
            asset.latitude = property.value;
          }
          if (property.propertyName == "longitude") {
            asset.longitude = property.value;
          }
          if (property.propertyName == "access_point_id") {
            asset["assetIdentifierId"] = property.value;
          }
        });
        asset["azimuth_list"]?.forEach((sector: any) => {
          label_Text = { text: asset["assetIdentifierId"] };
          this.setState({
            sasStatus: asset["sasStatus"],
            assetName: asset["assetName"],
          });
          new_request_list.push({
            assetType: asset["assetType"],
            assetId: asset["assetId"],
            sasStatus: asset["sasStatus"],
            assetOperationalStatus: asset["operationalStatus"],
            assetIdentifierId: asset["assetIdentifierId"],
            coOrdinates: {
              lat: parseFloat(asset.latitude),
              lng: parseFloat(asset.longitude),
            },
            name: asset["assetName"],
            labelText: label_Text,
            markerIcon: getSectorImage(
              {
                assetType: asset["assetType"],
                noOfSectors: asset["azimuth_list"].length,
                status: "sas-status",
                azimuth: sector,
                assetId: asset["assetId"],
                action: "create",
                pciCardShow: this.state.pciCardShow,
                sasStatus: asset["sasStatus"],
              },
              () => {}
            ),
            azimuth: asset["azimuth_list"],
            sector_azimuth: sector[1],
          });
        });
      }

      this.setState({
        assetList: new_request_list,
        rawAssetList: new_raw_list,
      });
      this.checkIconsLoaded = setInterval(() => {
        this.state.assetList?.map((asset: any) => {
          asset["azimuth"]?.forEach((sector: any) => {
            getSectorImage(
              {
                assetType: asset["assetType"],
                noOfSectors: asset["azimuth"].length,
                status: "sas-status",
                azimuth: sector,
                assetId: asset["assetId"],
                action: "rotate",
                pciCardShow: this.state.pciCardShow,
                sasStatus: asset["sasStatus"],
              },
              () => {}
            );
          });
        });
      }, 500);
    });
  };
  get_asset_details = (selectedAssetId: any, text: any) => {
    this.setState({ isLoading: true });
    let requestBody = {};
    requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody).then((data) => {
      this.setState({ isLoading: false });
      if (data.responseCode !== 200) {
        this.setState({
          warnMessage: "Error occured while fetching the details of the asset.",
          alertbox: true,
        });
        return;
      }
      this.props.updateAssetTypeId(data.assetTypeId);
      if (text === "Info") {
        this.setState(
          {
            asset_details: data,
            card: 7,
          },
          () => {
            this.apdetails_ref.current.set_property();
            setTimeout(() => {
              $(".gm-bundled-control").css("left", "73%");
              $(".gm-bundled-control").show();
            }, 50);
          }
        );
        data.properties?.map((data: any) => {
          if (data.propertyName == "radiation_center") {
            this.setState({
              rad_center: data["value"],
            });
          }
          if (data.propertyName == "lattitude") {
            this.setState({
              lat: data["value"],
            });
          }
          if (data.propertyName == "longitude") {
            this.setState({
              lng: data["value"],
            });
          }
        });
      }
      if (text === "Measure") {
        data.properties?.map((data: any) => {
          if (data.propertyName == "radiation_center") {
            this.state.radiation_center.push(parseFloat(data.value));
          }
        });
      }
    });
  };

  getSelectedAsset(trackingId: any) {
    try {
      this.setState({ isLoading: true, rawAssetList: [], assetList: [] });
      let requestBody = {
        trackingId: trackingId,
      };
      requestAPICall("get_selected_asset", requestBody, {}).then((data) => {
        if (data.responseCode == 200) {
          if (data.assets.length > 0) {
            this.setState({ rawAssetList: data.assets }, () => {
              this.markAssetsOnMap();
            });
          } else {
            this.setState({ isLoading: false });
          }
        } else if (data.responseCode == 401) {
          localStorage.removeItem("backButton");
          localStorage.removeItem("appState");
          window.location.reload();
        } else {
          this.setState({ isLoading: false, alertbox: true, warnMessage: data.message });
        }
      });
    } catch (exception) {
      this.setState({
        isLoading: false,
        alertbox: true,
        warnMessage: exception,
      });
    }
  }

  getAssetsInBounds() {
    try {
      this.setState({ isFetchingAssets: true });
      let mapBounds = this._map?.getBounds();
      let mapZoomLevel = this._map?.getZoom();
      let requestBody = {
        northEast: mapBounds?.getNorthEast(),
        southWest: mapBounds?.getSouthWest(),
        zoom: mapZoomLevel,
      };
      requestAPICall("get_bounded_assets", {}, requestBody).then((data) => {
        if (data.responseCode == 200) {
          if (data.assets.length > 0) {
            this.setState({ rawAssetList: data.assets }, () => {
              this.markAssetsOnMap();
            });
          } else {
            this.setState({ isFetchingAssets: false });
          }
        } else if (data.responseCode == 401) {
          localStorage.removeItem("backButton");
          localStorage.removeItem("appState");
          window.location.reload();
        } else {
          this.setState({ isFetchingAssets: false, alertbox: true, warnMessage: data.message });
        }
      });
    } catch (exception) {
      this.setState({
        isFetchingAssets: false,
        alertbox: true,
        warnMessage: exception,
      });
    }
  }

  getClustersInBounds() {
    try {
      this.setState({ isFetchingAssets: true });
      let mapBounds = this._map?.getBounds();
      let mapZoomLevel = this._map?.getZoom();
      let requestBody = {
        northEast: mapBounds?.getNorthEast(),
        southWest: mapBounds?.getSouthWest(),
        zoom: mapZoomLevel,
      };
      requestAPICall("get_bounded_clusters", {}, requestBody).then((data) => {
        if (data.responseCode == 200) {
          if (data.clusters.length > 0) {
            this.markClustersOnMap(data.clusters);
          } else {
            this.setState({ isFetchingAssets: false });
          }
        } else if (data.responseCode == 401) {
          localStorage.removeItem("backButton");
          localStorage.removeItem("appState");
          window.location.reload();
        } else {
          this.setState({ isFetchingAssets: false, alertbox: true, warnMessage: data.message });
        }
      });
    } catch (exception) {
      this.setState({
        isFetchingAssets: false,
        alertbox: true,
        warnMessage: exception,
      });
    }
  }

  markAssetsOnMap() {
    try {
      let STATUS = "sas-status";
      let mappedAssets: any = [];
      let label_Text: any = "";

      try {
        clearInterval(this.checkIconsLoaded);
      } catch (error) {}

      this.state.rawAssetList?.map((asset: any) => {
        asset["azimuth"]?.forEach((sector: any) => {
          label_Text = { text: asset["assetIdentifierId"] };
          mappedAssets.push({
            assetType: asset["assetType"],
            assetId: asset["assetId"],
            sasStatus: asset["sasStatus"],
            assetOperationalStatus: asset["operationalStatus"],
            assetIdentifierId: asset["assetIdentifierId"],
            coOrdinates: {
              lat: parseFloat(asset.latitude),
              lng: parseFloat(asset.longitude),
            },
            name: asset["name"],
            labelText: label_Text,
            markerIcon: getSectorImage(
              {
                assetType: asset["assetType"],
                noOfSectors: asset["azimuth"].length,
                status: STATUS,
                azimuth: sector,
                assetId: asset["assetId"],
                action: "rotate",
                pciCardShow: this.state.pciCardShow,
                sasStatus: asset["sasStatus"],
              },
              () => {}
            ),
            azimuth: asset["azimuth"],
            sector_azimuth: sector[1],
          });
        });
      });

      this.setState({
        assetList: mappedAssets,
      });

      this.checkIconsLoaded = setInterval(() => {
        this.state.assetList?.map((asset: any) => {
          asset["azimuth"]?.forEach((sector: any) => {
            getSectorImage(
              {
                assetType: asset["assetType"],
                noOfSectors: asset["azimuth"].length,
                status: STATUS,
                azimuth: sector,
                assetId: asset["assetId"],
                action: "rotate",
                pciCardShow: this.state.pciCardShow,
                sasStatus: asset["sasStatus"],
              },
              () => {}
            );
          });
        });
      }, 10);
      this.setState({ isFetchingAssets: false, isLoading: false });
    } catch (exception) {
      this.setState({
        isLoading: false,
        isFetchingAssets: false,
        alertbox: true,
        warnMessage: exception,
      });
    }
  }

  markClustersOnMap(clusters: any) {
    try {
      let mappedClusters: any = [];

      clusters?.map((cluster: any) => {
        mappedClusters.push({
          coordinates: { lat: cluster.lat, lng: cluster.lng },
          icon: this.drawClusterIcon(),
          totalAssets: cluster.totalAssets,
        });
      });

      this.setState({
        clusterList: mappedClusters,
        isFetchingAssets: false,
        isLoading: false,
      });
    } catch (exception) {
      this.setState({
        isLoading: false,
        isFetchingAssets: false,
        alertbox: true,
        warnMessage: exception,
      });
    }
  }

  drawClusterIcon() {
    let icon: any = {
      url: ClusterCircle,
      scaledSize: new window.google.maps.Size(30, 30),
      anchor: new window.google.maps.Point(15, 15),
      labelAnchor: new window.google.maps.Point(15, 15),
      labelOrigin: new window.google.maps.Point(15, 15),
    };
    return icon;
  }

  clusterClicked(cluster: any) {
    this.changeMapCenter(cluster.coordinates.lat, cluster.coordinates.lng);
    this._map.setZoom(this._map?.getZoom() + 3);
  }

  decideClustersOrAssets() {
    if (this._map?.getZoom() <= 15) {
      this.getClustersInBounds();
    } else {
      this.getAssetsInBounds();
    }
  }

  setMeasureIcons(asset: any, icon: any) {
    asset["azimuth"]?.forEach((sector: any) => {
      getSectorImageMeasure(
        {
          assetType: asset["assetType"],
          noOfSectors: asset["azimuth"].length,
          status: "measure-angle",
          azimuth: sector,
        },
        async (data: any) => {
          this.setState(
            (prevState: any) => ({
              addAssetList: prevState.addAssetList.concat([
                {
                  assetType: asset["assetType"],
                  assetId: asset["assetId"],
                  assetName: asset["name"],
                  assetIdentifierId: asset["assetIdentifierId"],
                  coOrdinates: {
                    lat: parseFloat(asset["coOrdinates"]["lat"]),
                    lng: parseFloat(asset["coOrdinates"]["lng"]),
                  },
                  name: asset["name"],
                  markerIcon: data,
                  azimuth: asset["azimuth"],
                },
              ]),
            }),
            () => {
              if (icon === "m1" && data.url.length > 600) {
                clearInterval(this.callMeasureIcon1);
              } else if (icon === "m2" && data.url.length > 600) {
                clearInterval(this.callMeasureIcon2);
              }
            }
          );
        }
      );
    });
  }

  markerpos = (event: any, asset: any, text: any) => {
    const { latLng } = event;
    this.get_asset_details(asset.assetId, text);
    if (this.state.temp === 0) {
      this.marker_event1 = latLng;
      this.callMeasureIcon1 = setInterval(() => {
        this.setMeasureIcons(asset, "m1");
      }, 100);
      this.setState({
        param1: [parseFloat(latLng.lat().toFixed(6)), parseFloat(latLng.lng().toFixed(6))],
        temp: 1,
      });
      this.state.latlonglist.push({
        lat: parseFloat(latLng.lat().toFixed(6)),
        lng: parseFloat(latLng.lng().toFixed(6)),
      });
    } else if (this.state.temp === 1) {
      this.marker_event2 = latLng;
      this.callMeasureIcon2 = setInterval(() => {
        this.setMeasureIcons(asset, "m2");
      }, 100);
      this.setState({
        param2: [parseFloat(latLng.lat().toFixed(6)), parseFloat(latLng.lng().toFixed(6))],
        temp: 2,
      });
      this.state.latlonglist.push({
        lat: parseFloat(latLng.lat().toFixed(6)),
        lng: parseFloat(latLng.lng().toFixed(6)),
      });
      localStorage.setItem("outdoor_map_center_lat", latLng.lat().toFixed(6));
      localStorage.setItem("outdoor_map_center_lng", latLng.lng().toFixed(6));
      this.props.upadateMapCoordinates({
        latitude: latLng.lat().toFixed(6),
        longitude: latLng.lng().toFixed(6),
      });
      this.setShowLoading(true);
      this.measure_timer = setInterval(() => {
        if (this.state.radiation_center.length == 2) {
          this.childMeasure.current.graph();
          let requestBody = {
            param1: this.state.param1,
            param2: this.state.param2,
            rad1: this.state.radiation_center[0],
            rad2: this.state.radiation_center[1],
          };
          requestAPICall("azimuth", {}, requestBody).then((data) => {
            this.setShowLoading(false);
            if (data.responseCode == 200) {
              this.setState({
                azimuth1: data.azimuth1.toFixed(1),
                azimuth2: data.azimuth2.toFixed(1),
                tilt1: data.tilt1.toFixed(1),
                tilt2: data.tilt2.toFixed(1),
              });
              const element = data.properties;
              this.setState({ properties: element });
            } else {
              this.setState({
                warnMessage: data.message,
                alertbox: true,
              });
            }
          });
          requestAPICall("distance", {}, requestBody).then((data) => {
            this.setShowLoading(false);
            if (data.responseCode == 200) {
              this.setState({
                distance: data.distance,
              });
            } else {
              this.setState({
                warnMessage: data.message,
                alertbox: true,
              });
            }
          });
          this.setState(
            (prevState: any) => ({
              height1: (prevState.radiation_center[0] * 0.3048).toFixed(1),
              height2: (prevState.radiation_center[1] * 0.3048).toFixed(1),
            }),
            () => {
              this.setState(
                {
                  radiation_center: [],
                },
                () => {
                  clearInterval(this.measure_timer);
                }
              );
            }
          );
        }
      }, 2000);
    }
    this.inBetween = google.maps.geometry.spherical.interpolate(this.marker_event1, this.marker_event2, 0.5);
  };

  setSectorIcon = (noOfSec: any) => {
    if (noOfSec > 0) {
      this.setState((prevState: any) => ({
        addAssetList: [
          {
            assetType: "AP-outdoor",
            coOrdinates: {
              lat: parseFloat(prevState.lat),
              lng: parseFloat(prevState.lng),
            },
            name: "New Asset",
            markerIcon: getSectorImage(
              {
                assetType: "AP-outdoor",
                sectors: noOfSec,
                status: "create-list",
                azimuth: 0,
                pciCardShow: prevState.pciCardShow,
              },
              () => {}
            ),
          },
        ],
      }));
    } else {
      this.setState({ addAssetList: [] });
    }
  };
  assetCardDeatils(e: any, asset: any, text: any) {
    const { latLng } = e;
    if (text == "Info") {
      this.setState({
        assetIdentifierId: asset.assetIdentifierId,
        assetStatus: asset.assetOperationalStatus,
        assetName: asset.name,
      });
      this.setState({
        assetPointclickList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
    }
  }
  onHover = (e: any, asset: any) => {
    const { latLng } = e;
    if (isBrowser) {
      this.setState({
        onHover: true,
        assetIdentifierId: asset.assetIdentifierId,
        assetStatus: asset.assetOperationalStatus,
        assetName: asset.name,
        assethoverLatLongList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
    }
  };
  onMouseOut() {
    if (isBrowser) {
      this.setState({
        onHover: false,
      });
    }
  }
  assetDetail = (e: any, asset: any, text: any) => {
    if (isMobile) {
      this.assetCardDeatils(e, asset, text);
    }
    this.setState({
      locmark: false,
      ap_cpe_locamrk: false,
    });
    this.setState({
      selectedAssetId: asset.assetId,
    });
    this.setState(
      {
        center: {
          lat: parseFloat(asset.coOrdinates["lat"]),
          lng: parseFloat(asset.coOrdinates["lng"]),
        },
      },
      () => {
        this.props.upadateMapCoordinates({
          latitude: this.state.center["lat"],
          longitude: this.state.center["lng"],
        });
      }
    );
    localStorage.setItem("outdoor_map_center_lat", asset.coOrdinates["lat"]);
    localStorage.setItem("outdoor_map_center_lng", asset.coOrdinates["lng"]);

    this.props.updateAssetId(asset.assetId);
    if (isMobile) {
      this.setState({ card: 7 }, () => {
        this.childAssetDetails.current.get_asset_details();
      });
    } else {
      this.get_asset_details(asset.assetId, text);
    }
    if (text == "Info") {
      this.setState({ isAssetClickEnabled: true });
    }
    this._map?.setZoom(22);
  };
  asset_delete_confirm = () => {
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 5);
    this.setState({
      card: 1,
      isAssetClickEnabled: false,
    });
  };
  editAsset = (details: any) => {
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 5);
    this.setState(
      {
        properties: details["properties"],
        assetAction: details["assetAction"],
        noOfSectors: details["noOfSectors"],
        selectedAssetId: details["assetId"],
        sector_id_list: details["sector_id_list"],
        serial: details["serialId"],
        editSerial: details["editSerial"],
        isAssetDropEnabled: true,
      },
      () => {}
    );
    this.props.updateAssetAction("edit");
    if (details["assetType"] == 3) {
      this.setState({
        card: 4,
        typeId: 3,
        ap_cpe_locamrk: false,
      });
    } else if (details["assetType"] == 1 || details["assetType"] == 6) {
      this.setState({
        card: 2,
        ap_cpe_locamrk: false,
        typeId: 1,
      });
    }
  };
  set_asset_property = (asset_property: any, typeId: any) => {
    this.setState({
      asset_properties: asset_property,
      assetTypeId: typeId,
    });
  };
  set_ap2_propety = (asset_property: any) => {
    this.setState({
      add_ap2_properties: asset_property,
    });
  };

  set_ap3_property = (asset_property: any) => {
    this.setState({
      add_ap3_properties: asset_property,
    });
  };
  set_cp2_propety = (asset_property: any) => {
    this.setState({
      add_cp2_properties: asset_property,
    });
  };

  set_cp3_property = (asset_property: any) => {
    this.setState({
      add_cp3_properties: asset_property,
    });
  };

  next_step_cp2 = (asset_property: any) => {
    this.setState({
      card: 10,
      add_cp2_properties: asset_property,
    });
  };
  next_step_cp3 = () => {
    this.setState({
      card: 11,
    });
  };
  next_step_ap3 = () => {
    this.setState({
      card: 9,
    });
  };
  next_step_ap2 = (asset_property: any) => {
    this.setState({
      card: 8,
      add_ap2_properties: asset_property,
    });
  };
  setAssetDisabledState(state: any) {
    this.setState({ isAssetsDisabled: state });
  }
  hideOverlay() {
    this.setState({
      card: 1,
      marks: [],
      param1: "",
      param2: "",
      temp: 0,
      latlonglist: [],
      azimuth1: 0,
      azimuth2: 0,
      tilt1: 0,
      tilt2: 0,
      distance: 0,
      assetPointDropList: [],
      addAssetList: [],
      isAssetDropEnabled: false,
      measure_pair: [],
      rad_center: null,
      show_overlay: false,
      isAssetClickEnabled: false,
    });
    this.marker_event1 = null;
    this.marker_event2 = null;
    this.inBetween = null;
  }

  changeMapCenter(lat: any, lng: any) {
    try {
      this.setState(
        {
          center: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
          isAssetDropEnabled: false,
        },
        () => {
          this._map.setCenter(this.state.center);
          localStorage.setItem("outdoor_map_center_lat", this.state.center["lat"].toString());
          localStorage.setItem("outdoor_map_center_lng", this.state.center["lng"].toString());
          this.props.upadateMapCoordinates({
            latitude: this.state.center["lat"],
            longitude: this.state.center["lng"],
          });
        }
      );
    } catch (error) {}
  }

  azimuthClickedNewCbsd(lat: any, lng: any) {
    this.changeMapCenter(lat, lng);
    this.setState({
      azimuthInAction: true,
    });
    this.hideOverlay();
    this._map.setOptions({ draggable: false });
  }

  azimuthClickedNewCbsdWeb(lat: any, lng: any) {
    this.changeMapCenter(lat, lng);
    this.setState({
      azimuthInActionWeb: true,
      markerVisible: false,
    });
    this._map.setOptions({ draggable: false });
  }

  azimuthClickedNewCbsdAcpmob(lat: any, lng: any) {
    this.changeMapCenter(lat, lng);
    this.setState({
      azimuthInAction: true,
      markerVisible: false,
    });
    this.hideOverlay();
    this._map.setOptions({ draggable: false });
  }

  zoomIncrease = () => {
    let currentzoom = this._map?.getZoom();
    this._map?.setZoom(currentzoom + 1);
  };
  zoomDecrease = () => {
    let currentzoom = this._map?.getZoom();
    this._map?.setZoom(currentzoom - 1);
  };

  centerPinDrop = () => {
    this.setState({ isAssetDropEnabled: true });

    let lat = this._map?.getCenter()?.lat()?.toFixed(6);
    let long = this._map?.getCenter()?.lng()?.toFixed(6);

    this.reduxStore["coordinates"]["latitude"] = lat;
    this.reduxStore["coordinates"]["longitude"] = long;

    this.setState({
      assetPointDropList: [
        {
          lat: parseFloat(lat),
          lng: parseFloat(long),
        },
      ],
      lat: lat,
      lng: long,
      para: [lat, long],
    });
    localStorage.setItem("outdoor_map_center_lat", lat);
    localStorage.setItem("outdoor_map_center_lng", long);
    this.props.upadateMapCoordinates({ latitude: lat, longitude: long });
  };

  renderSwitch() {
    let param = this.state.card;
    switch (param) {
      case 1:
        return (
          <Dashboard
            centerPinDrop={() => {
              this.centerPinDrop();
            }}
            azimuthActionChange={() => {
              this.setState({
                azimuthInAction: false,
                show_overlay: true,
                card: 2,
                isAzimuthSet: true,
              });
              this._map.setOptions({ draggable: true });
            }}
            azimuthActionNotChange={() => {
              this.setState({
                azimuthInAction: false,
                show_overlay: true,
                card: 2,
                isAzimuthSet: false,
              });
              this._map.setOptions({ draggable: true });
            }}
            azimuthActionChangeAcpmob={() => {
              this.setState({
                azimuthInAction: false,
                show_overlay: true,
                card: 7,
                isAzimuthSet: true,
                markerVisible: true,
              });
              this._map.setOptions({ draggable: true });
            }}
            azimuthActionNotChangeAcpmob={() => {
              this.setState({
                azimuthInAction: false,
                show_overlay: true,
                card: 7,
                isAzimuthSet: false,
                markerVisible: true,
              });
              this._map.setOptions({ draggable: true });
            }}
            azimuthAction={this.state.azimuthInAction}
            ref={this.dashRef}
            searchfn={this.search}
            set_subcard={(value: any) => {
              this.setState({ subcard: value });
            }}
            addAP={this.addAP}
            addCPE={this.addCPE}
            measure={this.measure}
            setAddress={(address: any, locmark: any) => {
              this.setAddress(address, locmark);
            }}
            latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => {
              this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset);
            }}
            zoomPlus={this.zoomIncrease}
            zoomMinus={this.zoomDecrease}
            changeMapToSatellite={this.changeMapType}
            calculate={this.calculate}
            setCurrentPosition={() => this.curren_geo_position()}
            enableAssetDrop={(st: any) => {
              this.enableAssetDrop(st);
            }}
            changeMapCenter={(lat: any, lng: any) => {
              this.changeMapCenter(lat, lng);
            }}
            polygon_set={(val: any) => {
              this.displayKml(val);
            }}
            heatmap_set={(val: any) => {
              this.displayHeatmap(val);
            }}
            sas_set={(val: any) => {
              this.displaySASstatus(val);
            }}
          />
        );
      case 2:
        if (isBrowser) {
          return (
            <AddAP
              changeAziToDefault={() => {
                this.setState({
                  isAzimuthSet: false,
                  azimuthInActionWeb: false,
                });
                this.removeAzimuthFromRedux();
              }}
              isAzimuthSet={this.state.isAzimuthSet}
              azimuthClickedWeb={(lat: any, lng: any) => {
                this.azimuthClickedNewCbsdWeb(lat, lng);
              }}
              changeMapToSatellite={this.changeMapType}
              ref={this.childAP}
              setCurrentPosition={() => this.curren_geo_position()}
              loc={this.state.locmark}
              serial={this.state.serial}
              typeId={this.state.typeId}
              latlong={this.state.para}
              assetAction={this.state.assetAction}
              assetId={1}
              properties={this.state.properties}
              parentState={this.state}
              selectedAssetId={this.state.selectedAssetId}
              back={this.dash}
              name={this.state.username}
              markAssetsOnMap={(id: any) => {
                this.refreshAsset(id);
              }}
              setSector={(e: any) => {
                this.setSectorIcon(e);
              }}
              sector_id_list={this.state.sector_id_list}
              get_asset_details={(selectAssetId: any) => this.get_asset_details(selectAssetId, "Info")}
              editSerial={this.state.editSerial}
              next_step={this.next_step_ap2}
              set_ap2_proeprty={this.set_ap2_propety}
              set_ap3_property={this.set_ap3_property}
              set_asset_property={this.set_asset_property}
              setAssetDisabledState={(state: any) => {
                this.setAssetDisabledState(state);
              }}
              changeMapCenter={(lat: any, lng: any) => {
                this.changeMapCenter(lat, lng);
              }}
            />
          );
        }
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.show_overlay === true ? "block" : "none",
              }}
            >
              <AddAP
                changeAziToDefault={() => {
                  this.setState({
                    azimuthInAction: false,
                    isAzimuthSet: false,
                  });
                }}
                isAzimuthSet={this.state.isAzimuthSet}
                hideOverlay={this.hideOverlay}
                serial={this.state.serial}
                typeId={this.state.typeId}
                latlong={this.state.para}
                assetAction={this.state.assetAction}
                assetId={1}
                properties={this.state.properties}
                parentState={this.state}
                selectedAssetId={this.state.selectedAssetId}
                back={this.dash}
                name={this.state.username}
                markAssetsOnMap={(id: any) => {
                  this.refreshAsset(id);
                }}
                setSector={(e: any) => {
                  this.setSectorIcon(e);
                }}
                sector_id_list={this.state.sector_id_list}
                set_asset_property={this.set_asset_property}
                azimuthClickedGoBack={(lat: any, lng: any) => {
                  this.azimuthClickedNewCbsd(lat, lng);
                }}
                changeMapCenter={(lat: any, lng: any) => {
                  this.changeMapCenter(lat, lng);
                }}
                setAssetDisabledState={(state: any) => {
                  this.setAssetDisabledState(state);
                }}
              />
            </div>
          );
        break;
      case 3:
        if (isBrowser)
          return (
            <Search1
              back={this.dash}
              setAddress={(address: any, locmark: any) => {
                this.setAddress(address, locmark);
              }}
              searchLatLng={(latLng: any) => {
                this.setLatLngSearch(latLng);
              }}
              latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => {
                this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset);
              }}
            />
          );
        if (isMobile) return <Redirect from="/map" to="/search" />;
        break;
      case 4:
        if (isBrowser)
          return (
            <AddCPE
              assetAction={this.state.assetAction}
              setCurrentPosition={() => this.curren_geo_position()}
              selectedAssetId={this.state.selectedAssetId}
              markAssetsOnMap={(status: any) => {
                this.getAssetsInBounds();
              }}
              ref={this.childCPE}
              typeId={this.state.typeId}
              select_floor_id={0}
              buildid={0}
              next_step={this.next_step_cp2}
              set_cp2_property={this.set_cp2_propety}
              set_cp3_property={this.set_cp3_property}
              set_asset_property={this.set_asset_property}
              latlong={this.state.para}
              properties={this.state.properties}
              back={this.dash}
              editSerial={this.state.editSerial}
              setAssetDisabledState={(state: any) => {
                this.setAssetDisabledState(state);
              }}
              changeMapCenter={(lat: any, lng: any) => {
                this.changeMapCenter(lat, lng);
              }}
              get_asset_details={(selectAssetId: any) => this.get_asset_details(selectAssetId, "Info")}
            />
          );
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.show_overlay === true ? "block" : "none",
              }}
            >
              <AddCPE
                hideOverlay={this.hideOverlay}
                assetAction={this.state.assetAction}
                selectedAssetId={this.state.selectedAssetId}
                markAssetsOnMap={(status: any) => {
                  this.getAssetsInBounds();
                }}
                typeId={this.state.typeId}
                select_floor_id={0}
                buildid={0}
                set_asset_property={this.set_asset_property}
                latlong={this.state.para}
                properties={this.state.properties}
                setAssetDisabledState={(state: any) => {
                  this.setAssetDisabledState(state);
                }}
                changeMapCenter={(lat: any, lng: any) => {
                  this.changeMapCenter(lat, lng);
                }}
              />
            </div>
          );
        break;
      case 5:
        return (
          <Measure
            back={this.dash}
            setCurrentPosition={() => this.curren_geo_position()}
            dist={this.state.distance}
            ref={this.childMeasure}
            measure_pair={this.state.latlonglist}
            distance={this.state.distance}
            temp={this.state.temp}
          />
        );
      case 6:
        return (
          <Calculate
            back={this.dash}
            setCurrentPosition={() => this.curren_geo_position()}
            place={this.onMapClick}
            markers={this.state.marks}
            latitude={this.state.lat}
            longitude={this.state.lng}
            radiation_center={this.state.rad_center}
            changeProps={(target: any, value: any) => {
              this.setState({ [target]: value });
            }}
          />
        );
      case 7:
        if (isBrowser)
          return (
            <APdetails1
              ref={this.apdetails_ref}
              refreshAsset={(id: any) => this.refreshAsset(id)}
              back={() => {
                this.dash();
              }}
              calculate={this.calculate}
              set_values={(lat: any, long: any, radc: any) => {
                this.set_calculate(lat, long, radc);
              }}
              changeMapToSatellite={this.changeMapType}
              setCurrentPosition={() => this.curren_geo_position()}
              asset_delete_confirm={this.asset_delete_confirm}
              asset_details={this.state.asset_details}
              editAsset={(details: any) => {
                this.editAsset(details);
              }}
            />
          );
        if (isMobile)
          return (
            <APdetails
              vanishInfoPopup={() => {
                this.setState({ isAssetClickEnabled: false });
              }}
              zoomPlus={this.zoomIncrease}
              zoomMinus={this.zoomDecrease}
              resetToOutdoor={() => this.resetState()}
              azimuthClickedGoBack={(lat: any, lng: any) => {
                this.azimuthClickedNewCbsdAcpmob(lat, lng);
              }}
              changeMapToSatellite={this.changeMapType}
              next6={this.asset_delete_confirm}
              placeMarker={() => {
                this.setState({ isAssetDropEnabled: true });
              }}
              removeMarker={() => {
                this.setState({ isAssetDropEnabled: false });
              }}
              refreshAsset={(id: any) => this.refreshAsset(id)}
              back={() => {
                this.dash1();
              }}
              set_subcard={(value: any) => {
                this.setState({ subcard: value });
              }}
              setCurrentPosition={() => this.curren_geo_position()}
              addAP={this.addAP}
              addCPE={this.addCPE}
              measure={this.measure}
              enableAssetDrop={(st: any) => {
                this.enableAssetDrop(st);
              }}
              calculate={this.calculate}
              set_values={(lat: any, long: any, radc: any) => {
                this.set_calculate(lat, long, radc);
              }}
              ref={this.childAssetDetails}
              changeMapCenter={(lat: any, lng: any) => {
                this.changeMapCenter(lat, lng);
              }}
              updateSearchText={(value: any) => this.updateSearchText(value)}
              setAddress={(address: any, locmark: any) => {
                this.setAddress(address, locmark);
              }}
              latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => {
                this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset);
              }}
            />
          );
        break;
      case 8:
        return (
          <AddAP2
            next_step3={this.next_step_ap3}
            back={this.addAP}
            asset_properties={this.state.asset_properties}
            asset_property={this.state.add_ap2_properties}
            set_asset_property={this.set_asset_property}
          ></AddAP2>
        );
      case 9:
        return (
          <AddAP3
            asset_property={this.state.add_ap3_properties}
            back={this.next_step_ap2}
            serial={this.state.serial}
            typeId={this.state.assetTypeId}
            asset_properties={this.state.asset_properties}
          >
            {" "}
          </AddAP3>
        );
      case 10:
        return (
          <ADDCP2
            next_step3={this.next_step_cp3}
            asset_properties={this.state.asset_properties}
            asset_property={this.state.add_cp2_properties}
            set_asset_property={this.set_asset_property}
          ></ADDCP2>
        );
      case 11:
        return <ADDCP3 asset_property={this.state.add_cp3_properties} asset_properties={this.state.asset_properties}></ADDCP3>;
    }
  }

  handleClick = (event: any) => {
    const { latLng } = event;
    this.reduxStore["coordinates"]["latitude"] = event.latLng.lat().toFixed(6);
    this.reduxStore["coordinates"]["longitude"] = event.latLng.lng().toFixed(6);
    console.log("redux on drag : ", this.reduxStore["coordinates"]);

    this.setState({
      lat: event.latLng.lat().toFixed(6),
      lng: event.latLng.lng().toFixed(6),
    });

    this.setState({
      para: [latLng.lat().toFixed(6), latLng.lng().toFixed(6)],
      currentMapClickEvent: event,
      locmark: false,
      ap_cpe_locamrk: false,
    });
    this.props.upadateGMapClickEvent(event);
    localStorage.setItem("outdoor_map_center_lat", latLng.lat().toFixed(6));
    localStorage.setItem("outdoor_map_center_lng", latLng.lng().toFixed(6));
    this.props.upadateMapCoordinates({
      latitude: latLng.lat().toFixed(6),
      longitude: latLng.lng().toFixed(6),
    });
    if (isMobile) {
      this.setState({
        assetPointDropList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
    }
    if (this.state.card == 4) {
      if (isBrowser) {
        this.setState({
          assetPointDropList: [
            {
              lat: parseFloat(latLng.lat().toFixed(6)),
              lng: parseFloat(latLng.lng().toFixed(6)),
            },
          ],
        });

        this.childCPE.current.componentRef.current.disableButton();
      }
    } else if (this.state.card == 2) {
      if (isBrowser) {
        this.setState({
          assetPointDropList: [
            {
              lat: parseFloat(latLng.lat().toFixed(6)),
              lng: parseFloat(latLng.lng().toFixed(6)),
            },
          ],
        });
        this.childAP.current.componentRef.current.disableButton();
      }
    }
  };

  options = {
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "20px",
      },
    ],
    strokeColor: "#FF9445",
    strokeOpacity: 0,
    strokeWeight: 2,
    fillColor: "#FF9445",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: true,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  mapZoomChanged = () => {
    try {
      if (Claims.getUserRole() !== "Installer" && this._map != undefined) {
        this.decideClustersOrAssets();
      }
      google.maps.event.trigger(this._map, "resize");
      this.setState({
        mapZoom: this._map?.getZoom(),
      });
    } catch (exception) {
      this.setState({
        alertbox: true,
        warnMessage: "Error while loading map. " + exception,
      });
    }
  };

  displayKml = (val: any) => {
    if (val == null) {
      this.setState({
        polygon_files: null,
      });
    } else {
      let requestBody = {
        polygon_id: val,
      };
      requestAPICall("display_polygon_boundaries", {}, requestBody).then((data) => {
        if (data.responseCode == 200) {
          this.setState(
            {
              polygon_files: data.polygon_files,
            },
            () => {}
          );
        } else {
          this.setState({
            alertbox: true,
            warnMessage: data.message,
          });
        }
      });
    }
  };

  kmlMount = (kml: any) => {};
  kmlUnmount = (kml: any) => {};
  onMapDragEnd = () => {
    try {
      if (Claims.getUserRole() !== "Installer" && this._map != undefined) {
        this.decideClustersOrAssets();
      }
      localStorage.setItem("outdoor_map_center_lat", this._map.getCenter().lat().toFixed(6));
      localStorage.setItem("outdoor_map_center_lng", this._map.getCenter().lng().toFixed(6));
    } catch (exception) {
      this.setState({
        alertbox: true,
        warnMessage: "Error while loading map. " + exception,
      });
    }
  };

  updateSearchText = (value: any) => {
    this.props.updateSearchText(value);
  };

  clusterMarkerHtml = (cluster: any) => {
    return (
      <Marker
        key={`${cluster.coordinates.toString()}-cluster`}
        position={cluster.coordinates}
        visible={this.state.markerVisible}
        icon={cluster.icon}
        label={{
          text: cluster.totalAssets.toString(),
          color: "#fff",
          fontSize: "11px",
          fontWeight: "bold",
        }}
        draggable={false}
        onClick={(e: any) => {
          this.clusterClicked(cluster);
        }}
      />
    );
  };

  assetMarkerHtml = (asset: any) => {
    return (
      <Marker
        position={asset.coOrdinates}
        visible={this.state.markerVisible}
        icon={asset.markerIcon}
        label={asset.labelText}
        title={this.state.isSafari ? "asset" + asset["assetId"] + asset["sector_azimuth"] : ""}
        draggable={false}
        onMouseOut={this.onMouseOut}
        onMouseOver={(e: any) => this.onHover(e, asset)}
        onClick={(e: any) => {
          this.state.card === 5 ? this.markerpos(e, asset, "Measure") : this.assetDetail(e, asset, "Info");
        }}
      />
    );
  };

  assetDotHtml = (asset: any) => {
    return (
      <Marker
        position={asset.coOrdinates}
        visible={this.state.markerVisible}
        icon={asset.markerIcon}
        label={asset.labelText}
        title={this.state.isSafari ? "asset" + asset["assetId"] + asset["sector_azimuth"] : ""}
        draggable={false}
        onMouseOut={this.onMouseOut}
        onMouseOver={(e: any) => this.onHover(e, asset)}
        onClick={(e: any) => {
          this.state.card === 5 ? this.markerpos(e, asset, "Measure") : this.assetDetail(e, asset, "Info");
        }}
      />
    );
  };
  render() {
    return (
      <div className="container-fluid dash p-0" style={this.state.azimuthInAction ? { position: "fixed" } : {}}>
        <div className="row google-map">
          <div className="col-md-12">
            {this.state.center != null && this.state.center != undefined ? (
              <GoogleMap
                id="searchbox"
                clickableIcons={false}
                mapContainerStyle={
                  localStorage.getItem("platform") == "ios"
                    ? {
                        height: "calc(100vh - calc(58px + env(safe-area-inset-top)))",
                      }
                    : { height: "calc(100vh - 58px)" }
                }
                zoom={18}
                onClick={this.handleClick}
                onTilesLoaded={this.handleTilesLoad}
                center={this.state.center}
                onLoad={this.onMapLoad}
                onDragEnd={this.onMapDragEnd}
                onDrag={() => {
                  google.maps.event.trigger(this._map, "resize");
                }}
                onZoomChanged={this.mapZoomChanged}
                options={
                  isBrowser
                    ? {
                        minZoom: 4,
                        zoomControl: true,
                        disableDefaultUI: true,
                        styles: [
                          {
                            featureType: "poi",
                            stylers: [{ visibility: "off" }],
                          },
                        ],
                      }
                    : {
                        minZoom: 4,
                        disableDefaultUI: true,
                        styles: [
                          {
                            featureType: "poi",
                            stylers: [{ visibility: "off" }],
                          },
                        ],
                      }
                }
              >
                {this.state.azimuthInActionWeb ? (
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        right: "6%",
                        top: "50%",
                        transform: "translate(0%, -50%)",
                        display: "flex",
                        width: "70px",
                        flexDirection: "column",
                      }}
                    >
                      <button
                        title="Capture Azimuth"
                        tabIndex={0}
                        style={{
                          cursor: "pointer",
                          borderStyle: "solid",
                          borderColor: "#2559b2",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 20px #cbcccc",
                          borderRadius: "100%",
                          marginBottom: "25px",
                          width: "50px",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          this.azimuthConfirmClicked();
                        }}
                      >
                        <i className="material-icons-outlined" style={{ color: "#2559b2", fontSize: "30px" }}>
                          check
                        </i>
                      </button>
                      <div
                        style={{
                          borderRadius: "15px",
                          boxShadow: "0px 0px 20px #cbcccc",
                        }}
                      >
                        <button
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            width: "-webkit-fill-available",
                            backgroundColor: "#2559b2",
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                          }}
                          onMouseDown={this.azimuthUpHoldStart}
                          onMouseUp={this.azimuthHoldStop}
                          onMouseLeave={this.azimuthHoldStop}
                        >
                          <i
                            className="material-icons-outlined"
                            style={{
                              cursor: "pointer",
                              marginTop: "10px",
                              marginBottom: "10px",
                              color: "white",
                            }}
                          >
                            add
                          </i>
                        </button>
                        <div
                          style={{
                            backgroundColor: "white",
                            width: "-webkit-fill-available",
                            textAlign: "center",
                          }}
                        >
                          <label
                            style={{
                              marginTop: "15px",
                              marginBottom: "15px",
                              fontWeight: "normal",
                              fontSize: "18px !important",
                            }}
                          >
                            {this.state.currentAzimuthAngle} N
                          </label>
                        </div>
                        <button
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            width: "-webkit-fill-available",
                            backgroundColor: "#2559b2",
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            borderBottomLeftRadius: "15px",
                            borderBottomRightRadius: "15px",
                          }}
                          onMouseDown={this.azimuthDownHoldStart}
                          onMouseUp={this.azimuthHoldStop}
                          onMouseLeave={this.azimuthHoldStop}
                        >
                          <i
                            className="material-icons-outlined"
                            style={{
                              cursor: "pointer",
                              marginTop: "10px",
                              marginBottom: "10px",
                              color: "white",
                            }}
                          >
                            remove
                          </i>
                        </button>
                      </div>
                      <button
                        title="Reset"
                        style={{
                          cursor: "pointer",
                          borderStyle: "solid",
                          borderColor: "#FF4324",
                          backgroundColor: "white",
                          boxShadow: "0px 0px 20px #cbcccc",
                          borderRadius: "100%",
                          marginTop: "25px",
                          width: "50px",
                          height: "50px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          this.azimuthBackClicked();
                        }}
                      >
                        <i className="material-icons-outlined" style={{ color: "#FF4324", fontSize: "30px" }}>
                          restart_alt
                        </i>
                      </button>
                    </div>
                    <div
                      id="cbsd-sector-image-web"
                      style={{
                        position: "fixed",
                        left: "49.8%",
                        top: "53%",
                        transform: "translate(-50%, -50%) rotate(" + this.state.currentAzimuthAngle + "deg)",
                      }}
                      draggable="false"
                    >
                      <img
                        alt="cbsd-sector-2"
                        src={
                          this.reduxStore["currentAssetProperties"].filter((property: any) => property.propertyName == "ap_mounting_type")[0].value ==
                          "ROE"
                            ? cbsdSectorRoeImage
                            : cbsdSectorImage
                        }
                      />
                    </div>
                  </div>
                ) : null}
                <div>{this.renderSwitch()}</div>
                {this.state.assetCardShow &&
                  this.state.clusterList &&
                  Claims.getUserRole() != "Installer" &&
                  (this.state.mapZoom <= 15 || this._map?.getZoom() <= 15) &&
                  this.state.clusterList?.map((cluster: any) => {
                    return this.clusterMarkerHtml(cluster);
                  })}
                {this.state.assetCardShow &&
                  this.state.assetList &&
                  Claims.getUserRole() != "Installer" &&
                  (this.state.mapZoom > 15 || this._map?.getZoom() > 15) &&
                  this.state.assetList?.map((asset: any, index: any) => {
                    return this.assetMarkerHtml(asset);
                  })}
                {this.state.assetCardShow &&
                  this.state.assetList &&
                  Claims.getUserRole() == "Installer" &&
                  this.state.assetList?.map((asset: any, index: any) => {
                    return this.assetDotHtml(asset);
                  })}
                {this.state.addAssetList?.map((mark: any, index: any) => (
                  <Marker position={mark.coOrdinates} key={`${index.toString()}-add-asset`} icon={mark.markerIcon} draggable={false} />
                ))}
                {this.state.locmark === true ? <Marker position={this.state.center} onClick={this.handleClick} /> : ""}{" "}
                {this.state.ap_cpe_locamrk === true ? (
                  <Marker
                    position={this.state.center}
                    onClick={(e: any) => {
                      this.state.card === 5
                        ? this.markerpos(e, this.state.search_asset, "Measure")
                        : this.assetDetail(e, this.state.search_asset, "Info");
                    }}
                  />
                ) : (
                  ""
                )}
                {this.state.latlonglist?.map((mark: any, index: any) => (
                  <InfoWindow position={mark} key={`${index.toString()}-measure-info-window`}>
                    <div>
                      {index == 0 ? (
                        <div>
                          <div className="font14 fontw400">
                            <span>
                              Azimuth: <span style={{ color: "#FF9445" }}>{this.state.azimuth1}° </span>{" "}
                            </span>
                          </div>
                          <div className="font14 fontw400 m-t-6">
                            <span>
                              Tilt: <span style={{ color: "#FF9445" }}>{this.state.tilt1}</span>
                            </span>
                          </div>
                          <div className="font14 fontw400 m-t-6">
                            <span>
                              Height: <span style={{ color: "#FF9445" }}>{this.state.height1} m</span>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="font14 fontw400">
                            <span>
                              Azimuth: <span style={{ color: "#FF9445" }}>{this.state.azimuth2}° </span>{" "}
                            </span>
                          </div>
                          <div className="font14 fontw400 m-t-6">
                            <span>
                              Tilt: <span style={{ color: "#FF9445" }}>{this.state.tilt2}</span>
                            </span>
                          </div>
                          <div className="font14 fontw400 m-t-6">
                            <span>
                              Height: <span style={{ color: "#FF9445" }}>{this.state.height2} m</span>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </InfoWindow>
                ))}
                {this.state.isAssetDropEnabled &&
                  this.state.assetPointDropList?.map((mark: any, index: any) => (
                    <Marker
                      position={mark}
                      key={`${index.toString()}-drag-drop-asset`}
                      icon={markerIcon}
                      draggable={true}
                      onDragEnd={this.handleClick}
                    />
                  ))}
                {this.state.isAssetClickEnabled &&
                  this.state.assetPointclickList?.map((mark: any, index: any) => (
                    <InfoWindow position={mark} key={`${index.toString()}-point-click-list`}>
                      <div>
                        <div>
                          <button className="material-icons infowindowclose" style={{ background: "none" }} onClick={() => this.resetState()}>
                            clear
                          </button>
                        </div>
                        {/* StatusMobSmall */}
                        <div className="font13 fontw400 m-t-5">
                          <span style={{ color: "#FF9445" }}>Tracking ID </span>
                          <div>
                            <span style={{ color: "#000000" }}>{this.state.assetIdentifierId} </span>
                          </div>
                        </div>
                        <div className="font13 fontw400 m-t-5">
                          <span style={{ color: "#FF9445" }}>CBRS Node Name </span>
                          <div>
                            <span style={{ color: "#000000" }}>{this.state.assetName} </span>{" "}
                          </div>
                        </div>
                        <div className="font13 fontw400 m-t-5">
                          <span style={{ color: "#FF9445" }}>Status </span>
                          <div>
                            <span style={{ color: "#000000" }}>{this.state.assetStatus}</span>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  ))}
                {this.state.onHover &&
                  this.state.assethoverLatLongList?.map((mark: any, index: any) => (
                    <InfoWindow position={mark} key={`${index.toString()}-hover-info-window`}>
                      <div onMouseOut={this.onMouseOut} onBlur={this.onMouseOut}>
                        {/* StatusWebSmall */}
                        <div className="font13 fontw400 m-t-5">
                          <span style={{ color: "#FF9445" }}>Tracking ID </span>
                          <div>
                            <span style={{ color: "#000000" }}>{this.state.assetIdentifierId} </span>
                          </div>
                        </div>
                        <div className="font13 fontw400 m-t-5">
                          <span style={{ color: "#FF9445" }}>CBRS Node Name </span>
                          <div>
                            <span style={{ color: "#000000" }}>{this.state.assetName} </span>{" "}
                          </div>
                        </div>
                        <div className="font13 fontw400 m-t-5">
                          <span style={{ color: "#FF9445" }}>Status </span>
                          <div>
                            <span style={{ color: "#000000" }}>{this.state.assetStatus}</span>
                          </div>
                        </div>
                      </div>
                    </InfoWindow>
                  ))}
                {this.state.marks?.map((mark: any, index: any) => (
                  <Marker
                    position={mark}
                    key={`${index.toString()}-drag-and-drop`}
                    icon={markerIcon}
                    draggable={true}
                    onDragEnd={this.onMarkerDragEnd}
                  />
                ))}
                {this.state.polygon_files != undefined && this.state.polygon_files != null
                  ? this.state.polygon_files?.map((file_name: any, index: any) => (
                      <KmlLayer
                        key={`${index.toString()}-kml-layer`}
                        url={"http://democ3spectra.k8s.whirldatascience.com/kml/" + file_name}
                        onLoad={this.kmlMount}
                        onUnmount={this.kmlUnmount}
                      />
                    ))
                  : null}
                <Marker
                  position={{
                    lat: this.state.gps_lat,
                    lng: this.state.gps_lng,
                  }}
                  icon={gps_icon}
                />
                <Circle
                  center={{ lat: this.state.gps_lat, lng: this.state.gps_lng }}
                  options={circleOptions}
                  visible={this.state.accuracy_visible}
                  radius={this.state.gps_accuracy}
                />
                {this.state.temp == 2 ? (
                  <Polyline
                    options={this.options}
                    path={[
                      { lat: this.state.param1[0], lng: this.state.param1[1] },
                      { lat: this.state.param2[0], lng: this.state.param2[1] },
                    ]}
                  />
                ) : null}
                {this.inBetween ? (
                  <InfoWindow position={this.inBetween}>
                    <div style={{}}>
                      <div className="font14 fontw400">
                        <span>
                          {`Distance: `}
                          <span style={{ color: "#FF9445" }}>{`${this.state.distance} m`}</span>
                        </span>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
                <div style={this.state.isFetchingAssets ? { visibility: "visible" } : { visibility: "hidden" }} className="outdoor-loader center">
                  <span></span>
                </div>
              </GoogleMap>
            ) : null}
          </div>
        </div>
        <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />

        <IonLoading isOpen={this.state.isLoading} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Outdoor));
