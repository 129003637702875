import { IonAlert, IonContent, IonLoading, IonPage, withIonLifeCycle } from "@ionic/react";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import Back from "../assets/img/go-back-icon-img.png";
import Lockicon from "../assets/img/password-img-icon-img.png";
import { requestAPICall } from "../service/apiHandler";
import Modal from "./modal";
import "./profile-info.css";

class Profile extends Component {
  state: any = {};
  props: any = {};
  constructor(props: any) {
    super(props);
    this.state = {
      cpiId: "",
      cpiName: "",
      certPassword: "",
      p12cert: [],
      p12Path: "",
      certificateSubmitted: false,
      show: false,
      userList: {},
      username: "",
      userId: 0,
      userEmail: "",
      currentPassword: "",
      newPassword: "",
      confirm_newPassword: "",
      // images: [],
      userImage: "",
      auto_check: false,
      manual_check: true,
      warnMessage: "",
      alertbox: false,
      is_loader: false,
      isLoading: false,
      files1: [],
      currentPassword_type: "password",
      new_password_type: "password",
      confirm_new_password: "password",
      certificate_password_type: "password",
    };
  }

  showModal = (e: any) => {
    this.setState({
      card: e.target.id,
      show: true,
      currentPassword: "",
      newPassword: "",
      confirm_newPassword: "",
    });
    console.log(this.state.show);
  };
  hideModal = () => {
    this.setState({
      card: "",
      newPassword: "",
      confirmnewPassword: "",
      show: false,
    });
  };

  handleClick = (e: any, type: any) => {
    let passType: string = "text";
    if (type === "current_password") {
      if (this.state.currentPassword_type === "text") {
        passType = "password";
      }
      this.setState({
        currentPassword_type: passType,
      });
    } else if (type === "new_password") {
      if (this.state.new_password_type === "text") {
        passType = "password";
      }
      this.setState({
        new_password_type: passType,
      });
    } else if (type === "confirm_new_password") {
      if (this.state.confirm_new_password_type === "text") {
        passType = "password";
      }
      this.setState({
        confirm_new_password_type: passType,
      });
    } else if (type === "certificate_password") {
      if (this.state.certificate_password_type === "text") {
        passType = "password";
      }
      this.setState({
        certificate_password_type: passType,
      });
    }
  };

  componentDidMount() {
    console.log("ionview");
    this.setState({
      cpiName: "",
      cpiId: "",
      certPassword: "",
    });
    this.getUserProfile();
  }

  callchangePasswordAPI = () => {
    this.setState({
      is_loader: true,
    });
    this.setState((prevValues: any) => ({
      userList: { ...prevValues.userList, userEmail: prevValues.userEmail },
    }));
    let requestBody = {
      username: this.state.userList["userName"],
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      email: this.state.userList["userEmail"],
      resetEmpPassword: false,
    };
    console.log(requestBody);
    if (this.state.newPassword != this.state.confirm_newPassword) {
      this.setState({
        warnMessage: "New password and confirm password doesn't match",
        alertbox: true,
      });
      return;
    }

    if (this.state.auto_check === true) {
      requestAPICall("change_random_password", {}, requestBody).then((data) => {
        this.setState({
          is_loader: false,
        });
        if (data.responseCode == 200) {
          this.setState({
            warnMessage: "Your auto generated password has been sent to your email",
            alertbox: true,
          });
        } else {
          this.setState({
            warnMessage: "Failed to update your password",
            alertbox: true,
          });
        }
      });
      this.hideModal();
    } else if (this.state.manual_check === true) {
      requestAPICall("change_password", {}, requestBody).then((data) => {
        this.setState({
          is_loader: false,
          warnMessage: data.message,
          alertbox: true,
        });
      });

      this.hideModal();
    }
  };

  callSaveProfileAPI = () => {
    this.setState((prevValues: any) => ({
      userList: { ...prevValues.userList, userEmail: prevValues.userEmail },
    }));
    if (this.state.cpiName != null && this.state.cpiId != null && this.state.cpiName != "" && this.state.cpiId != "") {
      this.setState((prevValues: any) => ({
        userList: { ...prevValues.userList, cpiId: prevValues.cpiId, cpiName: prevValues.cpiName },
      }));
    }
    if (this.state.p12cert.length > 0) {
      let picReader = new FileReader();
      let fileName = this.state.p12cert[0].name;
      let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
      picReader.onload = (e) => {
        let requestBody = {
          userEmail: this.state.userList["userEmail"],
          cpiId: this.state.cpiId,
          cpiName: this.state.cpiName,
          certPassword: this.state.certPassword,
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
          type: "p12",
          fileUpload: true,
        };

        requestAPICall("update_user_profile", {}, requestBody).then((data) => {
          console.log(data);
          if (data.responseCode === 200) {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
            });
          } else {
            this.setState({
              warnMessage: "Profile update failed",
              alertbox: true,
            });
          }
        });
      };
      picReader.readAsDataURL(this.state.p12cert[0]);
    } else {
      let requestBody = {
        userEmail: this.state.userEmail,
        // 'username': this.state.userName,
        cpiId: this.state.cpiId,
        cpiName: this.state.cpiName,
        certPassword: this.state.certPassword,
        fileUpload: false,
      };
      console.log(requestBody);
      requestAPICall("update_user_profile", {}, requestBody).then((data) => {
        console.log(data);
        if (data.responseCode === 200) {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
          });
        } else {
          this.setState({
            warnMessage: "Profile update failed",
            alertbox: true,
          });
        }
      });
    }
  };

  onChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (this.state.newPassword === this.state.confirm_newPassword && this.state.confirm_newPassword.length > 0) {
      this.setState({
        is_confirm_newPassword: true,
      });
    } else {
      this.setState({
        is_confirm_newPassword: false,
      });
    }
  };
  onDrop = (files: any, type: any) => {
    if (type !== undefined && type === "p12") {
      this.setState({
        p12cert: files,
      });
    } else {
      this.setState({ files: files, clearImage: false });
    }
  };
  getUserProfile() {
    console.log("GET USER PROFILE");
    let userList: any = {};
    requestAPICall("get_user_profile", {}, {}).then((data) => {
      console.log(data);
      userList = data["user_list"];
      if (userList["isSubmitted"]) {
        this.setState({
          certificateSubmitted: true,
        });
      }
      this.setState({ userList: userList, userEmail: userList["userEmail"], userId: userList.userId });
      if (userList.hasOwnProperty("cpiName") && userList.hasOwnProperty("cpiId")) {
        this.setState({
          cpiId: userList["cpiId"],
          cpiName: userList["cpiName"],
        });
      } else {
        this.setState({
          cpiId: null,
          cpiName: null,
        });
      }
    });
  }
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  setShowLoading = (st: any) => {
    this.setState({ isLoading: st });
  };

  uploadCertificateHtml = (files1: any) => {
    if (this.state.certificateSubmitted && files1.length == 0) {
      return <span style={{ fontSize: "14px" }}>File Uploaded already</span>;
    } else if (files1.length) {
      return <span style={{ color: "#00D56E" }}>{files1}</span>;
    } else {
      return <span style={{ fontSize: "14px" }}>+ Upload CPI Certificate</span>;
    }
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div>
            <div className="mobonly">
              <div className="param-info-mob" style={{ height: "100vh" }}>
                <div className="col-12 text-center header" style={{ padding: "10px" }}>
                  <img
                    alt="back"
                    className="backicon-mob"
                    style={{ width: "43px", paddingTop: "6px" }}
                    onClick={() => {
                      this.props.hideOverlay();
                    }}
                    src={Back}
                  />
                  <span className="apheader account-settings">ACCOUNT SETTINGS</span>
                </div>
                <div className="container-fluid apdash">
                  <div className="card profilecard">
                    <div className="card-body" style={{ overflowY: "auto", padding: "16px 12px" }}>
                      <h4 className="m-0 font22">Profile Info</h4>
                      <div className="col-12 m-t-20 p-0">
                        <div className="form-label-group m-0">
                          <input
                            type="text"
                            name="username"
                            id="profuser"
                            style={{ height: "60px" }}
                            disabled={true}
                            className="text-user img-center"
                            placeholder="Username*"
                            value={this.state.userList["userName"]}
                          />
                          <label className="forgot" htmlFor="profuser">
                            Username*
                          </label>
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="form-label-group m-0">
                          <input
                            type="email"
                            name="userEmail"
                            disabled
                            id="profemail"
                            className="text-user img-center"
                            style={{ borderRadius: "0px", height: "60px" }}
                            placeholder="Email"
                            value={this.state.userEmail}
                            onChange={this.onChange}
                          />
                          <label className="forgot" htmlFor="profemail">
                            Email
                          </label>
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="form-label-group m-0">
                          <img
                            alt="lockicon"
                            style={{ width: "14px", margin: "21px", position: "absolute", right: "0px" }}
                            src={Lockicon}
                          ></img>
                          <input
                            type="password"
                            disabled
                            name="password"
                            id="profpwd"
                            className="text-user img-center"
                            style={{ borderRadius: "0px", height: "60px" }}
                            placeholder="Password*"
                            value={this.state.userList["userPassword"]}
                          />
                          <label className="forgot" htmlFor="profpwd">
                            Password*
                          </label>
                        </div>
                      </div>
                      <div className="col-12 p-0">
                        <div className="form-label-group m-0">
                          <input
                            type="text"
                            name="cpiId"
                            id="profcpi"
                            className="text-user img-center"
                            style={{ borderRadius: "0px", height: "60px" }}
                            placeholder="CPI ID"
                            value={this.state.cpiId}
                            onChange={this.onChange}
                          />

                          <label className="forgot" htmlFor="profcpi">
                            CPI ID
                          </label>
                        </div>
                      </div>
                      {this.state.userList["UserRole"] != "Installer" ? (
                        <div>
                          <div className="col-12 p-b-10" style={{ border: "1px solid #CED7DF", borderBottom: "0px" }}>
                            <div className="form-label-group m-0">
                              <div>
                                <div
                                  className="col-12 p-0 m-t-10 justify-content-center"
                                  style={{ padding: "0px 35px", fontSize: "12px" }}
                                >
                                  <Dropzone
                                    onDrop={(e: any) => {
                                      this.onDrop(e, "p12");
                                    }}
                                    disabled={this.state.field_disable || this.state.override_cpi}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="dropzone-set">
                                        <div {...getRootProps({ className: "dropzone" })}>
                                          <input {...getInputProps()} />
                                          {this.uploadCertificateHtml(this.state.files1)}
                                        </div>
                                        <div></div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-0">
                            <i
                              className="far fa-eye eyeicon"
                              onClick={(e) => this.handleClick(e, "certificate_password")}
                            ></i>
                            <div className="form-label-group m-0">
                              <input
                                type={this.state.certificate_password_type}
                                name="certPassword"
                                id="profcp"
                                className="text-user img-center"
                                style={{ borderRadius: "0px", height: "60px" }}
                                placeholder="CPI ID"
                                value={this.state.certPassword}
                                onChange={this.onChange}
                              />

                              <label className="forgot" htmlFor="profcp">
                                CERTIFICATE PASSWORD
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-12 p-0">
                        <div className="form-label-group m-0">
                          <input
                            type="text"
                            name="cpiName"
                            id="profcpiname"
                            style={{ height: "60px" }}
                            className="text-pwd img-center"
                            placeholder="CPI Name"
                            value={this.state.cpiName}
                            onChange={this.onChange}
                          />
                          <label className="forgot" htmlFor="profcpiname">
                            CPI Name
                          </label>
                        </div>
                      </div>

                      <div className="col-12 p-0 m-t-20">
                        <button
                          className="btn cardbtn"
                          style={{ backgroundColor: "#e5eef7", color: "#0159b2" }}
                          id="changepwd"
                          onClick={this.showModal}
                        >
                          Update Password
                        </button>
                      </div>
                      <div className="col-12 p-0 m-t-10">
                        <button
                          disabled={
                            (this.state.cpiId == "" &&
                              this.state.cpiName == "" &&
                              this.state.cpiId != this.state.userList["cpiId"]) ||
                            (this.state.cpiName == "" &&
                              this.state.cpiName == null &&
                              this.state.cpiName != this.state.userList["cpiName"])
                          }
                          onClick={this.callSaveProfileAPI}
                          className="btn cardbtn"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={this.state.show && this.state.card === "changepwd"} handleClose={this.hideModal}>
                <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
                  <div className="modal-content">
                    <div className="modal-body" style={{ padding: "20px 16px" }}>
                      <div className="col-12 p-0">
                        <h6 className="modal-title font14 d-inline-flex" style={{ marginTop: "0px" }}>
                          CHANGING PASSWORD
                        </h6>
                        <i
                          className="material-icons imgclose-file modalclose d-inline-flex"
                          style={{ right: "0px" }}
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.hideModal();
                          }}
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          clear
                        </i>
                      </div>
                      <div className="col-12 m-t-29 p-0">
                        <div className="col-12 p-0">
                          <i
                            className="far fa-eye eyeicon"
                            onClick={(e) => this.handleClick(e, "current_password")}
                          ></i>
                          <div className="form-label-group m-0">
                            <input
                              type={this.state.currentPassword_type}
                              style={{ height: "59px" }}
                              name="currentPassword"
                              id="currentP"
                              value={this.state.currentPassword}
                              className="text-user img-center"
                              placeholder="Current Password"
                              onChange={this.onChange}
                            />
                            <label className="forgot" htmlFor="currentP">
                              Current Password
                            </label>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <i className="far fa-eye eyeicon" onClick={(e) => this.handleClick(e, "new_password")}></i>
                          <div className="form-label-group m-0">
                            <input
                              type={this.state.new_password_type}
                              name="newPassword"
                              id="newP"
                              className="text-user img-center"
                              value={this.state.newPassword}
                              style={{ borderRadius: "0px", height: "59px" }}
                              placeholder="New Password"
                              onChange={this.onChange}
                            />
                            <label className="forgot" htmlFor="newP">
                              New Password
                            </label>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <div className="form-label-group m-0">
                            {this.state.is_confirm_newPassword === true ? (
                              <i
                                className="material-icons eyeicon"
                                style={{ color: "#00D56E", fontSize: "20px" }}
                                onClick={(e) => this.handleClick(e, "confirm_new_password")}
                              >
                                check
                              </i>
                            ) : null}

                            <input
                              type="password"
                              style={{ height: "59px" }}
                              name="confirm_newPassword"
                              className="text-pwd img-center"
                              id="confirmP"
                              value={this.state.confirm_newPassword}
                              placeholder="Confirm New Password"
                              onChange={this.onChange}
                            />
                            <label className="forgot" htmlFor="confirmP">
                              Confirm New Password
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.newPassword === "" ||
                      this.state.confirm_newPassword === "" ||
                      this.state.currentPassword === "" ? (
                        <div className="col-12 p-0 m-t-20">
                          <button
                            className="btn cardbtn"
                            id="updatepwd"
                            disabled={true}
                            onClick={(e: any) => {
                              this.callchangePasswordAPI();
                            }}
                          >
                            Update Password
                          </button>
                        </div>
                      ) : (
                        <div className="col-12 p-0 m-t-20">
                          <button
                            className="btn cardbtn"
                            id="updatepwd"
                            onClick={(e: any) => {
                              this.callchangePasswordAPI();
                            }}
                          >
                            Update Password
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal show={this.state.show && this.state.card === "updatepwd"} handleClose={this.hideModal}>
                <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="col-12 p-0">
                        <h6 className="modal-title font13 d-inline-flex" style={{ marginTop: "0px" }}>
                          CHANGING PASSWORD
                        </h6>
                        <i
                          className="material-icons imgclose-file modalclose d-inline-flex"
                          style={{ right: "0px" }}
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.hideModal();
                          }}
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          clear
                        </i>
                      </div>
                      <div className="row justify-content-center m-t-20">
                        <div className="pwdsuccess"></div>
                        <span className="material-icons pwdsuccesstick">done</span>
                      </div>

                      <div className="row justify-content-center m-t-10">
                        <span className="font13">Password Successfully Updated!</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </IonContent>
        <IonAlert
          isOpen={this.state.alertbox}
          onDidDismiss={() => this.setShowAlert(false)}
          message={this.state.warnMessage}
          buttons={["OK"]}
        />
        <IonLoading
          isOpen={this.state.isLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={"Loading..."}
          duration={2000}
        />
      </IonPage>
    );
  }
}

export default withIonLifeCycle(Profile);
