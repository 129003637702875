import React, { Component } from "react";
import { IonTextarea, IonContent, IonPage, IonAlert, IonLoading, withIonLifeCycle, IonItem, IonLabel } from "@ionic/react";
import "./building.css";
import BackIcon from "../assets/img/go-back-icon-img.png";
import geo from "../assets/img/target-icon.png";
import align from "../assets/img/align-icon.png";
import { requestAPICall } from "../service/apiHandler";
import Modal from "./modal";
import { connect } from "react-redux";
import { ASSET, COORDINATES } from "../constants/actionTypes";
import store from "../store";
import { isMobile, isBrowser } from "react-device-detect";

const Building_INITIAL_STATE = {
  properties: [],
  buildingproperty: [],
  status_show: false,
  floor_details: [],
  old_floor_num: 0,
  new_floor_num: 0,
  field_disable: true,
  is_submit: false,
  building_name: "",
  no_of_floors: "",
  building_notes: "",
  street_address: "",
  city: "",
  state: "",
  zip: "",
  latitude: "",
  longitude: "",
  alertbox: false,
  building_id: "",
  is_loader: false,
  selected_building_id: "",
  button_text: "Save",
  warnMessage: "",
  isShow: false,
  building_address_id: "",
  disable_button: true,
};
let addition: any;
let building_data: any = [];
let selected_floor_list: any = [];
let is_edit: boolean = false;
let is_submit_form = false;

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  upadateBuildingId: (value: any) => dispatch({ type: ASSET, key: "buildingId", value }),
  upadateBuildingAction: (value: any) => dispatch({ type: ASSET, key: "buildingAction", value }),
  upadateBuildingName: (value: any) => dispatch({ type: ASSET, key: "buildingName", value }),
  upadateFloorNumbers: (value: any) => dispatch({ type: ASSET, key: "noOfFloors", value }),
  upadateMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "coordinates", value }),
});

interface Iprops {
  action: string;
  select_id: string;
  next6: Function;
  next4: Function;
  back: Function;
  calculate: Function;
  floor_no: Function;
  building_name: Function;
  setBuildingId: Function;
  focusBuilding: any;
}

class BuildingInfo1 extends Component<Iprops> {
  state: any = {};
  props: any = {};
  buildingnameInput: any;
  buildingNotesInput: any;
  addressInput: any;
  cityInput: any;
  stateInput: any;
  countryInput: any;
  zipInput: any;
  latitudeInput: any;
  longitudeInput: any;
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.buildingnameInput = React.createRef();
    this.buildingNotesInput = React.createRef();
    this.addressInput = React.createRef();
    this.cityInput = React.createRef();
    this.stateInput = React.createRef();
    this.countryInput = React.createRef();
    this.zipInput = React.createRef();
    this.latitudeInput = React.createRef();
    this.longitudeInput = React.createRef();

    this.next4 = this.next4.bind(this);
    this.state = { ...Building_INITIAL_STATE };
    this.fetch_data = this.fetch_data.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit_parameters = this.submit_parameters.bind(this);
    this.set_building_id = this.set_building_id.bind(this);
    this.delete_floor = this.delete_floor.bind(this);
    this.validateRequest = this.validateRequest.bind(this);
  }
  componentDidMount() {
    this.set_building_id(this.reduxStore["buildingAction"]);
  }

  componentDidUpdate() {
    this.reduxStore = store.getState()["general"];
  }

  ionViewDidEnter() {
    //NOSONAR
    this.set_building_id(this.reduxStore["buildingAction"]);
  }

  status_hideModal = () => {
    this.setState({
      status_show: false,
    });
  };
  set_building_id(action: any) {
    if (action === "Edit" || action === "back") {
      this.setState({
        selected_building_id: this.props.select_id,
        field_disable: false,
      });
      this.get_details();
    } else if (action == "Add") {
      is_edit = false;
      this.load_buildingData();
    }
  }

  get_details() {
    this.setState({
      is_loader: true,
    });
    let requestBody = {};

    requestAPICall("get_particular_building", { building_id: this.reduxStore["buildingId"] }, requestBody).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == "200") {
        if (data.building_adress_id !== null) {
          this.setState({
            building_address_id: data.building_address_id,
          });
        }
        is_edit = true;
        this.setState({
          isShow: true,
          buildingproperty: data.details,
          selected_building_id: data.building_id,
        });
        data.details?.forEach((property: any) => {
          if (property.propertyName == "no_of_floors") {
            this.setState({
              old_floor_num: property.value,
              new_floor_num: property.value,
            });
          }
        });
        let lat_long_check = false;
        data.details?.map((property: any) => {
          if (property.propertyName === "latitude" || property.propertyName === "longitude") {
            if (property.value === "") {
              lat_long_check = true;
            }
          }
          return property;
        });
        this.setState({
          disable_button: lat_long_check,
        });
      }
      this.setState({
        buildingproperty: data.details,
        button_text: "Edit",
      });
    });
  }

  set_lat_long = () => {
    if (isMobile) {
      this.state.buildingproperty?.forEach((property: any) => {
        if (property.propertyName == "latitude") {
          property.value = this.reduxStore["coordinates"]["latitude"];
        }
        if (property.propertyName == "longitude") {
          property.value = this.reduxStore["coordinates"]["longitude"];
        }
      });
    }
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };

  load_buildingData() {
    let building_property: any = [];
    let requestBody = {};
    let buildingData1AddProperty = localStorage.getItem("buildingData1AddProperty");
    if (buildingData1AddProperty === null) {
      requestAPICall("get_building_property", {}, requestBody).then((data) => {
        if (data.responseCode === 200) {
          localStorage.setItem("buildingData1AddProperty", JSON.stringify(data["properties"]));
          building_property = data["properties"];
          building_data = data["properties"];
          this.setState(
            {
              field_disable: true,
              buildingproperty: building_property,
              button_text: "Add",
            },
            () => this.set_lat_long()
          );
        }
      });
    } else {
      let properties = JSON.parse(buildingData1AddProperty);
      building_property = properties;
      building_data = properties;
      this.setState(
        {
          buildingproperty: building_property,
          button_text: "Add",
        },
        () => this.set_lat_long()
      );
    }
  }
  fetch_data(data: any) {
    this.setState({
      buildingproperty: data,
      button_text: "Edit",
    });
  }
  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name == "no_of_floors") {
      this.set_old_floor_num(e.target.value);
    }
    if (e.target.name === "no_of_floors" && (this.props.action === "Add" || this.props.action === "back")) {
      this.props.floor_no(e.target.value);
      this.props.upadateFloorNumbers(e.target.value);
    }
    if (e.target.name === "building_name") {
      this.props.building_name(e.target.value);
    }
    this.state.buildingproperty?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
    });
    let lat_long_check = false;
    this.state.buildingproperty?.map((property: any) => {
      if (
        (property.propertyName === "latitude" && e.target.name === "latitude") ||
        (property.propertyName === "longitude" && e.target.name === "longitude")
      ) {
        property.value = e.target.value;
        if (property.value === "") {
          lat_long_check = true;
        }
      }
      return property;
    });
    this.setState({
      disable_button: lat_long_check,
    });
  }

  next6() {
    this.props.next6();
  }

  set_old_floor_num(number: any) {
    this.setState({
      new_floor_num: number,
    });
  }

  validateRequest() {
    let check = false;
    this.state.buildingproperty?.forEach((property: any) => {
      if (!check) {
        if (property.propertyName == "building_name" && property.value == "" && property.isRequired) {
          this.buildingnameInput.current.focus();
          check = true;
        } else if (
          property.propertyName == "latitude" &&
          (property.value === 0 || property.value == "" || property.value === "0") &&
          property.isRequired
        ) {
          this.latitudeInput.current.focus();
          check = true;
        } else if (
          property.propertyName == "longitude" &&
          (property.value === 0 || property.value == "" || property.value === "0") &&
          property.isRequired
        ) {
          this.longitudeInput.current.focus();
          check = true;
        }
      }
    });
    is_submit_form = !check;
  }

  submit_parameters = (e: any) => {
    e.preventDefault();
    this.validateRequest();
    let requestBody = {
      properties: this.state.buildingproperty,
    };
    this.state.buildingproperty?.map((property: any) => {
      if (property.propertyName === "building_name") {
        this.props.upadateBuildingName(property.value);
      } else if (property.propertyName === "no_of_floors") {
        this.props.upadateFloorNumbers(property.value);
      }
    });

    if (this.reduxStore["buildingAction"] == "Add") {
      if (is_submit_form) {
        this.setState({
          is_loader: true,
        });
        $("#submitbtn").attr("disabled", "true");
        $("#submitbtnmob").attr("disabled", "true");
        requestAPICall("create_building", {}, requestBody).then((data) => {
          this.setState({
            is_loader: false,
          });
          if (data.responseCode == "200") {
            this.props.upadateBuildingId(data.building_id);
            this.setState(
              {
                building_id: data.building_id,
              },
              () => {
                if (isMobile) {
                  this.props.upadateBuildingId(this.state.building_id);
                  this.props.next4();
                } else {
                  this.props.setBuildingId(this.state.building_id);
                  this.props.next4();
                }
              }
            );
          } else {
            $("#submitbtn").removeAttr("disabled");
            $("#submitbtnmob").removeAttr("disabled");
            this.setState({
              alertbox: true,
              warnMessage: data.message,
            });
          }
        });
      }
    } else if (this.reduxStore["buildingAction"] == "Edit" || this.reduxStore["buildingAction"] == "back") {
      if (is_submit_form) {
        this.setState({ is_loader: true });
        let requestBody = {
          properties: this.state.buildingproperty,
          building_address_id: this.state.building_address_id,
        };
        requestAPICall("update_building", { building_id: this.reduxStore["buildingId"] }, requestBody).then((data) => {
          this.setState({ is_loader: false });
          if (data.responseCode == "200") {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
            });
            this.setState({
              building_id: data.building_id,
            });
            this.props.setBuildingId(this.state.building_id);
            if (this.state.old_floor_num < this.state.new_floor_num) {
              this.props.next4();
            }
            if (this.reduxStore["buildingAction"] == "back") {
              this.props.next4();
            }
            if (this.reduxStore["buildingAction"] == "Edit") {
              this.set_building_coordinates_after_edit();
            }
          } else {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
            });
          }
        });
      }
      this.props.next4();
    }
  };
  set_building_coordinates_after_edit() {
    let lat = "";
    let lng = "";
    this.state.buildingproperty?.forEach((property: any) => {
      if (property.propertyName == "latitude") {
        lat = property.value;
      }
      if (property.propertyName == "longitude") {
        lng = property.value;
      }
    });
    this.setState(
      {
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
      },
      () => {
        this.props.upadateMapCoordinates({ latitude: this.state.center["lat"], longitude: this.state.center["lng"] });
      }
    );
    localStorage.setItem("indoor_map_center_lat", lat);
    localStorage.setItem("indoor_map_center_lng", lng);
    this.props.next6();
    this.props.focusBuilding(this.state.center["lat"], this.state.center["lng"]);
  }

  complete_building = () => {
    this.validateRequest();

    let requestBody = {
      properties: this.state.buildingproperty,
    };
    if (!is_edit) {
      if (is_submit_form) {
        this.setState({
          is_loader: true,
        });
        requestAPICall("create_building", {}, requestBody).then((data) => {
          this.setState({
            is_loader: false,
          });
          if (data.responseCode == "200") {
            this.setState({
              building_id: data.building_id,
            });
            this.props.setBuildingId(this.state.building_id);
            this.props.back();
          } else {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
            });
          }
        });
      }
    } else if (is_submit_form) {
      let requestBody = {
        properties: this.state.buildingproperty,
        building_address_id: this.state.building_address_id,
      };
      requestAPICall("update_building", { building_id: this.props.select_id }, requestBody).then((data) => {
        if (data.responseCode == "200") {
          this.setState({
            building_id: data.building_id,
          });
          this.props.setBuildingId(this.state.building_id);
          this.props.back();
        } else {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
          });
        }
      });
    }
  };

  next4 = (e: any) => {
    e.preventDefault();
    this.props.setBuildingId(this.state.building_id);
    this.props.next4(this.state.building_id);
  };
  floor_checked(e: any) {
    let floor_index = selected_floor_list.indexOf(e.target.id);
    if (e.target.checked) {
      selected_floor_list.push(parseInt(e.target.id));
    } else if (floor_index <= 0) {
      selected_floor_list.splice(floor_index, 1);
    }
  }

  delete_floor() {
    this.setState({ is_loader: true });
    let total_floor = this.state.old_floor_num - this.state.new_floor_num;
    if (selected_floor_list.length == total_floor) {
      let requestBody = {
        floor_id: selected_floor_list,
      };
      let build_id = this.state.selected_building_id;
      requestAPICall("delete_floor", { building_id: build_id }, requestBody).then((data) => {
        this.setState({ is_loader: false });
        if (data.responseCode == 200) {
          selected_floor_list = [];
          this.setState({
            status_show: false,
          });
          this.next6();
        }
      });
    } else {
      this.setState({
        warnMessage: "Please Select Correct floor number",
        alertbox: true,
      });
    }
  }

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  clickHandle = () => {
    if (this.state.new_floor_num == 0) {
      this.complete_building();
    }
    if (this.state.new_floor_num > 0 && this.props.action == "Add") {
      this.setState({
        warnMessage: "Please add floor details",
        alertbox: true,
      });
    }
    if (this.props.action == "Edit") {
      if (this.state.new_floor_num == this.state.old_floor_num) {
        this.complete_building();
      }
      if (this.state.new_floor_num > this.state.old_floor_num) {
        this.setState({
          warnMessage: "Please add floor details",
          alertbox: true,
        });
      }
      if (this.state.new_floor_num < this.state.old_floor_num) {
        this.setState({
          warnMessage: "Please edit floor details",
          alertbox: true,
        });
      }
    }
  };

  render() {
    return (
      <div>
        {isBrowser ? (
          <div className="row webonly">
            <div className="col-md-9">
              <div className="card apcard">
                <div className="card-body" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-md-3">
                      {this.props.action !== "Edit" ? (
                        <img
                          alt="spectra-pro-icons"
                          className="backicon"
                          src={BackIcon}
                          onClick={this.props.back}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.props.back();
                            }
                          }}
                        />
                      ) : (
                        <img
                          alt="spectra-pro-icons"
                          className="backicon"
                          src={BackIcon}
                          onClick={this.props.next6}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.props.next6();
                            }
                          }}
                        />
                      )}

                      <button type="button" className="backbutton"></button>
                      {/* <button type='button' className='backbutton material-icons' style={{ color: '#0059B2' }} onClick={this.props.back}>keyboard_backspace </button> */}
                    </div>
                    <div className="col-md-8" style={{ padding: "13px 0" }}>
                      {this.props.action === "Add" || this.props.action === "back" ? <span className="apheader m-r-m">NEW BUILDING INFO</span> : null}
                      {this.props.action === "Edit" ? <span className="apheader m-r-m">EDIT BUILDING INFO</span> : null}
                      {this.props.action !== "Edit" ? (
                        <span className="apnote float-right" style={{ fontSize: "12px", margin: "5px" }}>
                          1/3
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row m-t-10 form-group" style={{ padding: "0px 35px" }}>
                    {this.state.buildingproperty?.map((pty: any, index: number) => {
                      if (pty["propertyName"] === "building_name") {
                        return (
                          <div className="col-md-12 p-0" key={`${index.toString()}-building-name`}>
                            <div className="form-label-group m-0">
                              <input
                                type="text"
                                className="text-user"
                                maxLength={45}
                                ref={this.buildingnameInput}
                                name={pty.propertyName}
                                id={pty.propertyName}
                                onChange={this.onChange}
                                placeholder={pty.displayName}
                                style={{ borderBottom: "none", height: "45px", width: "100%" }}
                                value={pty.value}
                              />
                              <label className="pages" style={{ top: "3px" }} htmlFor={pty.propertyName}>
                                {pty.displayName}
                              </label>
                            </div>
                          </div>
                        );
                      } else if (pty["propertyName"] === "state" || pty["propertyName"] === "city") {
                        if (pty["propertyName"] === "state") {
                          return (
                            <div className="col-md-6 p-0" key={`${index.toString()}-state`}>
                              <div className="form-label-group m-0">
                                <input
                                  ref={this.stateInput}
                                  type="text"
                                  className="text-user b-t-0 "
                                  id={pty.propertyName}
                                  name={pty.propertyName}
                                  value={pty.value}
                                  onChange={this.onChange}
                                  style={{ borderRadius: "0px", height: "45px", width: "100%" }}
                                  placeholder={pty.displayName}
                                />
                                <label className="pages" style={{ top: "3px" }} htmlFor={pty.propertyName}>
                                  {pty.displayName}
                                </label>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-md-6 p-0" key={`${index.toString()}-city`}>
                              <div className="form-label-group m-0">
                                <input
                                  ref={this.cityInput}
                                  type="text"
                                  className="text-user b-t-0  b-r-0 "
                                  id={pty.propertyName}
                                  name={pty.propertyName}
                                  value={pty.value}
                                  onChange={this.onChange}
                                  style={{ borderRadius: "0px", height: "45px", width: "100%" }}
                                  placeholder={pty.displayName}
                                />
                                <label className="pages" style={{ top: "3px" }} htmlFor={pty.propertyName}>
                                  {pty.displayName}
                                </label>
                              </div>
                            </div>
                          );
                        }
                      } else if (pty["propertyName"] == "country" || pty["propertyName"] == "zip") {
                        if (pty["propertyName"] == "country") {
                          return (
                            <div className="col-md-6 p-0" key={`${index.toString()}-zip`}>
                              <div className="form-label-group m-0">
                                <input
                                  type="text"
                                  ref={this.countryInput}
                                  className={pty["propertyName"] == "zip" ? "text-user b-t-0" : "text-user b-t-0 b-r-0"}
                                  id={pty.propertyName}
                                  name={pty.propertyName}
                                  value={pty.value}
                                  onChange={this.onChange}
                                  style={{ borderRadius: "0px", height: "45px", width: "100%" }}
                                  placeholder={pty.displayName}
                                />
                                <label className="pages" style={{ top: "3px" }} htmlFor={pty.propertyName}>
                                  {pty.displayName}
                                </label>
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div className="col-md-6 p-0" key={`${index.toString()}-zip`}>
                              <div className="form-label-group m-0">
                                <input
                                  ref={this.zipInput}
                                  type="number"
                                  className={pty["propertyName"] == "zip" ? "text-user b-t-0" : "text-user b-t-0 b-r-0"}
                                  id={pty.propertyName}
                                  name={pty.propertyName}
                                  value={pty.value}
                                  onChange={this.onChange}
                                  style={{ borderRadius: "0px", height: "45px", width: "100%" }}
                                  placeholder={pty.displayName}
                                />
                                <label className="pages" style={{ top: "3px" }} htmlFor={pty.propertyName}>
                                  {pty.displayName}
                                </label>
                              </div>
                            </div>
                          );
                        }
                      } else if (pty["propertyName"] === "street_address") {
                        return (
                          <div className="col-md-12 p-0" key={`${index.toString()}-street`}>
                            <div className="form-label-group m-0">
                              <input
                                type="text"
                                ref={this.addressInput}
                                className={pty["propertyName"] == "street_address" ? "text-user b-t-0" : "text-user"}
                                id={pty.propertyName}
                                name={pty.propertyName}
                                onChange={this.onChange}
                                style={{ borderRadius: "0px", height: "45px", width: "100%" }}
                                placeholder={pty.displayName}
                                value={pty.value}
                              />
                              <label className="pages" style={{ top: "3px" }} htmlFor={pty.propertyName}>
                                {pty.displayName}
                              </label>
                            </div>
                          </div>
                        );
                      } else if (pty["dataType"] === "textArea") {
                        if (is_edit) {
                          return (
                            <IonItem
                              key={`${index.toString()}-desc`}
                              lines="none"
                              color="none"
                              className="description"
                              style={{ borderRadius: "0px" }}
                            >
                              <IonLabel position="floating">{pty.displayName}</IonLabel>
                              <IonTextarea
                                maxlength={45}
                                id={pty.propertyName}
                                value={pty.value}
                                name={pty.propertyName}
                                rows={4}
                                onInput={this.onChange}
                                style={{ border: "", height: "100px" }}
                              />
                            </IonItem>
                          );
                        } else {
                          return (
                            <IonItem
                              key={`${index.toString()}-desc-2`}
                              lines="none"
                              color="none"
                              className="description"
                              style={{ borderRadius: "0px" }}
                            >
                              <IonLabel position="floating">{pty.displayName}</IonLabel>
                              <IonTextarea
                                maxlength={45}
                                id={pty.propertyName}
                                defaultValue={pty.value}
                                name={pty.propertyName}
                                rows={4}
                                onIonInput={this.onChange}
                                style={{ border: "", height: "100px" }}
                              />
                            </IonItem>
                          );
                        }
                      } else if (pty["dataType"] === "float") {
                        if (is_edit) {
                          if (pty["propertyName"] == "longitude") {
                            return (
                              <div className="col-md-6 p-0" key={`${index.toString()}-building-info`}>
                                <div className="form-label-group m-0">
                                  <input
                                    type="number"
                                    ref={this.longitudeInput}
                                    className="text-user serial buildinfo-web b-t-0"
                                    id="blong"
                                    value={is_edit ? pty.value : this.reduxStore["coordinates"]["latitude"]}
                                    name={pty.propertyName}
                                    style={{ borderRadius: "0px 0px 6px", height: "45px" }}
                                    onChange={this.onChange}
                                    placeholder={pty.displayName}
                                    step="any"
                                  />
                                  <label className="pages p-b-0" style={{ top: "3px" }} htmlFor="blong">
                                    {pty.displayName}
                                  </label>
                                </div>
                              </div>
                            );
                          } else if (pty["propertyName"] == "latitude") {
                            return (
                              <div className="col-md-6 p-0" key={`${index.toString()}-latitude`}>
                                <div className="form-label-group m-0">
                                  <input
                                    type="number"
                                    ref={this.latitudeInput}
                                    className="text-user serial buildinfo-web b-t-0 b-r-0"
                                    id="blong"
                                    value={is_edit ? pty.value : this.reduxStore["coordinates"]["longitude"]}
                                    name={pty.propertyName}
                                    style={{ borderRadius: "0px 0px 0px 6px", height: "45px" }}
                                    onChange={this.onChange}
                                    placeholder={pty.displayName}
                                    step="any"
                                  />
                                  <label className="pages p-b-0" style={{ top: "3px" }} htmlFor="blong">
                                    {pty.displayName}
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        } else if (pty["propertyName"] == "longitude") {
                          return (
                            <div className="col-md-6 p-0" key={`${index.toString()}-long`}>
                              <div className="form-label-group m-0">
                                <input
                                  type="number"
                                  ref={this.longitudeInput}
                                  className="text-user serial buildinfo-web b-t-0"
                                  id="blong"
                                  disabled={this.state.field_disable}
                                  value={pty.value === 0 ? this.reduxStore["coordinates"]["latitude"] : pty.value}
                                  name={pty.propertyName}
                                  onChange={this.onChange}
                                  style={{ borderRadius: "0px 0px 6px", height: "45px" }}
                                  placeholder={pty.displayName}
                                  step="any"
                                />
                                <label className="pages p-b-0" style={{ top: "3px" }} htmlFor="blong">
                                  {pty.displayName}
                                </label>
                              </div>
                            </div>
                          );
                        } else if (pty["propertyName"] == "latitude") {
                          return (
                            <div className="col-md-6 p-0" key={`${index.toString()}-lat`}>
                              <div className="form-label-group m-0">
                                <input
                                  type="number"
                                  ref={this.latitudeInput}
                                  className="text-user serial buildinfo-web b-t-0 b-r-0"
                                  id="blat"
                                  disabled={this.state.field_disable}
                                  value={pty.value === 0 ? this.reduxStore["coordinates"]["longitude"] : pty.value}
                                  name={pty.propertyName}
                                  onChange={this.onChange}
                                  style={{ borderRadius: "0px 0px 0px 6px", height: "45px" }}
                                  placeholder={pty.displayName}
                                  step="any"
                                />
                                <label className="pages p-b-0" style={{ top: "3px" }} htmlFor="blong">
                                  {pty.displayName}
                                </label>
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>

                  <div className="row m-t-10" style={{ padding: "0px 35px", position: "absolute", bottom: "68px" }}>
                    {this.props.action === "Add" || this.props.action === "back" ? (
                      <button
                        id="submitbtn"
                        className="btn btn-secondary-c3"
                        disabled={this.state.disable_button}
                        onClick={this.submit_parameters}
                        style={{ width: "258px" }}
                      >
                        Save & Add Floor
                      </button>
                    ) : null}
                    {this.props.action === "Edit" ? (
                      <button
                        className="btn btn-secondary-c3"
                        disabled={this.state.disable_button}
                        onClick={this.submit_parameters}
                        style={{ width: "258px" }}
                      >
                        Edit & Save
                      </button>
                    ) : null}
                    {this.props.action !== "Edit" ? (
                      <button className="btn savebtn" disabled={this.state.disable_button} onClick={this.clickHandle}>
                        Complete
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img alt="spectra-pro-icons" style={{ padding: "5px" }} src={geo} />
                </div>
              </div>
              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img
                    alt="spectra-pro-icons"
                    style={{ padding: "6.5px" }}
                    onClick={this.props.calculate}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.props.calculate();
                      }
                    }}
                    src={align}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobonly">
            <IonPage>
              <IonContent>
                <div className="param-info-mob" style={{ height: "100vh" }}>
                  <div className="col-12 text-center header-mob" style={{ padding: "10px" }}>
                    {this.props.action === "Edit" ? (
                      <img
                        alt="spectra-pro-icons"
                        className="backicon-mob"
                        style={{ width: "43px", paddingTop: "6px" }}
                        onClick={() => {
                          this.props.hideEditB1Overlay();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.props.hideEditB1Overlay();
                          }
                        }}
                        src={BackIcon}
                      />
                    ) : (
                      <img
                        alt="spectra-pro-icons"
                        className="backicon-mob"
                        style={{ width: "43px", paddingTop: "6px" }}
                        onClick={() => {
                          this.props.hideB1Overlay();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.props.hideB1Overlay();
                          }
                        }}
                        src={BackIcon}
                      />
                    )}
                    {this.props.action === "Edit" ? (
                      <span className="apheader" style={{ fontSize: "13px" }}>
                        EDIT BUILDING
                      </span>
                    ) : (
                      <span className="apheader" style={{ fontSize: "13px" }}>
                        NEW BUILDING
                      </span>
                    )}
                  </div>
                  <div className="container-fluid apdash">
                    <div className="card buildinfocard" style={{ height: "calc(100vh - 58px)" }}>
                      <div className="card-body" style={{ padding: "15px", overflow: "hidden auto" }}>
                        <div className="col-12 p-0">
                          <h6 className="m-0 d-inline-block">Building Info</h6>
                          {this.props.action !== "Edit" ? (
                            <span className="apnote float-right" style={{ fontSize: "11px", margin: "5px" }}>
                              1/2
                            </span>
                          ) : null}
                        </div>
                        <div className="row m-t-10 form-group" style={{ padding: "15px" }}>
                          {this.state.buildingproperty?.map((pty: any, index: number) => {
                            if (pty["propertyName"] === "building_name") {
                              return (
                                <div className="col-12 p-0" key={`${index.toString()}-build-name`}>
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      className="text-user "
                                      maxLength={45}
                                      ref={this.buildingnameInput}
                                      name={pty.propertyName}
                                      id={"mob_" + pty.propertyName}
                                      onChange={this.onChange}
                                      placeholder={pty.displayName}
                                      style={{ borderBottom: "none", height: "58px", width: "100%" }}
                                      value={pty.value}
                                    />
                                    <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                      {pty.displayName}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else if (pty["propertyName"] === "state" || pty["propertyName"] === "city") {
                              if (pty["propertyName"] === "state") {
                                return (
                                  <div className="col-6 p-0" key={`${index.toString()}-state-input`}>
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={this.stateInput}
                                        type="text"
                                        className="text-user b-t-0"
                                        id={"mob_" + pty.propertyName}
                                        name={pty.propertyName}
                                        value={pty.value}
                                        onChange={this.onChange}
                                        style={{ borderRadius: "0px", height: "58px", width: "100%" }}
                                        placeholder={pty.displayName}
                                      />
                                      <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                        {pty.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="col-6 p-0" key={`${index.toString()}-mob-text-user`}>
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={this.cityInput}
                                        type="text"
                                        className="text-user b-t-0  b-r-0 "
                                        id={"mob_" + pty.propertyName}
                                        name={pty.propertyName}
                                        value={pty.value}
                                        onChange={this.onChange}
                                        style={{ borderRadius: "0px", height: "58px", width: "100%" }}
                                        placeholder={pty.displayName}
                                      />
                                      <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                        {pty.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            } else if (pty["propertyName"] == "country" || pty["propertyName"] == "zip") {
                              if (pty["propertyName"] == "country") {
                                return (
                                  <div className="col-6 p-0" key={`${index.toString()}-zip-forgot`}>
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        ref={this.countryInput}
                                        className={pty["propertyName"] == "zip" ? "text-user b-t-0" : "text-user b-t-0 b-r-0"}
                                        id={"mob_" + pty.propertyName}
                                        name={pty.propertyName}
                                        value={pty.value}
                                        onChange={this.onChange}
                                        style={{ borderRadius: "0px", height: "58px", width: "100%" }}
                                        placeholder={pty.displayName}
                                      />
                                      <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                        {pty.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="col-6 p-0" key={`${index.toString()}-text-user-inp`}>
                                    <div className="form-label-group m-0">
                                      <input
                                        ref={this.zipInput}
                                        type="number"
                                        className={pty["propertyName"] == "zip" ? "text-user b-t-0" : "text-user b-t-0 b-r-0"}
                                        id={"mob_" + pty.propertyName}
                                        name={pty.propertyName}
                                        value={pty.value}
                                        onChange={this.onChange}
                                        style={{ borderRadius: "0px", height: "58px", width: "100%" }}
                                        placeholder={pty.displayName}
                                      />
                                      <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                        {pty.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            } else if (pty["propertyName"] === "street_address") {
                              return (
                                <div className="col-12 p-0" key={`${index.toString()}-mob-street-address`}>
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      ref={this.addressInput}
                                      className={pty["propertyName"] == "street_address" ? "text-user b-t-0" : "text-user"}
                                      id={"mob_" + pty.propertyName}
                                      name={pty.propertyName}
                                      onChange={this.onChange}
                                      style={{ borderRadius: "0px", height: "58px", width: "100%" }}
                                      placeholder={pty.displayName}
                                      value={pty.value}
                                    />
                                    <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                      {pty.displayName}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else if (pty["dataType"] === "textArea") {
                              if (is_edit) {
                                return (
                                  <IonItem lines="none" color="none" className="description" key={`${index.toString()}-description-mob`}>
                                    <IonLabel position="floating">{pty.displayName}</IonLabel>
                                    <IonTextarea
                                      maxlength={45}
                                      id={pty.propertyName}
                                      value={pty.value}
                                      name={pty.propertyName}
                                      rows={4}
                                      onInput={this.onChange}
                                      style={{ border: "", height: "100px" }}
                                    />
                                  </IonItem>
                                );
                              } else {
                                return (
                                  <IonItem lines="none" color="none" className="description" key={`${index.toString()}-desc-name`}>
                                    <IonLabel position="floating">{pty.displayName}</IonLabel>
                                    <IonTextarea
                                      maxlength={45}
                                      ref={this.buildingNotesInput}
                                      defaultValue={pty.value}
                                      name={pty.propertyName}
                                      rows={4}
                                      onInput={this.onChange}
                                      style={{ border: "", height: "100px" }}
                                    />
                                  </IonItem>
                                );
                              }
                            } else if (pty["dataType"] === "float") {
                              if (is_edit) {
                                if (pty["propertyName"] == "longitude") {
                                  return (
                                    <div className="col-6 p-0" key={`${index.toString()}-longitude-item`}>
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          ref={this.longitudeInput}
                                          className="text-user serial buildinfo-web b-t-0"
                                          id={"mob_" + pty.propertyName}
                                          value={is_edit ? pty.value : this.reduxStore["coordinates"]["latitude"]}
                                          name={pty.propertyName}
                                          onChange={this.onChange}
                                          style={{
                                            borderRadius: "0px 0px 6px",
                                            height: "58px",
                                            borderBottom: "1px solid #CED7DF",
                                          }}
                                          placeholder={pty.displayName}
                                          step="any"
                                        />
                                        <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                          {pty.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                } else if (pty["propertyName"] == "latitude") {
                                  return (
                                    <div className="col-6 p-0" key={`${index.toString()}-longitude-item`}>
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          ref={this.latitudeInput}
                                          className="text-user serial buildinfo-web b-t-0 b-r-0"
                                          id={"mob_" + pty.propertyName}
                                          value={is_edit ? pty.value : this.reduxStore["coordinates"]["longitude"]}
                                          name={pty.propertyName}
                                          onChange={this.onChange}
                                          style={{
                                            borderRadius: "0px 0px 0px 6px",
                                            height: "58px",
                                            borderBottom: "1px solid #CED7DF",
                                          }}
                                          placeholder={pty.displayName}
                                          step="any"
                                        />
                                        <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                          {pty.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (pty["propertyName"] == "longitude") {
                                return (
                                  <div className="col-6 p-0" key={`${index.toString()}-serial-build`}>
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        ref={this.longitudeInput}
                                        className="text-user serial buildinfo-web b-t-0"
                                        id={"mob_" + pty.propertyName}
                                        value={pty.value === "0" ? this.reduxStore["coordinates"]["latitude"] : pty.value}
                                        name={pty.propertyName}
                                        onChange={this.onChange}
                                        style={{
                                          borderRadius: "0px 0px 6px",
                                          height: "58px",
                                          borderBottom: "1px solid #CED7DF",
                                        }}
                                        placeholder={pty.displayName}
                                        step="any"
                                      />
                                      <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                        {pty.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else if (pty["propertyName"] == "latitude") {
                                return (
                                  <div className="col-6 p-0" key={`${index.toString()}-serial-user`}>
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        ref={this.latitudeInput}
                                        className="text-user serial buildinfo-web b-t-0 b-r-0"
                                        id={"mob_" + pty.propertyName}
                                        value={pty.value === "0" ? this.reduxStore["coordinates"]["longitude"] : pty.value}
                                        name={pty.propertyName}
                                        onChange={this.onChange}
                                        style={{
                                          borderRadius: "0px 0px 0px 6px",
                                          height: "58px",
                                          borderBottom: "1px solid #CED7DF",
                                        }}
                                        placeholder={pty.displayName}
                                        step="any"
                                      />
                                      <label className="forgot" htmlFor={"mob_" + pty.propertyName}>
                                        {pty.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>

                        <div className="col-12 p-0 m-t-20">
                          {this.props.action === "Add" || this.props.action === "back" ? (
                            <button
                              id="submitbtnmob"
                              className="btn2 cardbtn"
                              onClick={(e: any) => {
                                this.submit_parameters(e);
                              }}
                            >
                              Save & Add Floor
                            </button>
                          ) : null}

                          {this.props.action == "Edit" && this.state.isShow ? (
                            <button className="btn btn-secondary-c3" onClick={(e: any) => this.submit_parameters(e)}>
                              Edit & Save
                            </button>
                          ) : null}
                        </div>
                        <div className="col-12 p-0 m-t-10">
                          {this.props.action !== "Edit" ? (
                            <button className="btn cardbtn" onClick={this.clickHandle}>
                              Complete
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </IonContent>
            </IonPage>
          </div>
        )}
        <Modal show={this.state.status_show}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    Building Floor List
                  </h6>
                  <i
                    className="far fa-times-circle modalclose"
                    onClick={this.status_hideModal}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.status_hideModal();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </div>
                <table className="table sitecollapse">
                  <thead>
                    <tr>
                      <th className="w15">Floor Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {this.state.floor_details?.map((floor: any, index: any) => {
                        return (
                          <div key={`${index.toString()}-floor-check`}>
                            <td>
                              {" "}
                              <input type="checkbox" id={floor["floorId"]} name="anyConnection" onChange={this.floor_checked} />
                              {floor["floor_type"]} : {floor["floor_number"]}
                            </td>
                          </div>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
                <div className="col-12 p-0 m-t-10">
                  <button className="btn cardbtn" onClick={this.delete_floor}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["CLOSE"]} />
        <IonLoading isOpen={this.state.is_loader} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} />
      </div>
    );
  }
}

export default connect<{}, {}, Iprops>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withIonLifeCycle(BuildingInfo1));
