import { IonLoading, withIonLifeCycle } from "@ionic/react";
import { GoogleMap } from "@react-google-maps/api";
import { saveAs } from "file-saver";
import React from "react";
import { withRouter } from "react-router-dom";
import smbImage from "../assets/img/SMB.png";
import aerialImage from "../assets/img/azimuth_sectors_black.svg";
import roeImage from "../assets/img/roe_sector.svg";
import { requestAPICall } from "../service/apiHandler";
import store from "../store";
import Modal from "./modal";
class NetworkActivity extends React.Component {
  _map: any;
  state: any = {};
  props: any = {};
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.state = {
      searchSelected: "trackingid",
      requestExpanded: true,
      submittedExpanded: false,
      rmaExpanded: false,
      requestedList: [],
      submittedList: [],
      rmaList: [],
      sectorList: [],
      rmaHistoryList: [],
      selectedSectorId: "",
      selectedAsset: null,
      isLoading: false,
      viewSector: false,
      center: null,
      category: "",
      assetAzimuth: "0",
      assetMountingType: "",
      imageScale: 1,
      searchList: [],
      image: {},
      imageCard: false,
      rmaHistoryCard: false,
      fetchingData: false,
    };
  }

  componentDidMount() {
    this.getCloudAssets();
  }

  trackCloudScrolling = (e: any) => {
    let scrollBottom = $(".cloudbody").scrollTop()! + $(".cloudbody").innerHeight()! >= $(".cloudbody")[0].scrollHeight;
    if (scrollBottom) {
      $(".cloudbody").off("scroll", this.trackCloudScrolling);
      this.getCloudAssets();
    }
  };

  trackSASScrolling = (e: any) => {
    let scrollBottom = $(".sasbody").scrollTop()! + $(".sasbody").innerHeight()! >= $(".sasbody")[0].scrollHeight;
    if (scrollBottom) {
      $(".sasbody").off("scroll", this.trackSASScrolling);
      this.getSASAssets();
    }
  };

  trackRMAScrolling = (e: any) => {
    let scrollBottom = $(".rmabody").scrollTop()! + $(".rmabody").innerHeight()! >= $(".rmabody")[0].scrollHeight;
    if (scrollBottom) {
      $(".rmabody").off("scroll", this.trackRMAScrolling);
      this.getRMAAssets();
    }
  };

  getCloudAssets() {
    this.setState({ fetchingData: true });
    requestAPICall("list_network_activity", { type: "cloud", start: this.state.requestedList.length }, {}).then((data) => {
      this.setState({ fetchingData: false });
      if (data.responseCode == 200) {
        if (data.assets.length !== 0) {
          this.setState((prevValues: any) => ({
            requestedList: [...prevValues.requestedList, ...data.assets],
          }));
          $(".cloudbody").on("scroll", this.trackCloudScrolling);
        }
      }
    });
  }

  getSASAssets() {
    this.setState({ fetchingData: true });
    requestAPICall("list_network_activity", { type: "sas", start: this.state.submittedList.length }, {}).then((data) => {
      this.setState({ fetchingData: false });
      if (data.responseCode == 200) {
        if (data.assets.length !== 0) {
          this.setState((prevValues: any) => ({
            submittedList: [...prevValues.submittedList, ...data.assets],
          }));
          $(".sasbody").on("scroll", this.trackSASScrolling);
        }
      }
    });
  }

  getRMAAssets() {
    this.setState({ fetchingData: true });
    requestAPICall("list_network_activity", { type: "rma", start: this.state.rmaList.length }, {}).then((data) => {
      this.setState({ fetchingData: false });
      if (data.responseCode == 200) {
        if (data.assets.length !== 0) {
          this.setState((prevValues: any) => ({
            rmaList: [...prevValues.rmaList, ...data.assets],
          }));
          $(".rmabody").on("scroll", this.trackRMAScrolling);
        }
      }
    });
  }

  getSectors(assetId: any, status: any, mountingType: any) {
    this.setState({
      searchList: [],
      isLoading: true,
    });
    this._map?.setZoom(22);
    let stat: string = "sas";
    if (status === 2) {
      stat = "cloud";
    }
    this.setState({ category: stat });
    requestAPICall("get_sectors", { asset_id: assetId, status: status }, {}).then((data) => {
      this.setState({ isLoading: false });
      if (data.responseCode == 200) {
        if (!data.sectors.isEmpty) {
          let azimuth = 0;
          if (mountingType == "Aerial") {
            azimuth = parseInt(data.sectors[0]["antennaAzimuth"]) - 225;
          } else if (mountingType == "ROE") {
            azimuth = parseInt(data.sectors[0]["antennaAzimuth"]);
          }
          if (azimuth < 0) azimuth = azimuth + 360;
          this.setState({
            selectedSectorId: data.sectors[0].sectorId,
            assetMountingType: mountingType,
            assetAzimuth: azimuth.toString(),
            imageScale: 1,
            center: {
              lat: parseFloat(data.sectors[0].installation_latitude),
              lng: parseFloat(data.sectors[0].installation_longitude),
            },
          });
          let asset = {
            assetId: assetId,
            coOrdinates: {
              lat: data.sectors[0].installation_latitude,
              lng: data.sectors[0].installation_longitude,
            },
          };

          this.setState({
            selectedAsset: asset,
            sectorList: data.sectors,
            rmaHistoryList: data.rma_history,
            viewSector: true,
          });
        }
      }
    });
  }

  hideModal() {
    this.setState({ viewSector: false });
  }

  downloadImage(url: any, type: any, trackingId: any) {
    let img_ext = "";
    let base64_content = url.substring(url.indexOf(";base64,") + ";base64,".length, url.length - 1);
    switch (base64_content.charAt(0)) {
      case "/":
        img_ext = "jpg";
        break;
      case "i":
        img_ext = "png";
        break;
      default:
        img_ext = "jpg";
        break;
    }
    let img_name = trackingId + "-" + type + "." + img_ext;
    saveAs(url, img_name);
  }

  takeToOutdoor() {
    this.reduxStore["networkSelectedAsset"] = this.state.selectedAsset;
    this.props.history.push("/map/outdoor");
  }

  searchAsset = (e: any) => {
    if (e.target.value != "") {
      let searchType = "";
      if (this.state.searchSelected == "trackingid") {
        searchType = "tid";
      } else if (this.state.searchSelected == "siteid") {
        searchType = "sid";
      }
      requestAPICall("network_activity_search", { type: searchType, asset: e.target.value }, {}).then((data: any) => {
        if (data.responseCode == 200) {
          console.log("data : ", data.cloud_assets, data.sas_assets);
          if (data.cloud_assets.length !== 0 || data.sas_assets.length !== 0 || data.rma_assets.length !== 0) {
            let all_assets: any = [];
            data.cloud_assets?.map((cloud: any) => {
              all_assets.push({
                status: "cloud",
                trackingId: cloud[0],
                siteId: cloud[1],
              });
            });
            data.sas_assets?.map((sas: any) => {
              all_assets.push({
                status: "sas",
                trackingId: sas[0],
                siteId: sas[1],
              });
            });
            data.rma_assets?.map((rma: any) => {
              all_assets.push({
                status: "rma",
                trackingId: rma[0],
                siteId: rma[1],
              });
            });
            this.setState({ searchList: all_assets });
          } else {
            this.setState({ searchList: [] });
          }
        }
      });
    } else {
      this.setState({ searchList: [] });
    }
  };

  clearSearch = () => {
    $("#searchString").val("");
    this.searchAsset({ target: { value: "" } });
    this.setState(
      {
        requestExpanded: true,
        submittedExpanded: false,
        rmaExpanded: false,
        requestedList: [],
        submittedList: [],
        rmaList: [],
      },
      () => {
        this.getCloudAssets();
        this.getSASAssets();
        this.getRMAAssets();
      }
    );
  };

  searchAssetClicked = (val: any) => {
    let id = "";
    if (this.state.searchSelected == "trackingid") {
      id = val.trackingId;
    } else {
      id = val.siteId;
    }
    $("#searchString").val(id);
    this.searchAsset({ target: { value: "" } });

    requestAPICall("single_network_activity", { status: val.status, trackingid: val.trackingId }, {}).then((data) => {
      if (data.responseCode == 200) {
        if (data.asset.length !== 0) {
          if (val.status == "cloud") {
            this.setState({
              requestedList: data.asset,
              requestExpanded: true,
              submittedExpanded: false,
              rmaExpanded: false,
            });
          } else if (val.status == "rma") {
            this.setState({
              rmaList: data.asset,
              requestExpanded: false,
              submittedExpanded: false,
              rmaExpanded: true,
            });
          } else {
            this.setState({
              submittedList: data.asset,
              requestExpanded: false,
              submittedExpanded: true,
              rmaExpanded: false,
            });
          }
        }
      }
    });
  };

  hideImageModal = () => {
    this.setState({
      imageCard: false,
      image: {},
    });
  };

  showImageModal = () => {
    this.setState({
      imageCard: true,
    });
  };

  showRmaHistoryModal = () => {
    this.setState({
      rmaHistoryCard: true,
    });
  };

  hideRmaHistoryModal = () => {
    this.setState({
      rmaHistoryCard: false,
    });
  };

  getImage = (id: any, type: any, trackingId: any) => {
    this.setState({
      isLoading: true,
    });
    requestAPICall("get_image", { image_id: id }, {}).then((data) => {
      this.setState({
        isLoading: false,
      });
      if (data.responseCode == 200) {
        this.setState(
          {
            image: {
              url: data.image,
              type: type,
              trackingId: trackingId,
            },
          },
          () => {
            this.showImageModal();
          }
        );
      }
    });
  };

  getStatusColorSearch: any = {
    cloud: { backgroundColor: "#f49344" },
    sas: { backgroundColor: "#50d56e" },
    rma: { backgroundColor: "#be34fe" },
    "": {},
  };

  fetchAll = () => {
    if (this.state.requestedList.length == 0 || this.state.submittedList.length == 0 || this.state.rmaList.length == 0) {
      this.getCloudAssets();
      this.getSASAssets();
      this.getRMAAssets();
    }
  };

  requestedPullAndToggle = () => {
    this.fetchAll();
    this.setState({
      requestExpanded: true,
      submittedExpanded: false,
      rmaExpanded: false,
    });
  };

  submittedPullAndToggle = () => {
    this.fetchAll();
    this.setState({
      requestExpanded: false,
      submittedExpanded: true,
      rmaExpanded: false,
    });
  };

  rmaPullAndToggle = () => {
    this.fetchAll();
    this.setState({
      requestExpanded: false,
      submittedExpanded: false,
      rmaExpanded: true,
    });
  };

  tableHtml = () => {
    if (this.state.requestedList.length == 0 && this.state.fetchingData) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <span className="network-loader"></span>
        </div>
      );
    } else if (this.state.requestedList.length == 0 && !this.state.fetchingData) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <span className="font14">~ Nothing to show ~</span>
        </div>
      );
    } else
      return this.state.requestedList?.map((val: any, index: any) => {
        return (
          <React.Fragment key={`${index.toString()}-requested-list`}>
            <tr
              className="p-t-5 p-b-5 row-hover"
              style={{ borderTop: "solid 1px #ededef", cursor: "pointer", height: "50px" }}
              onClick={() => {
                this.getSectors(val.assetId, 2, val.mountingType);
              }}
            >
              <td className="w4"></td>
              <td className="w10">{val.trackingId}</td>
              <td className="w18">{val.cbrsNodeName}</td>
              <td className="w14">{val.siteId}</td>
              <td className="w10">{val.mountingType}</td>
              <td className="w16" style={{ textAlign: "center" }}>
                {val.cbsdCategory === "DEVICE_CATEGORY_B" ? "B" : "A"}
              </td>
              <td className="w10">{val.installer}</td>
              <td className="w18">{val.lastModified}</td>
            </tr>
          </React.Fragment>
        );
      });
  };

  submittedTableHtml = () => {
    if (this.state.submittedList.length == 0 && this.state.fetchingData) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <span className="network-loader"></span>
        </div>
      );
    } else if (this.state.submittedList.length == 0 && !this.state.fetchingData) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <span className="font14">~ Nothing to show ~</span>
        </div>
      );
    } else
      return this.state.submittedList?.map((val: any, index: any) => {
        return (
          <React.Fragment key={`${index.toString()}-submitted-list`}>
            <tr
              className="p-t-5 p-b-5 row-hover"
              style={{ borderTop: "solid 1px #ededef", cursor: "pointer", height: "50px" }}
              onClick={() => {
                this.getSectors(val.assetId, 4, val.mountingType);
              }}
            >
              <td className="w4"></td>
              <td className="w10">{val.trackingId}</td>
              <td className="w18">{val.cbrsNodeName}</td>
              <td className="w14">{val.siteId}</td>
              <td className="w10">{val.mountingType}</td>
              <td className="w16" style={{ textAlign: "center" }}>
                {val.cbsdCategory === "DEVICE_CATEGORY_B" ? "B" : "A"}
              </td>
              <td className="w10">{val.installer}</td>
              <td className="w18">{val.lastModified}</td>
            </tr>
          </React.Fragment>
        );
      });
  };

  rmaTableHtml = () => {
    if (this.state.rmaList.length == 0 && this.state.fetchingData) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <span className="network-loader"></span>
        </div>
      );
    } else if (this.state.rmaList.length == 0 && !this.state.fetchingData) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}>
          <span className="font14">~ Nothing to show ~</span>
        </div>
      );
    } else
      return this.state.rmaList?.map((val: any, index: any) => {
        return (
          <React.Fragment key={`${index.toString()}-rma-list`}>
            <tr
              className="p-t-5 p-b-5 row-hover"
              style={{ borderTop: "solid 1px #ededef", cursor: "pointer", height: "50px" }}
              onClick={() => {
                this.getSectors(val.assetId, 2, val.mountingType);
              }}
            >
              <td className="w4"></td>
              <td className="w10">{val.trackingId}</td>
              <td className="w18">{val.cbrsNodeName}</td>
              <td className="w14">{val.siteId}</td>
              <td className="w10">{val.mountingType}</td>
              <td className="w16" style={{ textAlign: "center" }}>
                {val.cbsdCategory === "DEVICE_CATEGORY_B" ? "B" : "A"}
              </td>
              <td className="w10">{val.installer}</td>
              <td className="w18">{val.lastModified}</td>
            </tr>
          </React.Fragment>
        );
      });
  };

  getMountingTypeImage: any = {
    Aerial: aerialImage,
    ROE: roeImage,
    SMB: smbImage,
    "": smbImage,
  };

  getSectorStatusTitle: any = {
    Cloud: "Requested By: ",
    SAS: "Submitted By: ",
    RMA: "Replaced By: ",
    "": "Action By: ",
  };

  render() {
    return (
      <div>
        <div className="container-fluid" style={{ overflow: "hidden" }}>
          <div className="row" style={{ backgroundColor: "#F9FAFC" }}>
            <div className="m-t-20">
              <div className="col-md-12">
                <div className="card networkcard" style={{ margin: "0px", position: "relative" }}>
                  <div id="activitytab" className="card-body" style={{ padding: "20px 30px" }}>
                    <div className="row" style={{ paddingLeft: "20px" }}>
                      <div
                        style={{ marginRight: "12px", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ searchSelected: "trackingid" });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ searchSelected: "trackingid" });
                          }
                        }}
                      >
                        <span
                          style={
                            this.state.searchSelected == "trackingid"
                              ? { fontWeight: "bold", color: "#4f585f", userSelect: "none" }
                              : { color: "#4f585f", userSelect: "none" }
                          }
                          className="font14"
                        >
                          Tracking ID
                        </span>
                      </div>
                      <div
                        style={{ marginLeft: "12px", cursor: "pointer" }}
                        onClick={() => {
                          this.setState({ searchSelected: "siteid" });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.setState({ searchSelected: "siteid" });
                          }
                        }}
                      >
                        <span
                          style={
                            this.state.searchSelected == "siteid"
                              ? { fontWeight: "bold", color: "#4f585f", userSelect: "none" }
                              : { color: "#4f585f", userSelect: "none" }
                          }
                          className="font14"
                        >
                          Site ID
                        </span>
                      </div>
                    </div>

                    {/* Search Bar div */}
                    <div className="row card-body m-t-10 network-search-bar">
                      <i className="material-icons-outlined" style={{ marginRight: "10px", marginLeft: "12px", color: "#C4C4C4", cursor: "default" }}>
                        search
                      </i>
                      <input
                        id="searchString"
                        name="searchString"
                        type={"text"}
                        onChange={this.searchAsset}
                        placeholder="Search"
                        style={{ outline: "none" }}
                        className="b-none"
                      ></input>
                      <i
                        onClick={this.clearSearch}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.clearSearch();
                          }
                        }}
                        className="material-icons-outlined"
                        style={{
                          marginRight: "10px",
                          marginLeft: "10px",
                          color: "#C4C4C4",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                      >
                        close
                      </i>
                    </div>

                    {this.state.searchList.length > 0 ? (
                      <div className="network-search">
                        {this.state.searchList?.map((val: any, index: any) => {
                          return (
                            <div
                              key={`${index.toString()}-search-asset`}
                              onClick={() => {
                                this.searchAssetClicked(val);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.searchAssetClicked(val);
                                }
                              }}
                              className="row search-list"
                            >
                              <div className="col-2 center-dots p-l-0 p-r-0">
                                <span className="status-dot" style={this.getStatusColorSearch[val.status]}></span>
                              </div>
                              <div className="col-10 p-l-0">
                                <div>
                                  <span className="netactid">{"Tracking ID: "}</span>
                                  <span className="netactdetail">{val.trackingId}</span>
                                </div>
                                <div>
                                  <span className="netactid">{"Site ID: "}</span>
                                  <span className="netactdetail">{val.siteId}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    {/* Request for approval div */}
                    <div
                      className="row m-t-20"
                      style={{
                        background: "#ededf0",
                        borderRadius: "8px",
                        alignItems: "center",
                        padding: "10px 20px 10px 5%",
                        cursor: "pointer",
                      }}
                      onClick={this.requestedPullAndToggle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.requestedPullAndToggle();
                        }
                      }}
                    >
                      <span className="col-md-11 p-0" style={{ fontWeight: "bold", color: "#4f585f" }}>
                        Cloud
                      </span>
                      {/* Expand button div for request for approval */}
                      <div className="col-md-1 p-0" style={{ display: "flex", justifyContent: "center" }}>
                        {this.state.requestExpanded ? (
                          <i className="material-icons-outlined" style={{ color: "#4f585f" }}>
                            expand_less
                          </i>
                        ) : (
                          <i className="material-icons-outlined" style={{ color: "#4f585f" }}>
                            expand_more
                          </i>
                        )}
                      </div>
                    </div>

                    {/* table of request for approval */}
                    <div
                      className="row cloudbody"
                      style={
                        this.state.requestExpanded
                          ? {
                              height: "calc(-400px + 100vh)",
                              boxShadow: "0px 3px 4px #0000001a",
                              marginTop: "10px",
                              borderRadius: "8px",
                              overflow: "scroll",
                            }
                          : { display: "none" }
                      }
                    >
                      <table
                        style={{
                          width: "100%",
                          margin: "0px 10px 10px 10px",
                          borderCollapse: "separate",
                          borderSpacing: "0 5px",
                        }}
                      >
                        <thead
                          style={{
                            width: "100%",
                            display: "table",
                            top: "0",
                            position: "sticky",
                            zIndex: 20,
                            backgroundColor: "#ffffff",
                            margin: "0px 0px 0px 0px",
                            height: "30px",
                          }}
                        >
                          <tr>
                            <th className="w4"></th>
                            <th className="w10">Tracking ID</th>
                            <th className="w18">CBRS Node Name</th>
                            <th className="w14">Site ID</th>
                            <th className="w10">Mounting Type</th>
                            <th className="w16" style={{ textAlign: "center" }}>
                              CBSD Category
                            </th>
                            {/* <th className="w15">Requested By</th> */}
                            <th className="w10">Installer</th>
                            <th className="w18">Last Modified</th>
                          </tr>
                        </thead>

                        <tbody style={{ display: "table", width: "100%", overflow: "scroll" }}>{this.tableHtml()}</tbody>
                      </table>
                    </div>

                    {/* ================================================================================ */}

                    {/* Submitted to sas div */}
                    <div
                      className="row m-t-20"
                      style={{
                        background: "#ededf0",
                        borderRadius: "8px",
                        alignItems: "center",
                        padding: "10px 20px 10px 5%",
                        cursor: "pointer",
                      }}
                      onClick={this.submittedPullAndToggle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.submittedPullAndToggle();
                        }
                      }}
                    >
                      <span className="col-md-11 p-0" style={{ fontWeight: "bold", color: "#4f585f" }}>
                        SAS
                      </span>
                      {/* expand button for submitted to sas */}
                      <div className="col-md-1 p-0" style={{ display: "flex", justifyContent: "center" }}>
                        {this.state.submittedExpanded ? (
                          <i className="material-icons-outlined" style={{ color: "#4f585f" }}>
                            expand_less
                          </i>
                        ) : (
                          <i className="material-icons-outlined" style={{ color: "#4f585f" }}>
                            expand_more
                          </i>
                        )}
                      </div>
                    </div>

                    {/* Table for submitted to SAS */}
                    <div
                      className="row sasbody"
                      style={
                        this.state.submittedExpanded
                          ? {
                              height: "calc(-400px + 100vh)",
                              boxShadow: "0px 3px 4px #0000001a",
                              marginTop: "10px",
                              borderRadius: "8px",
                              overflow: "scroll",
                            }
                          : { display: "none" }
                      }
                    >
                      <table
                        style={{
                          width: "100%",
                          margin: "0px 10px 10px 10px",
                          borderCollapse: "separate",
                          borderSpacing: "0 5px",
                        }}
                      >
                        <thead
                          style={{
                            width: "100%",
                            display: "table",
                            top: "0",
                            position: "sticky",
                            zIndex: 20,
                            backgroundColor: "#ffffff",
                            margin: "0px 0px 0px 0px",
                            height: "30px",
                          }}
                        >
                          <tr>
                            <th className="w4"></th>
                            <th className="w10">Tracking ID</th>
                            <th className="w18">CBRS Node Name</th>
                            <th className="w14">Site ID</th>
                            <th className="w10">Mounting Type</th>
                            <th className="w16" style={{ textAlign: "center" }}>
                              CBSD Category
                            </th>
                            {/* <th className="w15">Requested By</th> */}
                            <th className="w10">Installer</th>
                            <th className="w18">Last Modified</th>
                          </tr>
                        </thead>
                        <tbody style={{ display: "table", width: "100%", overflow: "scroll" }}>{this.submittedTableHtml()}</tbody>
                      </table>
                    </div>
                    {/* ==================================================== */}
                    {/* RMA div */}
                    <div
                      className="row m-t-20"
                      style={{
                        background: "#ededf0",
                        borderRadius: "8px",
                        alignItems: "center",
                        padding: "10px 20px 10px 5%",
                        cursor: "pointer",
                      }}
                      onClick={this.rmaPullAndToggle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.rmaPullAndToggle();
                        }
                      }}
                    >
                      <span className="col-md-11 p-0" style={{ fontWeight: "bold", color: "#4f585f" }}>
                        RMA
                      </span>
                      {/* Expand button div for rma */}
                      <div className="col-md-1 p-0" style={{ display: "flex", justifyContent: "center" }}>
                        {this.state.rmaExpanded ? (
                          <i className="material-icons-outlined" style={{ color: "#4f585f" }}>
                            expand_less
                          </i>
                        ) : (
                          <i className="material-icons-outlined" style={{ color: "#4f585f" }}>
                            expand_more
                          </i>
                        )}
                      </div>
                    </div>

                    {/* table of rma */}
                    <div
                      className="row rmabody"
                      style={
                        this.state.rmaExpanded
                          ? {
                              height: "calc(-400px + 100vh)",
                              boxShadow: "0px 3px 4px #0000001a",
                              marginTop: "10px",
                              borderRadius: "8px",
                              overflow: "scroll",
                            }
                          : { display: "none" }
                      }
                    >
                      <table
                        style={{
                          width: "100%",
                          margin: "0px 10px 10px 10px",
                          borderCollapse: "separate",
                          borderSpacing: "0 5px",
                        }}
                      >
                        <thead
                          style={{
                            width: "100%",
                            display: "table",
                            top: "0",
                            position: "sticky",
                            zIndex: 20,
                            backgroundColor: "#ffffff",
                            margin: "0px 0px 0px 0px",
                            height: "30px",
                          }}
                        >
                          <tr>
                            <th className="w4"></th>
                            <th className="w10">Tracking ID</th>
                            <th className="w18">CBRS Node Name</th>
                            <th className="w14">Site ID</th>
                            <th className="w10">Mounting Type</th>
                            <th className="w16" style={{ textAlign: "center" }}>
                              CBSD Category
                            </th>
                            {/* <th className="w15">Requested By</th> */}
                            <th className="w10">Installer</th>
                            <th className="w18">Last Modified</th>
                          </tr>
                        </thead>

                        <tbody style={{ display: "table", width: "100%", overflow: "scroll" }}>{this.rmaTableHtml()}</tbody>
                      </table>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal for popup of asset installation details */}

        <Modal show={this.state.viewSector} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-md" style={{ maxWidth: "60%" }}>
            <div className="modal-content" style={{ borderRadius: "8px" }}>
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px", display: "flex", alignItems: "center" }}>
                  <h1 className="modal-title" style={{ marginTop: "0px" }}>
                    Asset Details
                  </h1>
                  {this.state.rmaHistoryList.length != 0 ? (
                    <div
                      className="rma-history-button"
                      onClick={this.showRmaHistoryModal}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.showRmaHistoryModal();
                        }
                      }}
                    >
                      <span className="netactid rma-history-button-text" style={{ color: "#2559ad" }}>
                        RMA History
                      </span>
                    </div>
                  ) : null}
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal();
                      }
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>

                <div className="row m-t-10">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="nav nav-tabs sectab font12" id="secTab" role="tablist">
                          {this.state.sectorList?.map((sector: any, index: any) => {
                            let wid = (100 / this.state.sectorList.length).toString() + "%";
                            return sector["sectorId"] ? (
                              <div style={{ width: wid }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({
                                      selectedSectorId: sector["sectorId"],
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.setState({
                                        selectedSectorId: sector["sectorId"],
                                      });
                                    }
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${this.state.selectedSectorId === sector["sectorId"] ? "active" : ""} p-l-0`}
                                    style={{ padding: "0.5rem 0rem" }}
                                    id="sec1-tab"
                                    data-toggle="tab"
                                    href="#sec1"
                                    role="tab"
                                    aria-controls="sec1"
                                    aria-selected={this.state.selectedSectorId === sector["sectorId"]}
                                  >
                                    Sector{" " + sector["sectorId"]}
                                  </a>
                                </li>
                                {this.state.sectorList.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    {this.state.sectorList
                      .filter((sector: any) => sector["sectorId"] == this.state.selectedSectorId)
                      ?.map((sector: any, index: any) => {
                        return (
                          <div
                            key={`${index.toString()}-sector-ids`}
                            className={sector["sectorId"] ? "row m-t-10 justify-content-center" : "row justify-content-center"}
                            style={{ padding: "0 10px" }}
                          >
                            <div
                              className="col-12"
                              style={{
                                border: "1px solid #E3E3E3",
                                padding: "10px",
                                overflow: "hidden auto",
                                maxHeight: "490px",
                                borderRadius: "8px",
                              }}
                            >
                              <div className="row m-b-10">
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">Tracking ID:</span>
                                    <span className="netactdetail">{sector["tracking_id"]}</span>
                                  </div>
                                  <div>
                                    <span className="netactid">Site ID:</span>
                                    <span className="netactdetail">{sector["site_id"]}</span>
                                    {sector["site_image"] !== "" && sector["site_image"] !== undefined && sector["site_image"] !== null ? (
                                      <span>
                                        <i
                                          className="imagelink fa fa-picture-o"
                                          onClick={() => {
                                            this.getImage(sector["site_image"], "site", sector["tracking_id"]);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.getImage(sector["site_image"], "site", sector["tracking_id"]);
                                            }
                                          }}
                                        ></i>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">{this.getSectorStatusTitle[sector["status"] ?? ""]}</span>
                                    <span className="netactdetail">{sector["actionBy"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">{"Status: "}</span>
                                    <span className="netactdetail">{sector["status"]}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 p-0 text-center searchborder"></div>
                              <div className="netacthead m-t-10">Installation Parameters</div>
                              <div className="row m-t-10 m-b-10">
                                <div className="col-6 p-r-0">
                                  <div className="m-b-5">
                                    <span className="netactid">Latitude:</span>
                                    <span className="netactdetail">{sector["installation_latitude"]}º</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Longitude:</span>
                                    <span className="netactdetail">{sector["installation_longitude"]}º</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Height (m):</span>
                                    <span className="netactdetail">{sector["height"]}</span>
                                    {sector["heightImage"] !== "" && sector["heightImage"] !== undefined && sector["heightImage"] !== null ? (
                                      <span>
                                        <i
                                          className="imagelink fa fa-picture-o"
                                          onClick={() => {
                                            this.getImage(sector["heightImage"], "height", sector["tracking_id"]);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              this.getImage(sector["heightImage"], "height", sector["tracking_id"]);
                                            }
                                          }}
                                        ></i>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="m-b-5">
                                    <span className="netactid">Height Type:</span>
                                    <span className="netactdetail">{sector["heightType"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">HorizantalAccuracy (m):</span>
                                    <span className="netactdetail">{sector["horizantalAccuracy"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Vertical Accuracy (m):</span>
                                    <span className="netactdetail">{sector["verticalAccuracy"]}</span>
                                  </div>
                                  <div>
                                    <span className="netactid">Indoor deployment:</span>
                                    <span className="netactdetail">{sector["indoor_deployment"]}</span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">HAAT:</span>
                                    <span className="netactdetail">{sector["HAAT"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Antenna Azimuth (Degrees):</span>
                                    <span className="netactdetail">{sector["antennaAzimuth"]}</span>
                                    {sector["azimuthImage"] !== "" && sector["azimuthImage"] !== undefined && sector["azimuthImage"] !== null ? (
                                      <i
                                        className="imagelink fa fa-picture-o"
                                        onClick={() => {
                                          this.getImage(sector["azimuthImage"], "azimuth", sector["tracking_id"]);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.getImage(sector["azimuthImage"], "azimuth", sector["tracking_id"]);
                                          }
                                        }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="m-b-5">
                                    <span className="netactid">Antenna Downtilt (Degrees):</span>
                                    <span className="netactdetail">{sector["downtilt"]}</span>
                                    {sector["downtiltImage"] !== "" && sector["downtiltImage"] !== undefined && sector["downtiltImage"] !== null ? (
                                      <i
                                        className="imagelink fa fa-picture-o"
                                        onClick={() => {
                                          this.getImage(sector["downtiltImage"], "downtilt", sector["tracking_id"]);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            this.getImage(sector["downtiltImage"], "downtilt", sector["tracking_id"]);
                                          }
                                        }}
                                      ></i>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Antenna Gain (dBi):</span>
                                    <span className="netactdetail">{sector["antennaGain"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Eirp Capability (dBm):</span>
                                    <span className="netactdetail">{sector["eirbCapability"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Antenna Beamwidth (Degrees):</span>
                                    <span className="netactdetail">{sector["antennaBeamwidth"]}</span>
                                  </div>
                                  <div>
                                    <span className="netactid">Antenna Model:</span>
                                    <span className="netactdetail">{sector["antennaModel"]}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 p-0 text-center searchborder"></div>
                              <div className="netacthead m-t-10">CBSD Info</div>
                              <div className="row m-t-10 m-b-10">
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">FCC ID:</span>
                                    <span className="netactdetail">{sector["fccId"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">Serial ID:</span>
                                    <span className="netactdetail">{sector["sectorSerial"]}</span>
                                  </div>
                                  <div className="m-b-5">
                                    <span className="netactid">CBSD Vendor Model:</span>
                                    <span className="netactdetail">{sector["CBSDVendorModal"]}</span>
                                  </div>
                                  <div>
                                    <span className="netactid">Software Version:</span>
                                    <span className="netactdetail">{sector["softwareVersion"]}</span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">Hardware Version:</span>
                                    <span className="netactdetail">{sector["hardwareVersion"]}</span>
                                  </div>
                                  <div>
                                    <span className="netactid">Firmware Version:</span>
                                    <span className="netactdetail">{sector["firmwareVersion"]}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 p-0 text-center searchborder"></div>
                              <div className="netacthead m-t-10">Optional</div>
                              <div className="row m-t-10">
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">CallSign:</span>
                                    {sector["callsign"] == "" ? (
                                      <span className="netactdetail">-</span>
                                    ) : (
                                      <span className="netactdetail">{sector["callsign"]}</span>
                                    )}
                                  </div>

                                  <div>
                                    <span className="netactid">Grouping Param:</span>
                                    <span className="netactdetail">{sector["groupingParam"]}</span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="m-b-5">
                                    <span className="netactid">Category:</span>
                                    <span className="netactdetail">{sector["CBSDCategory"]}</span>
                                  </div>
                                  <div>
                                    <span className="netactid">Air Interface:</span>
                                    <span className="netactdetail">{sector["airInterface"]}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {/* MAP */}
                  <div className="col-md-4" style={{ overflow: "hidden auto" }}>
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#2559ad",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "5px",
                        textAlign: "center",
                        height: "7%",
                      }}
                      onClick={() => {
                        this.takeToOutdoor();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.takeToOutdoor();
                        }
                      }}
                    >
                      <span className="netactid" style={{ cursor: "pointer", color: "#ffffff" }}>
                        Show on Map
                      </span>
                    </div>
                    <div style={{ height: "2%" }}></div>
                    <GoogleMap
                      id="searchbox"
                      clickableIcons={false}
                      mapContainerStyle={{ height: "91%", borderRadius: "8px" }}
                      zoom={22}
                      center={this.state.center}
                      onZoomChanged={() => {
                        this.setState({ imageScale: parseInt(this._map?.getZoom()) ** 2 * 0.002 });
                      }}
                      onLoad={(map: any) => {
                        this._map = map;
                      }}
                      options={{
                        mapTypeId: "hybrid",
                        zoomControl: true,
                        tilt: 0,
                        draggable: false,
                        disableDoubleClickZoom: true,
                        disableDefaultUI: true,
                        styles: [
                          {
                            featureType: "poi",
                            stylers: [{ visibility: "off" }],
                          },
                        ],
                      }}
                    >
                      <img
                        alt="assetImage"
                        src={this.getMountingTypeImage[this.state.assetMountingType ?? ""]}
                        style={{
                          position: "relative",
                          top: "50%",
                          transition: "0.5s",
                          transform: "translate(-50%, -50%) scale(" + this.state.imageScale + ") rotate(" + this.state.assetAzimuth + "deg)",
                          right: "-50%",
                        }}
                      ></img>
                    </GoogleMap>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal show={this.state.imageCard} handleClose={this.hideImageModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-md" style={{ maxHeight: "100vh" }}>
            <div className="modal-content" style={{ borderRadius: "8px", width: "fit-content", height: "fit-content" }}>
              <div className="modal-body">
                <div className="row" style={{ padding: "5px 10px", alignItems: "center" }}>
                  <h1
                    className="modal-title"
                    style={{
                      marginTop: "0px",
                      textTransform: "capitalize",
                    }}
                  >
                    {this.state.image.type} Image
                  </h1>
                  <i
                    style={{ marginLeft: "20px", color: "#2559ad", cursor: "pointer" }}
                    className="fas fa-download"
                    onClick={() => {
                      this.downloadImage(this.state.image.url, this.state.image.type, this.state.image.trackingId);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        this.downloadImage(this.state.image.url, this.state.image.type, this.state.image.trackingId);
                      }
                    }}
                  ></i>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideImageModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideImageModal();
                      }
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div>
                  <img alt="download-url" src={this.state.image.url} style={{ cursor: "default", height: "60vh", borderRadius: "8px" }} />
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal show={this.state.rmaHistoryCard} handleClose={this.hideRmaHistoryModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-md" style={{ maxHeight: "100vh" }}>
            <div className="modal-content" style={{ borderRadius: "8px", width: "fit-content", height: "fit-content" }}>
              <div className="modal-body">
                <div className="row" style={{ padding: "5px 10px", alignItems: "center" }}>
                  {/* <img className='' src={closeIconImg} /> */}
                  <h1
                    className="modal-title"
                    style={{
                      marginTop: "0px",
                      textTransform: "capitalize",
                    }}
                  >
                    {"RMA History"}
                  </h1>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideRmaHistoryModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideRmaHistoryModal();
                      }
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div
                  className="row"
                  style={{
                    height: "60vh",
                    width: "45vw",
                    marginTop: "10px",
                    overflow: "scroll",
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      margin: "0px 10px",
                      borderCollapse: "collapse",
                      borderSpacing: "0 5px",
                    }}
                  >
                    <thead
                      style={{
                        width: "100%",
                        display: "table",
                        top: "0",
                        position: "sticky",
                        zIndex: 20,
                        backgroundColor: "#ededef",
                        margin: "0px 0px 0px 0px",
                        height: "50px",
                      }}
                    >
                      <tr>
                        <th style={{ padding: "0px 10px" }} className="w50">
                          Old Serial Number
                        </th>
                        <th style={{ padding: "0px 10px" }} className="w50">
                          Replaced On
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ display: "table", width: "100%" }}>
                      {this.state.rmaHistoryList?.map((history: any, index: any) => {
                        return (
                          <React.Fragment key={`${index.toString()}-rma-history`}>
                            <tr className="p-t-5 p-b-5" style={{ borderTop: "solid 1px #ededef", cursor: "default", height: "50px" }}>
                              <td style={{ padding: "0px 10px" }} className="w50">
                                {history.serialNumber}
                              </td>
                              <td style={{ padding: "0px 10px" }} className="w50">
                                {history.timestamp}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <IonLoading isOpen={this.state.isLoading} onDidDismiss={() => this.setState({ isLoading: false })} message={"Loading..."} />
      </div>
    );
  }
}
export default withRouter(withIonLifeCycle(NetworkActivity));
