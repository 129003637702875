import React, { Component } from "react";
import sort from "../assets/img/checked.png";
import Modal from "./modal";
import { requestAPICall } from "../service/apiHandler";
import Dropzone from "react-dropzone";
import { IonAlert, IonLoading } from "@ionic/react";
import XLSX from "xlsx";
import { TablePagination } from "@material-ui/core";
import { saveAs } from "file-saver";

const INITIAL_STATE = {
  card: "",
  show: false,
  status_show: false,
  path: "",
  status_list: [],
};
let cbsd_list: any = [];
let installation_list: any = [];
let optional_list: any = [];
let asset_property_list: any = [];
let global_list: any = [];
let sector_list: any = [];
let others_list: any = [];
let unit: any = "";
let status_list: any = [];
let bind_list: any = [];
let request_list: any = [];
let asset_status_list: any = [];
let select_vendor = "";
const operationalStatusColors: any = {
  "Field Tech": "#3474fe",
  Cloud: "#f49344",
  SAS: "#50d56e",
  Offline: "rgb(226, 43, 43)",
  "In Design": "#00D56E",
  "On Air": "#FCD369",
  "In Construction": "#0059B2",
  "In Zoning": "#0000A0",
  "Site Acquisition": "#8B4513",
  Others: "rgb(226, 43, 43)",
};

export default class SitedataDetails extends Component {
  state: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.state = {
      card1: false,
      show: false,
      files: [],
      list_asset: [],
      sector_details: [],
      status: "",
      asset_type_id: 1,
      asset_id: 0,
      sector_id: 0,
      asset_name: "",
      serial_id: 0,
      bind_list: [],
      showbutton: false,
      is_loader: false,
      alertbox: false,
      selectedSector: 0,
      assetType: "AP-outdoor",
      sheet_name: "Outdoor_CBSD",
      allAssets: [],
      assetStatus: "",
      asset_property_list: [],
      sector_property_list: [],
      asset_cpe_List: [],
      installationList: [],
      assetVendorList: [],
      assetModelList: [],
      select_asset_type_id: 1,
      sectorClickId: "",
      selected_vendor_id: "",
      sector_id_list: [],
      selected_vendor_name: "",
      selected_model_name: "",
      all_vendor_list: [],
      delete_asset_id: "",
      delete_sector_id: "",
      selected_asset_id: 0,
      delete_type: "asset",
      selected_data: "",
      show_asset_details: false,
      show_sector_details: false,
      asset_list: [],
      antenna_files: [],
      height_image_files: [],
      azimuth_image_files: [],
      down_tilt_image_files: [],
      image_value: "",
      selected_image_type: "",
      check_arrow: false,
      sector_check_arrow: false,
      heightImage: null,
      azimuthImage: null,
      downtiltImage: null,
      totalDataCount: 0,
      currentPage: 0,
      rowsPerPage: 10,
    };
    this.import_files = this.import_files.bind(this);
    this.list_asset = this.list_asset.bind(this);
    this.onChange = this.onChange.bind(this);
    this.update_asset = this.update_asset.bind(this);
    this.dataRowsPerPageChanged = this.dataRowsPerPageChanged.bind(this);
    this.dataPageChanged = this.dataPageChanged.bind(this);
  }
  componentDidMount() {
    this.list_asset();
    this.list_asset_status();
  }
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };
  setAssetId(assetType: any) {
    this.setState({
      is_loader: true,
    });
    this.setState({
      sheet_name: "Outdoor_CBSD",
      assetType: assetType,
      asset_type_id: 1,
      select_asset_type_id: 1,
      show_asset_details: false,
      show_sector_details: false,
      check_arrow: false,
      sector_check_arrow: false,
    });
    let asset_list: any = [];
    this.state.allAssets?.forEach((asset: any) => {
      asset_list.push(asset);
    });
    this.setState({
      asset_list: asset_list,
      is_loader: false,
    });
  }

  hideModal1 = () => {
    this.setState({
      card1: false,
    });
  };
  listVendorAPI() {
    requestAPICall(
      "list_particular_vendor",
      {
        asset_type_id: this.state.select_asset_type_id,
      },
      {}
    ).then((data) => {
      if (data["vendor_list"] !== undefined && data["vendor_list"].length !== 0) {
        this.setState({
          assetVendorList: data["vendor"],
          selected_vendor_name: data["vendor"][0],
          all_vendor_list: data["vendor_list"],
        });
        if (this.state.select_asset_type_id !== 1) {
          let response_list = cbsd_list.filter((cbsd: any) => cbsd.propertyName === "cbsd_vendor_model");
          if (response_list[0]["value"] !== undefined) {
            let value = response_list[0]["value"].split("-");
            let vendor = data["vendor_list"].filter((vendor: any) => vendor.venor_name === value[0]);
            this.setState({
              assetModelList: vendor,
              selected_vendor_name: value[0],
              selected_model_name: value[1],
            });
          } else {
            let vendor = data["vendor_list"].filter((vendor: any) => vendor.venor_name === data["vendor"][0]);
            this.setState({
              assetModelList: vendor,
            });
          }
        } else {
          let vendor = data["vendor_list"].filter((vendor: any) => vendor.venor_name === data["vendor"][0]);
          this.setState({
            assetModelList: vendor,
          });
        }
      }
    });
  }
  list_asset = () => {
    this.setState({
      is_loader: true,
      asset_list: [],
    });
    let requestBody = {
      page: this.state.currentPage,
      limit: this.state.rowsPerPage,
    };
    requestAPICall("list_asset", {}, requestBody).then((data) => {
      this.setState({
        allAssets: data["assets"],
        totalDataCount: data["count"],
      });
      this.setState({ is_loader: false }, () => this.setAssetId(this.state.assetType));
    });
  };

  list_asset_status() {
    let requestBody = {};
    requestAPICall("list_asset_status", {}, requestBody).then((data) => {
      asset_status_list = data["assets"];
    });
  }
  showModal = (e: any) => {
    e.preventDefault();
    this.setState({
      card: e.target.id,
      show: true,
    });
  };

  hideModal = () => {
    this.setState({
      card: "",
      files: [],
      show: false,
    });
  };
  status_hideModal = () => {
    this.setState({
      status_show: false,
    });
  };
  onDrop = (files: any, type: any) => {
    if (type === "antenna_azimuth") {
      this.setState(
        {
          azimuth_image_files: files,
        },
        () => this.uploadFile(this.state.azimuth_image_files, type)
      );
    } else if (type === "height") {
      this.setState(
        {
          height_image_files: files,
        },
        () => this.uploadFile(this.state.height_image_files, type)
      );
    } else if (type === "down_tilt") {
      this.setState(
        {
          down_tilt_image_files: files,
        },
        () => this.uploadFile(this.state.down_tilt_image_files, type)
      );
    } else {
      this.setState({ files: files });
    }
  };
  uploadFile = (files: any, value: any) => {
    let filerequestBody = {};
    let picReader = new FileReader();
    let fileName = files[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    if (files[0].uploadType === undefined) {
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
        };
        this.setState({
          isLoading: true,
          image_value: picReader.result,
        });
        this.uploadImage(filerequestBody, value);
      };
      picReader.readAsDataURL(files[0]);
    }
  };

  setHeightImage = (value: any, data: any) => {
    if (value !== "height") return;
    installation_list?.map((installation: any) => {
      if (installation.propertyName === "height_image") {
        installation.value = data.file_id;
      }
    });
    this.state.sector_property_list?.map((sector: any) => {
      if (sector.sectorId_id === this.state.selectedSector) {
        sector.height_image = data.file_id;
      }
    });
  };

  setAzimuthImage = (value: any, data: any) => {
    if (value !== "antenna_azimuth") return;
    installation_list?.map((installation: any) => {
      if (installation.propertyName === "azimuth_image") {
        installation.value = data.file_id;
      }
    });
    this.state.sector_property_list?.map((sector: any) => {
      if (sector.sectorId_id === this.state.selectedSector) {
        sector.azimuth_image = data.file_id;
      }
    });
  };

  setDownTiltImage = (value: any, data: any) => {
    if (value !== "down_tilt") return;
    installation_list?.map((installation: any) => {
      if (installation.propertyName === "down_tilt_image") {
        installation.value = data.file_id;
      }
    });
    this.state.sector_property_list?.map((sector: any) => {
      if (sector.sectorId_id === this.state.selectedSector) {
        sector.down_tilt_image = data.file_id;
      }
    });
  };

  uploadImage = (filerequestBody: any, value: any) => {
    requestAPICall("upload_file", {}, filerequestBody).then((data) => {
      if (data["responseCode"] == 200) {
        this.setHeightImage(value, data);
        this.setAzimuthImage(value, data);
        this.setDownTiltImage(value, data);

        this.updateSectorDetails();

        this.setState({ isLoading: false }, () => {
          this.setState({
            image_show: false,
          });
        });
      } else {
        this.setState({ isLoading: false });
        this.setState({ alertbox: true, warnMessage: data.message });
      }
    });
  };

  import_files() {
    let url = "";
    if (this.state.assetType === "AP-outdoor") {
      url = "import_site_data_asset";
    } else if (this.state.assetType === "CPE") {
      url = "import_site_data_cpe";
    } else if (this.state.assetType === "AP-indoor") {
      url = "import_site_data_indoor";
    }
    this.setState({
      is_loader: true,
    });
    status_list = [];
    let picReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.files[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    picReader.onload = (e) => {
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: picReader.result,
      };
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        let requestBody = {
          file_path_id: data.file_id,
          file_path: data.file_path,
        };
        requestAPICall(url, {}, requestBody).then((data) => {
          status_list = data.asset_status;
          if (data.responseCode == 200) {
            this.list_asset();
            this.setState({
              show: false,
              status_show: true,
              is_loader: false,
            });
          } else {
            status_list = [];
            this.setState({
              show: false,
              status_show: false,
              warnMessage: data.message,
              alertbox: true,
            });
          }
        });
      });
    };
    picReader.readAsDataURL(this.state.files[0]);
    this.hideModal();
  }
  download_site_data = () => {
    this.setState({ is_loader: true });
    let requestBody = {
      sheet_name: this.state.sheet_name,
    };
    requestAPICall("download_files", {}, requestBody).then((data) => {
      let worksheet = XLSX.utils.aoa_to_sheet(data["file_content"]);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, this.state.sheet_name);
      let requestBody = {
        sheet_name: "Outdoor_Site_Sector",
      };
      if (this.state.assetType === "AP-outdoor") {
        requestAPICall("download_files", {}, requestBody).then((data) => {
          let worksheet1 = XLSX.utils.aoa_to_sheet(data["file_content"]);
          XLSX.utils.book_append_sheet(new_workbook, worksheet1, "Outdoor_Site_Sector");
          XLSX.writeFile(new_workbook, this.state.sheet_name + ".xlsx");
        });
      } else {
        XLSX.writeFile(new_workbook, this.state.sheet_name + ".xlsx");
      }
      this.setState({ is_loader: false });
    });
  };
  exportFile = () => {
    this.setState({ is_loader: true });
    requestAPICall("export_files_v2", {}, {}).then((data: any) => {
      this.setState({ is_loader: false });
      if (data.responseCode !== 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        return;
      }
      saveAs(data.url);
    });
  };

  getSector = (val: any, assetClickId: any) => {
    this.setState(
      {
        asset_id: val.assetId,
        select_asset_type_id: val.assetTypeId,
        assetClickId: assetClickId,
        check_arrow: true,
      },
      () => this.get_sector_details(val.assetId)
    );
  };

  setAssetIdDropDownLabel = () => {
    if (this.state.select_asset_type_id === 3) {
      this.set_dropdown_label("heightypeid2", "HEIGHT_TYPE_AGL");
      this.set_dropdown_label("indoordep2", "True");
      this.set_dropdown_label("optcalsign2", "1");
      this.set_dropdown_label("optcbsdcat2", "DEVICE_CATEGORY_A");
      this.set_dropdown_label("optairinter2", "RADIO_TECHNOLOGY_UNSPECIFIED");
      this.set_dropdown_label("measurecapab2", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT");
      this.set_dropdown_label("cbsdvendor2", this.state.assetVendorList[0]);
      this.set_dropdown_label("cbsdmodel2", this.state.assetModelList[0]);
    } else {
      this.set_dropdown_label("heightypeid3", "HEIGHT_TYPE_AGL");
      this.set_dropdown_label("indoordep3", "True");
      this.set_dropdown_label("optcalsign3", "1");
      this.set_dropdown_label("optcbsdcat3", "DEVICE_CATEGORY_A");
      this.set_dropdown_label("optairinter3", "RADIO_TECHNOLOGY_UNSPECIFIED");
      this.set_dropdown_label("measurecapab3", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT");
      this.set_dropdown_label("cbsd_vendor3", this.state.assetVendorList[0]);
      this.set_dropdown_label("cbsd_model3", this.state.assetModelList[0]);
    }
  };

  get_sector_details = (asset_id: number) => {
    bind_list = [];
    let requestBody = {};
    installation_list = [];
    optional_list = [];
    cbsd_list = [];
    sector_list = [];
    asset_property_list = [];
    if (this.state.select_asset_type_id !== 3 && this.state.select_asset_type_id !== 2) {
      this.get_sector_properties(asset_id);
      this.listVendorAPI();
      return;
    }
    requestAPICall("get_asset_details", { asset_id: asset_id }, requestBody).then((data) => {
      if (data.responseCode !== 200) return;
      request_list = data["properties"];

      sector_list = data["sector_id"];
      this.setState(
        {
          asset_type_id: data["assetTypeId"],
          asset_id: data["assetId"],
          asset_name: data["assetName"],
          serial_id: data["serialId"],
          assetStatus: data["operationalStatus"],
        },
        () => {
          this.getAssetEditableDetails();
        }
      );
      if (![2, 3, 4, 5].includes(data["assetTypeId"])) {
        this.setState(
          {
            sector_id: data["sector_id"],
          },
          () => this.listVendorAPI()
        );
        return;
      }
      installation_list = data["installation_params"]["installation"];
      optional_list = data["installation_params"]["optional"];
      cbsd_list = data["installation_params"]["cbsd"];
      global_list = data["installation_params"]["global"];
      others_list = data["installation_params"]["others"];

      let heightImg, azimuthImg, downtiltImg;

      data["installation_params"]["installation"]?.map((data: any) => {
        if (data.propertyName === "height_image") {
          heightImg = data.value;
        } else if (data.propertyName === "azimuth_image") {
          azimuthImg = data.value;
        } else if (data.propertyName === "down_tilt_image") {
          downtiltImg = data.value;
        }
      });

      this.setState(
        {
          cpe_list: data["properties"],
          heightImage: heightImg,
          azimuthImage: azimuthImg,
          downtiltImage: downtiltImg,
        },
        () => {
          this.listVendorAPI();
          this.setAssetIdDropDownLabel();
        }
      );
    });
  };
  get_sector_properties = (assetId: number) => {
    requestAPICall("get_all_sector_details", { asset_type_id: this.state.select_asset_type_id, asset_id: assetId }, {}).then((data) => {
      console.log(data);
      if (data.responseCode === 200) {
        bind_list = data["properties"];
        this.setState({
          sector_property_list: data["properties"],
        });
      }
    });
  };
  getAssetEditableDetails = () => {
    requestAPICall(
      "get_asset_editable_data",
      {
        asset_type: this.state.select_asset_type_id,
        asset_id: this.state.asset_id,
      },
      {}
    ).then((data) => {
      console.log(data);
      if (data.responseCode === 200) {
        asset_property_list = data["properties"];
        this.setState({
          asset_property_list: data["properties"],
        });
      }
    });
  };
  getSectorDetails = (sector_id: any, sectorClickId: any) => {
    this.setState({
      selectedSector: sector_id,
      sectorClickId: sectorClickId,
      sector_check_arrow: true,
      sector_id: sector_id,
    });
    requestAPICall(
      "get_sector_details",
      {
        asset_type_id: this.state.select_asset_type_id,
        asset_id: this.state.asset_id,
        sector_id: sector_id,
      },
      {}
    ).then((data) => {
      if (data.responseCode === 200) {
        installation_list = data["properties"]["installation"];
        optional_list = data["properties"]["optional"];
        cbsd_list = data["properties"]["cbsd"];
        global_list = data["properties"]["global"];
        others_list = data["properties"]["others"];

        let heightImg, azimuthImg, downtiltImg;

        data["properties"]["installation"]?.map((data: any) => {
          if (data.propertyName === "height_image") {
            heightImg = data.value;
          } else if (data.propertyName === "azimuth_image") {
            azimuthImg = data.value;
          } else if (data.propertyName === "down_tilt_image") {
            downtiltImg = data.value;
          }
        });

        this.setState({
          heightImage: heightImg,
          azimuthImage: azimuthImg,
          downtiltImage: downtiltImg,
        });

        let response_list = cbsd_list.filter((cbsd: any) => cbsd.propertyName === "cbsd_vendor_model");
        if (response_list[0]["value"] !== undefined) {
          let value = response_list[0]["value"].split("-");
          this.setState((prevState: any) => ({
            assetModelList: prevState.all_vendor_list.filter((vendor: any) => vendor.venor_name === value[0]),
          }));
          this.setState({
            selected_vendor_name: value[0],
            selected_model_name: value[1],
          });
        }
        this.set_dropdown_label("heightypeid1", "HEIGHT_TYPE_AGL");
        this.set_dropdown_label("indoordep1", "True");
        this.set_dropdown_label("optcalsign1", "1");
        this.set_dropdown_label("optcbsdcat1", "DEVICE_CATEGORY_A");
        this.set_dropdown_label("optairinter1", "RADIO_TECHNOLOGY_UNSPECIFIED");
        this.set_dropdown_label("measurecapab1", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT");
        this.set_dropdown_label("cbsdvendor1", this.state.assetVendorList[0]);
        this.set_dropdown_label("cbsdmodel1", this.state.assetModelList[0]);
        this.setState({
          showvalue: true,
        });
      }
    });
  };

  changeInstallationValues = (asset: any, e: any) => {
    if (asset !== "installation") return;
    installation_list?.map((property: any) => {
      if (property.propertyName === e.target.name) {
        property.value = e.target.value;
      }
    });
  };

  changeCbsdValues = (asset: any, e: any) => {
    if (asset !== "cbsd") return;
    cbsd_list?.map((property: any) => {
      if (property.propertyName === e.target.name) {
        property.value = e.target.value;
      }
    });
  };

  changeOptionalValues = (asset: any, e: any) => {
    if (asset !== "optional") return;
    optional_list?.map((property: any) => {
      if (property.propertyName === e.target.name) {
        property.value = e.target.value;
      }
    });
  };

  changeGlobalValues = (asset: any, e: any) => {
    if (asset !== "global") return;
    global_list?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
    });
  };

  changeOthersValues = (asset: any, e: any) => {
    if (asset === "others") {
      others_list?.map((property: any) => {
        if (property.propertyName === e.target.name) {
          property.value = e.target.value;
        }
      });
    }
  };

  changeAssetValues = (asset: any, e: any) => {
    if (asset !== "asset") return;
    asset_property_list?.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
    });
  };

  changeSectorValues = (asset: any, e: any) => {
    if (asset !== "sector") return;
    this.state.sector_property_list?.map((sector: any) => {
      if (sector.sectorId_id === this.state.sector_id) {
        if (e.target.name === "cell_id") {
          sector.cell_id = e.target.value;
        } else if (e.target.name === "pci") {
          sector.pci = e.target.value;
        } else if (e.target.name === "antenna_height") {
          sector.antenna_height = e.target.value;
        } else if (e.target.name === "frequence_band") {
          sector.frequence_band = e.target.value;
        } else if (e.target.name === "fcc_id") {
          sector.fcc_id = e.target.value;
        }
      }
    });
  };

  onChange(e: any, asset: any) {
    this.setState({
      [e.target.name]: e.target.value,
      status: e.target.value,
    });
    this.state.list_asset?.map((asset: any) => {
      let val = parseInt(e.target.name);
      if (asset.assetId === val) {
        asset.operationalStatus = e.target.value;
      }
    });
    this.changeInstallationValues(asset, e);
    this.changeCbsdValues(asset, e);
    this.changeOptionalValues(asset, e);
    this.changeGlobalValues(asset, e);
    this.changeAssetValues(asset, e);
    this.changeOthersValues(asset, e);
    this.changeSectorValues(asset, e);

    this.state.asset_list?.map((property: any) => {
      if (property.assetId === this.state.asset_id) {
        if (e.target.name === "serial_id") {
          property.serial_id = e.target.value;
        } else if (e.target.name === "cluster_name") {
          property.cluster_name = e.target.value;
        } else if (e.target.name === "deployment_type") {
          property.deployment_type = e.target.value;
        } else if (e.target.name === "latitude") {
          property.latitude = e.target.value;
        } else if (e.target.name === "longitude") {
          property.longitude = e.target.value;
        } else if (e.target.name === "customer_id") {
          property.customer_id = e.target.value;
        } else if (e.target.name === "enb_id") {
          property.enb_id = e.target.value;
        } else if (e.target.name === "ap_name") {
          property.ap_name = e.target.value;
        } else if (e.target.name === "structure_height") {
          property.structure_height = e.target.value;
        } else if (e.target.name === "cpe_name") {
          property.cpe_name = e.target.value;
        }
      }
    });
  }

  updateSectorDetails = () => {
    let sector_list = this.state.sector_property_list.filter((sector: any) => sector.sectorId_id === this.state.sector_id);
    let requestBody = {
      properties: {
        cbsd: cbsd_list,
        optional: optional_list,
        installtion: installation_list,
        global: global_list,
        others: others_list,
      },
      property: sector_list[0],
      vendorId: this.state.selected_vendor_id,
    };
    let url = "";
    if (this.state.select_asset_type_id === 1) {
      url = "update_sector_details";
    } else {
      url = "update_asset_install_details";
    }
    requestAPICall(
      url,
      {
        asset_type_id: this.state.select_asset_type_id,
        asset_id: this.state.asset_id,
        sector_id: this.state.sector_id,
      },
      requestBody
    ).then((data) => {
      this.setState({
        warnMessage: data.message,
        alertbox: true,
      });
    });
    this.list_asset();
  };

  select_onChange(e: any, type: any, property: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.set_dropdown_label(id, e.target.value);
    if (type === "cbsd") {
      cbsd_list?.map((cbsd: any) => {
        if (cbsd.propertyName === property) {
          cbsd.value = e.target.value;
        }
      });
    } else if (type === "optional") {
      optional_list?.map((optional: any) => {
        if (optional.propertyName === property) {
          optional.value = e.target.value;
        }
      });
    }
  }

  updateAssetProperties = () => {
    let requestBody = {
      properties: asset_property_list,
      sector_id: sector_list,
      assetName: this.state.asset_name,
      status: this.state.assetStatus,
    };
    requestAPICall(
      "edit_asset_details",
      {
        asset_type_id: this.state.select_asset_type_id,
        asset_id: this.state.asset_id,
      },
      requestBody
    ).then((data) => {
      this.setState({});
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          show_asset_details: false,
        });
        if (this.state.asset_type_id === 3) {
          this.updateSectorDetails();
        }
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          card1: false,
          show_asset_details: false,
        });
        this.get_sector_details(this.state.asset_id);
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };

  update_asset(e: any, asset: any) {
    let assetId = asset.assetId;
    let requestBody = {};
    let check = false;
    requestAPICall("get_asset_details", { asset_id: assetId }, requestBody).then((data) => {
      request_list = data["properties"];
      let new_request_list;
      try {
        new_request_list = request_list.filter((property: any) => property.propertyName !== "image");
      } catch (error) {}
      let sector_id_list: any = [];
      check = true;
      if (data["assetTypeId"] === 1) {
        sector_id_list = data["sector_id"].filter((sector_prop: any) => sector_prop.sector_id !== this.state.delete_sector_id);
        if (sector_id_list.length < 1) {
          check = false;
        }
      }
      let requestBody = {
        assetName: data["assetName"],
        assetTypeId: data["assetTypeId"],
        properties: new_request_list,
        status: this.state.assetStatus,
        sector_id: sector_id_list,
      };
      if (check) {
        requestAPICall("edit_asset_details", { asset_type_id: data["assetTypeId"], asset_id: assetId }, requestBody).then((data) => {
          this.setState({});
          if (data.responseCode == 200) {
            if (data["assetTypeId"] === 3) {
              this.updateSectorDetails();
            }
            this.setState({
              warnMessage: data.message,
              alertbox: true,
              card1: false,
              show_asset_details: false,
            });
            this.get_sector_details(assetId);
          } else {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
            });
          }
        });
        return;
      }
      this.setState({
        warnMessage: "Asset should be mapped with one sector",
        alertbox: true,
      });
    });
  }

  setAssetVendor = (e: any, vendor: any, id: any) => {
    try {
      select_vendor = e.target.value;
      this.setState({
        [e.target.name]: e.target.value,
      });
      this.set_dropdown_label(id, e.target.value);
      this.setState((prevState: any) => ({
        assetModelList: prevState.all_vendor_list.filter((vendor: any) => vendor.venor_name === e.target.value),
        selected_vendor_name: e.target.value,
      }));
    } catch (error) {}
  };

  setAssetModel(e: any, id: any) {
    try {
      this.setState({
        [e.target.name]: e.target.value,
        selected_model_name: e.target.value,
      });
      this.set_dropdown_label(id, e.target.value);
      let vendor_model = this.state.selected_vendor_name + "-" + e.target.value;
      cbsd_list?.map((cbsd: any) => {
        if (cbsd.propertyName === "cbsd_vendor_model") {
          cbsd.value = vendor_model;
        }
      });
    } catch (error) {}
  }

  setAssetStatus(e: any, asset: any) {
    this.setState({
      assetStatus: e.target.value,
    });
    this.state.asset_list?.map((property: any) => {
      if (property.assetId === asset.assetId) {
        property.operationalStatus = e.target.value;
      }
    });
  }

  height_type_change = (e: any, id: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.set_dropdown_label(id, e.target.value);
    installation_list?.map((property: any) => {
      if (property.propertyName === "height_type") {
        property.value = e.target.value;
      }
    });
  };
  set_dropdown_label(id: any, value: any) {
    console.log(id, value);
    if (value === "") {
      $("#" + id).css({
        top: "14px",
        fontSize: "14px",
      });
      $("#" + id).show();
    } else {
      $("#" + id).css({
        top: "5px",
        fontSize: "12px",
      });
      $("#" + id).show();
    }
  }

  measurement_capability(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.set_dropdown_label(id, e.target.value);
    if (e.target.value) {
      installation_list?.map((installation: any) => {
        if (installation.propertyName === "measurement_capability") {
          installation.value = e.target.value;
        }
      });
    }
  }

  cbsd_category(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.set_dropdown_label(id, e.target.value);
    optional_list?.map((optional: any) => {
      if (optional.propertyName === "cbsd_category") {
        optional.value = e.target.value;
      }
    });
  }

  air_interface(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.set_dropdown_label(id, e.target.value);
    optional_list?.map((optional: any) => {
      if (optional.propertyName === "air_interface") {
        optional.value = e.target.value;
      }
    });
  }

  indoor_deployment(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.set_dropdown_label(id, e.target.value);
    installation_list?.map((installation: any) => {
      if (installation.propertyName === "indoor_deployment") {
        installation.value = e.target.value;
      }
    });
  }

  delete_asset() {
    let requestbody = {};
    requestAPICall("delete_asset", { asset_id: this.state.delete_asset_id }, requestbody).then((data) => {
      this.setShowLoading(false);
      if (data.responseCode == 200) {
        this.setState({
          card1: false,
          warnMessage: data.message,
          alertbox: true,
        });
        this.list_asset();
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  }
  showModal1 = (asset: any, sector: any) => {
    if (sector !== "") {
      this.setState({
        card1: true,
        delete_asset_id: asset.assetId,
        delete_sector_id: sector.sectorId_id,
        delete_type: "sector",
        selected_data: asset,
      });
    } else if (asset == "image") {
      this.setState({
        card1: true,
        delete_type: "images",
      });
    } else {
      this.setState({
        card1: true,
        delete_asset_id: asset.assetId,
        delete_type: "asset",
        selected_data: asset,
      });
    }
  };

  edit_asset_value = (asset: any) => {
    this.setState(
      {
        show_asset_details: true,
        asset_id: asset.assetId,
        selected_asset_id: asset.assetId,
      },
      () => this.getAssetEditableDetails()
    );
  };

  sortByPriceAsc = (key: any) => {
    this.setState({
      show_sector_details: false,
    });
    this.ascending(key);
  };
  sortByPriceDesc = (key: any) => {
    this.setState({
      show_sector_details: false,
    });
    this.descending(key);
  };

  ascending = (key: any) => {
    return this.state.asset_list.sort(function (a: any, b: any) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (b[key] > a[key]) {
        return -1;
      }
      return 0;
    });
  };
  descending = (key: any) => {
    return this.state.asset_list.sort(function (a: any, b: any) {
      if (b[key] > a[key]) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      return 0;
    });
  };
  showImageModel = (image: any, type: any) => {
    this.setState({
      is_loader: true,
    });
    console.log(type);
    let himg = "",
      aimg = "",
      dimg = "";
    if (type == "height") {
      himg = image;
    } else if (type == "antenna_azimuth") {
      aimg = image;
    } else if (type == "down_tilt") {
      dimg = image;
    }

    let reqBody = {
      heightid: himg,
      azimuthid: aimg,
      downtiltid: dimg,
      siteid: "",
    };

    requestAPICall("get_images", {}, reqBody).then((data) => {
      if (data.responseCode === 200) {
        if (type == "height") {
          image = data.heightimage;
        } else if (type == "antenna_azimuth") {
          image = data.azimuthimage;
        } else if (type == "down_tilt") {
          image = data.downtiltimage;
        }
        this.setState(
          {
            image_value: image,
          },
          () => {
            this.setState({
              image_show: true,
              selected_image_type: type,
            });
          }
        );
      }
      this.setState(
        {
          image_value: image,
        },
        () => {
          this.setState({
            image_show: true,
            selected_image_type: type,
          });
        }
      );
      this.setState({ is_loader: false });
    });
  };

  deleteDownTiltImage = () => {
    if (this.state.selected_image_type !== "down_tilt") return;
    installation_list?.map((property: any) => {
      if (property.propertyName === "down_tilt_image") {
        property.value = "";
        this.state.sector_property_list?.map((sector: any) => {
          if (sector.sectorId_id === this.state.selectedSector) {
            sector.down_tilt_image = "";
          }
        });
      } else if (property.propertyName === "down_tilt") {
        property.image_value = "";
      }
    });
  };

  deleteHeightImage = () => {
    if (this.state.selected_image_type !== "height") return;
    installation_list?.map((property: any) => {
      if (property.propertyName === "height_image") {
        property.value = "";
        this.state.sector_property_list?.map((sector: any) => {
          if (sector.sectorId_id === this.state.selectedSector) {
            sector.height_image = "";
          }
        });
      } else if (property.propertyName === "height") {
        property.image_value = "";
      }
    });
  };

  deleteAzimuthImage = () => {
    if (this.state.selected_image_type !== "antenna_azimuth") return;
    installation_list?.map((property: any) => {
      if (property.propertyName === "azimuth_image") {
        property.value = "";
        this.state.sector_property_list?.map((sector: any) => {
          if (sector.sectorId_id === this.state.selectedSector) {
            sector.azimuth_image = "";
          }
        });
      } else if (property.propertyName === "antenna_azimuth") {
        property.image_value = "";
      }
    });
  };

  delete_image = () => {
    this.deleteDownTiltImage();
    this.deleteHeightImage();
    this.deleteAzimuthImage();
    this.updateSectorDetails();
    this.setState({
      image_show: false,
      card1: false,
    });
  };

  hideImageModal = () => {
    this.setState({
      image_show: false,
    });
  };
  hide_arrow = (type: any) => {
    if (type == "sector") {
      this.setState({
        sector_check_arrow: false,
      });
    } else {
      this.setState({
        check_arrow: false,
      });
    }
  };

  dataPageChanged(newPage: any) {
    this.setState(
      {
        currentPage: newPage,
      },
      () => {
        this.list_asset();
      }
    );
  }

  dataRowsPerPageChanged(newRowNums: any) {
    this.setState(
      {
        rowsPerPage: newRowNums,
      },
      () => {
        this.list_asset();
      }
    );
  }

  getOperationalStatusColor(status: any) {
    if (status in operationalStatusColors) return operationalStatusColors[status];
    return operationalStatusColors["Others"];
  }

  getModalContent() {
    if ((this.state.delete_type === "asset" && this.state.select_asset_type_id === 1) || this.state.select_asset_type_id === 2) {
      return "CBSD";
    } else if (this.state.delete_type === "images") {
      return "Image";
    } else if (this.state.delete_type === "asset" && this.state.select_asset_type_id === 3) {
      return "CPE";
    } else {
      return "Sector";
    }
  }

  render() {
    let files = this.state.files?.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "importdropzuploaded" : "importdropz";
    return (
      <div className="card importcard">
        <div className="card-body">
          <div className="row" style={{ padding: "0px 10px 0px 5px" }}>
            <div className="col-md-6 p-l-0" style={{ top: "-10px" }}>
              <ul className="nav nav-tabs sectab float-left" id="siteDataTab" role="tablist" style={{ top: "-10px" }}>
                <li className="nav-item">
                  <a
                    className="nav-link active p-l-0 font12"
                    onClick={() => {
                      this.setState({ assetType: "AP-outdoor" });
                      this.setAssetId("AP-outdoor");
                    }}
                    style={{ padding: ".5rem 1rem", fontSize: "12px" }}
                    id="outdoor-ap"
                    data-toggle="tab"
                    href="#outdoor-ap-details"
                    role="tab"
                    aria-controls="outdoor-ap-details"
                    aria-selected="true"
                  >
                    Outdoor CBSD
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-6" style={{ display: "flex", justifyContent: "flex-end" }}>
              <i className="material-icons" style={{ color: "#0073FF", cursor: "pointer" }}>
                get_app
              </i>
              <span
                className="p-l-5 m-r-30 font13"
                style={{
                  color: "#0073FF",
                  cursor: "pointer",
                  paddingTop: "2px",
                }}
                onClick={this.download_site_data}
                onKeyDown={(e) => e.key == "Enter" && this.download_site_data()}
              >
                Download Sample File
              </span>
              <i
                className="material-icons p-l-5"
                style={{
                  color: "#0073FF",
                  transform: "rotate(180deg)",
                  cursor: "pointer",
                  marginBottom: "12px",
                }}
              >
                get_app
              </i>
              <span
                className="font13 m-r-30"
                style={{
                  color: "#0073FF",
                  paddingTop: "2px",
                  cursor: "pointer",
                }}
                id="heat"
                onClick={this.showModal}
                onKeyDown={(e) => e.key == "Enter" && this.showModal(e)}
              >
                Import File
              </span>
              <span
                className="material-icons  p-l-5"
                style={{
                  color: "#0073FF",
                  cursor: "pointer",
                  marginBottom: "12px",
                }}
              >
                cloud_upload
              </span>
              <span
                className="font13 p-l-10"
                style={{
                  color: "#0073FF",
                  paddingTop: "2px",
                  cursor: "pointer",
                }}
                onClick={(e) => this.exportFile()}
                onKeyDown={(e) => e.key == "Enter" && this.exportFile()}
              >
                Export Data
              </span>
            </div>
          </div>
          <div className="tab-content" id="site-data-details">
            <TablePagination
              component="div"
              count={this.state.totalDataCount}
              page={this.state.currentPage}
              onPageChange={(e: any, newPage: any) => {
                this.dataPageChanged(newPage);
              }}
              rowsPerPage={this.state.rowsPerPage}
              onRowsPerPageChange={(e: any) => {
                this.dataRowsPerPageChanged(e.target.value);
              }}
            />
            <div className="tab-pane fade show active" id="outdoor-ap-details" role="tabpanel" aria-labelledby="outdoor-ap-details-tab">
              <div id="demo" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row m-t-10" style={{ padding: "0px 10px" }}>
                      <div className="col-md-12">
                        <table className="table sitetable assetdata">
                          <thead className="sitehead" style={{ background: "none", display: "table" }}>
                            <tr>
                              <th className="w14 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>
                                Region/Market Name
                                {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  alt="sort"
                                  style={{ width: "10px", cursor: "pointer" }}
                                  onClick={(e) => this.sortByPriceDesc("regionName")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("regionName")}
                                />
                                <img
                                  src={sort}
                                  alt="sort"
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("regionName")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("regionName")}
                                />
                              </th>
                              <th className="w12 table-bg-hdr-color">
                                Tracking ID
                                {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  alt="sort"
                                  style={{ width: "10px", cursor: "pointer" }}
                                  onClick={(e) => this.sortByPriceDesc("access_point_id")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("access_point_id")}
                                />
                                <img
                                  src={sort}
                                  alt="sort"
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("access_point_id")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("access_point_id")}
                                />
                              </th>

                              <th className="w17 table-bg-hdr-color">CBRS Node Name</th>
                              <th className="w9 table-bg-hdr-color">Site Status</th>
                              <th className="w9 table-bg-hdr-color">Mounting Type</th>
                              <th className="w9 table-bg-hdr-color">Latitude</th>
                              <th className="w9 table-bg-hdr-color">Longitude</th>
                              <th className="w9 table-bg-hdr-color">Antenna Height</th>
                              <th className="w12 table-bg-hdr-color">Serial No</th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              display: "block",
                              overflow: "hidden scroll",
                              maxHeight: "calc(100vh - 375px)",
                              width: "calc(100% + 8px)",
                            }}
                          >
                            {this.state.asset_list?.map((asset: any, index: any) => {
                              return (
                                <React.Fragment key={asset.assetId}>
                                  <tr key={asset.assetId} className="panel-group" style={{ display: "flex" }}>
                                    {this.state.assetClickId === "#_" + asset.assetId + index && this.state.check_arrow === true ? (
                                      <td
                                        className="w14 networkellipsis"
                                        data-toggle="tooltip"
                                        title={asset.regionName}
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <img
                                          src={sort}
                                          alt="sort"
                                          style={{
                                            width: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                            transform: "rotate(180deg)",
                                          }}
                                          onClick={(_e: any) => this.hide_arrow("asset")}
                                          onKeyDown={(e) => e.key == "Enter" && this.hide_arrow("asset")}
                                          data-toggle="collapse"
                                          data-target={"#_" + asset.assetId + index}
                                        />
                                        {asset.regionName}
                                      </td>
                                    ) : (
                                      <td
                                        className="w14 networkellipsis"
                                        data-toggle="tooltip"
                                        title={asset.regionName}
                                        style={asset.ap_mounting_type === "SMB" ? { paddingLeft: "20px" } : { paddingLeft: "0px" }}
                                      >
                                        <img
                                          className={asset.ap_mounting_type === "SMB" ? "d-none" : ""}
                                          src={sort}
                                          alt="sort"
                                          style={{
                                            width: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => this.getSector(asset, "#_" + asset.assetId + index)}
                                          onKeyDown={(e) => e.key == "Enter" && this.getSector(asset, "#_" + asset.assetId + index)}
                                          data-toggle="collapse"
                                          data-target={"#_" + asset.assetId + index}
                                        />
                                        {asset.regionName}
                                      </td>
                                    )}
                                    <td className="w12 networkellipsis" data-toggle="tooltip" title={asset.access_point_id}>
                                      {asset.access_point_id}
                                    </td>

                                    <td className="w17 networkellipsis" data-toggle="tooltip" title={asset.name}>
                                      {asset.ap_name}
                                    </td>
                                    <td
                                      className="w9 networkellipsis"
                                      data-toggle="tooltip"
                                      style={{
                                        color: this.getOperationalStatusColor(asset.operationalStatus),
                                      }}
                                      title={asset.operationalStatus}
                                    >
                                      <span
                                        className="dot m-r-5"
                                        style={{
                                          backgroundColor: this.getOperationalStatusColor(asset.operationalStatus),
                                        }}
                                      ></span>
                                      {asset.operationalStatus}
                                    </td>
                                    <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.ap_mounting_type}>
                                      {asset.ap_mounting_type}
                                    </td>
                                    <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.latitude + "°"}>
                                      {asset.latitude}°
                                    </td>
                                    <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.longitude + "°"}>
                                      {asset.longitude}°
                                    </td>
                                    <td
                                      className="w9 networkellipsis"
                                      data-toggle="tooltip"
                                      title={asset.radiation_center}
                                      style={{ textAlign: "center" }}
                                    >
                                      {asset.radiation_center} ft
                                    </td>
                                    <td className="w12 networkellipsis" data-toggle="tooltip" title={asset.serial_id}>
                                      {asset.serial_id}
                                    </td>
                                  </tr>

                                  <div id={"_" + asset.assetId + index} className="collapse">
                                    {this.state.assetClickId === "#_" + asset.assetId + index && (
                                      <div
                                        className=""
                                        style={{
                                          padding: "0px 12px",
                                          background: "#f3f3f5",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <div
                                          className=""
                                          style={{
                                            padding: "0px 12px",
                                            background: "#f3f3f5",
                                          }}
                                        >
                                          <table className="subtable" style={{ width: "100%" }}>
                                            <thead>
                                              <th className="w5 fontw500 b-t-0" style={{ paddingLeft: "0px" }}></th>
                                              <th className="w10 fontw500 b-t-0">Sector ID</th>
                                              <th className="w10 fontw500 b-t-0 ">Cell Id</th>
                                              <th className="w10 fontw500 b-t-0 ">PCI</th>
                                              <th className="w15 fontw500 b-t-0 ">Antenna Height</th>
                                              <th className="w15 fontw500 b-t-0">Frequency band</th>
                                              <th className="w15 fontw500 b-t-0">FCC ID</th>
                                              <th className="w20 fontw500 b-t-0">Serial Number</th>
                                            </thead>
                                            {this.state.sector_property_list?.map((sector: any, index: any) => {
                                              return (
                                                <tbody key={sector.sectorId_id}>
                                                  <tr
                                                    className="panel-group"
                                                    style={{
                                                      height: "60px",
                                                    }}
                                                  >
                                                    {this.state.sectorClickId === "#_" + asset.assetId + sector.sectorId_id + index &&
                                                    this.state.sector_check_arrow === true ? (
                                                      <td
                                                        className="w5"
                                                        title=""
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      >
                                                        <img
                                                          src={sort}
                                                          style={{
                                                            width: "10px",
                                                            marginRight: "10px",
                                                            cursor: "pointer",
                                                            transform: "rotate(180deg)",
                                                          }}
                                                          data-toggle="collapse"
                                                          data-target={"#_" + asset.assetId + sector.sectorId_id + index}
                                                          onClick={(e: any) => {
                                                            this.hide_arrow("sector");
                                                          }}
                                                          onKeyDown={(e) => e.key == "Enter" && this.hide_arrow("sector")}
                                                        />
                                                      </td>
                                                    ) : (
                                                      <td
                                                        className="w5"
                                                        title=""
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      >
                                                        <img
                                                          src={sort}
                                                          style={{
                                                            width: "10px",
                                                            marginRight: "10px",
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="collapse"
                                                          data-target={"#_" + asset.assetId + sector.sectorId_id + index}
                                                          onClick={(e: any) => {
                                                            this.getSectorDetails(
                                                              sector.sectorId_id,
                                                              "#_" + asset.assetId + sector.sectorId_id + index
                                                            );
                                                          }}
                                                          onKeyDown={(e) =>
                                                            e.key == "Enter" &&
                                                            this.getSectorDetails(
                                                              sector.sectorId_id,
                                                              "#_" + asset.assetId + sector.sectorId_id + index
                                                            )
                                                          }
                                                        />
                                                      </td>
                                                    )}
                                                    <td className="w10">{sector.sector_id}</td>
                                                    <td className="w10 ellipsitooltip" title={sector.cell_id}>
                                                      {sector.cell_id}
                                                    </td>
                                                    <td className="w10 ellipsitooltip" title={sector.pci}>
                                                      {sector.pci}
                                                    </td>
                                                    <td className="w15">{sector.height} m</td>
                                                    <td className="w15">{sector.frequence_band}</td>
                                                    <td className="w15 ellipsitooltip" title={sector.fcc_id}>
                                                      {sector.fcc_id}
                                                    </td>
                                                    <td
                                                      className="w20 ellipsitooltip"
                                                      title={sector.sector_id === "1" ? asset.serial_id : asset.serial_id + ":" + sector.sector_id}
                                                    >
                                                      {sector.sector_id === "1" ? asset.serial_id : asset.serial_id + ":" + sector.sector_id}
                                                    </td>
                                                  </tr>

                                                  <tr
                                                    style={{
                                                      height: "0px",
                                                    }}
                                                  >
                                                    <td
                                                      style={{
                                                        padding: "0px",
                                                        border: "none",
                                                      }}
                                                      colSpan={11}
                                                    >
                                                      <div id={"_" + asset.assetId + sector.sectorId_id + index} className="collapse">
                                                        {this.state.sectorClickId === "#_" + asset.assetId + sector.sectorId_id + index && (
                                                          <div
                                                            className=""
                                                            style={{
                                                              padding: "0px 12px",
                                                              background: "#f3f3f5",
                                                            }}
                                                          >
                                                            <div className="col-md-12">
                                                              <div className="row m-t-10">
                                                                <div className="col-md-12">
                                                                  <ul className="nav nav-tabs sectab" id="secTab" role="tablist">
                                                                    <li className="nav-item">
                                                                      <a
                                                                        className="nav-link active p-l-0"
                                                                        id={"_" + sector.sectorId_id + index + "_installation"}
                                                                        data-toggle="tab"
                                                                        href={"#" + "_" + sector.sectorId_id + index + "_sector1"}
                                                                        role="tab"
                                                                        aria-controls={"_" + sector.sectorId_id + index + "_sector1"}
                                                                        aria-selected="true"
                                                                      >
                                                                        INSTALLATIONS
                                                                      </a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                      <a
                                                                        className="nav-link"
                                                                        id={"_" + sector.sectorId_id + index + "_cbsd"}
                                                                        data-toggle="tab"
                                                                        href={"#" + "_" + sector.sectorId_id + index + "_sector2"}
                                                                        role="tab"
                                                                        aria-controls={"_" + sector.sectorId_id + index + "_sector2"}
                                                                        aria-selected="false"
                                                                      >
                                                                        CBSD
                                                                      </a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                      <a
                                                                        className="nav-link"
                                                                        id={"_" + sector.sectorId_id + index + "_optional"}
                                                                        data-toggle="tab"
                                                                        href={"#" + "_" + sector.sectorId_id + index + "_sector3"}
                                                                        role="tab"
                                                                        aria-controls={"_" + sector.sectorId_id + index + "_sector3"}
                                                                        aria-selected="false"
                                                                      >
                                                                        OPTIONAL
                                                                      </a>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                      <a
                                                                        className="nav-link"
                                                                        id={"_" + sector.sectorId_id + index + "_others"}
                                                                        data-toggle="tab"
                                                                        href={"#" + "_" + sector.sectorId_id + index + "_sector4"}
                                                                        role="tab"
                                                                        aria-controls={"_" + sector.sectorId_id + index + "_sector4"}
                                                                        aria-selected="false"
                                                                      >
                                                                        OTHERS
                                                                      </a>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                              <div className="tab-content" id="secTab">
                                                                <div
                                                                  className="tab-pane fade show active"
                                                                  id={"_" + sector.sectorId_id + index + "_sector1"}
                                                                  role="tabpanel"
                                                                  aria-labelledby={"_" + sector.sectorId_id + index + "_installation"}
                                                                >
                                                                  <div className="row ">
                                                                    <div className="col-md-12">
                                                                      <div
                                                                        className="row m-t-20 justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {installation_list?.map((installation: any) => {
                                                                          if (installation.units !== "") {
                                                                            unit = "(" + installation.units + ")";
                                                                          } else {
                                                                            unit = "";
                                                                          }
                                                                          if (
                                                                            installation.propertyName === "latitude" ||
                                                                            installation.propertyName === "longitude" ||
                                                                            installation.propertyName === "horizantal_accuracy" ||
                                                                            installation.propertyName === "vertical_accuracy"
                                                                          ) {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob  b-r-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          } else if (installation.propertyName === "height") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob  b-r-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                  {this.state.heightImage?.length !== 0 && (
                                                                                    <span>
                                                                                      <i
                                                                                        className="imagelink fa fa-picture-o"
                                                                                        id="data-tab"
                                                                                        onClick={(e: any) =>
                                                                                          this.showImageModel(
                                                                                            this.state.heightImage,
                                                                                            installation.propertyName
                                                                                          )
                                                                                        }
                                                                                        onKeyDown={(e) =>
                                                                                          e.key == "Enter" &&
                                                                                          this.showImageModel(
                                                                                            this.state.heightImage,
                                                                                            installation.propertyName
                                                                                          )
                                                                                        }
                                                                                      ></i>
                                                                                    </span>
                                                                                  )}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          } else if (installation.propertyName === "haat") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          } else if (installation.propertyName === "height_type") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob  b-r-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        })}
                                                                      </div>
                                                                      <div
                                                                        className="row justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {installation_list?.map((installation: any) => {
                                                                          if (installation.units !== "") {
                                                                            unit = "(" + installation.units + ")";
                                                                          } else {
                                                                            unit = "";
                                                                          }
                                                                          if (
                                                                            installation.propertyName === "antenna_gain" ||
                                                                            installation.propertyName === "antenna_beamwidth" ||
                                                                            installation.propertyName === "eirp_capability" ||
                                                                            installation.propertyName === "antenna_model"
                                                                          ) {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob b-r-0 b-t-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          } else if (installation.propertyName === "indoor_deployment") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob b-t-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value == 0 ? "False" : "True"}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          } else if (installation.propertyName === "antenna_azimuth") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob b-t-0 b-r-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                  onChange={(e: any) => {
                                                                                    this.onChange(e, "installation");
                                                                                  }}
                                                                                />
                                                                                <label
                                                                                  className="importdetails"
                                                                                  style={{
                                                                                    paddingRight: "0px",
                                                                                  }}
                                                                                  htmlFor={installation.propertyName}
                                                                                >
                                                                                  {installation.displayName} {unit}
                                                                                  {this.state.azimuthImage?.length !== 0 && (
                                                                                    <span>
                                                                                      <i
                                                                                        className="imagelink fa fa-picture-o"
                                                                                        style={{
                                                                                          paddingLeft: "3px",
                                                                                        }}
                                                                                        id="data-tab"
                                                                                        onClick={(e: any) =>
                                                                                          this.showImageModel(
                                                                                            this.state.azimuthImage,
                                                                                            installation.propertyName
                                                                                          )
                                                                                        }
                                                                                        onKeyDown={(e) =>
                                                                                          e.key == "Enter" &&
                                                                                          this.showImageModel(
                                                                                            this.state.azimuthImage,
                                                                                            installation.propertyName
                                                                                          )
                                                                                        }
                                                                                      ></i>
                                                                                    </span>
                                                                                  )}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          } else if (installation.propertyName === "down_tilt") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob b-t-0 b-r-0"
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                  onChange={(e: any) => {
                                                                                    this.onChange(e, "installation");
                                                                                  }}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                  {this.state.downtiltImage?.length !== 0 && (
                                                                                    <span>
                                                                                      <i
                                                                                        className="imagelink fa fa-picture-o"
                                                                                        id="data-tab"
                                                                                        onClick={(e: any) =>
                                                                                          this.showImageModel(
                                                                                            this.state.downtiltImage,
                                                                                            installation.propertyName
                                                                                          )
                                                                                        }
                                                                                        onKeyDown={(e) =>
                                                                                          e.key == "Enter" &&
                                                                                          this.showImageModel(
                                                                                            this.state.downtiltImage,
                                                                                            installation.propertyName
                                                                                          )
                                                                                        }
                                                                                      ></i>
                                                                                    </span>
                                                                                  )}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        })}
                                                                      </div>
                                                                      <div
                                                                        className="row m-b-10 justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {installation_list?.map((installation: any) => {
                                                                          if (installation.units !== "") {
                                                                            unit = "(" + installation.units + ")";
                                                                          } else {
                                                                            unit = "";
                                                                          }
                                                                          if (installation.propertyName === "antenna_pattern") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  name={installation.propertyName}
                                                                                  className="paramtext-mob b-t-0"
                                                                                  style={{
                                                                                    width: "163px",
                                                                                  }}
                                                                                  id={installation.propertyName}
                                                                                  value={installation.value}
                                                                                  placeholder={installation.displayName}
                                                                                />
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName} {unit}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        })}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className="tab-pane fade show "
                                                                  id={"_" + sector.sectorId_id + index + "_sector2"}
                                                                  role="tabpanel"
                                                                  aria-labelledby={"_" + sector.sectorId_id + index + "_cbsd"}
                                                                >
                                                                  <div className="row ">
                                                                    <div className="col-md-12">
                                                                      <div
                                                                        className="row m-t-20 justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {cbsd_list?.map((cbsd: any, index: any) => {
                                                                          return (
                                                                            <div
                                                                              className="form-label-group m-0 sectordetails brd2"
                                                                              style={{
                                                                                width: "200px",
                                                                              }}
                                                                              key={cbsd.propertyName}
                                                                            >
                                                                              <input
                                                                                type="text"
                                                                                disabled
                                                                                name={cbsd.propertyName}
                                                                                className={`paramtext-mob ${
                                                                                  cbsd_list.length - 1 === index ? "" : "b-r-0"
                                                                                }`}
                                                                                id={cbsd.propertyName}
                                                                                value={cbsd.value}
                                                                                style={{
                                                                                  borderRadius: "0px",
                                                                                }}
                                                                                placeholder={cbsd.displayName}
                                                                              />
                                                                              <label className="importdetails" htmlFor={cbsd.propertyName}>
                                                                                {cbsd.displayName}
                                                                              </label>
                                                                            </div>
                                                                          );
                                                                        })}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>

                                                                <div
                                                                  className="tab-pane fade show"
                                                                  id={"_" + sector.sectorId_id + index + "_sector3"}
                                                                  role="tabpanel"
                                                                  aria-labelledby={"_" + sector.sectorId_id + index + "_optional"}
                                                                >
                                                                  <div className="row ">
                                                                    {/* <div className='col-md-1'>
                                                                                                                                </div> */}
                                                                    <div className="col-md-12">
                                                                      <div
                                                                        className="row m-t-20 justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {optional_list?.map((optional: any, index: any) => {
                                                                          return (
                                                                            <div
                                                                              className="form-label-group m-0 sectordetails brd2"
                                                                              key={optional.propertyName}
                                                                            >
                                                                              <input
                                                                                type="text"
                                                                                disabled
                                                                                className={`paramtext-mob ${
                                                                                  optional_list.length - 1 === index ? "" : "b-r-0"
                                                                                }`}
                                                                                name={optional.propertyName}
                                                                                id={optional.propertyName}
                                                                                value={
                                                                                  ["DEVICE_CATEGORY_A", "DEVICE_CATEGORY_B"].includes(optional.value)
                                                                                    ? optional.value.replace("DEVICE_CATEGORY_", "")
                                                                                    : optional.value
                                                                                }
                                                                                style={{
                                                                                  borderRadius: "0px",
                                                                                }}
                                                                                placeholder={optional.displayName}
                                                                              />
                                                                              <label className="importdetails" htmlFor={optional.propertyName}>
                                                                                {optional.displayName + optional.units}
                                                                              </label>
                                                                            </div>
                                                                          );
                                                                        })}
                                                                      </div>

                                                                      <div
                                                                        className="row  m-b-10 justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {installation_list?.map((installation: any) => {
                                                                          if (installation.propertyName === "measurement_capability") {
                                                                            return (
                                                                              <div
                                                                                className="form-label-group m-0 sectordetails"
                                                                                key={installation.propertyName}
                                                                              >
                                                                                <input
                                                                                  type="text"
                                                                                  disabled
                                                                                  value={
                                                                                    installation.value ==
                                                                                    "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT"
                                                                                      ? "WITH_GRANT"
                                                                                      : "WITHOUT_GRANT"
                                                                                  }
                                                                                  className="paramtext-mob b-t-0"
                                                                                  name={installation.propertyName}
                                                                                  id={installation.propertyName}
                                                                                  style={{
                                                                                    borderRadius: "0px",
                                                                                  }}
                                                                                  placeholder={installation.displayName}
                                                                                ></input>
                                                                                <label className="importdetails" htmlFor={installation.propertyName}>
                                                                                  {installation.displayName}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        })}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className="tab-pane fade show"
                                                                  id={"_" + sector.sectorId_id + index + "_sector4"}
                                                                  role="tabpanel"
                                                                  aria-labelledby={"_" + sector.sectorId_id + index + "_others"}
                                                                >
                                                                  <div className="row ">
                                                                    <div className="col-md-12">
                                                                      <div
                                                                        className="row m-t-20 m-b-10 justify-content-left"
                                                                        style={{
                                                                          paddingLeft: "65px",
                                                                          paddingRight: "65px",
                                                                        }}
                                                                      >
                                                                        {others_list?.map((others: any, index: any) => {
                                                                          return (
                                                                            <div
                                                                              className="form-label-group m-0 sectordetails brd2"
                                                                              key={others.propertyName}
                                                                            >
                                                                              <input
                                                                                type="text"
                                                                                disabled
                                                                                name={others.propertyName}
                                                                                autoComplete="off"
                                                                                className={`paramtext-mob ${
                                                                                  others_list.length - 1 === index ? "" : "b-r-0"
                                                                                }`}
                                                                                id={others.propertyName}
                                                                                value={others.value}
                                                                                style={{
                                                                                  borderRadius: "0px",
                                                                                }}
                                                                                placeholder={others.displayName}
                                                                              />
                                                                              <label className="importdetails" htmlFor={others.propertyName}>
                                                                                {others.displayName + others.units}
                                                                              </label>
                                                                            </div>
                                                                          );
                                                                        })}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              );
                                            })}
                                          </table>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row m-t-10" style={{ padding: "0px 10px" }}>
                      <div
                        className="col-md-12"
                        style={{
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 258px)",
                        }}
                      >
                        <table className="table sitetable">
                          <thead className="sitehead" style={{ background: "none" }}>
                            <tr>
                              <th className="w8 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>
                                Cluster Name
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} alt="sort down" />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  alt="sort"
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">Site Address</th>
                              <th className="w10 table-bg-hdr-color">Site Access </th>
                              <th className="w15 table-bg-hdr-color">Structure Additional field </th>
                              <th className="w10 table-bg-hdr-color">Feeder Length </th>
                              <th className="w8 table-bg-hdr-color">Backhaul Type </th>
                              <th className="w8 table-bg-hdr-color">Equipment Type </th>
                              <th className="w10 table-bg-hdr-color">Cabinet Type</th>
                              <th className="w6 table-bg-hdr-color">PLMN ID </th>
                              <th className="w5 table-bg-hdr-color">MNC</th>
                              <th className="w5 table-bg-hdr-color">MCC </th>
                              <th className="w5 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}>
                                TAC
                              </th>
                            </tr>
                          </thead>
                          {this.state.list_asset?.map((asset: any, index: any) => {
                            return (
                              <tbody key={asset.cluster_name}>
                                <tr>
                                  <td className="w8">{asset.cluster_name}</td>
                                  <td className="w10">{asset.street_address}</td>
                                  <td className="w10">{asset.ap_access}</td>
                                  <td className="w15">{asset.structure_additional_field}</td>
                                  <td className="w10">{asset.feeder_length}</td>
                                  <td className="w8">{asset.backhaul_type}</td>
                                  <td className="w8">{asset.equipment_type}</td>
                                  <td className="w10">{asset.cabinet_type}</td>
                                  <td className="w6">{asset.plmn_id}</td>
                                  <td className="w5">{asset.mnc}</td>
                                  <td className="w5">{asset.mcc}</td>
                                  <td className="w5">{asset.tac}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <a className="carousel-left carousel-control-prev" href="#demo" data-slide="prev">
                  <i className="fa fa-angle-left" style={{ color: "#000" }}></i>
                </a>
                <a className=" carousel-right carousel-control-next" href="#demo" data-slide="next">
                  <i className="fa fa-angle-right" style={{ color: "#000" }}></i>
                </a>
              </div>
            </div>
            <div className="tab-pane fade" id="cpe-details" role="tabpanel" aria-labelledby="cpe-details-tab">
              <div id="" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row m-t-10" style={{ padding: "0px 10px" }}>
                      <div className="col-md-12">
                        <table className="table sitetable">
                          <thead className="sitehead" style={{ background: "none", display: "table" }}>
                            <tr>
                              <th className="w14 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>
                                Region/Market Name
                                {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  style={{ width: "10px" }}
                                  onClick={(e) => this.sortByPriceDesc("regionName")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("regionName")}
                                />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("regionName")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("regionName")}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Cluster Name
                                {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  style={{ width: "10px" }}
                                  onClick={(e) => this.sortByPriceDesc("cluster_name")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("cluster_name")}
                                />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("cluster_name")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("cluster_name")}
                                />
                              </th>
                              <th className="w7 table-bg-hdr-color">
                                CPE Id
                                {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  style={{ width: "10px" }}
                                  onClick={(e) => this.sortByPriceDesc("cpe_id")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("cpe_id")}
                                />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("cpe_id")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("cpe_id")}
                                />
                              </th>
                              <th className="w9 table-bg-hdr-color">CPE Name</th>
                              <th className="w10 table-bg-hdr-color">Site Status</th>
                              <th className="w10 table-bg-hdr-color">Customer ID </th>
                              <th className="w12 table-bg-hdr-color">Deployment Type </th>
                              <th className="w7 table-bg-hdr-color">Latitude </th>
                              <th className="w7 table-bg-hdr-color">Longitude </th>
                              <th className="w7 table-bg-hdr-color">Serial No </th>
                              <th className="w7 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              display: "block",
                              overflow: "hidden scroll",
                              maxHeight: "calc(100vh - 310px)",
                              width: "calc(100% + 8px)",
                            }}
                          >
                            {this.state.asset_list?.map((asset: any, index: any) => {
                              return (
                                <React.Fragment key={asset.assetId}>
                                  {this.state.show_asset_details && this.state.selected_asset_id === asset.assetId ? (
                                    <tr id={asset.assetId} className="panel-group">
                                      <td
                                        className="w14 ellipsitooltip"
                                        data-toggle="tooltip"
                                        title={asset.regionName}
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <img
                                          src={sort}
                                          style={{
                                            width: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          data-toggle="collapse"
                                          data-target={"#_" + asset.assetId + index}
                                          onClick={() => this.getSector(asset, "#_" + asset.assetId + index)}
                                          onKeyDown={(e) => e.key == "Enter" && this.getSector(asset, "#_" + asset.assetId + index)}
                                        />
                                        {asset.regionName}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="cluster_name"
                                          value={asset.cluster_name}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w7 ellipsitooltip" data-toggle="tooltip" title={asset.cpe_id}>
                                        {asset.cpe_id}
                                      </td>
                                      <td className="w9">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="cpe_name"
                                          value={asset.cpe_name}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />
                                      </td>
                                      <td className="w10">
                                        <select
                                          className="form-control"
                                          value={asset.operationalStatus}
                                          onChange={(e: any) => this.setAssetStatus(e, asset)}
                                        >
                                          {asset_status_list?.map((status: any) => (
                                            <option value={status} key={status}>
                                              {status}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="customer_id"
                                          value={asset.customer_id}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w12">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="deployment_type"
                                          value={asset.deployment_type}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w7">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="latitude"
                                          value={asset.latitude}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w7">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="longitude"
                                          value={asset.longitude}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w7">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="serial_id"
                                          value={asset.serial_id}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w7">
                                        <i
                                          className="far fa-check-circle  p-r-15"
                                          style={{
                                            color: "#0272fb",
                                            fontSize: "22px",
                                          }}
                                          title="Save"
                                          onClick={(e) => this.updateAssetProperties()}
                                          onKeyDown={(e) => e.key == "Enter" && this.updateAssetProperties()}
                                        ></i>
                                        <i
                                          className="fas fa-trash"
                                          style={{
                                            color: "#E22B2B",
                                            fontSize: "20px",
                                          }}
                                          title="Delete"
                                          onClick={(e) => this.showModal1(asset, "")}
                                          onKeyDown={(e) => e.key == "Enter" && this.showModal1(asset, "")}
                                        ></i>
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr className="panel-group" id={asset.assetId} style={{ display: "flex" }}>
                                      {this.state.assetClickId === "#_" + asset.assetId + index && this.state.check_arrow === true ? (
                                        <td
                                          className="w14 networkellipsis"
                                          data-toggle="tooltip"
                                          title={asset.regionName}
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <img
                                            src={sort}
                                            style={{
                                              width: "10px",
                                              marginRight: "10px",
                                              cursor: "pointer",
                                              transform: "rotate(180deg)",
                                            }}
                                            onClick={this.hide_arrow}
                                            onKeyDown={this.hide_arrow}
                                            data-toggle="collapse"
                                            data-target={"#_" + asset.assetId + index}
                                          />
                                          {asset.regionName}
                                        </td>
                                      ) : (
                                        <td
                                          className="w14 networkellipsis"
                                          data-toggle="tooltip"
                                          title={asset.regionName}
                                          style={{ paddingLeft: "0px" }}
                                        >
                                          <img
                                            src={sort}
                                            style={{
                                              width: "10px",
                                              marginRight: "10px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => this.getSector(asset, "#_" + asset.assetId + index)}
                                            onKeyDown={(e) => e.key == "Enter" && this.getSector(asset, "#_" + asset.assetId + index)}
                                            data-toggle="collapse"
                                            data-target={"#_" + asset.assetId + index}
                                          />
                                          {asset.regionName}
                                        </td>
                                      )}
                                      <td className="w10 networkellipsis" data-toggle="tooltip" title={asset.cluster_name}>
                                        {asset.cluster_name}
                                      </td>
                                      <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.cpe_id}>
                                        {asset.cpe_id}
                                      </td>
                                      <td className="w9 networkellipsis" data-toggle="tooltip" title={asset.cpe_name}>
                                        {asset.cpe_name}
                                      </td>
                                      <td
                                        className="w10 networkellipsis"
                                        data-toggle="tooltip"
                                        style={{
                                          color: this.getOperationalStatusColor(asset.operationalStatus),
                                        }}
                                        title={asset.operationalStatus}
                                      >
                                        <span
                                          className="dot m-r-5"
                                          style={{
                                            backgroundColor: this.getOperationalStatusColor(asset.operationalStatus),
                                          }}
                                        ></span>
                                        {asset.operationalStatus}
                                      </td>
                                      <td className="w10 networkellipsis" data-toggle="tooltip" title={asset.customer_id}>
                                        {asset.customer_id}
                                      </td>
                                      <td className="w12 networkellipsis" data-toggle="tooltip" title={asset.deployment_type}>
                                        {asset.deployment_type}
                                      </td>
                                      <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.latitude + "°"}>
                                        {asset.latitude}°
                                      </td>
                                      <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.longitude + "°"}>
                                        {asset.longitude}°
                                      </td>
                                      <td className="w7 networkellipsis" data-toggle="tooltip" title={asset.serial_id}>
                                        {asset.serial_id}
                                      </td>
                                      <td className="w7">
                                        <div className="col-3">
                                          <button
                                            className="actionIconBtn"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i
                                              className="fas fa-ellipsis-v "
                                              style={{
                                                color: "#B4B4B4",
                                                marginTop: "10px",
                                                height: "30px",
                                              }}
                                            ></i>
                                          </button>
                                          <div
                                            className="dropdown-menu indoorfloor"
                                            style={{
                                              backgroundColor: "black",
                                            }}
                                            aria-labelledby="dropdownMenuLink"
                                          >
                                            <span
                                              className="dropdown-item cursor"
                                              style={{ color: "white" }}
                                              onClick={(e) => this.edit_asset_value(asset)}
                                              onKeyDown={(e) => e.key == "Enter" && this.edit_asset_value(asset)}
                                            >
                                              Edit Asset
                                            </span>
                                            <span
                                              className="dropdown-item cursor"
                                              style={{ color: "white" }}
                                              id="delete"
                                              onClick={(e) => this.showModal1(asset, "")}
                                              onKeyDown={(e) => e.key == "Enter" && this.showModal1(asset, "")}
                                            >
                                              {" "}
                                              Delete Asset
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}

                                  <div id={"_" + asset.assetId + index} className="collapse">
                                    {this.state.assetClickId === "#_" + asset.assetId + index && (
                                      <div
                                        className=""
                                        style={{
                                          padding: "0px 12px",
                                          background: "#f3f3f5",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <ul className="nav nav-tabs sectab" id="secTab" role="tablist">
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link active p-l-0"
                                                    id="cpe1-tab"
                                                    data-toggle="tab"
                                                    href="#cpe1"
                                                    role="tab"
                                                    aria-controls="sec1"
                                                    aria-selected="true"
                                                  >
                                                    INSTALLATIONS
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    id="cpe2-tab"
                                                    data-toggle="tab"
                                                    href="#cpe2"
                                                    role="tab"
                                                    aria-controls="sec2"
                                                    aria-selected="false"
                                                  >
                                                    CBSD
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    id="cpe3-tab"
                                                    data-toggle="tab"
                                                    href="#cpe3"
                                                    role="tab"
                                                    aria-controls="sec3"
                                                    aria-selected="false"
                                                  >
                                                    OPTIONAL
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    id="cpe4-tab"
                                                    data-toggle="tab"
                                                    href="#cpe4"
                                                    role="tab"
                                                    aria-controls="sec4"
                                                    aria-selected="false"
                                                  >
                                                    OTHERS
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="tab-content" id="secTab">
                                            <div className="tab-pane fade show active" id="cpe1" role="tabpanel" aria-labelledby="cpe1-tab">
                                              <div className="row">
                                                <div className="row ">
                                                  <div className="col-md-12">
                                                    <div
                                                      className="row m-t-20 m-l-30 justify-content-left"
                                                      style={{
                                                        paddingLeft: "54px",
                                                      }}
                                                    >
                                                      {installation_list?.map((installation: any) => {
                                                        if (installation.units !== "") {
                                                          unit = "(" + installation.units + ")";
                                                        } else {
                                                          unit = "";
                                                        }
                                                        if (
                                                          installation.propertyName === "latitude" ||
                                                          installation.propertyName === "longitude" ||
                                                          installation.propertyName === "horizantal_accuracy" ||
                                                          installation.propertyName === "vertical_accuracy"
                                                        ) {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob  b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "height") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob  b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                                <span>
                                                                  <i
                                                                    className="imagelink fa fa-picture-o"
                                                                    id="data-tab"
                                                                    onClick={(e: any) =>
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                      e.key == "Enter" &&
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "haat") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "height_type") {
                                                          return (
                                                            <div className="floating-label sectordetails" key={installation.propertyName}>
                                                              <select
                                                                className="floating-select selectarrow b-r-0"
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                  height: "45px",
                                                                }}
                                                                onChange={(e: any) => {
                                                                  this.height_type_change(e, "heightypeid2");
                                                                }}
                                                              >
                                                                <option value="" hidden>
                                                                  {installation.displayName} {unit}
                                                                </option>
                                                                <option value="HEIGHT_TYPE_AGL">AGL</option>
                                                                <option value="HEIGHT_TYPE_AMSL">AMSL</option>
                                                              </select>
                                                              <label id="heightypeid2">{installation.displayName}</label>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                    <div
                                                      className="row justify-content-left m-l-30"
                                                      style={{
                                                        paddingLeft: "54px",
                                                      }}
                                                    >
                                                      {installation_list?.map((installation: any) => {
                                                        if (installation.units !== "") {
                                                          unit = "(" + installation.units + ")";
                                                        } else {
                                                          unit = "";
                                                        }
                                                        if (
                                                          installation.propertyName === "antenna_gain" ||
                                                          installation.propertyName === "antenna_beamwidth" ||
                                                          installation.propertyName === "eirp_capability" ||
                                                          installation.propertyName === "antenna_model"
                                                        ) {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-r-0 b-t-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "indoor_deployment") {
                                                          return (
                                                            <div className="floating-label sectordetails" key={installation.propertyName}>
                                                              <select
                                                                className="floating-select selectarrow b-t-0"
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                  height: "45px",
                                                                }}
                                                                onChange={(e: any) => {
                                                                  this.indoor_deployment(e, "indoordep2");
                                                                }}
                                                              >
                                                                <option value="" hidden>
                                                                  {installation.displayName} {unit}
                                                                </option>
                                                                <option value="1">True</option>
                                                                <option value="0">False</option>
                                                              </select>
                                                              <label id="indoordep2">{installation.displayName}</label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "antenna_azimuth") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-t-0 b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label
                                                                className="importdetails"
                                                                style={{
                                                                  paddingRight: "0px",
                                                                }}
                                                                htmlFor={installation.propertyName}
                                                              >
                                                                {installation.displayName} {unit}
                                                                <span>
                                                                  <i
                                                                    className="imagelink fa fa-picture-o"
                                                                    style={{
                                                                      paddingLeft: "3px",
                                                                    }}
                                                                    id="data-tab"
                                                                    onClick={(e: any) =>
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                      e.key == "Enter" &&
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "down_tilt") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-t-0 b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                                <span>
                                                                  <i
                                                                    className="imagelink fa fa-picture-o"
                                                                    id="data-tab"
                                                                    onClick={(e: any) =>
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                      e.key == "Enter" &&
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                    <div
                                                      className="row m-b-10 m-l-30 justify-content-left"
                                                      style={{
                                                        paddingLeft: "54px",
                                                      }}
                                                    >
                                                      {installation_list?.map((installation: any) => {
                                                        if (installation.units !== "") {
                                                          unit = "(" + installation.units + ")";
                                                        } else {
                                                          unit = "";
                                                        }
                                                        if (installation.propertyName === "antenna_pattern") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                autoComplete="off"
                                                                className="paramtext-mob b-t-0"
                                                                style={{
                                                                  width: "163px",
                                                                }}
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="tab-pane fade show" id="cpe2" role="tabpanel" aria-labelledby="cpe2-tab">
                                              <div className="row ">
                                                <div className="col-md-1"></div>
                                                <div className="col-md-11">
                                                  <div
                                                    className="row m-t-20 m-b-10 m-l-30"
                                                    style={{
                                                      paddingLeft: "40px",
                                                    }}
                                                  >
                                                    {cbsd_list?.map((cbsd: any, index: any) => {
                                                      if (cbsd.dataType === "string") {
                                                        return (
                                                          <div className="form-label-group m-0 sectordetails brd2" key={cbsd.propertyName}>
                                                            <input
                                                              type="text"
                                                              name={cbsd.propertyName}
                                                              className={`paramtext-mob ${cbsd_list.length - 1 === index ? "" : "b-r-0"}`}
                                                              id={cbsd.propertyName}
                                                              value={cbsd.value}
                                                              style={{
                                                                borderRadius: "0px",
                                                              }}
                                                              placeholder={cbsd.displayName}
                                                              onChange={(e: any) => {
                                                                this.onChange(e, "cbsd");
                                                              }}
                                                            />
                                                            <label className="importdetails" htmlFor={cbsd.propertyName}>
                                                              {cbsd.displayName}
                                                            </label>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div key={cbsd.propertyName}>
                                                            <div className="floating-label sectordetails">
                                                              <select
                                                                name={cbsd.propertyName}
                                                                value={this.state.selected_vendor_name}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                className="floating-select selectarrow b-r-0"
                                                                onChange={(e) => this.setAssetVendor(e, "vendor", "cbsdvendor2")}
                                                              >
                                                                <option hidden>Vendor</option>
                                                                {this.state.assetVendorList?.map((vendor: any) => (
                                                                  <option value={vendor} key={vendor}>
                                                                    {vendor}
                                                                  </option>
                                                                ))}
                                                              </select>
                                                              <span id="cbsdvendor2">Vendor</span>
                                                            </div>
                                                            <div className="floating-label sectordetails">
                                                              <select
                                                                name={cbsd.propertyName}
                                                                value={this.state.selected_model_name}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                className="floating-select selectarrow b-r-0"
                                                                onChange={(e) => this.setAssetModel(e, "cbsdmodel2")}
                                                              >
                                                                <option hidden>Model</option>
                                                                {this.state.assetModelList?.map((vendor: any) => (
                                                                  <option value={vendor["model_name"]} key={vendor["model_name"]}>
                                                                    {vendor["model_name"]}
                                                                  </option>
                                                                ))}
                                                              </select>
                                                              <span id="cbsdmodel2">Model</span>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="tab-pane fade show" id="cpe3" role="tabpanel" aria-labelledby="cpe3-tab">
                                              <div className="row ">
                                                {/* <div className='col-md-1'>
                                                                                            </div> */}
                                                <div className="col-md-12">
                                                  {/* <div className='row  m-t-20 m-b-10'> */}
                                                  <div
                                                    className="row m-t-20 m-l-30 justify-content-left"
                                                    style={{
                                                      paddingLeft: "54px",
                                                    }}
                                                  >
                                                    {optional_list?.map((optional: any, index: any) => {
                                                      if (optional.dataType === "string") {
                                                        return (
                                                          <div className="form-label-group m-0 sectordetails brd2" key={optional.propertyName}>
                                                            <input
                                                              type="text"
                                                              className={`paramtext-mob ${optional_list.length - 1 === index ? "" : "b-r-0"}`}
                                                              name={optional.propertyName}
                                                              id={optional.propertyName}
                                                              value={optional.value}
                                                              style={{
                                                                borderRadius: "0px",
                                                              }}
                                                              placeholder={optional.displayName}
                                                              onChange={(e: any) => {
                                                                this.onChange(e, "optional");
                                                              }}
                                                            />
                                                            <label className="importdetails" htmlFor={optional.propertyName}>
                                                              {optional.displayName}
                                                            </label>
                                                          </div>
                                                        );
                                                      } else if (optional.propertyName === "call_sign") {
                                                        return (
                                                          <div className="floating-label sectordetails" key={optional.propertyName}>
                                                            <select
                                                              className="floating-select selectarrow b-r-0"
                                                              style={{
                                                                borderRadius: "0px",
                                                                height: "45px",
                                                              }}
                                                              onChange={(e) => {
                                                                this.select_onChange(e, "optional", optional.propertyName, "optcalsign2");
                                                              }}
                                                            >
                                                              <option value="" hidden>
                                                                1
                                                              </option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                            <label id="optcalsign2">{optional.displayName}</label>
                                                          </div>
                                                        );
                                                      } else if (optional.propertyName === "air_interface") {
                                                        return (
                                                          <div className="floating-label sectordetails" key={optional.propertyName}>
                                                            <select
                                                              value={optional.value}
                                                              className="floating-select selectarrow b-r-0"
                                                              style={{
                                                                borderRadius: "0px",
                                                                height: "45px",
                                                              }}
                                                              onChange={(e: any) => this.air_interface(e, "optairinter2")}
                                                            >
                                                              <option value="" hidden>
                                                                {optional.displayName}
                                                              </option>
                                                              <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                                                              <option value="E_UTRA">E_UTRA</option>
                                                              <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                                                              <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                                                              <option value="NR">NR</option>
                                                              <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                                                              <option value="REDLINE">REDLINE</option>
                                                              <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                                                            </select>
                                                            <label id="optairinter2">{optional.displayName}</label>
                                                          </div>
                                                        );
                                                      } else if (optional.propertyName === "cbsd_category") {
                                                        return (
                                                          <div className="floating-label" key={optional.propertyName}>
                                                            <select
                                                              value={optional.value}
                                                              className="floating-select selectarrow"
                                                              style={{
                                                                width: "180px",
                                                                borderRadius: "0px",
                                                                height: "45px",
                                                              }}
                                                              onChange={(e) => {
                                                                this.cbsd_category(e, "optcbsdcat2");
                                                              }}
                                                            >
                                                              <option value="" hidden>
                                                                {optional.displayName + optional.units}
                                                              </option>
                                                              <option value="DEVICE_CATEGORY_A">DEVICE_CATEGORY_A</option>
                                                              <option value="DEVICE_CATEGORY_B">DEVICE_CATEGORY_B</option>
                                                            </select>
                                                            <label id="optcbsdcat2">{optional.displayName}</label>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                  <div
                                                    className="row  m-b-10 m-l-30 justify-content-left"
                                                    style={{
                                                      paddingLeft: "54px",
                                                    }}
                                                  >
                                                    {installation_list?.map((installation: any) => {
                                                      if (installation.propertyName === "measurement_capability") {
                                                        return (
                                                          <div className="floating-label sectordetails" key={installation.propertyName}>
                                                            <select
                                                              style={{
                                                                borderRadius: "0px",
                                                                width: "163px",
                                                              }}
                                                              value={installation.value}
                                                              className="floating-select selectarrow b-t-0"
                                                              onChange={(e: any) => this.measurement_capability(e, "measurecapab2")}
                                                            >
                                                              <option hidden>WITH GRANT</option>
                                                              <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT">WITH_GRANT</option>
                                                              <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT">
                                                                WITHOUT_GRANT
                                                              </option>
                                                            </select>
                                                            <label id="measurecapab2">{installation.displayName}</label>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="tab-pane fade show" id="cpe4" role="tabpanel" aria-labelledby="cpe4-tab">
                                              <div className="row ">
                                                <div className="col-md-5"></div>
                                                <div className="col-md-7">
                                                  <div className="row m-t-20 m-b-10">
                                                    {others_list?.map((others: any, index: any) => {
                                                      if (others.propertyName == "earfcn") {
                                                        return (
                                                          <div className="form-label-group m-0 sectordetails brd2" key={others.propertyName}>
                                                            <input
                                                              type="text"
                                                              name={others.propertyName}
                                                              className={`paramtext-mob ${others_list.length - 1 === index ? "" : "b-r-0"}`}
                                                              id={others.propertyName}
                                                              value={others.value}
                                                              style={{
                                                                borderRadius: "0px",
                                                              }}
                                                              placeholder={others.displayName}
                                                              onChange={(e: any) => {
                                                                this.onChange(e, "others");
                                                              }}
                                                            />
                                                            <label className="importdetails" htmlFor={others.propertyName}>
                                                              {others.displayName}
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row m-t-10" style={{ padding: "0px 10px" }}>
                      <div
                        className="col-md-12"
                        style={{
                          overflowY: "auto",
                          maxHeight: "calc(100vh - 258px)",
                        }}
                      >
                        <table className="table sitetable">
                          <thead className="sitehead" style={{ background: "none" }}>
                            <tr>
                              <th className="w8 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>
                                CPE Id
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w8 table-bg-hdr-color">
                                Type
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Region Name
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Market Name
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w12 table-bg-hdr-color">
                                Boundary Name
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                No of Sectors
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Site Status
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Latitude
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Longitude
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                CPI
                                {/* */}
                                <img className="sortdown" src={sort} style={{ width: "10px" }} />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                />
                              </th>
                              <th className="w5 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="indoor-details" role="tabpanel" aria-labelledby="indoor-details-tab">
              <div id="" className="carousel slide" data-ride="carousel" data-interval="false" data-wrap="false">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row m-t-10" style={{ padding: "0px 10px" }}>
                      <div className="col-md-12">
                        <table className="table sitetable">
                          <thead className="sitehead" style={{ background: "none", display: "table" }}>
                            <tr>
                              <th className="w14 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>
                                Region/Market Name
                                {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  style={{ width: "10px" }}
                                  onClick={(e) => this.sortByPriceDesc("regionName")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("regionName")}
                                />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("regionName")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("regionName")}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                Cluster Name {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  style={{ width: "10px" }}
                                  onClick={(e) => this.sortByPriceDesc("cluster_name")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("cluster_name")}
                                />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("cluster_name")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("cluster_name")}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">
                                CBSD Id {/* */}
                                <img
                                  className="sortdown"
                                  src={sort}
                                  style={{ width: "10px" }}
                                  onClick={(e) => this.sortByPriceDesc("assetIdentifierId")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceDesc("assetIdentifierId")}
                                />
                                <img
                                  src={sort}
                                  style={{
                                    width: "10px",
                                    marginLeft: "10px",
                                    marginBottom: "-10px",
                                  }}
                                  onClick={(e) => this.sortByPriceAsc("assetIdentifierId")}
                                  onKeyDown={(e) => e.key == "Enter" && this.sortByPriceAsc("assetIdentifierId")}
                                />
                              </th>
                              <th className="w10 table-bg-hdr-color">CBSD Name</th>
                              <th className="w10 table-bg-hdr-color">Site Status</th>
                              <th className="w10 table-bg-hdr-color">Deployment Type </th>
                              <th className="w10 table-bg-hdr-color">Latitude </th>
                              <th className="w10 table-bg-hdr-color">Longitude </th>
                              <th className="w9 table-bg-hdr-color">Serial No </th>
                              <th className="w7 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              display: "block",
                              overflow: "hidden scroll",
                              maxHeight: "calc(100vh - 310px)",
                              width: "calc(100% + 8px)",
                            }}
                          >
                            {this.state.asset_list?.map((asset: any, index: any) => {
                              return (
                                <React.Fragment key={asset.assetId}>
                                  {this.state.show_asset_details === true && this.state.selected_asset_id === asset.assetId ? (
                                    <tr style={{ display: "flex" }}>
                                      <td
                                        className="w14 networkellipsis"
                                        data-toggle="tooltip"
                                        title={asset.regionName}
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <img
                                          src={sort}
                                          style={{
                                            width: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => this.getSector(asset, "#_" + asset.assetId + index)}
                                          onKeyDown={(e) => e.key == "Enter" && this.getSector(asset, "#_" + asset.assetId + index)}
                                          data-toggle="collapse"
                                          data-target={"#_" + asset.assetId + index}
                                        />
                                        {asset.regionName}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="cluster_name"
                                          value={asset.cluster_name}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="assetIdentifierId"
                                          value={asset.assetIdentifierId}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="ap_name"
                                          value={asset.ap_name}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w10">
                                        <select
                                          className="form-control"
                                          value={asset.operationalStatus}
                                          onChange={(e: any) => this.setAssetStatus(e, asset)}
                                        >
                                          {asset_status_list?.map((status: any) => (
                                            <option value={status} key={status}>
                                              {status}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="deployment_type"
                                          value={asset.deployment_type}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="latitude"
                                          value={asset.latitude}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="longitude"
                                          value={asset.longitude}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w10">
                                        {" "}
                                        <input
                                          className="accesspointname"
                                          style={{ height: "38px" }}
                                          name="serial_id"
                                          value={asset.serial_id}
                                          onChange={(e: any) => {
                                            this.onChange(e, "asset");
                                          }}
                                        />{" "}
                                      </td>
                                      <td className="w7">
                                        <i
                                          className="far fa-check-circle p-r-15"
                                          style={{
                                            color: "#0272fb",
                                            fontSize: "20px",
                                          }}
                                          title="Save"
                                          onClick={(e) => this.updateAssetProperties()}
                                          onKeyDown={(e) => e.key == "Enter" && this.updateAssetProperties()}
                                        ></i>
                                        <i
                                          className="fas fa-trash "
                                          style={{
                                            color: "#E22B2B",
                                            fontSize: "20px",
                                          }}
                                          title="Delete"
                                          onClick={(e) => this.showModal1(asset, "")}
                                          onKeyDown={(e) => e.key == "Enter" && this.showModal1(asset, "")}
                                        ></i>
                                      </td>
                                    </tr>
                                  ) : (
                                    <tr style={{ display: "flex" }}>
                                      <td
                                        className="w14 networkellipsis"
                                        data-toggle="tooltip"
                                        title={asset.regionName}
                                        style={{ paddingLeft: "0px" }}
                                      >
                                        <img
                                          src={sort}
                                          style={{
                                            width: "10px",
                                            marginRight: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => this.getSector(asset, "#_" + asset.assetId + index)}
                                          onKeyDown={(e) => e.key == "Enter" && this.getSector(asset, "#_" + asset.assetId + index)}
                                          data-toggle="collapse"
                                          data-target={"#_" + asset.assetId + index}
                                        />
                                        {asset.regionName}
                                      </td>
                                      <td className="w10 networkellipsis" title={asset.cluster_name}>
                                        {asset.cluster_name}
                                      </td>
                                      <td className="w10 networkellipsis" title={asset.assetIdentifierId}>
                                        {asset.assetIdentifierId}
                                      </td>
                                      <td className="w10 networkellipsis" title={asset.ap_name}>
                                        {asset.ap_name}
                                      </td>
                                      <td
                                        className="w10 networkellipsis"
                                        data-toggle="tooltip"
                                        style={{
                                          color: this.getOperationalStatusColor(asset.operationalStatus),
                                        }}
                                        title={asset.operationalStatus}
                                      >
                                        <span
                                          className="dot m-r-5"
                                          style={{
                                            backgroundColor: this.getOperationalStatusColor(asset.operationalStatus),
                                          }}
                                        ></span>
                                        {asset.operationalStatus}
                                      </td>
                                      <td className="w10 networkellipsis" title={asset.deployment_type}>
                                        {asset.deployment_type}
                                      </td>
                                      <td className="w10 networkellipsis" title={asset.latitude}>
                                        {asset.latitude}
                                      </td>
                                      <td className="w10 networkellipsis" title={asset.longitude}>
                                        {asset.longitude}
                                      </td>
                                      <td className="w9 networkellipsis" title={asset.serial_id}>
                                        {asset.serial_id}
                                      </td>
                                      <td className="w7">
                                        <div className="col-3">
                                          <button
                                            className="actionIconBtn"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <i
                                              className="fas fa-ellipsis-v "
                                              style={{
                                                color: "#B4B4B4",
                                                marginTop: "10px",
                                                height: "30px",
                                              }}
                                            ></i>
                                          </button>
                                          <div
                                            className="dropdown-menu indoorfloor"
                                            style={{
                                              backgroundColor: "black",
                                            }}
                                            aria-labelledby="dropdownMenuLink"
                                          >
                                            <span
                                              className="dropdown-item cursor"
                                              style={{ color: "white" }}
                                              onClick={(e) => this.edit_asset_value(asset)}
                                              onKeyDown={(e) => e.key == "Enter" && this.edit_asset_value(asset)}
                                            >
                                              Edit Asset
                                            </span>
                                            <span
                                              className="dropdown-item cursor"
                                              style={{ color: "white" }}
                                              id="delete"
                                              onClick={(e) => this.showModal1(asset, "")}
                                              onKeyDown={(e) => e.key == "Enter" && this.showModal1(asset, "")}
                                            >
                                              {" "}
                                              Delete Asset
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  <div id={"_" + asset.assetId + index} className="collapse">
                                    {this.state.assetClickId === "#_" + asset.assetId + index && (
                                      <div
                                        className=""
                                        style={{
                                          padding: "0px 12px",
                                          background: "#f3f3f5",
                                          marginLeft: "20px",
                                        }}
                                      >
                                        <div className="col-md-12">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <ul className="nav nav-tabs sectab" id="secTab" role="tablist">
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link active p-l-0"
                                                    id="indoor1-tab"
                                                    data-toggle="tab"
                                                    href="#indoor1"
                                                    role="tab"
                                                    aria-controls="sec1"
                                                    aria-selected="true"
                                                  >
                                                    INSTALLATIONS
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    id="indoor2-tab"
                                                    data-toggle="tab"
                                                    href="#indoor2"
                                                    role="tab"
                                                    aria-controls="sec2"
                                                    aria-selected="false"
                                                  >
                                                    CBSD
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    id="indoor3-tab"
                                                    data-toggle="tab"
                                                    href="#indoor3"
                                                    role="tab"
                                                    aria-controls="sec3"
                                                    aria-selected="false"
                                                  >
                                                    OPTIONAL
                                                  </a>
                                                </li>
                                                <li className="nav-item">
                                                  <a
                                                    className="nav-link"
                                                    id="indoor4-tab"
                                                    data-toggle="tab"
                                                    href="#indoor4"
                                                    role="tab"
                                                    aria-controls="sec4"
                                                    aria-selected="false"
                                                  >
                                                    OTHERS
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="tab-content" id="secTab">
                                            <div className="tab-pane fade show active" id="indoor1" role="tabpanel" aria-labelledby="indoor1-tab">
                                              <div className="row">
                                                <div className="row ">
                                                  <div className="col-md-12">
                                                    <div
                                                      className="row m-t-20 m-l-30 justify-content-left"
                                                      style={{
                                                        paddingLeft: "54px",
                                                      }}
                                                    >
                                                      {installation_list?.map((installation: any) => {
                                                        if (installation.units !== "") {
                                                          unit = "(" + installation.units + ")";
                                                        } else {
                                                          unit = "";
                                                        }
                                                        if (
                                                          installation.propertyName === "latitude" ||
                                                          installation.propertyName === "longitude" ||
                                                          installation.propertyName === "horizantal_accuracy" ||
                                                          installation.propertyName === "vertical_accuracy"
                                                        ) {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob  b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "height") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob  b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                                <span>
                                                                  <i
                                                                    className="imagelink fa fa-picture-o"
                                                                    id="data-tab"
                                                                    onClick={(e: any) =>
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                      e.key == "Enter" &&
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "haat") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "height_type") {
                                                          return (
                                                            <div className="floating-label sectordetails" key={installation.propertyName}>
                                                              <select
                                                                className="floating-select selectarrow b-r-0"
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                  height: "45px",
                                                                }}
                                                                onChange={(e: any) => {
                                                                  this.height_type_change(e, "heightypeid3");
                                                                }}
                                                              >
                                                                <option value="" hidden>
                                                                  {installation.displayName} {unit}
                                                                </option>
                                                                <option value="HEIGHT_TYPE_AGL">AGL</option>
                                                                <option value="HEIGHT_TYPE_AMSL">AMSL</option>
                                                              </select>
                                                              <label id="heightypeid3">{installation.displayName}</label>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                    <div
                                                      className="row justify-content-left m-l-30"
                                                      style={{
                                                        paddingLeft: "54px",
                                                      }}
                                                    >
                                                      {installation_list?.map((installation: any) => {
                                                        if (installation.units !== "") {
                                                          unit = "(" + installation.units + ")";
                                                        } else {
                                                          unit = "";
                                                        }
                                                        if (
                                                          installation.propertyName === "antenna_gain" ||
                                                          installation.propertyName === "antenna_beamwidth" ||
                                                          installation.propertyName === "eirp_capability" ||
                                                          installation.propertyName === "antenna_model"
                                                        ) {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-r-0 b-t-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "indoor_deployment") {
                                                          return (
                                                            <div className="floating-label sectordetails" key={installation.propertyName}>
                                                              <select
                                                                className="floating-select selectarrow b-t-0"
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                  height: "45px",
                                                                }}
                                                                onChange={(e: any) => {
                                                                  this.indoor_deployment(e, "indoordep3");
                                                                }}
                                                              >
                                                                <option value="" hidden>
                                                                  {installation.displayName} {unit}
                                                                </option>
                                                                <option value="1">True</option>
                                                                <option value="0">False</option>
                                                              </select>
                                                              <label id="indoordep3">{installation.displayName}</label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "antenna_azimuth") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-t-0 b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label
                                                                className="importdetails"
                                                                style={{
                                                                  paddingRight: "0px",
                                                                }}
                                                                htmlFor={installation.propertyName}
                                                              >
                                                                {installation.displayName} {unit}
                                                                <span>
                                                                  <i
                                                                    className="imagelink fa fa-picture-o"
                                                                    style={{
                                                                      paddingLeft: "3px",
                                                                    }}
                                                                    id="data-tab"
                                                                    onClick={(e: any) =>
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                      e.key == "Enter" &&
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              </label>
                                                            </div>
                                                          );
                                                        } else if (installation.propertyName === "down_tilt") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-t-0  b-r-0"
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                                <span>
                                                                  <i
                                                                    className="imagelink fa fa-picture-o"
                                                                    id="data-tab"
                                                                    onClick={(e: any) =>
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                    onKeyDown={(e) =>
                                                                      e.key == "Enter" &&
                                                                      this.showImageModel(installation.image_value, installation.propertyName)
                                                                    }
                                                                  ></i>
                                                                </span>
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                    <div
                                                      className="row m-b-10 m-l-30 justify-content-left"
                                                      style={{
                                                        paddingLeft: "54px",
                                                      }}
                                                    >
                                                      {installation_list?.map((installation: any) => {
                                                        if (installation.units !== "") {
                                                          unit = "(" + installation.units + ")";
                                                        } else {
                                                          unit = "";
                                                        }
                                                        if (installation.propertyName === "antenna_pattern") {
                                                          return (
                                                            <div className="form-label-group m-0 sectordetails" key={installation.propertyName}>
                                                              <input
                                                                type="text"
                                                                name={installation.propertyName}
                                                                className="paramtext-mob b-t-0"
                                                                style={{
                                                                  width: "163px",
                                                                }}
                                                                id={installation.propertyName}
                                                                value={installation.value}
                                                                placeholder={installation.displayName}
                                                                onChange={(e: any) => {
                                                                  this.onChange(e, "installation");
                                                                }}
                                                              />
                                                              <label className="importdetails" htmlFor={installation.propertyName}>
                                                                {installation.displayName} {unit}
                                                              </label>
                                                            </div>
                                                          );
                                                        }
                                                      })}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="tab-pane fade show" id="indoor2" role="tabpanel" aria-labelledby="indoor2-tab">
                                              <div className="row ">
                                                <div className="col-md-1"></div>
                                                <div className="col-md-11">
                                                  <div
                                                    className="row m-t-20 m-b-10 m-l-30"
                                                    style={{
                                                      paddingLeft: "40px",
                                                    }}
                                                  >
                                                    {cbsd_list?.map((cbsd: any, index: any) => {
                                                      if (cbsd.dataType === "string") {
                                                        return (
                                                          <div className="form-label-group m-0 sectordetails brd2" key={cbsd.propertyName}>
                                                            <input
                                                              type="text"
                                                              name={cbsd.propertyName}
                                                              className={`paramtext-mob ${cbsd_list.length - 1 === index ? "" : "b-r-0"}`}
                                                              id={cbsd.propertyName}
                                                              value={cbsd.value}
                                                              style={{
                                                                borderRadius: "0px",
                                                              }}
                                                              placeholder={cbsd.displayName}
                                                              onChange={(e: any) => {
                                                                this.onChange(e, "cbsd");
                                                              }}
                                                            />
                                                            <label className="importdetails" htmlFor={cbsd.propertyName}>
                                                              {cbsd.displayName}
                                                            </label>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div key={cbsd.propertyName}>
                                                            <div className="floating-label sectordetails">
                                                              <select
                                                                name={cbsd.propertyName}
                                                                value={this.state.selected_vendor_name}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                className="floating-select selectarrow b-r-0"
                                                                onChange={(e) => this.setAssetVendor(e, "vendor", "cbsd_vendor3")}
                                                              >
                                                                <option hidden>Vendor</option>
                                                                {this.state.assetVendorList?.map((vendor: any) => (
                                                                  <option value={vendor} key={vendor}>
                                                                    {vendor}
                                                                  </option>
                                                                ))}
                                                              </select>
                                                              <span id="cbsd_vendor3">Vendor</span>
                                                            </div>
                                                            <div className="floating-label sectordetails">
                                                              <select
                                                                name={cbsd.propertyName}
                                                                value={this.state.selected_model_name}
                                                                style={{
                                                                  borderRadius: "0px",
                                                                }}
                                                                className="floating-select selectarrow b-r-0"
                                                                onChange={(e) => this.setAssetModel(e, "cbsd_model3")}
                                                              >
                                                                <option hidden>Model</option>
                                                                {this.state.assetModelList?.map((vendor: any) => (
                                                                  <option value={vendor["model_name"]} key={vendor["model_name"]}>
                                                                    {vendor["model_name"]}
                                                                  </option>
                                                                ))}
                                                              </select>
                                                              <span id="cbsd_model3">Model</span>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="tab-pane fade show" id="indoor3" role="tabpanel" aria-labelledby="indoor3-tab">
                                              <div className="row ">
                                                <div className="col-md-12">
                                                  <div
                                                    className="row m-t-20 m-l-30 justify-content-left"
                                                    style={{
                                                      paddingLeft: "54px",
                                                    }}
                                                  >
                                                    {optional_list?.map((optional: any, index: any) => {
                                                      if (optional.dataType === "string") {
                                                        return (
                                                          <div className="form-label-group m-0 sectordetails brd2" key={optional.propertyName}>
                                                            <input
                                                              type="text"
                                                              className={`paramtext-mob ${optional_list.length - 1 === index ? "" : "b-r-0"}`}
                                                              name={optional.propertyName}
                                                              id={optional.propertyName}
                                                              value={optional.value}
                                                              style={{
                                                                borderRadius: "0px",
                                                              }}
                                                              placeholder={optional.displayName}
                                                              onChange={(e: any) => {
                                                                this.onChange(e, "optional");
                                                              }}
                                                            />
                                                            <label className="importdetails" htmlFor={optional.propertyName}>
                                                              {optional.displayName}
                                                            </label>
                                                          </div>
                                                        );
                                                      } else if (optional.propertyName === "call_sign") {
                                                        return (
                                                          <div className="floating-label sectordetails" key={optional.propertyName}>
                                                            <select
                                                              className="floating-select selectarrow b-r-0"
                                                              style={{
                                                                borderRadius: "0px",
                                                                height: "45px",
                                                              }}
                                                              onChange={(e) => {
                                                                this.select_onChange(e, "optional", optional.propertyName, "optcalsign3");
                                                              }}
                                                            >
                                                              <option value="" hidden>
                                                                {optional.displayName}
                                                              </option>
                                                              <option value="1">1</option>
                                                              <option value="2">2</option>
                                                            </select>
                                                            <label id="optcalsign3">{optional.displayName}</label>
                                                          </div>
                                                        );
                                                      } else if (optional.propertyName === "air_interface") {
                                                        return (
                                                          <div className="floating-label sectordetails" key={optional.propertyName}>
                                                            <select
                                                              value={optional.value}
                                                              className="floating-select selectarrow b-r-0"
                                                              style={{
                                                                borderRadius: "0px",
                                                                height: "45px",
                                                              }}
                                                              onChange={(e: any) => this.air_interface(e, "optairinter3")}
                                                            >
                                                              <option value="" hidden>
                                                                {optional.displayName}
                                                              </option>
                                                              <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                                                              <option value="E_UTRA">E_UTRA</option>
                                                              <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                                                              <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                                                              <option value="NR">NR</option>
                                                              <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                                                              <option value="REDLINE">REDLINE</option>
                                                              <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                                                            </select>
                                                            <label id="optairinter3">{optional.displayName}</label>
                                                          </div>
                                                        );
                                                      } else if (optional.propertyName === "cbsd_category") {
                                                        return (
                                                          <div className="floating-label " key={optional.propertyName}>
                                                            <select
                                                              value={optional.value}
                                                              className="floating-select selectarrow b-r-0"
                                                              style={{
                                                                width: "180px",
                                                                borderRadius: "0px",
                                                                height: "45px",
                                                              }}
                                                              onChange={(e) => {
                                                                this.cbsd_category(e, "optcbsdcat3");
                                                              }}
                                                            >
                                                              <option value="" hidden>
                                                                {optional.displayName + optional.units}
                                                              </option>
                                                              <option value="DEVICE_CATEGORY_A">DEVICE_CATEGORY_A</option>
                                                              <option value="DEVICE_CATEGORY_B">DEVICE_CATEGORY_B</option>
                                                            </select>
                                                            <label id="optcbsdcat3">{optional.displayName}</label>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                  <div
                                                    className="row m-b-10 m-l-30 justify-content-left"
                                                    style={{
                                                      paddingLeft: "54px",
                                                    }}
                                                  >
                                                    {installation_list?.map((installation: any) => {
                                                      if (installation.propertyName === "measurement_capability") {
                                                        return (
                                                          <div className="floating-label sectordetails" key={installation.propertyName}>
                                                            <select
                                                              style={{
                                                                borderRadius: "0px",
                                                                width: "163px",
                                                              }}
                                                              value={installation.value}
                                                              className="floating-select selectarrow b-t-0"
                                                              onChange={(e: any) => this.measurement_capability(e, "measurecapab3")}
                                                            >
                                                              <option hidden>WITH GRANT</option>
                                                              <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT">WITH_GRANT</option>
                                                              <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT">
                                                                WITHOUT_GRANT
                                                              </option>
                                                            </select>
                                                            <label id="measurecapab3">{installation.displayName}</label>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="tab-pane fade show" id="indoor4" role="tabpanel" aria-labelledby="indoor4-tab">
                                              <div className="row ">
                                                <div className="col-md-5"></div>
                                                <div className="col-md-7">
                                                  <div className="row m-t-20 m-b-10">
                                                    {others_list?.map((others: any, index: any) => {
                                                      if (others.propertyName == "earfcn") {
                                                        return (
                                                          <div className="form-label-group m-0 sectordetails brd2" key={others.propertyName}>
                                                            <input
                                                              type="text"
                                                              name={others.propertyName}
                                                              className={`paramtext-mob ${others_list.length - 1 === index ? "" : "b-r-0"}`}
                                                              id={others.propertyName}
                                                              value={others.value}
                                                              style={{
                                                                borderRadius: "0px",
                                                              }}
                                                              placeholder={others.displayName}
                                                              onChange={(e: any) => {
                                                                this.onChange(e, "others");
                                                              }}
                                                            />
                                                            <label className="importdetails" htmlFor={others.propertyName}>
                                                              {others.displayName}
                                                            </label>
                                                          </div>
                                                        );
                                                      }
                                                    })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={this.state.show} handleClose={this.hideModal}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 10px" }}>
                    <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                      IMPORT FILE
                    </h6>
                    <i
                      className="material-icons modalclose imgclose-file m-l-5"
                      onClick={this.hideModal}
                      onKeyDown={(e) => e.key == "Enter" && this.hideModal}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      clear
                    </i>
                  </div>
                  <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                    <div className="col-md-12 p-0">
                      <Dropzone onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone-set">
                            <div
                              style={{
                                height: "125px",
                                width: "260px",
                                marginLeft: "8px",
                              }}
                              {...getRootProps({ className: classDName })}
                            >
                              <input {...getInputProps()} />
                              {files.length ? (
                                <div>
                                  <div className="m-t-30">
                                    <span
                                      className="justify-content-center"
                                      style={{
                                        color: "#00D56E",
                                        display: "flex",
                                      }}
                                    >
                                      {files}
                                    </span>
                                  </div>
                                  <div className="m-t-10">
                                    <span>
                                      <span
                                        style={{
                                          color: "#8F9FAC",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Change File
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className="m-t-5" style={{ textAlign: "center" }}>
                                    <i
                                      className="fas fa-plus-circle text-center"
                                      style={{
                                        fontSize: "40px",
                                        color: "#ccc",
                                      }}
                                    ></i>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <span
                                      className="text-center"
                                      style={{
                                        fontSize: "14px",
                                        color: "#9b9b9b",
                                      }}
                                    >
                                      Drag and drop files here
                                    </span>
                                    <br />
                                  </div>
                                  <div
                                    className="m-t-5"
                                    style={{
                                      textAlign: "center",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <span className="font14 cursor" style={{ color: "#0059b2" }}>
                                      Browse
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  {files.length ? (
                    <div className="row m-t-5 justify-content-center" style={{ padding: "0 10px" }}>
                      <button
                        className="btn cardbtn"
                        style={{ width: "268px" }}
                        onClick={() => {
                          this.import_files();
                        }}
                      >
                        IMPORT
                      </button>
                    </div>
                  ) : (
                    <div className="row m-t-5 justify-content-center" style={{ padding: "0 10px" }}>
                      <button className="btn cardbtn" style={{ width: "268px" }} disabled={true}>
                        IMPORT
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
          <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["OK"]} />
          <IonLoading isOpen={this.state.is_loader} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} />
          <Modal show={this.state.status_show}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 10px" }}>
                    <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                      IMPORT SITE DATA STATUS
                    </h6>
                    <i
                      className="material-icons modalclose imgclose-file m-l-5"
                      onClick={this.status_hideModal}
                      onKeyDown={(e) => e.key == "Enter" && this.status_hideModal()}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      clear
                    </i>
                  </div>
                  <table className="table sitecollapse m-t-20">
                    <thead>
                      <tr>
                        <th className="w15">Asset Name</th>
                        <th className="w20">Status</th>
                      </tr>
                      {status_list?.map((status: any) => {
                        return (
                          <tr key={status.name}>
                            <td>{status.name}</td>
                            <td>{status.status}</td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </Modal>
          <Dropzone
            onDrop={(e: any) => {
              this.onDrop(e, this.state.selected_image_type);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-set">
                {this.state.selected_image_type === "down_tilt" && (
                  <div style={{ display: "none" }} id="down_tilt_file" {...getRootProps({ className: classDName })}>
                    <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                  </div>
                )}
                {this.state.selected_image_type === "height" && (
                  <div style={{ display: "none" }} id="height_image_file" {...getRootProps({ className: classDName })}>
                    <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                  </div>
                )}
                {this.state.selected_image_type === "antenna_azimuth" && (
                  <div style={{ display: "none" }} id="azimuth_image_file" {...getRootProps({ className: classDName })}>
                    <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                  </div>
                )}
                <div></div>
              </section>
            )}
          </Dropzone>

          <Modal show={this.state.image_show}>
            <div className="modal-dialog center-modal modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 10px" }}>
                    <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "14px" }}>
                      {this.state.selected_image_type === "antenna_azimuth" ? "ANTENNA AZIMUTH IMAGE" : null}
                      {this.state.selected_image_type === "height" ? "HEIGHT IMAGE" : null}
                      {this.state.selected_image_type === "down_tilt" ? "DOWN TILT IMAGE" : null}
                    </h6>

                    <i
                      className="material-icons resetcloseicon imgclose-file m-l-5"
                      onClick={this.hideImageModal}
                      onKeyDown={(e) => e.key == "Enter" && this.hideImageModal()}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      clear
                    </i>
                  </div>

                  <div className="m-t-10 text-center" style={{ position: "relative" }}>
                    <img className="dataimportimage" src={this.state.image_value} />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal show={this.state.card1} handleClose={this.hideModal1}>
            <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row" style={{ padding: "0 10px" }}>
                    <h6 className="modal-title" style={{ marginTop: "0px" }}>
                      CONFIRMATION
                    </h6>
                    <i
                      className="material-icons modalclose imgclose-file"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal1();
                      }}
                      onKeyDown={(e) => {
                        e.preventDefault();
                        e.key == "Enter" && this.hideModal1();
                      }}
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      clear
                    </i>
                  </div>
                  <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                    <span className="modaltext">Are you sure you want to delete this {this.getModalContent()}?</span>
                  </div>
                  <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                    <div className="col-6 p-r-5 p-l-0">
                      <button
                        className="btn modalbtn btn-secondary-c3"
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.hideModal1();
                        }}
                      >
                        No
                      </button>
                    </div>
                    <div className="col-6 p-l-5">
                      {this.state.delete_type === "asset" && (
                        <button
                          className="btn modalbtn"
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.delete_asset();
                          }}
                        >
                          Yes
                        </button>
                      )}
                      {this.state.delete_type === "images" && (
                        <button
                          className="btn modalbtn"
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.delete_image();
                          }}
                        >
                          Yes
                        </button>
                      )}
                      {this.state.delete_type !== "images" && this.state.delete_type !== "asset" && (
                        <button
                          className="btn modalbtn"
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.update_asset(e, this.state.selected_data);
                          }}
                        >
                          Yes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
