import React from "react";
import Checkright from "../assets/img/checked-right.png";
import "./settings.css";
import Logout from "../assets/img/logout.svg";
import { withRouter } from "react-router-dom";
import { requestAPICall } from "../service/apiHandler";
import Modal from "./modal";
import { IonAlert, IonLoading } from "@ionic/react";
import Dropzone from "react-dropzone";
import avatar from "../assets/img/avatar.png";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { isMobile } from "react-device-detect";
import Profile from "./profile-info";
import { Plugins, AppState } from "@capacitor/core";
import { QRCode } from "react-qr-svg";
import ConfigJSON from "../config/config.json";

const { App } = Plugins;
const { BackgroundTask } = Plugins;

const INITIAL_STATE = {
  aerialVendorList: [],
  smbVendorList: [],
  roeVendorList: [],
  cpeVendorList: [],
  sasList: [],
  p12cert: [],
  domainProxyList: [],
  selectedAerialVendorId: 0,
  selectedSMBVendorId: 0,
  selectedROEVendorId: 0,
  selectedCPEVendorId: 0,
  selectedProviderId: 0,
  certificateSubmitted: false,
  userList: {},
  username: "",
  cpiName: "",
  userId: 0,
  email: "",
  currentPassword: "",
  newPassword: "",
  confirm_newPassword: "",
  card: "",
  certPassword: "",
  cpiId: "",
  show: true,
  auto_check: false,
  manual_check: true,
  warningmessage: "",
  alertbox: false,
  is_loader: false,
  images: [],
  userImage: "",
  sasChecked: "",
  dpChecked: "",
  selectedUserId: "",
  show_overlay: false,
  currentPassword_type: "password",
  new_password_type: "password",
  is_confirm_newPassword: false,
  files1: [],
  version: "",
};

class Settings extends React.Component {
  state: any = {};
  props: any = {};
  apFocuscpiName: any = "";
  apFocuscpiId: any = "";
  backButton_event: any;
  backButton_timer: any = null;
  appState_event: any;
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onChange = this.onChange.bind(this);
    this.deleteCookies = this.deleteCookies.bind(this);
    this.listVendorAPI = this.listVendorAPI.bind(this);
    this.getCookies = this.getCookies.bind(this);
    this.listProviders = this.listProviders.bind(this);
    this.updateUserProfileChanges = this.updateUserProfileChanges.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.password_click = this.password_click.bind(this);
    this.hideOverlay = this.hideOverlay.bind(this);
    this.showOverlay = this.showOverlay.bind(this);
  }

  componentDidMount() {
    this.getCookies();
    this.listVendorAPI();
    this.listProviders();
    this.getUserProfile();
    this.getDefaultSettings();
    if (isMobile) {
      localStorage.setItem("urlPath", this.props.location.pathname);
      this.set_backButton_listener();
      this.setState({ version: "version : " + ConfigJSON["version"] });
    }
  }

  backButtonEventListener = () => {
    let backButtonListenerExists = localStorage.getItem("backButton");
    if (backButtonListenerExists === null) {
      localStorage.setItem("backButton", "true");
      this.backButton_event = App.addListener("backButton", (state: any) => {
        if (this.state.show_overlay) {
          this.hideOverlay();
        } else {
          this.props.history.push("/map");
        }
      });
    }
    if (this.backButton_timer == null) {
      this.backButton_timer = setInterval(() => {
        this.set_backButton_listener();
      }, 2000);
    }
  };

  appStateEventListener = () => {
    let appStateListenerExists = localStorage.getItem("appState");
    if (appStateListenerExists !== null) return;
    localStorage.setItem("appState", "true");
    this.appState_event = App.addListener("appStateChange", (state: AppState) => {
      if (state.isActive) {
        this.set_backButton_listener();
      }
      if (!state.isActive) {
        let taskId = BackgroundTask.beforeExit(async () => {
          if (this.backButton_timer != null) {
            clearInterval(this.backButton_timer);
            this.backButton_timer = null;
          }
          if (this.backButton_event != null) {
            this.backButton_event.remove();
          }
          localStorage.removeItem("backButton");
          BackgroundTask.finish({
            taskId,
          });
        });
      }
    });
  };

  set_backButton_listener = () => {
    this.backButtonEventListener();
    this.appStateEventListener();
  };

  componentWillUnmount() {
    if (isMobile) {
      if (this.backButton_timer != null) {
        clearInterval(this.backButton_timer);
        this.backButton_timer = null;
      }
      if (this.backButton_event != null) {
        this.backButton_event.remove();
      }
      if (this.appState_event != null) {
        this.appState_event.remove();
      }
      localStorage.removeItem("backButton");
      localStorage.removeItem("appState");
    }
  }

  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };
  setErrorImage = (e: any) => {
    this.setState({ userImage: avatar });
  };

  deleteCookies() {
    localStorage.removeItem("backButton");
    localStorage.removeItem("urlPath");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("platform");
    localStorage.removeItem("browser");
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("logintime");
    localStorage.removeItem("username");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("userRole");
    localStorage.removeItem("permissionId");
    localStorage.removeItem("CPE_Outdoor_Data");
    localStorage.removeItem("CPE_Indoor_Data");
    localStorage.removeItem("AP_Outdoor_Data");
    localStorage.removeItem("AP_Indoor_Data");
    localStorage.removeItem("DAS_Data");
    localStorage.removeItem("floorAddProperty");
    localStorage.removeItem("buildingData1AddProperty");
    localStorage.removeItem("outdoor_map_center_lat");
    localStorage.removeItem("outdoor_map_center_lng");
    localStorage.removeItem("indoor_map_center_lat");
    localStorage.removeItem("indoor_map_center_lng");
    localStorage.removeItem("backButton");
    localStorage.removeItem("vendor_type");
    localStorage.removeItem("rawAssetList");
    this.props.history.push("/home");
  }
  onDrop = (image: any) => {
    this.setState({ images: image });
    this.changeProfiePicture();
  };
  onDrop2 = (files: any, type: any) => {
    if (type !== undefined && type === "p12") {
      this.setState({
        p12cert: files,
      });
    } else {
      this.setState({ files: files });
    }
  };
  getCookies() {
    if (localStorage.getItem("idToken") === null) {
      this.props.history.push("/map");
    }
  }
  getUserProfile() {
    let userList: any = {};
    requestAPICall("get_user_profile", {}, {}).then((data) => {
      if (data.responseCode === 200) {
        userList = data["user_list"];
        if (userList["isSubmitted"]) {
          this.setState({
            certificateSubmitted: true,
          });
        }
        let image = userList["userImage"];
        this.setState({
          userList: userList,
          userId: userList.userId,
          userImage: image,
          userName: userList["userName"],
          userEmail: userList["userEmail"],
          cpiId: userList["cpiId"],
          cpiName: userList["cpiName"],
        });
      }
    });
  }

  showModal = (e: any) => {
    this.setState({
      card: e.target.id,
      show: true,
      currentPassword: "",
      newPassword: "",
      confirm_newPassword: "",
    });
  };
  hideModal = () => {
    this.setState({
      card: "",
      newPassword: "",
      confirmnewPassword: "",
      show: false,
    });
  };
  listProviders() {
    requestAPICall("list_provider", {}, {}).then((data) => {
      let sasList: any = [];
      let domainProxyList: any = [];
      if (data["provider_list"] != undefined) {
        let resp = data["provider_list"];
        resp?.forEach((element: any) => {
          if (element["providerTypeId"] == 1) {
            sasList.push(element);
          } else {
            domainProxyList.push(element);
          }
        });
      }
      this.setState({ domainProxyList: domainProxyList });
      this.setState({ sasList: sasList });
    });
  }
  setUserId = (e: any) => {
    this.setState({ selectedUserId: e.target.value }, () => {});
  };
  setAerialVendor = (e: any) => {
    this.setState({ selectedAerialVendorId: e.target.value }, () => {
      this.updateSettings();
    });
    localStorage.removeItem("AP_Outdoor_Data");
    localStorage.removeItem("AP_Indoor_Data");
    localStorage.removeItem("vendor_type");
  };
  setSMBVendor = (e: any) => {
    this.setState({ selectedSMBVendorId: e.target.value }, () => {
      this.updateSettings();
    });
    localStorage.removeItem("AP_Outdoor_Data");
    localStorage.removeItem("AP_Indoor_Data");
    localStorage.removeItem("vendor_type");
  };
  setROEVendor = (e: any) => {
    this.setState({ selectedROEVendorId: e.target.value }, () => {
      this.updateSettings();
    });
    localStorage.removeItem("AP_Outdoor_Data");
    localStorage.removeItem("AP_Indoor_Data");
    localStorage.removeItem("vendor_type");
  };
  setCPEVendor = (e: any) => {
    this.setState({ selectedCPEVendorId: e.target.value }, () => {
      this.updateSettings();
    });
  };
  setProvider = (e: any, id: any) => {
    if (id === 0) {
      this.setState({ selectedProviderId: e.target.value }, () => {
        this.updateSettings();
      });
    } else {
      this.setState({ selectedProviderId: id }, () => {
        this.updateSettings();
      });
    }
  };

  listVendorAPI() {
    requestAPICall("list_vendor", {}, {}).then((data) => {
      let aerialVendorList: any = [];
      let smbVendorList: any = [];
      let roeVendorList: any = [];
      let cpeVendorList: any = [];
      if (data["aerial_vendor_list"] != undefined) {
        let resp = data["aerial_vendor_list"];
        resp?.forEach((element: any) => {
          if (element["assetTypeId"] == 1) {
            aerialVendorList.push(element);
          } else {
            cpeVendorList.push(element);
          }
        });
      }

      if (data["smb_vendor_list"] != undefined) {
        let resp = data["smb_vendor_list"];
        resp?.forEach((element: any) => {
          if (element["assetTypeId"] == 6) {
            smbVendorList.push(element);
          } else {
            cpeVendorList.push(element);
          }
        });
      }

      if (data["roe_vendor_list"] != undefined) {
        let resp = data["roe_vendor_list"];
        resp?.forEach((element: any) => {
          if (element["assetTypeId"] == 1) {
            roeVendorList.push(element);
          } else {
            cpeVendorList.push(element);
          }
        });
      }
      this.setState({
        aerialVendorList: aerialVendorList,
        smbVendorList: smbVendorList,
        roeVendorList: roeVendorList,
      });
      this.setState({ cpeVendorList: cpeVendorList });
    });
  }
  getDefaultSettings() {
    requestAPICall("get_default_setings", {}, {}).then((data) => {
      if (data.responseCode === 200) {
        this.setState({
          selectedAerialVendorId: data.defaultAerialVendorId,
          selectedSMBVendorId: data.defaultSMBVendorId,
          selectedROEVendorId: data.defaultROEVendorId,
        });
        this.setState({ selectedCPEVendorId: data.defaultCpeVendorId });
        this.setState({ selectedProviderId: data.defaultProviderId });
        this.setState({ selectedUserId: data.defaultGlobalUserId });

        if (data.defaultProviderTypeId == 1) {
          this.setState({
            sasChecked: true,
            dpChecked: false,
          });
        }
        if (data.defaultProviderTypeId == 2) {
          this.setState({
            dpChecked: true,
            sasChecked: false,
          });
        }
      }
    });
  }

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (this.state.confirm_newPassword === this.state.newPassword && this.state.confirm_newPassword.length > 0) {
      this.setState({
        is_confirm_newPassword: true,
      });
    } else {
      this.setState({
        is_confirm_newPassword: false,
      });
    }
  }
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  changeProfiePicture = () => {
    this.setState({
      is_loader: true,
    });
    let fileReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.images[0].name;
    let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
    fileReader.onload = (e) => {
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: fileReader.result,
      };
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        if (data["responseCode"] == 200) {
          let requestBody = {
            user_profile_id: data.file_id,
            user_id: this.state.userId,
          };

          requestAPICall("update_profile_pic", {}, requestBody).then((data) => {
            this.setState({
              is_loader: false,
            });
            if (data["responseCode"] == 200) {
              this.getUserProfile();
            }
            this.hideModal();
          });
        }
      });
    };
    fileReader.readAsDataURL(this.state.images[0]);
  };

  callchangePasswordAPI = () => {
    this.setState({
      is_loader: true,
    });
    let requestBody = {
      username: this.state.userList["userName"],
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
      email: this.state.userList["userEmail"],
      resetEmpPassword: false,
    };
    if (this.state.newPassword != this.state.confirm_newPassword) {
      this.setState({
        is_loader: false,
        warningmessage: "New password and confirm password doesn't match",
        alertbox: true,
      });
      return;
    }
    if (this.state.auto_check === true) {
      requestAPICall("change_random_password", {}, requestBody).then((data) => {
        this.setState({
          is_loader: false,
        });
        if (data.responseCode == 200) {
          this.setState({
            warningmessage: "Your auto generated password has been sent to your email",
            alertbox: true,
          });
        } else {
          this.setState({
            warningmessage: "Failed to update your password",
            alertbox: true,
          });
        }
      });
      this.hideModal();
    } else if (this.state.manual_check === true) {
      requestAPICall("change_password", {}, requestBody).then((data) => {
        this.setState({
          is_loader: false,
        });
        if (data.responseCode == 200) {
          setTimeout(() => {
            this.deleteCookies();
          }, 4000);

          this.setState({
            warningmessage: data.message + ". Please login again",
            alertbox: true,
          });
          this.setState((prevValues: any) => ({
            userList: { ...prevValues.userList, userPassword: prevValues.newPassword },
          }));
        } else {
          this.setState({
            warningmessage: data.message,
            alertbox: true,
          });
        }
      });

      this.hideModal();
    }
  };

  password_click(e: any) {
    if (e.target.name == "auto") {
      this.setState({
        auto_check: true,
        manual_check: false,
      });
    } else if (e.target.name == "manual") {
      this.setState({
        auto_check: false,
        manual_check: true,
      });
    }
  }

  updateSettings = () => {
    let requestBody = {
      user_default_global_userId: this.state.selectedUserId,
      user_default_provider: this.state.selectedProviderId,
      user_default_aerial_vendor: this.state.selectedAerialVendorId,
      user_default_smb_vendor: this.state.selectedSMBVendorId,
      user_default_roe_vendor: this.state.selectedROEVendorId,
      user_default_cpe_vendor: this.state.selectedCPEVendorId,
    };
    requestAPICall("update_settings", {}, requestBody).then((data) => {
      if (data.responseCode == 200) {
        this.setState({
          warningmessage: "Successfully updated the settings",
          alertbox: true,
        });
      } else {
        this.setState({
          warnMessage: "Failed to update the settings",
          alertbox: true,
        });
      }
    });
  };
  enableSAS = (e: any) => {
    this.setState({
      sasChecked: e.target.checked,
      dpChecked: false,
    });
  };

  saveupdateSettings = () => {
    let requestBody = {
      user_default_global_userId: this.state.selectedUserId,
      user_default_provider: this.state.selectedProviderId,
      user_default_aerial_vendor: this.state.selectedAerialVendorId,
      user_default_smb_vendor: this.state.selectedSMBVendorId,
      user_default_roe_vendor: this.state.selectedROEVendorId,
      user_default_cpe_vendor: this.state.selectedCPEVendorId,
    };
    requestAPICall("update_settings", {}, requestBody).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        this.setState({
          warningmessage: "Successfully updated the settings",
          alertbox: true,
        });
      } else {
        this.setState({
          warningmessage: "Failed to update the settings",
          alertbox: true,
        });
      }
    });
  };
  handleClick = (e: any, type: any) => {
    if (type === "current_password") {
      if (this.state.currentPassword_type === "text") {
        this.setState({
          currentPassword_type: "password",
        });
      } else {
        this.setState({
          currentPassword_type: "text",
        });
      }
    } else if (type === "new_password") {
      if (this.state.new_password_type === "text") {
        this.setState({
          new_password_type: "password",
        });
      } else {
        this.setState({
          new_password_type: "text",
        });
      }
    }
  };

  enableDP = (e: any) => {
    this.setState({
      sasChecked: false,
      dpChecked: e.target.checked,
    });
  };

  updateUserProfileChanges() {
    if (this.state.cpiName !== "" && [undefined, null, "undefined", "null", ""].includes(this.state.cpiId)) {
      this.apFocuscpiId.focus();
    } else if (this.state.cpiId !== "" && [undefined, null, "undefined", "null", ""].includes(this.state.cpiName)) {
      this.apFocuscpiName.focus();
    } else if (this.state.p12cert.length > 0) {
      let picReader = new FileReader();
      let requestBody = {};
      let fileName = this.state.p12cert[0].name;
      let fileExt = fileName.split(/\.(?=[^.]+$)/)[1];
      picReader.onload = (e) => {
        requestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
          type: "p12",
          userEmail: this.state.userEmail,
          cpiId: this.state.cpiId,
          cpiName: this.state.cpiName,
          certPassword: this.state.certPassword,
          fileUpload: true,
        };
        requestAPICall("update_user_profile", {}, requestBody).then((data) => {
          if (data.responseCode == 200) {
            this.setState({
              warningmessage: data.message,
              alertbox: true,
              certPassword: "",
            });
          }
        });
      };
      picReader.readAsDataURL(this.state.p12cert[0]);
    } else {
      let requestBody = {
        userEmail: this.state.userEmail,
        cpiId: this.state.cpiId,
        cpiName: this.state.cpiName,
        certPassword: this.state.certPassword,
        fileUpload: false,
      };
      requestAPICall("update_user_profile", {}, requestBody).then((data) => {
        if (data.responseCode == 200) {
          this.setState({
            warningmessage: data.message,
            alertbox: true,
            certPassword: "",
          });
        }
      });
    }
  }
  hideOverlay() {
    this.setState({
      show_overlay: false,
    });
  }
  showOverlay() {
    this.setState({
      show_overlay: true,
    });
  }

  getCpiFileHtml = (files1: any) => {
    if (this.state.certificateSubmitted && files1.length === 0) return <span style={{ fontSize: "14px" }}>{`File Uploaded already`}</span>;
    else if (files1.length) return <span style={{ color: "#00D56E" }}>{files1}</span>;
    else return <span style={{ fontSize: "14px" }}>{`+ Upload CPI Certificate`}</span>;
  };

  render() {
    let files1 = this.state.p12cert?.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    return (
      <div>
        <Modal show={this.state.show && this.state.card === "changepwd"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body" style={{ padding: "20px 16px" }}>
                <div className="col-12 p-0">
                  <h6 className="modal-title font14 d-inline-flex" style={{ marginTop: "0px" }}>
                    CHANGING PASSWORD
                  </h6>
                  <i
                    className="material-icons imgclose-file modalclose d-inline-flex"
                    style={{ right: "0px" }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="col-12 m-t-15 p-0">
                  <div className="col-12 p-0">
                    <div className="form-label-group m-0">
                      <i
                        className="far fa-eye eyeicon"
                        onClick={(e) => this.handleClick(e, "current_password")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleClick(e, "current_password");
                          }
                        }}
                      ></i>
                      <input
                        autoComplete="off"
                        type={this.state.currentPassword_type}
                        style={{ height: "59px" }}
                        name="currentPassword"
                        id="currentPassword"
                        value={this.state.currentPassword}
                        className="text-user img-center"
                        placeholder="Current Password"
                        onChange={this.onChange}
                      />
                      <label className="forgot" htmlFor="currentPassword">
                        Current Password
                      </label>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <div className="form-label-group m-0">
                      <i
                        className="far fa-eye eyeicon"
                        onClick={(e) => this.handleClick(e, "new_password")}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.handleClick(e, "new_password");
                          }
                        }}
                      ></i>
                      <input
                        autoComplete="off"
                        type={this.state.new_password_type}
                        name="newPassword"
                        id="newPassword"
                        className="text-user img-center"
                        value={this.state.newPassword}
                        style={{ borderRadius: "0px", height: "59px" }}
                        placeholder="New Password"
                        onChange={this.onChange}
                      />
                      <label className="forgot" htmlFor="newPassword">
                        New Password
                      </label>
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <div className="form-label-group m-0">
                      {this.state.is_confirm_newPassword === true ? <i className="fas fa-check eyeicon" style={{ color: "#61d976" }}></i> : null}

                      <input
                        autoComplete="off"
                        type="password"
                        style={{ height: "59px" }}
                        name="confirm_newPassword"
                        className="text-pwd img-center"
                        id="confirm_newPassword"
                        value={this.state.confirm_newPassword}
                        placeholder="Confirm New Password"
                        onChange={this.onChange}
                      />
                      <label className="forgot" htmlFor="confirm_newPassword">
                        Confirm New Password
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.newPassword === "" || this.state.confirm_newPassword === "" || this.state.currentPassword === "" ? (
                  <div className="col-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      id="updatepwd"
                      disabled={true}
                      onClick={(e: any) => {
                        this.callchangePasswordAPI();
                      }}
                    >
                      Update Password
                    </button>
                  </div>
                ) : (
                  <div className="col-12 p-0 m-t-15">
                    <button
                      className="btn cardbtn"
                      id="updatepwd"
                      onClick={(e: any) => {
                        this.callchangePasswordAPI();
                      }}
                    >
                      Update Password
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>

        <Modal show={this.state.show && this.state.card === "updatep"} handleClose={this.hideModal}>
          {/* <div className="modal fade"  role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    RESET EMPLOYEE PARAMETERS
                  </h6>
                  <i
                    className="far fa-times-circle modalclose"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal();
                      }
                    }}
                    aria-label="Close"
                  ></i>
                </div>
                <div className="row m-t-5" style={{ padding: "0 10px" }}>
                  <span className="distance" style={{ fontSize: "13px" }}>
                    Select an option and password reset instructions{"\n"}will be emailed to the employee
                  </span>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender2">
                      <FormControlLabel
                        name="auto"
                        checked={this.state.auto_check}
                        onClick={this.password_click}
                        value="auto"
                        control={<Radio color="primary" />}
                        label="Auto generate random password"
                      />
                      <FormControlLabel
                        name="manual"
                        checked={this.state.manual_check}
                        onClick={this.password_click}
                        value="manual"
                        control={<Radio color="primary" />}
                        label="Manual password"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="col-md-10 p-r-0">
                    <div className="form-label-group m-0">
                      <input
                        autoComplete="off"
                        type="password"
                        className="accesspointname img-center m-t-5"
                        id="newPassword"
                        name="newPassword"
                        value={this.state.newPassword}
                        placeholder="Password"
                        style={{ borderRadius: "6px" }}
                        onChange={this.onChange}
                        disabled={!this.state.manual_check}
                      />
                      <label className="pages" htmlFor="newPassword">
                        Password
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                  {this.state.manual_check ? (
                    <button
                      className="btn cardbtn"
                      style={{ width: "268px" }}
                      disabled={!this.state.manual_check}
                      onClick={this.callchangePasswordAPI}
                    >
                      Submit
                    </button>
                  ) : (
                    <button className="btn cardbtn" style={{ width: "268px" }} disabled={!this.state.auto_check} onClick={this.callchangePasswordAPI}>
                      Send Reset Instructions
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <div className="webonly">
          <div className="container-fluid dash p-0" style={{ background: "#F9FAFC" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                  <div className="col-md-12 p-0">
                    <h4>Settings</h4>
                  </div>
                </div>
                <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                  <div className="col-md-6 p-0">
                    <div className="card settingcard">
                      <div className="card-body" style={{ overflowY: "auto" }}>
                        <div className="col-md-12">
                          <span className="searchcontent">SYSTEM SETTINGS</span>
                        </div>
                        <div className="col-md-12 p-0 m-t-10">
                          <div className="col-md-6 d-inline-block">
                            <div className="form-label-group m-0">
                              <input
                                autoComplete="off"
                                type="text"
                                className="accesspointname"
                                id="usersettings"
                                value={this.state.selectedUserId}
                                placeholder="User Id"
                                onChange={(e) => this.setUserId(e)}
                              />
                              <label className="pages" htmlFor="usersettings">
                                User Id*
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 d-inline-block text-right">
                            <button
                              className="btn cardbtn"
                              style={{
                                width: "150px",
                                height: "40px",
                                verticalAlign: "super",
                              }}
                              onClick={this.updateSettings}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                        <div className="col-md-4 p-0"></div>
                        <div className="row col-md-12 m-t-10">
                          <div className="col-md-6">
                            <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                              SELECT SAS
                            </span>
                          </div>
                          <div className="col-6 text-right p-0">
                            <div className="custom-control custom-checkbox mobsettings">
                              <input
                                autoComplete="off"
                                type="checkbox"
                                className="custom-control-input"
                                id="webChecksass"
                                name="example1"
                                checked={this.state.sasChecked}
                                onChange={(e: any) => this.enableSAS(e)}
                              />
                              <label
                                className="custom-control-label check fontw500"
                                style={{
                                  fontSize: "12px",
                                  color: "#aaaaaa",
                                  left: "-10px",
                                }}
                                htmlFor="webChecksass"
                              >
                                Enable SAS
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12" style={{ overflowY: "auto", height: "122px" }}>
                          {this.state.sasList?.map((sas: any, index: number) => (
                            <div className="col-md-3 d-inline-block p-l-0 p-r-10" key={`${index.toString()}-saslist`}>
                              <button
                                type="button"
                                onClick={(e) => {
                                  this.setProvider(e, sas.providerId);
                                }}
                                className={`settingbtn m-t-10 ${sas.providerId === this.state.selectedProviderId ? "settingbtn-active" : ""}`}
                                data-toggle="button"
                                disabled={this.state.dpChecked || (sas.providerName != "google" && sas.providerName != "federated")}
                              >
                                <img alt="sasprovider" className={` ${sas.providerName === "commscope" ? "h15" : "h23"}`} src={sas.providerImage} />
                              </button>
                            </div>
                          ))}
                        </div>

                        <div className="col-md-4 m-t-10">
                          <span className=" fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                            SELECT AERIAL VENDOR
                          </span>
                        </div>
                        <div className="col-md-4 m-t-10">
                          <select
                            className="form-control settingdropdown buildinfo"
                            name="apVendor"
                            value={this.state.selectedAerialVendorId}
                            onChange={(e) => this.setAerialVendor(e)}
                          >
                            <option value="Select" hidden>
                              Please select one
                            </option>
                            {this.state.aerialVendorList?.map((vendor: any) => (
                              <option key={`${vendor["name"]}-aerial`} value={vendor["vendorModalId"]}>
                                {vendor["name"]}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-4 m-t-10">
                          <span className=" fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                            SELECT SMB VENDOR
                          </span>
                        </div>
                        <div className="col-md-4 m-t-10">
                          <select
                            className="form-control settingdropdown buildinfo"
                            name="apVendor"
                            value={this.state.selectedSMBVendorId}
                            onChange={(e) => this.setSMBVendor(e)}
                          >
                            <option value="Select" hidden>
                              Please select one
                            </option>
                            {this.state.smbVendorList?.map((vendor: any) => (
                              <option key={`${vendor["name"]}-smb`} value={vendor["vendorModalId"]}>
                                {vendor["name"]}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-md-4 m-t-10">
                          <span className=" fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                            SELECT ROE VENDOR
                          </span>
                        </div>
                        <div className="col-md-4 m-t-10">
                          <select
                            className="form-control settingdropdown buildinfo"
                            name="apVendor"
                            value={this.state.selectedROEVendorId}
                            onChange={(e) => this.setROEVendor(e)}
                          >
                            <option value="Select" hidden>
                              Please select one
                            </option>
                            {this.state.roeVendorList?.map((vendor: any) => (
                              <option key={`${vendor["name"]}-roe`} value={vendor["vendorModalId"]}>
                                {vendor["name"]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-l-10">
                    <div className="card settingcard">
                      <div className="card-body" style={{ overflowY: "auto" }}>
                        <div className="col-md-12">
                          <span className="searchcontent">PROFILE INFO</span>
                        </div>
                        <div className="col-md-12 m-t-20">
                          <div className="col-md-6 d-inline-block">
                            <Dropzone onDrop={this.onDrop}>
                              {({ getRootProps, getInputProps }) => (
                                <section className=" m-t-10">
                                  <div {...getRootProps({ className: "" })}>
                                    <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />

                                    <i
                                      className="fas fa-pen editavatar"
                                      id="userProfile"
                                      onClick={this.showModal}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          this.showModal(e);
                                        }
                                      }}
                                    ></i>
                                  </div>
                                  <div></div>
                                </section>
                              )}
                            </Dropzone>

                            <img
                              src={this.state.userImage}
                              alt="Avatar"
                              onError={(e: any) => {
                                this.setErrorImage(e);
                              }}
                              className="settingavatar"
                            />
                          </div>
                          <div className="col-md-6 d-inline-block" style={{ width: "120px" }}>
                            <QRCode id="qr-code" value={`${window.location.origin}/api`} level={"M"} />
                          </div>
                        </div>

                        <div className="row m-0">
                          <div className="col-md-6 m-t-15">
                            <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                              CPI NAME
                            </span>
                          </div>
                          <div className="col-md-6 m-t-15">
                            <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                              USERNAME
                            </span>
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-6 m-t-10">
                            <input
                              autoComplete="off"
                              type="text"
                              name="cpiName"
                              ref={(ref) => {
                                this.apFocuscpiName = ref;
                                return true;
                              }}
                              value={this.state.cpiName}
                              onChange={this.onChange.bind(this)}
                              className="settinginput"
                              placeholder="CPI name"
                            />
                          </div>
                          <div className="col-md-6 m-t-10">
                            <input
                              autoComplete="off"
                              type="text"
                              name="login-userName"
                              value={this.state.userList["userName"]}
                              onChange={this.onChange.bind(this)}
                              className="settinginput"
                              placeholder="Username"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-6 m-t-20">
                            <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                              EMAIL
                            </span>
                          </div>
                          <div className="col-md-6 m-t-20">
                            <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                              CPI ID
                            </span>
                          </div>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-6 m-t-10">
                            <input
                              autoComplete="off"
                              type="email"
                              name="userEmail"
                              value={this.state.userEmail}
                              onChange={this.onChange.bind(this)}
                              className="settinginput"
                              placeholder="Email"
                            />
                          </div>
                          <div className="col-md-6 m-t-10">
                            <input
                              autoComplete="new-password"
                              ref={(ref) => {
                                this.apFocuscpiId = ref;
                                return true;
                              }}
                              type="text"
                              name="cpiId"
                              value={this.state.cpiId}
                              onChange={this.onChange.bind(this)}
                              className="settinginput"
                              placeholder="CPI ID"
                            />
                          </div>
                        </div>
                        {this.state.userList["UserRole"] == "Installer" ? null : (
                          <div>
                            <div className="row m-0">
                              <div className="col-md-6 m-t-20">
                                <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                                  CPI CERTIFICATE PASSWORD
                                </span>
                              </div>
                              <div className="col-md-6 m-t-20">
                                <span className="fontw500" style={{ fontSize: "12px", color: "#aaaaaa" }}>
                                  CPI CERTIFICATE
                                </span>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-md-6 m-t-10">
                                <input
                                  autoComplete="new-password"
                                  type="password"
                                  name="certPassword"
                                  value={this.state.certPassword}
                                  onChange={this.onChange.bind(this)}
                                  className="settinginput"
                                  placeholder="Password"
                                />
                              </div>
                              <div className="col-md-6 m-t-10" style={{ height: "auto" }}>
                                <Dropzone
                                  onDrop={(e: any) => {
                                    this.onDrop2(e, "p12");
                                  }}
                                  disabled={this.state.field_disable || this.state.override_cpi}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section className="dropzone-set" style={{ height: "auto" }}>
                                      <div
                                        {...getRootProps({
                                          className: "dropzone",
                                        })}
                                      >
                                        <input {...getInputProps()} accept=".p12" />

                                        {this.getCpiFileHtml(files1)}
                                      </div>
                                      <div></div>
                                    </section>
                                  )}
                                </Dropzone>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="row m-0">
                          <div className="col-md-6 m-t-20">
                            <button className="btn cardbtn" style={{ width: "100%" }} id="changepwd" onClick={this.showModal}>
                              Update Password
                            </button>
                          </div>
                          <div className="col-md-6 m-t-20 text-right">
                            <button className="btn cardbtn" style={{ width: "100%" }} onClick={this.updateUserProfileChanges}>
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobonly">
          <div className="container-fluid settingsdash p-0">
            <div className="col-12 m-t-20">
              <div className="form-label-group m-0">
                <input
                  autoComplete="off"
                  type="text"
                  style={{ height: "59px" }}
                  className="accesspointname"
                  id="usersettingsmob"
                  value={this.state.selectedUserId}
                  placeholder="User Id"
                  onChange={(e) => this.setUserId(e)}
                />
                <label className="forgot" htmlFor="usersettingsmob">
                  User Id*
                </label>
              </div>
            </div>
            <div className="row m-t-20" style={{ padding: "0px 15px" }}>
              <div className="col-6">
                <span className="mobset fontw500">SELECT SAS</span>
              </div>
              <div className="col-6 text-right">
                <div className="custom-control custom-checkbox mobsettings d-inline-block float-right">
                  <input
                    autoComplete="off"
                    type="checkbox"
                    className="custom-control-input"
                    style={{ margin: "5px" }}
                    id="customChecksass"
                    name="example1"
                    checked={this.state.sasChecked}
                    onChange={(e: any) => this.enableSAS(e)}
                  />
                  <label className="custom-control-label check" style={{ left: "-16px" }} htmlFor="customChecksass">
                    Enable SAS
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 m-t-10">
              {this.state.sasList?.map((sas: any, index: any) => (
                <div
                  className={`col-md-3 col-6 d-inline-block ${index % 2 === 0 ? " p-l-0 p-r-5" : "p-l-5 p-r-0"}`}
                  key={`${index.toString()}-provider-image`}
                >
                  <button
                    type="button"
                    value={sas.providerId}
                    onClick={(e) => {
                      this.setProvider(e, sas.providerId);
                    }}
                    className={`settingbtn m-t-10 ${sas.providerId === this.state.selectedProviderId ? "settingbtn-active" : ""}`}
                    data-toggle="button"
                    disabled={this.state.dpChecked || (sas.providerName != "google" && sas.providerName != "federated")}
                  >
                    <img alt="sas-provider" className={` ${sas.providerName === "commscope" ? "h18" : "h23"}`} src={sas.providerImage} />
                  </button>
                </div>
              ))}
            </div>

            <div className="row m-t-24 d-none" style={{ padding: "0px 29px" }}>
              <div className="col-6 p-0">
                <span className="mobset fontw500">SELECT DOMAIN PROXY</span>
              </div>
              <div className="col-6 text-right p-l-0">
                <div className="custom-control custom-checkbox mobsettings d-inline-block float-right p-l-0">
                  <input
                    autoComplete="off"
                    type="checkbox"
                    className="custom-control-input"
                    style={{ margin: "5px" }}
                    id="customCheckmob"
                    name="example2"
                    checked={this.state.dpChecked}
                    onChange={(e) => this.enableDP(e)}
                  />
                  <label className="custom-control-label check " htmlFor="customCheckmob">
                    Enable Domain Proxy
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 m-t-10 d-none">
              <select
                className="form-control-mob settingdropdown buildinfo"
                style={{ height: "59px" }}
                value={this.state.selectedProviderId}
                onChange={(e) => {
                  this.setProvider(e, 0);
                }}
                disabled={this.state.sasChecked}
              >
                <option value="Select" hidden>
                  Please select one
                </option>
                {this.state.domainProxyList?.map((proxy: any) => (
                  <option key={`${proxy["providerName"]}-provider`} value={proxy["providerId"]}>
                    {proxy["providerName"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 m-t-24">
              <span className="mobset fontw500">SELECT AERIAL VENDOR</span>
            </div>
            <div className="col-12 m-t-10">
              <select
                className={`form-control-mob settingdropdown buildinfo ${this.state.selectedAerialVendorId !== 0 ? "settingbtn-active" : ""}`}
                style={{ height: "59px" }}
                value={this.state.selectedAerialVendorId}
                onChange={(e) => this.setAerialVendor(e)}
              >
                <option value="css" hidden>
                  Please select one
                </option>

                {this.state.aerialVendorList?.map((vendor: any) => (
                  <option key={`${vendor["name"]}-modal`} value={vendor["vendorModalId"]}>
                    {vendor["name"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 m-t-24">
              <span className="mobset fontw500">SELECT SMB VENDOR</span>
            </div>
            <div className="col-12 m-t-10">
              <select
                className={`form-control-mob settingdropdown buildinfo ${this.state.selectedSMBVendorId !== 0 ? "settingbtn-active" : ""}`}
                style={{ height: "59px" }}
                value={this.state.selectedSMBVendorId}
                onChange={(e) => this.setSMBVendor(e)}
              >
                <option value="css" hidden>
                  Please select one
                </option>

                {this.state.smbVendorList?.map((vendor: any) => (
                  <option key={`${vendor["name"]}-model-id`} value={vendor["vendorModalId"]}>
                    {vendor["name"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 m-t-24">
              <span className="mobset fontw500">SELECT ROE VENDOR</span>
            </div>
            <div className="col-12 m-t-10">
              <select
                className={`form-control-mob settingdropdown buildinfo ${this.state.selectedROEVendorId !== 0 ? "settingbtn-active" : ""}`}
                style={{ height: "59px" }}
                value={this.state.selectedROEVendorId}
                onChange={(e) => this.setROEVendor(e)}
              >
                <option value="css" hidden>
                  Please select one
                </option>

                {this.state.roeVendorList?.map((vendor: any) => (
                  <option key={`${vendor["name"]}-name-modal-id`} value={vendor["vendorModalId"]}>
                    {vendor["name"]}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-12 m-t-24 d-none">
              <span className="mobset fontw500">SELECT CPE VENDOR</span>
            </div>
            <div className="col-12 m-t-10 d-none">
              <select
                className={`form-control-mob settingdropdown buildinfo ${this.state.selectedCPEVendorId !== 0 ? "settingbtn-active" : ""}`}
                style={{ height: "59px" }}
                value={this.state.selectedCPEVendorId}
                name="cpeVendor"
                onChange={(e) => this.setCPEVendor(e)}
              >
                <option value="" hidden>
                  Please select one
                </option>

                {this.state.cpeVendorList?.map((vendor: any) => (
                  <option key={`${vendor["name"]}-cpe`} value={vendor["vendorModalId"]}>
                    {vendor["name"]}
                  </option>
                ))}
              </select>
            </div>

            <div
              className="searchborder m-t-20"
              style={{
                margin: "10px 15px",
                borderBottom: "1.5px solid #E3E3E3",
              }}
            ></div>

            <div className="col-12 m-t-24">
              <button className="btn cardbtn" onClick={this.saveupdateSettings}>
                Save
              </button>
            </div>

            <div
              className="col-12 m-t-24"
              onClick={this.showOverlay}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.showOverlay();
                }
              }}
            >
              <div className="col-6 p-l-0 p-r-5 d-inline-block">
                <span className="font16">Profile </span>
              </div>

              <div className="col-6 p-r-0 p-l-5 d-inline-block">
                <img
                  alt="checkright"
                  src={Checkright}
                  style={{
                    float: "right",
                    filter: "brightness(0.3)",
                    width: "8px",
                  }}
                ></img>
              </div>
            </div>
            <div
              className="col-12 m-t-24"
              onClick={() => {
                this.deleteCookies();
                this.props.history.replace("/home");
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  this.deleteCookies();
                  this.props.history.replace("/home");
                }
              }}
            >
              <div className="col-6 p-l-0 p-r-5 d-inline-block">
                <span className="font16">Logout </span>
              </div>
              <div className="col-6 p-r-0 p-l-5 d-inline-block">
                <img alt="logout" className="float-right" style={{ width: "15px" }} src={Logout} />
              </div>
            </div>
            <div className="login-info m-t-10" style={{ fontSize: "11px" }}>
              <span>{this.state.version}</span>
            </div>
          </div>

          <div
            className="fixed-top"
            id="overlay"
            style={{
              height: "100%",
              display: this.state.show_overlay === true ? "block" : "none",
            }}
          >
            {this.state.show_overlay ? <Profile hideOverlay={this.hideOverlay} /> : null}
          </div>

          <IonAlert isOpen={this.state.alertbox} onDidDismiss={this.setShowAlert} message={this.state.warningmessage} buttons={["OK"]} />
          <IonLoading isOpen={this.state.is_loader} onDidDismiss={() => this.setShowLoading(false)} message={"Loading..."} duration={5000} />
        </div>
      </div>
    );
  }
}

export default withRouter(Settings);
