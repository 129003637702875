import { Plugins } from "@capacitor/core";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { IonAlert, IonBadge, IonContent, IonPage, withIonLifeCycle } from "@ionic/react";
import React, { Component } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";
import White from "../assets/img/SpectraWhite.png";
import Logout from "../assets/img/logout.svg";
import logoname from "../assets/img/spectra_hash.png";
import user from "../assets/img/userpic.png";
import ConfigJSON from "../config/config.json";
import { CURRENT_TAB, UPDATE_FIELD_AUTH } from "../constants/actionTypes";
import { requestAPICall } from "../service/apiHandler";
import store from "../store";
import CSettings from "./CompanySettings";
import Analytics from "./analytics";
import "./header.css";
import Import from "./import";
import Indoor from "./indoor";
import Modal from "./modal";
import NetworkActivity from "./network-activity";
import Outdoor from "./outdoor";
import Settings from "./settings";
import Claims from "../service/claims";

const { Device } = Plugins;

interface Params {
  topicId: string;
}

let INITIAL_STATE = {
  tabState: "outdoor-tab",
  company: false,
  show: false,
  modalName: "",
  alertbox: false,
  warnMessage: "Error occured.",
  accessToken: "",
  remember_me: "",
  notificationList: [],
  rejectedNotificationCount: 0,
  pendingNotificationCount: 0,
  notificationalert: false,
};

const mapStateToProps = (state: any) => ({ ...state.auth });

const mapDispatchToProps = (dispatch: any) => ({
  upadateUserPermissions: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "permissions", value }),
  upadateUserRole: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "role", value }),
  upadateTabState: (value: any) => dispatch({ type: CURRENT_TAB, key: "tab", value }),
  clickNotify: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "notification", value }),
  upadateUserCpiId: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "cpiId", value }),
  upadateUserCpiName: (value: any) => dispatch({ type: UPDATE_FIELD_AUTH, key: "cpiName", value }),
  upadatePendingNotificationCount: (value: any) =>
    dispatch({
      type: UPDATE_FIELD_AUTH,
      key: "pendingNotificationCount",
      value,
    }),
  upadateRejectedNotificationCount: (value: any) =>
    dispatch({
      type: UPDATE_FIELD_AUTH,
      key: "rejectedNotificationCount",
      value,
    }),
});

let role: any = "";
let urltab: any = "";
let rejectedNotificationCount: number = 0;
let pendingNotificationCount: number = 0;

class Header extends Component {
  state: any = {};
  props: any = {};
  timer: any;
  notificationCountInterval: any = {};

  majorRoles: string[] = ["MasterAdmin", "RegionalAdmin", "AdminManager", "Engineer", "CPI"];
  adminRoles: string[] = ["MasterAdmin", "RegionalAdmin", "AdminManager", "Engineer"];

  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.changeTab = this.changeTab.bind(this);
    this.deleteCookies = this.deleteCookies.bind(this);
    this.clickNotification = this.clickNotification.bind(this);
    this.getVersionDetails = this.getVersionDetails.bind(this);
    this.timer = null;
    defineCustomElements(window);
  }

  componentDidMount() {
    this.startRefreshingToken();
    this.getCookies();

    Device.getInfo().then((info) => {
      if (info["platform"] !== "web") {
        this.getVersionDetails();
      }
    });
    this.refreshController();
    urltab = this.props.location.pathname.split("/")[2] + "-tab";
    this.props.upadatePendingNotificationCount(0);
    this.props.upadateRejectedNotificationCount(0);
    this.checkNetworkStatus();
    role = store.getState()["auth"]["role"];

    setInterval(() => this.startRefreshingToken(), 3000000);
    this.setState({
      notitimer: this.notificationCountInterval,
    });
  }
  componentDidUpdate() {
    role = store.getState()["auth"]["role"];
  }
  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.notificationCountInterval);
    clearInterval(this.state.notitimer);
    this.notificationCountInterval = {};
  }

  getVersionDetails() {
    requestAPICall("version", {}, { env: ConfigJSON["env"] }).then((response: any) => {
      if (response.status == 200) {
        const currentVersion = ConfigJSON["version"];
        const latestVersion = response.data["version"].toString();
        if (currentVersion !== latestVersion) {
          this.props.history.replace("/update");
        }
      }
    });
  }

  checkNetworkStatus = async () => {};

  clickNotification() {
    let userRole = Claims.getUserRole();
    if (["RegionalAdmin", "MasterAdmin", "Engineer", "CPI"].includes(userRole)) {
      this.props.clickNotify(true);
      let url = this.props.match.url + "/network-activity";
      this.props.history.push(url);
    }
  }

  refreshController = () => {
    this.timer = setInterval(() => this.startRefreshingToken(), 3000000);
  };
  startRefreshingToken = () => {
    this.setState(
      {
        accessToken: Claims.getAccessToken(),
      },
      () => {
        let requestBody = {
          username: Claims.getUsername(),
          access_token: Claims.getAccessToken(),
        };

        requestAPICall("refresh_token", {}, requestBody).then((data) => {
          let date = new Date();
          date.setTime(date.getTime() + 3600000);
          localStorage.setItem("idToken", data.idToken);
        });
      }
    );
  };

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
    if (st === false) {
      this.deleteCookies();
    }
  };

  getCookies = () => {
    if ([undefined, null, "undefined", "null"].includes(localStorage.getItem("idToken"))) {
      this.props.history.push("/home");
    } else {
      this.setState(
        {
          role: Claims.getUserRole(),
        },
        () => {
          this.props.upadateUserRole(this.state.role);
          role = store.getState()["auth"]["role"];
        }
      );
    }
  };
  notificationalert = (st: any) => {
    this.setState({ notificationAlert: st });
  };
  moveToNotification() {
    this.props.history.push("/notification-mob");
  }

  deleteCookies() {
    clearInterval(this.notificationCountInterval);
    this.setState({
      role: "",
    });
    localStorage.removeItem("CPE_Outdoor_Data");
    localStorage.removeItem("CPE_Indoor_Data");
    localStorage.removeItem("AP_Outdoor_Data");
    localStorage.removeItem("AP_Indoor_Data");
    localStorage.removeItem("DAS_Data");
    localStorage.removeItem("floorAddProperty");
    localStorage.removeItem("buildingData1AddProperty");
    localStorage.removeItem("outdoor_map_center_lat");
    localStorage.removeItem("outdoor_map_center_lng");
    localStorage.removeItem("indoor_map_center_lat");
    localStorage.removeItem("indoor_map_center_lng");
    localStorage.removeItem("browser");
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("logintime");
    localStorage.removeItem("username");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("userRole");
    localStorage.removeItem("permissionId");
    localStorage.removeItem("rawAssetList");
    this.props.upadateUserPermissions([]);
    this.props.upadateUserRole([]);
    this.props.history.push("/home");
  }

  urlState = (state: any) => {
    urltab = state;
    this.props.upadateTabState(urltab);
    this.setState({
      tabState: state,
    });
    if ([undefined, null, "undefined", "null"].includes(localStorage.getItem("idToken"))) {
      this.setState({
        alertbox: true,
        warnMessage: "Your session has been expired! Please login again.",
      });
    }
  };

  changeTab = (e: any) => {
    e.preventDefault();
    this.setState({
      tabState: e.target.id,
    });
    this.props.upadateTabState(e.target.id);
    if (isMobile) {
      this.props.history.push("/map/" + e.target.id.split("-")[0] + "/main");
    }
  };
  changeSettingsTab = (e: any) => {
    if (this.state.role == "MasterAdmin" || this.state.role == "CPI") {
      this.setState({
        company: true,
        tabState: e.target.id,
      });
    } else {
      this.setState({
        company: false,
        tabState: e.target.id,
      });
    }
  };

  hideModal = () => {
    this.setState({
      show: false,
      modalName: "",
    });
  };

  decideAnalyticsFromRole = () => {
    if (["RegionalAdmin", "MasterAdmin", "AdminManager", "Engineer"].includes(role)) {
      return (
        <Link
          to={`${this.props.match.url}/company-analytics`}
          style={{ textDecoration: "none" }}
          className={`nav-link ${urltab === "company-analytics-tab" ? "active" : ""}`}
          id="analytics-tab"
          href="#analytics"
          role="tab"
          aria-controls="analytics-tab"
          aria-selected="false"
        >
          ANALYTICS
        </Link>
      );
    } else if (role == "CPI") {
      return (
        <Link
          to={`${this.props.match.url}/analytics`}
          style={{ textDecoration: "none" }}
          className={`nav-link ${urltab === "analytics-tab" ? "active" : ""}`}
          id="analytics-tab"
          href="#analytics"
          role="tab"
          aria-controls="analytics-tab"
          aria-selected="false"
        >
          ANALYTICS
        </Link>
      );
    } else return null;
  };

  render() {
    setTimeout(() => {
      $("#fade-reload").css("display", "");
    }, 1000);

    return (
      <IonPage>
        {isBrowser ? (
          <IonContent className="webonly">
            <div>
              <div className="header fixed-top">
                <div className="row">
                  <div className="col-md-2 col-sm-2">
                    <img alt="spectra-logo" className="header-logo" src={logoname} />
                  </div>
                  <div className="col-md-8 col-sm-8">
                    <ul className="nav nav-tabs tabhash" id="myTab" role="tablist">
                      <li
                        className="nav-item w-120 rm-m-b"
                        onClick={() => {
                          this.urlState("outdoor-tab");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.urlState("outdoor-tab");
                          }
                        }}
                      >
                        <Link
                          to={`${this.props.match.url}/outdoor`}
                          style={{ textDecoration: "none" }}
                          className={`nav-link ${urltab === "outdoor-tab" ? "active" : ""}`}
                          href="#outdoor"
                          id="outdoor-tab"
                          role="tab"
                          aria-controls="outdoor"
                          aria-selected="true"
                        >
                          OUTDOOR
                        </Link>
                      </li>

                      {this.majorRoles.includes(Claims.getUserRole()) ? (
                        <li
                          className="nav-item rm-m-b"
                          onClick={() => {
                            this.urlState("network-activity-tab");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.urlState("network-activity-tab");
                            }
                          }}
                        >
                          <Link
                            to={`${this.props.match.url}/network-activity`}
                            style={{ textDecoration: "none" }}
                            className={`nav-link ${urltab === "network-activity-tab" ? "active" : ""}`}
                            href="#network-activity"
                            id="network-activity-tab"
                            role="tab"
                            aria-controls="network-activity"
                            aria-selected="true"
                          >
                            NETWORK ACTIVITY
                          </Link>
                        </li>
                      ) : null}
                      {this.majorRoles.includes(Claims.getUserRole()) ? (
                        <li
                          className="nav-item d-none w-120 rm-m-b"
                          onClick={() => {
                            this.urlState("analytics-tab");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.urlState("analytics-tab");
                            }
                          }}
                        >
                          {this.decideAnalyticsFromRole()}
                        </li>
                      ) : null}

                      {this.adminRoles.includes(Claims.getUserRole()) ? (
                        <li
                          className="nav-item rm-m-b"
                          onClick={() => {
                            this.urlState("import-tab");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.urlState("import-tab");
                            }
                          }}
                        >
                          <Link
                            to={`${this.props.match.url}/import`}
                            style={{ textDecoration: "none" }}
                            className={`nav-link ${urltab === "import-tab" ? "active" : ""}`}
                            href="#import"
                            role="tab"
                            aria-controls="indoor"
                            id="import-tab"
                            aria-selected="false"
                          >
                            Data / Import
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div
                    className="col-md-2 col-sm-2 tabhash"
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      alignItems: "center",
                      justifyContent: "end",
                      paddingRight: "25px",
                    }}
                  >
                    <div
                      className="settings text-center m-r-10"
                      style={{ padding: "8px" }}
                      onClick={this.deleteCookies}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.deleteCookies();
                        }
                      }}
                    >
                      <img
                        alt="logout"
                        className="float-right"
                        style={{
                          width: "13px",
                          transform: "rotate(180deg)",
                          marginLeft: "2px",
                        }}
                        src={Logout}
                      />
                    </div>
                    {role == "MasterAdmin" || role == "RegionalAdmin" ? (
                      <Link to={`${this.props.match.url}/settings`} style={{ textDecoration: "none" }}>
                        <img
                          src={user}
                          alt="Avatar"
                          style={{
                            cursor: "pointer",
                            margin: "0px 15px 0px 5px",
                          }}
                          className="avatar"
                        />{" "}
                      </Link>
                    ) : null}

                    {this.majorRoles.includes(Claims.getUserRole()) ? (
                      <button className="settings text-center m-r-10" onClick={this.clickNotification}>
                        <IonBadge
                          color="danger"
                          class="custom-badge"
                          style={{
                            position: "absolute",
                            marginLeft: "7px",
                            marginTop: "-2px",
                          }}
                          item-end
                        >
                          {this.state.pendingNotificationCount}
                        </IonBadge>
                        <i className="fas fa-bell cogs"></i>
                      </button>
                    ) : null}

                    <div
                      className="settings text-center m-r-10"
                      onClick={() => {
                        this.urlState("settings-tab");
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.urlState("settings-tab");
                        }
                      }}
                    >
                      {role == "MasterAdmin" || role == "AdminManager" || role == "RegionalAdmin" || role == "Engineer" ? (
                        <Link to={`${this.props.match.url}/company-settings`} style={{ textDecoration: "none" }}>
                          <i
                            className={`fa fa-cog cogs ${urltab === "company-settings-tab" ? "active" : ""}`}
                            id="settings-tab"
                            onClick={this.changeSettingsTab}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.changeSettingsTab(e);
                              }
                            }}
                            style={{ display: "grid" }}
                          ></i>
                        </Link>
                      ) : (
                        <Link to={`${this.props.match.url}/settings`} style={{ textDecoration: "none" }}>
                          <i
                            className={`fa fa-cog cogs ${urltab === "settings-tab" ? "active" : ""}`}
                            id="settings-tab"
                            onClick={this.changeSettingsTab}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.changeSettingsTab(e);
                              }
                            }}
                            style={{ display: "grid" }}
                          ></i>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-content p-t-58">
                <Switch>
                  <Route exact path={this.props.match.path}>
                    <Redirect to={`${this.props.match.url}/outdoor`} />
                  </Route>
                  <Route path={`${this.props.match.path}/:topicId`}>
                    <Tabs />
                  </Route>
                </Switch>
              </div>
            </div>
          </IonContent>
        ) : (
          <IonContent className="mobonly">
            <div id="fade-reload">
              <div className="header fixed-top">
                <div
                  className="row"
                  style={{
                    background: "#0159b2",
                    paddingTop: "env(safe-area-inset-top)",
                  }}
                >
                  <div className="col-6" style={{ paddingLeft: "22px" }}>
                    <img alt="white-header" className="header-newlg" src={White} />
                  </div>
                  <div className="col-6" style={{ padding: "15px 15px 15px 15px" }}>
                    <div className="row" style={{ float: "right" }}>
                      <div
                        style={{ cursor: "pointer" }}
                        className={Claims.getUserRole() == "Installer" ? "col-3" : "col-2"}
                        onClick={() => {
                          this.props.history.push("/notification-mob");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.props.history.push("/notification-mob");
                          }
                        }}
                      >
                        <IonBadge
                          color="danger"
                          class="custom-badge"
                          style={{
                            position: "absolute",
                            marginLeft: "7px",
                            marginTop: "-2px",
                          }}
                          item-end
                        >
                          {this.state.rejectedNotificationCount}
                        </IonBadge>
                        <i className="fas fa-bell" style={{ color: "#fff" }}></i>
                      </div>

                      {Claims.getUserRole() == "Installer" ? null : (
                        <div
                          style={{ cursor: "pointer" }}
                          className="col-2"
                          onClick={() => {
                            this.props.history.push("/calculator");
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.props.history.push("/calculator");
                            }
                          }}
                        >
                          <i className="fa fa-calculator" style={{ color: "#fff" }}></i>
                        </div>
                      )}

                      <div
                        className={Claims.getUserRole() == "Installer" ? "col-3" : "col-2"}
                        onClick={() => {
                          localStorage.removeItem("backButton");
                          localStorage.removeItem("appState");
                          window.location.reload();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            localStorage.removeItem("backButton");
                            localStorage.removeItem("appState");
                            window.location.reload();
                          }
                        }}
                      >
                        <i className="fa fa-refresh" style={{ cursor: "pointer", color: "#fff" }}></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="nav nav-tabs tabhash" style={{ padding: "0px", fontWeight: "bold" }} id="myTab" role="tablist">
                      <li
                        className="nav-item p-0 w50"
                        id="outdoor"
                        onClick={(e: any) => {
                          this.changeTab(e);
                          this.urlState("outdoor-tab");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeTab(e);
                            this.urlState("outdoor-tab");
                          }
                        }}
                      >
                        <Link
                          to={`map/outdoor/main`}
                          className={`nav-link ${urltab === "outdoor-tab" ? "active" : ""}`}
                          style={{ padding: "0rem" }}
                          id="outdoor-tab"
                          data-toggle="tab"
                          href="#outdoor"
                          role="tab"
                          aria-controls="outdoor"
                          aria-selected="true"
                        >
                          OUTDOOR
                        </Link>
                      </li>
                      <div>
                        <div
                          style={{
                            borderRight: "1px solid #C1C8CE80",
                            height: "8px",
                            marginTop: "11px",
                          }}
                        ></div>
                        <div
                          style={{
                            borderRight: "1px solid #C1C8CE80",
                            height: "8px",
                            marginTop: "5px",
                          }}
                        ></div>
                      </div>
                      <li
                        className="nav-item d-none p-0 w33"
                        onClick={(e: any) => {
                          this.changeTab(e);
                          this.urlState("indoor-tab");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeTab(e);
                            this.urlState("indoor-tab");
                          }
                        }}
                      >
                        <Link
                          to={`map/indoor/main`}
                          className={`nav-link ${urltab === "indoor-tab" ? "active" : ""}`}
                          style={{ padding: "0rem" }}
                          id="indoor-tab"
                          data-toggle="tab"
                          href="#indoor"
                          role="tab"
                          aria-controls="indoor"
                          aria-selected="false"
                        >
                          INDOOR
                        </Link>
                      </li>
                      <div>
                        <div
                          style={{
                            borderRight: "1px solid #C1C8CE80",
                            height: "8px",
                            marginTop: "11px",
                          }}
                        ></div>
                        <div
                          style={{
                            borderRight: "1px solid #C1C8CE80",
                            height: "8px",
                            marginTop: "5px",
                          }}
                        ></div>
                      </div>
                      <li
                        className="nav-item p-0 w50"
                        id="settings"
                        onClick={(e: any) => {
                          this.changeTab(e);
                          this.urlState("settings-tab");
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            this.changeTab(e);
                            this.urlState("settings-tab");
                          }
                        }}
                      >
                        <Link
                          to={`${this.props.match.url}/settings/main`}
                          className={`nav-link ${urltab === "settings-tab" ? "active" : ""}`}
                          style={{ padding: "0rem" }}
                          id="settings-tab"
                          data-toggle="tab"
                          href="#settings"
                          role="tab"
                          aria-controls="settings"
                          aria-selected="false"
                        >
                          SETTINGS
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-content p-t-58" style={{ paddingTop: "calc(env(safe-area-inset-top) + 58px)" }}>
                <Switch>
                  <Route exact path={this.props.match.path}>
                    <Redirect to={`${this.props.match.url}/outdoor/main`} />
                  </Route>
                  <Route path={`${this.props.match.path}/:topicId/:functionality`}>
                    <Tabs />
                  </Route>
                </Switch>
                {/* <Tabs /> */}
              </div>
            </div>
          </IonContent>
        )}
        <Modal show={this.state.modalName == "url"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    URL
                  </h6>
                  <i
                    className="far fa-times-circle modalclose"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </div>

                <div className="row m-t-5 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="form-group">
                    <input type="text" style={{ borderRadius: "6px" }} className="text-user img-center" placeholder="URL" />
                  </div>
                </div>

                <div className="row m-t-5 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="col-6">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal();
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.modalName == "notification"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0" style={{}}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "14px" }}>
                    NOTIFICATIONS
                  </h6>
                  <i
                    className="far fa-times-circle modalclose"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        this.hideModal();
                      }
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  ></i>
                </div>
                <div className=" m-t-20 justify-content-center">
                  <table className="table sitetable">
                    <thead className="sitehead" style={{ background: "none" }}>
                      <tr>
                        <th className="w10 table-bg-hdr-color">Parameter Id</th>

                        <th className="w10 table-bg-hdr-color">Message</th>

                        <th className="w10 table-bg-hdr-color">Action</th>
                      </tr>
                    </thead>

                    {this.state.notificationList?.map((val: any, index: any) => {
                      return (
                        <tbody key={`${index.toString()}-notification`}>
                          <tr className="panel-group analytics" data-toggle="collapse" data-target={"#_" + val.name + index}>
                            <td className="w3">{val.parameterId}</td>
                            <td className="w12">{val.action}</td>
                            <td className="w10">
                              <button className="btn btn">View</button> :
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <IonAlert isOpen={this.state.alertbox} onDidDismiss={() => this.setShowAlert(false)} message={this.state.warnMessage} buttons={["GOT IT"]} />
        <IonAlert
          isOpen={this.state.notificationAlert}
          onDidDismiss={() => this.notificationalert(false)}
          message="Params are rejected by CPI"
          buttons={[
            {
              text: "Action",
              handler: () => {
                this.moveToNotification();
              },
            },
          ]}
        />
      </IonPage>
    );
  }
}

function Tabs() {
  let { topicId } = useParams<Params>();
  urltab = topicId + "-tab";
  return (
    <div>
      {(() => {
        if (topicId === "outdoor") return <Outdoor />;
        if (topicId === "indoor") return <Indoor />;
        if (topicId === "analytics") return <Analytics />;
        if (topicId === "import") return <Import />;
        if (topicId === "settings") return <Settings />;
        if (topicId === "company-settings") return <CSettings />;
        if (topicId === "network-activity") return <NetworkActivity />;
      })()}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(Header));

setTimeout(() => {
  $("#fade-reload").css("display", "");
}, 1000);
